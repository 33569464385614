import React from 'react';
import {Layout} from 'antd';
import { pageVariants } from '../Constants';
import { motion } from "framer-motion";
import CheckoutHeader from '../components/CheckoutHeader';

const {Content} = Layout;

const BlankLayout: React.FC<{children?: React.ReactNode }> = ({children}) => {

  return (
    <motion.div className='fullHeight' initial='initial' animate="in" exit="out" variants={pageVariants}>
      <Layout className="layout" style={{minHeight: '100%'}}>
        <CheckoutHeader center/>
        <Content>
            {children}
        </Content>
      </Layout>
    </motion.div>
  );
}

export default BlankLayout;
