import React from 'react';
import {Layout} from 'antd';
import CellnetLogo from '../elements/Logo/Logo';

const { Header } = Layout;

const CheckoutHeader: React.FC<{center?: boolean}> = ({center = false}) => {
    return (
        <Layout className='headerContainer' style={{minHeight: '64px'}}>
            <Header className='myHeader'>
                <div className={'content-wrapper heightAuto ' + (center ? 'flexCenter':'flexSpaceBetween')}>
                    <div className='flexSpaceBetween'>
                        <CellnetLogo className='maxWidth64px' to='/'/>
                    </div>
                </div>
            </Header>
        </Layout>
    );
}

export default CheckoutHeader;