import { ProductState } from '../Constants';
import { ProductExt } from '../RESTAPI';
import _ from 'lodash';

type Action = {
    payload: any,
    type: string
}

export const ProductReducer = (state: ProductState, action: Action) => {
    switch (action.type) {
        case "SET_PRODUCT":
            return {
                ...state,
                productIdToProduct: action.payload
            }
        case "SET_BRAND":
            return {
                ...state,
                brands: action.payload
            }
        case "SET_HIGHLIGHTED_BRAND":
            return {
                ...state,
                highlightedBrands: action.payload
            }
        case "SET_CATEGORY":
            return {
                ...state,
                categories: action.payload
            }
        case "SET_DEVICE_VENDOR":
            return {
                ...state,
                deviceVendors: action.payload
            }
        case "SET_CLEARANCE":
            return {
                ...state,
                clearance: action.payload
            }
        case "SET_NEW":
            return {
                ...state,
                new: action.payload
            }
        case "SET_SPECIAL":
            return {
                ...state,
                special: action.payload
            }
        case "SET_TOP_SELLER":
            return {
                ...state,
                topSeller: action.payload
            }
        case "SET_FAVOURITE_PRODUCT":
            return {
                ...state,
                favourites: action.payload
            }
        case "ADD_FAVOURITE_PRODUCT":
            const currentFavforAdding = [...state.favourites];
            const newAddedFav = [...new Set([...currentFavforAdding, action.payload])];
            
            return {
                ...state,
                favourites: newAddedFav
            }
        case "REMOVE_FAVOURITE_PRODUCT":
            const currentFavforDeleting = [...state.favourites];
            const newDeletedFav = _.pull(currentFavforDeleting, action.payload);
            return {
                ...state,
                favourites: newDeletedFav
            }
        case "SET_PRODUCT_ID_TO_MIN_ORDER_QTY":
            return {
                ...state,
                productIdToMinOrderQty: action.payload
            } 
        case "SET_PRODUCT_ID_TO_ORDER_MULTIPLE":
            return {
                ...state,
                productIdToOrderMultiple: action.payload
            }  
        case "SET_TABBED_PRODUCTS":
            return {
                ...state,
                tabbedProducts: action.payload
            }                                    
        case "LOADING_STATE":
            return {
                ...state,
                loading: true
            }
        case "FINISH_LOADING_STATE":
            return {
                ...state,
                loading: false
            }
        default:
            return {...state}
    }
}