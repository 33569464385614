import React from 'react';
import { Tabs } from 'antd';
import { Redirect } from 'react-router-dom';
import { formatText } from '../../utils';
import { ProductContext } from '../../contexts/ProductContext';
import { MethodCallback, ProductExt, ProductTabMessage } from '../../RESTAPI';
import { restClient, logout } from '../PrivateRoute/PrivateRoute';
import _ from 'lodash';

const { TabPane } = Tabs;

const ProductTabs: React.FC<{ productId: string | undefined }> = ({productId}) => {
    const [productName, setProductName] = React.useState<string>();
    const [redirectLink, setRedirectLink] = React.useState<string>();
    const [closable, setClosable] = React.useState<boolean>(false);
    const {productState, setTabbedProducts} = React.useContext(ProductContext);

    React.useEffect(() => {
        if (productId && productState && productState.productIdToProduct) {
            let product = productState.productIdToProduct.get(productId);
            if (product) {
                let foundTabbedProduct = _.find(productState.tabbedProducts, (p) => {
                    return p.product.id === productId;
                });
                if (!foundTabbedProduct) {
                    restClient.addProductTab(productId, AddProductTabCallback);
//                    setTabbedProducts([...productState.tabbedProducts, product]);
                }
            }
            setProductName(product?.product.name);
            setRedirectLink(undefined);
        }
    }, [productId, productState.productIdToProduct])

    React.useEffect(() => {
        setClosable(productState.tabbedProducts && productState.tabbedProducts.length > 1 ? true : false);
    }, [productState.tabbedProducts])   
    
    const AddProductTabCallback:MethodCallback<ProductTabMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: ProductTabMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                if(message.error === null) {
                    
                    let res: ProductExt[] = [];
                    if(message.productIds.length > 0) {
                        message.productIds.forEach(productId => {
                          let productExt: ProductExt | undefined = productState.productIdToProduct.get(productId);
                          if (productExt) {
                            res.push(productExt);
                          }
                        })
                    }
                    setTabbedProducts(res);
                }
                
            }
        }
    }  
    
    const DeleteProductTabCallback:MethodCallback<ProductTabMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: ProductTabMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            }
        }
    }      
    
    const onChange = (keyProductName: string) => {
        if (keyProductName !== productName) {
            let foundTabbedProduct = _.find(productState.tabbedProducts, (p) => {
                return p.product.name === keyProductName;
            });
            if (foundTabbedProduct) {
                // let newTabbedProducts = productState.tabbedProducts.filter(obj => {return obj !== foundTabbedProduct});
                // setTabbedProducts(newTabbedProducts);
                setRedirectLink('/shop/' + formatText(keyProductName));
            }
        }
    }

    const onEdit = (e: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, action: "add" | "remove") => {
        if (action === 'remove') {
            let foundTabbedProductIndex = _.findIndex(productState.tabbedProducts, (p) => {
                return p.product.name === e;
            });
            if (foundTabbedProductIndex !== -1) {
                let newTabbedProducts = [...productState.tabbedProducts];
                newTabbedProducts.splice(foundTabbedProductIndex, 1);
                setTabbedProducts(newTabbedProducts);
                restClient.deleteProductTab(productState.tabbedProducts[foundTabbedProductIndex].product.id, DeleteProductTabCallback);                
                if (e === productName) {
                    if (newTabbedProducts.length == 0) {

                    }
                    else if (newTabbedProducts.length == foundTabbedProductIndex) {
                        setRedirectLink('/shop/' + formatText(newTabbedProducts[foundTabbedProductIndex - 1].product.name));
                    }
                    else {
                        setRedirectLink('/shop/' + formatText(newTabbedProducts[foundTabbedProductIndex].product.name));
                    }
                }
            }
        }
    }

    return redirectLink ? (<Redirect to={{ pathname: redirectLink }}/>) : closable ? (
        <div className='product-tabs'>
            <div className='content-wrapper'>
                <Tabs
                    hideAdd
                    onChange={onChange}
                    onEdit={onEdit}
                    activeKey={productName}
                    type={closable ? "editable-card" : "card"}
                >
                    { productState.tabbedProducts.map(tabbedProduct => (
                        <TabPane tab={tabbedProduct.product.name} key={tabbedProduct.product.name} closable={closable}>
                        </TabPane>    
                    ))}
                </Tabs>     
            </div>                
        </div>
    ) : null;
}

export default ProductTabs;