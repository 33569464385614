import React from 'react';
import { Row, Col, Tabs} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import { Link } from 'react-router-dom';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { myAuth } from '../../../elements/PrivateRoute/PrivateRoute';
import { UserContext } from '../../../contexts/UserContext';
import { Order } from '../../../RESTAPI';
import _ from 'lodash';
import OrderList from '../../../components/OrderList';

const { TabPane } = Tabs;

const YourOrder: React.FC = () => {
    const { userState } = React.useContext(UserContext);

    const [orders, setOrders] = React.useState<Array<Order>>([]);
    // const [doneOrders, setDoneOrders] = React.useState<Array<Order>>([]);
    // const [openOrders, setOpenOrders] = React.useState<Array<Order>>([]);

    React.useEffect(() => {
        if(userState.orders) {
            setOrders(userState.orders.orders);
            // setDoneOrders(_.filter(userState.orders.orders, (o) => {
            //     return parseInt(o.lowstatus) >= 77;
            // }));

            // setOpenOrders(_.filter(userState.orders.orders, (o) => {
            //     return parseInt(o.lowstatus) < 77;
            // }));
        }
    }, [userState.orders])
    
    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20} className='marginBottom40px'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h2 className='headerTitle onlyDesktop'>Your Orders</h2>
                        <h2 className='headerTitle onlyMobile textCenter'>Your Orders</h2>
                        <MobileAccountMenu site='Your Orders'/>
                        <div className='orderSection'>
                            {/* <Tabs defaultActiveKey="1" className='onlyDesktop'>
                                <TabPane tab="Orders" key="1" className='orderPane'>
                                    <OrderList orders={doneOrders} isBackOrder={false}/>
                                </TabPane>
                                <TabPane tab="Open Orders" key="2" className='orderPane'>
                                    <OrderList orders={openOrders} isBackOrder={false}/>
                                </TabPane>
                                <TabPane tab="All Orders" key="3" className='orderPane'> */}
                                    <OrderList orders={orders} isBackOrder={false}/>
                                {/* </TabPane>
                            </Tabs> */}
                        </div>

                        <div className='accountSection onlyMobile'>
                            <div className='accountSectionContainer'>
                                {/* <Tabs defaultActiveKey="1">
                                    <TabPane tab="Orders" key="1" className='orderPane'>
                                        <OrderList orders={doneOrders} isBackOrder={false}/>
                                    </TabPane>
                                    <TabPane tab="Open Orders" key="2" className='orderPane'>
                                        <OrderList orders={openOrders} isBackOrder={false}/>
                                    </TabPane>
                                    <TabPane tab="All Orders" key="3" className='orderPane'> */}
                                        <OrderList orders={orders} isBackOrder={false}/>
                                    {/* </TabPane>
                                </Tabs> */}
                            </div>
                            <div className='signoutMobile onlyMobile'>
                                <Link to='/' onClick={(e: React.MouseEvent) => {e.preventDefault(); myAuth.signout()}}><strong>Sign out</strong></Link>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default YourOrder;
