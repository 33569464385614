import React from 'react';
import { Row, Col, Typography } from 'antd';
import MainLayout from '../../layouts/MainLayout';

const { Paragraph, Title } = Typography;

const DeliveryRates: React.FC = () => {
  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '800px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <h2 className='headerTitle textCenter marginTop20px'>Returns, Refunds and Repairs Policy (ACL)</h2>
                <Paragraph>
                          Our goods come with guarantees that cannot be excluded under the Australian Consumer Law (ACL). You are entitled to a replacement or refund for a major failure and for compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the goods repaired or replaced if the goods fail to be of acceptable quality and the failure does not amount to a major failure. In the unlikely event your good is defective you may choose to make a claim under the ACL or, as applicable, the manufacturer's warranty. The manufacturer's warranty operates alongside and in addition to the ACL.
                </Paragraph>
                <Paragraph>
                          Under the ACL, the remedy you are entitled to if a good fails to meet a consumer guarantee depends on whether the failure to comply with the guarantee is a major or minor failure. Major failures cannot ordinarily be fixed or resolved easily. Minor failures can normally be fixed or resolved in a reasonable period of time; a seller can choose to offer you a refund, replacement or repair (or resupply, in respect of services). If a seller does not resolve the failure or takes too long, you are entitled to engage a third party to fix the problem and recover the costs from the seller depending on the circumstances.
                </Paragraph>

                <Title level={5}>Returns &amp; Refunds</Title>
                <Paragraph>Cellnet Group Ltd can refund or replace a returned good if the item:
                <ul>
                    <li>has a major failure;</li>
                    <li>is unsafe;</li>
                    <li>is significantly different from the description, or sample;</li>
                    <li>has a problem that would have stopped you from buying the item if you had known about it;</li>
                    <li>does not do what we said it would, or what you asked for and cannot be easily fixed.<br /> </li>
                </ul>
                </Paragraph>
                <Paragraph>A remedy may not be available (other than at the customer's expense and/or at the discretion of Cellnet Group Limited) where a good has been damaged through misuse, abnormal or negligent use; where a customer changes their mind or discovers the goods are cheaper elsewhere; and where damage or loss occurs independent of Cellnet Group Limited's business.</Paragraph>

                <Title level={5}>Repairs</Title>
                <Paragraph>
                    If a good fails to be of acceptable quality and the failure does not amount to a major failure, Cellnet Group Limited can repair or replace it within a reasonable time. Refurbished parts may be used in the repair. Sometimes, instead of repair, Cellnet Group Limited may replace your faulty item with refurbished goods of the same type. Personal data should always be backed up.
                </Paragraph>

                <Title level={5}>Proof of Purchase</Title>
                <Paragraph>
                    Proof of purchase is required for returned goods; not limited to a tax invoice or receipt. Faulty goods should be returned directly to Cellnet Group Limited (see contact us information).
                </Paragraph>
                <Paragraph>
                    For more information on consumer guarantees, please visit: <a href='https://www.consumerlaw.gov.au' target='_blank' rel='noreferrer'>www.consumerlaw.gov.au</a>
                </Paragraph>

                <h2 className='headerTitle textCenter marginTop20px'>Service Return Authorisation Request Terms and Conditions - May 2023</h2>

                <Title level={5}>Service / Return Authorisations General Terms and Conditions</Title>
                <Paragraph>All prospective returns to Cellnet must have a Service / Return Authorisation Approval letter issued prior to stocks being returned. The issuing of a Service / Return Authorisation number (RA#) is not a guarantee that stock will be approved for credit. Only stock supplied by Cellnet can be processed and items not supplied by Cellnet will not be processed and may not be returned to the reseller. Only pre-approved items and quantities listed on an RA are eligible for return. Extra items/SKUs/QTY's that are physically returned but are not listed on an RA may not receive a credit, may incur an RA processing fee of 15% for those items or be rejected.</Paragraph>
                <Paragraph>All RA's need to be received by Cellnet Returns within 21 days of approval or the RA approval expires. The RA number must be clearly displayed on the outside of each carton being returned. IE; you will need to attach the carton labels from the RA paperwork to the outside of each carton, attach the dangerous goods label supplied to the outside of each carton (required), fill in the carton details for each carton label and place a copy of the RA paperwork inside each carton being returned.</Paragraph>
                <Paragraph>Failure to meet the terms and conditions of a Service / Return Authority will result in it being rejected. All rejected RA's will either be; required to be organised for collection within 30 days in liaison with service@cellnet.com.au or rejected RA's disposed on site at Cellnet's discretion. </Paragraph>
                <Paragraph>Cellnet does not accept liability to loss or damage in transit for any type of stock return.</Paragraph>
                <Paragraph>By proceeding with the Service / Return Authorisation, you agree to the Service / Return Authorisations General Terms and Conditions and the specified additional Terms and Conditions set out under the Service / Return Authorisation type Specific Terms and Conditions.</Paragraph>
                <Title level={5}>Service / Return Authorisation type Specific Terms and Conditions</Title>
                <Paragraph>These conditions are in conjunction with the Service / Return Authorisations General Terms and Conditions.</Paragraph>
                <Paragraph>For delivery discrepancies or stock packaging quality concerns
                <ul><li>Goods should be inspected on the day of delivery and deliveries should be checked to ensure that all cartons and items have been delivered in full as per order and of acceptable re-sellable quality. If there are concerns with your delivery, the Cellnet Service team will need to be contacted within 48-72 hours of delivery via the Service Request function in Cellnet Connect or via service@cellnet.com.au. Details about the delivery, including photos, invoice, delivery number and con note number would need to be provided prior to actioning your claim. Past the 48 hours escalation period, all deliveries made are deemed to be accepted and accurate as per the order.</li></ul></Paragraph>
                <Paragraph>For Good Stock Returns and Stock Rotations
                <ul>
                <li>Good Stock Returns and Aged Stock Rotations, freight must be pre-paid for by the reseller.</li>
                <li>Cellnet does not accept liability to loss or damage in transit for any type of stock return.</li>
                <li>All Good Stock / Aged Rotation Return Authorisations will be subject to a non-negotiable 15% restocking fee.</li>
                <li>Good stock credits are only for eligible items purchased by the reseller in the previous 14 days and/or applied only to active items, not END of LIFE or CLEARANCE item purchases. Any Return Authorisation request that are deemed a rotation and not conforming with these conditions will be rejected.</li>
                <li>Goods returned must be in resaleable condition on arrival with all Manufacturer's labels intact and properly packed to avoid damage. Soiled product IE; packaging with reseller stickers, labels attached, damaged and/or tampered packaging is deemed unsaleable to qualify for a credit and will be deemed rejected.</li>
                </ul></Paragraph>
                <Paragraph>For reseller Faulty &amp; Warranty Credit Requests
                <ul>
                <li>The customer's stated fault must be replicated prior to a claim being raised and be within Australian Consumer Law requirements (ACL), and Vendor / Brand requirements. </li>
                <li>For details on ACL; visit Cellnet Connect or the Australian Government website. </li>
                <li>For details on Vendor/Brand warranty; visit Cellnet Connect or each brands website.</li>
                <li>For reseller Faulty &amp; Warranty credit requests, the customer's proof of purchase must be included to qualify for Manufacturer's Defect on Arrival (DOA) claims. </li>
                <li>Warranty only covers products purchased from Cellnet.</li>
                <li>Cellnet is not responsible for loss of data or physical damage to customers' devices associated with a faulty product.</li>
                </ul></Paragraph>

                <h2 className='headerTitle textCenter marginTop20px'>Aged Stock Rotations and Good Stock Returns Policy - March 2024</h2>
                
                <Title level={5}>For pre-approved Cellnet Accounts</Title>
                <Paragraph>Customers are eligible for a maximum of 1 stock rotation per month. All Good Stock Credit RA Requests are subject to a replacement order requirement and a 15% restocking fee of the Approved / Credited RA Value. Only stocks supplied by Cellnet can be processed. Only items and QTY's specified on an Approved RA will be processed.</Paragraph>
                <Paragraph>Rotation requests apply only to cases and screen protection from specific stock and all 3SixT/Impact Zero branded product that is more than 30 days old and less than 90 days old from re-seller purchase date. Categories that are not cases and screen protection are not eligible for rotation as these are firm sale only (IE: audio, power, bundled packs etc.). Furthermore, items specifically marked in monthly marketing promotions, or items part of discounted bulk buys, or items marked End of Life (EOL) or CLEARANCE are considered non-rotatable.</Paragraph>
                <Paragraph>OEM brands, including but not limited to; Casetify, Lapp Mobility, My First, Poly, Plantronics, Fujifilm, Logitech (UE Boom), Oppo, Samsung (incl Buds) and Sennheiser are not eligible for rotation, these are firm sale only. These brands also cannot be added to the replacement order.</Paragraph>
                <Paragraph>Initiation of Stock Rotations requires a replacement order to be raised first, followed by an Application for Stock Rotation placed via the Cellnet Connect Service RA Request within 48 hours there-after.</Paragraph>
                <Paragraph>The standard replacement order for rotation is 150% or more of the Approved RA value.</Paragraph>
                <Paragraph>All rotation requests will undergo assessment, with an outcome provided within 5 business days.</Paragraph>
                <Paragraph>Special Rotation requests outside the above terms are open for consideration via your Cellnet Account Manager.</Paragraph>
                <Paragraph>All Aged Stock Rotations and Good stock Returns are further governed by the Service / Return Authorisations General Terms and Conditions and the specified additional Terms and Conditions set out under the Service / Return Authorisation type Specific Terms and Conditions.</Paragraph>

                <h2 className='headerTitle textCenter marginTop20px'>Brand Consumer Warranty Guide</h2>
                <Title level={5}>Vendor / Brands Warranty Criteria</Title>
                <Paragraph>For the Brand Consumer Warranty Guide, please download: <a target="_blank" href="https://dam.cellnet.com.au/Connect/service/BrandConsumerWarranty.xlsx">dam.cellnet.com.au/Connect/service/BrandConsumerWarranty.xlsx</a></Paragraph>
            </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default DeliveryRates;
