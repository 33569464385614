import { Button, Drawer, Row, Col, Form, Divider, Typography, Select, Checkbox, message} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { PasswordLength, UserAccountObj, permissionOptions } from '../Constants';
import { UserContext } from '../contexts/UserContext';
import { MethodCallback, ServiceMainRESTClient, UserProfile, UserAccountExt, UsersMessage } from '../RESTAPI';
import _ from 'lodash';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from "moment";

const { Paragraph } = Typography;

const EditUserPermissions: React.FC<{currentUserProfile?: UserProfile, currentUserAccount?: UserAccountExt, visible: boolean, setVisible: (visible: boolean) => void}> = ({currentUserProfile, currentUserAccount, visible = false, setVisible}) => {
    const [checkedList, setCheckedList] = React.useState<Array<any>>([]);
    const [managerid, setManagerid] = React.useState<string>('');

    const [possibleManagers, setPossibleManagers] = React.useState<Array<UserProfile>>([]);    
    const [cancelTime, setCancelTime] = React.useState<number>(0);

    const {userState} = React.useContext(UserContext);

    const {setManagedUserProfiles, setManagedUserAccounts} = React.useContext(UserContext);
    const [accountStatus, setAccountStatus] = React.useState<string>('');

    React.useEffect(() => {
        if(userState.managedUserAccounts && userState.managedUserProfiles && currentUserAccount) {
            setCheckedList(permissionOptions.filter(i => currentUserAccount.permissions.includes(i.value)).map(i => i.value));

            let managerOptions: Array<{label: string, value: string}> = [];
            let possibleManagerAccounts: Array<UserAccountExt> = userState.managedUserAccounts.filter(i => i.permissions.includes('A') && i.accountid === currentUserAccount.accountid);
            let possibleManagerUserIds: Set<string> = new Set<string>(possibleManagerAccounts.map(i => i.userid));
            possibleManagerUserIds.add(currentUserAccount.managerid);
            possibleManagerUserIds.delete(currentUserAccount.userid);
            setPossibleManagers(userState.managedUserProfiles.filter(i => possibleManagerUserIds.has(i.id)));
            setManagerid(currentUserAccount.managerid);
            setAccountStatus(currentUserAccount.status === '20' ? 'Active' : 'Inactive');
        }
    }, [userState.managedUserAccounts, userState.managedUserProfiles, currentUserAccount, cancelTime])    


    const onChangeManager = (list: string) => {
        // console.log(list);
        setManagerid(list);
    }

    const onChangeCheckList = (list: CheckboxValueType[]) => {
        // console.log(list);
        // don't allow both REQUIRE_ORDER_APPROVAL (E) and APPROVE_ORDERS (F)
        if (list.includes('E') && list.includes('F')) {
            list.splice(list.indexOf('E'), 1) ;
        }        
        setCheckedList(list);
    }

    const updateUserAccount:MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
          },
          onProgress(loaded: number, total: number): void {},
          onSuccess(returnMessage: UsersMessage, context?: any): void {
              if(!returnMessage.authenticated) {
                  logout();
              } else {
      
                  message.loading({ content: 'Updating...', key: 'updatable' });
      
                  if(returnMessage.error) {
                      setTimeout(() => {
                          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                      }, 200);
                  } else {
                      setTimeout(() => {
                          message.success({ content: 'Updated user!', key: 'updatable', duration: 2 });
                          context.setManagedUserAccounts(returnMessage.managedUserAccounts);
                          context.setManagedUserProfiles(returnMessage.managedUserProfiles);
                          setCancelTime(cancelTime + 1);
                          setVisible(false);
                      }, 200);
                  }
              }
          }
    }

    const handleSubmit = (values: any) => {
        if (currentUserProfile && currentUserAccount) {
            // console.log(JSON.stringify(managerid));
            // console.log(JSON.stringify(checkedList.join('')));
            restClient.updateUserAccount(currentUserProfile.id, currentUserAccount.accountid, checkedList.join(''), managerid, updateUserAccount, {setManagedUserProfiles, setManagedUserAccounts});
        }
    }

    const handleDeactivateAccount = () => {
        if(currentUserProfile && currentUserAccount) {
            restClient.deactivateUserAccount(currentUserProfile.id, currentUserAccount.accountid, updateUserAccount, {setManagedUserProfiles, setManagedUserAccounts})    
        }
    }    

    const handleReactivateAccount = () => {
        if(currentUserProfile && currentUserAccount) {
            restClient.reactivateUserAccount(currentUserProfile.id, currentUserAccount.accountid, updateUserAccount, {setManagedUserProfiles, setManagedUserAccounts})    
        }
    } 

    return (
        <Drawer
          title={currentUserProfile?.login + " access to " + currentUserAccount?.accountid}
          width='100%'
          height='100vh'
          placement='bottom'
          onClose={() => {setCancelTime(cancelTime + 1); setVisible(false);}}
          visible={visible}
        //   className='gradientBorderBackground bottom170px'
        //   className='editUserPermissionsDrawer'        
        >
            <Form layout="vertical" hideRequiredMark onFinish={(values) => handleSubmit(values)}>
            <div style={{overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100vh - 231px)'}}>
                <div style={{ float: "left"}}>
                    <p className='noMarginBottom text-grayColor'>Status</p>
                    { accountStatus === 'Inactive' &&
                        <Paragraph strong><span style={{color: "red"}}>Inactive</span></Paragraph>
                    }
                    { accountStatus === 'Active' &&
                        <Paragraph strong>Active</Paragraph>
                    }
                </div>
                <div style={{ float: "right"}}>
                    { accountStatus === 'Active' &&
                        <Button onClick={() => handleDeactivateAccount()} type="primary">
                            Deactivate Account
                        </Button>
                    }
                    { accountStatus === 'Inactive' &&
                        <Button onClick={() => handleReactivateAccount()} type="primary">
                            Reactivate Account
                        </Button>
                    }                  
                </div>
                <Divider/>                
                <Form.Item hasFeedback name='Manager'> 
                    <p className='fontSize18px marginTop20px'><strong>Manager</strong></p>
                    <Select size="large" value={managerid} onChange={onChangeManager}> 
                        { possibleManagers.map(i => (
                            <Select.Option value={i.id} label={i.firstname + ' ' + i.lastname}>
                                {i.firstname + ' ' + i.lastname}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className='accessCheckBox'>
                        <p className='fontSize18px marginTop20px'><strong>Access</strong></p>
                        <Checkbox.Group className='flexColumn' options={permissionOptions} value={checkedList} onChange={onChangeCheckList} />
                    </div>
                </Col>
                </Row>
                <div className='footerBtnDrawer form'>
                    <Button htmlType='submit' type="primary">
                        Done
                    </Button>
                    <Button onClick={() => {setCancelTime(cancelTime + 1); setVisible(false);}} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                </div>
            </div>
            </Form>
        </Drawer>
    );
}

export default EditUserPermissions;
