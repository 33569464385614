import React from 'react';
import { Row, Col, Checkbox, Input, Form, Button, message  } from 'antd';
import MainLayoutWithoutFooter from '../../layouts/MainLayoutWithoutFooter';
import { DeliveredProcedureOutlined, MailOutlined, LinkOutlined, CloudDownloadOutlined, FileExcelOutlined, FileDoneOutlined, FileTextOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import { DataFeedColumns, DataFeedDeliveryTime, DataFeedFileFormat, DataFeedMessage, MethodCallback, ServiceMainRESTClient } from '../../RESTAPI';
import { restClient, logout } from '../../elements/PrivateRoute/PrivateRoute';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import _ from 'lodash';

const plainOptions = [
    {value: 'AM11', label: '11:00am'}, 
    {value: 'PM3', label: '3:00pm'}, 
    {value: 'PM7', label: '7:00pm'}
];

const dataOptions = [
    {value: 'ID', label: 'ID'}, 
    {value: 'ProductName', label: 'Product Name'}, 
    {value: 'VendorName', label: 'Vendor Name'}, 
    {value: 'VendorSKU', label: 'Vendor SKU'}, 
    {value: 'Category', label: 'Category'}, 
    {value: 'SubCategory', label: 'Sub Category'}, 
    {value: 'ShortDescription', label: 'Short Description'}, 
    {value: 'LongDescription', label: 'Long Description'}, 
    {value: 'ImageURL', label: 'Image URL'}, 
    {value: 'Image2URL', label: 'Image2 URL'}, 
    {value: 'Image3URL', label: 'Image3 URL'}, 
    {value: 'FullImageURL', label: 'Full Image URL'},
    {value: 'FullImage2URL', label: 'Full Image2 URL'},
    {value: 'FullImage3URL', label: 'Full Image3 URL'},
    {value: 'EAN', label: 'EAN'}, 
    {value: 'Price', label: 'Price'}, 
    {value: 'RRP', label: 'RRP'}, 
    {value: 'ProductWeight', label: 'Product Weight'}, 
    {value: 'ProductVolume', label: 'Product Volume'}, 
    {value: 'ProductLength', label: 'Product Length'}, 
    {value: 'ProductHeight', label: 'Product Height'}, 
    {value: 'ProductWidth', label: 'Product Width'}, 
    {value: 'StockLevel', label: 'Stock Level'}, 
    {value: 'CustomerSKU', label: 'Customer SKU'}, 
    {value: 'Colour', label: 'Colour'}, 
    {value: 'ETA', label: 'ETA'}, 
    {value: 'PriceBreaks', label: 'Price Breaks'}
]

const DataFeed: React.FC = () => {
    const {userState} = React.useContext(UserContext);
    
    const [methodSelect, setMethodSelect] = React.useState<Array<string>>([]);
    const [deliveryTime, setDeliveryTime] = React.useState<Array<any>>([]);
    const [selectedColumns, setSelectedColumns] = React.useState<Array<any>>([]);
    const [typeSelect, setTypeSelect] = React.useState<Array<DataFeedFileFormat>>([]);
    const [dataFeeds, setDataFeeds] = React.useState<DataFeedMessage>(userState.dataFeeds);
    const [isAgree, setIsAgree] = React.useState<boolean>(true);

    const [checkAllDataColumn, setCheckAllDataColumn] = React.useState(false);
    const [indeterminate, setIndeterminate] = React.useState(true);

    React.useEffect(() => {
        if(userState.dataFeeds) {
            setDataFeeds(userState.dataFeeds);
            const methods:Array<string> = [];
            const deliveryTimeArray:Array<DataFeedDeliveryTime> = [];
            const selectedColumnArray:Array<DataFeedColumns> = [];
            let typeSelectArray:Array<DataFeedFileFormat> = [];
            
            if(userState.dataFeeds.deliveryMethodAzure) {
                methods.push('4');
            }

            if(userState.dataFeeds.deliveryMethodEmail) {
                methods.push('2');
            }

            if(userState.dataFeeds.deliveryMethodFTP) {
                methods.push('1');
            }

            if(userState.dataFeeds.deliveryMethodWebLink) {
                methods.push('3');
            }

            setMethodSelect(methods);

            if(userState.dataFeeds.deliveryTimes) {
                userState.dataFeeds.deliveryTimes.forEach(t => {
                    deliveryTimeArray.push(t)
                });
            }

            setDeliveryTime(deliveryTimeArray);

            if(userState.dataFeeds.selectedColumns) {
                userState.dataFeeds.selectedColumns.forEach(t => {
                    selectedColumnArray.push(t)
                });
            }

            setSelectedColumns(selectedColumnArray)

            if(userState.dataFeeds.fileFormat) {
                typeSelectArray = [userState.dataFeeds.fileFormat]
            }

            setTypeSelect(typeSelectArray)
        }
    }, [userState, dataFeeds])

    const handleSelectMethod = (e: React.MouseEvent) => {
        const value = e.currentTarget.getAttribute('data-value');
        //const currentSelect = [...methodSelect];
        if(value) {
            /*if(currentSelect.length === 0) {
                currentSelect.push(value);
                
            } else {
                let index = currentSelect.indexOf(value)
                if(index !== -1) {
                    currentSelect.splice(index, 1);
                } else {
                    currentSelect.push(value);
                }
            }*/

            setMethodSelect([value]);
        }
    }

    const handleAgree = () => {
        setIsAgree(!isAgree);
    }

    const handleSelectFileType = (e: React.MouseEvent) => {
        //let currentSelect = [...typeSelect];
        if (e.currentTarget.getAttribute('data-value')) {
            /*if(currentSelect.length === 0) {
                currentSelect.push(value);
                
            } else {
                let index = currentSelect.indexOf(value)
                if(index !== -1) {
                    currentSelect.splice(index, 1);
                } else {
                    currentSelect.push(value);
                }
            }*/
            const currentSelected = e.currentTarget.getAttribute('data-value');
            let value: DataFeedFileFormat = "CSV";

            switch (currentSelected) {
                case 'CSV':
                    value = 'CSV';
                    break;
                
                case 'TAB':
                    value = 'TAB';
                    break;

                case 'XML':
                    value = 'XML';
                    break;
            
                default:
                    break;
            }
            setTypeSelect([value]);
        }
    }

    const onChangeDeliveryTime = (list: CheckboxValueType[]) => {
        setDeliveryTime(list);
    }

    const onChangeDataColumns = (list: CheckboxValueType[]) => {
        setSelectedColumns(list);
        setIndeterminate(!!list.length && list.length < dataOptions.length);
        setCheckAllDataColumn(list.length === dataOptions.length);
    }

    const FeedCallback:MethodCallback<DataFeedMessage> = {
        onFailure(error: string, context: any): void {
            context.setFinishLoading();
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(returnMessage: DataFeedMessage, context: any): void {
            if(!returnMessage.authenticated) {
                logout();
            } else {
    
                message.loading({ content: 'Updating...', key: 'updatable' });
    
                if(returnMessage.error) {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                } else {
                    setTimeout(() => {
                        message.success({ content: 'Updated data feed!', key: 'updatable', duration: 2 });
                        //context.setManagedUserProfiles(returnMessage.managedUserProfiles);
                    }, 200);
                }
            }
        }
    }

    const handleSubmit = (values: any) => {
        if(isAgree) {
            methodSelect.forEach(method => {
                switch (method) {
                    case '1':
                        restClient.updateDataFeedFTP(values.host, values.user, values.password, values.folder, values.sftp, values.port, deliveryTime, selectedColumns, typeSelect[0], values.fullname, values.phone, values.email, FeedCallback);
                        break;
                    
                    case '2':
                        restClient.updateDataFeedEmail(values.sendEmail, deliveryTime, selectedColumns, typeSelect[0], values.fullname, values.phone, values.email, FeedCallback);
                        break;
    
                    case '3':
                        restClient.updateDataFeedWebLink(values.url, deliveryTime, selectedColumns, typeSelect[0], values.fullname, values.phone, values.email, FeedCallback);
                        break;
    
                    case '4':
                        restClient.updateDataFeedAzure(values.accountName, values.accountKey, values.container, true, deliveryTime, selectedColumns, typeSelect[0], values.fullname, values.phone, values.email, FeedCallback);
                        break;
                
                    default:
                        break;
                }
            });
        } else {
            alert('Please agree to the terms and conditions');
        }
        
    }

    const onCheckAllChange = (e: any) => {
        // extract value of dataOptions to array
        const dataList = _.map(dataOptions, 'value');

        setSelectedColumns(e.target.checked ? dataList : []);
        setIndeterminate(false);
        setCheckAllDataColumn(e.target.checked);
    };

    return (
        <MainLayoutWithoutFooter>
            {
                dataFeeds && <Form scrollToFirstError onFinish={handleSubmit} initialValues={{
                    'fullname': dataFeeds.contactName,
                    'phone': dataFeeds.contactPhone,
                    'email': dataFeeds.contactEmail,
                    'accountKey': dataFeeds.deliveryMethodAzure?.accountKey,
                    'accountName': dataFeeds.deliveryMethodAzure?.accountName,
                    'container': dataFeeds.deliveryMethodAzure?.container,
                    'url': dataFeeds.deliveryMethodWebLink?.url,
                    'sendEmail': dataFeeds.deliveryMethodEmail?.email,
                    'host': dataFeeds.deliveryMethodFTP?.host,
                    'user': dataFeeds.deliveryMethodFTP?.user,
                    'password': dataFeeds.deliveryMethodFTP?.password,
                    'folder': dataFeeds.deliveryMethodFTP?.folder,
                    'sftp': dataFeeds.deliveryMethodFTP?.sftp,
                    'port': dataFeeds.deliveryMethodFTP?.port,
                }}>
                    <div className='content-wrapper marginBottom40px fontSize18px marginTop40px' style={{maxWidth: '500px'}}>
                    <Row>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>Data Feed</h2>
                            <p>The Data Feed is a subscribed service and contains product prices, availability, short descriptions, long descriptions, image URLs, ids, SKUs, EANs and categories.</p>
                        </Col>
                    </Row>

                    <Row className='marginTop25px'>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>Delivery METHOD</h2>
                            <p>Select the method by which you wish to receive your data feed.</p>

                            <div className='flexSpaceBetween'>
                                <div className={'methodOption' + (methodSelect.includes('1') ? ' active': '')} data-value='1' onClick={handleSelectMethod}>
                                    <DeliveredProcedureOutlined />
                                    <p className='noMarginBottom fontSize14px'>FTP</p>
                                </div>
                                <div className={'methodOption' + (methodSelect.includes('2') ? ' active': '')} data-value='2' onClick={handleSelectMethod}>
                                    <MailOutlined />
                                    <p className='noMarginBottom fontSize14px'>Email</p>
                                </div>
                                <div className={'methodOption' + (methodSelect.includes('3') ? ' active': '')} data-value='3' onClick={handleSelectMethod}>
                                    <LinkOutlined />
                                    <p className='noMarginBottom fontSize14px'>Weblink</p>
                                </div>
                                <div className={'methodOption' + (methodSelect.includes('4') ? ' active': '')} data-value='4' onClick={handleSelectMethod}>
                                    <CloudDownloadOutlined />
                                    <p className='noMarginBottom fontSize14px'>Azure</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='marginTop25px'>
                        <Col span={24}>
                            {
                                (methodSelect.includes('1')) && (
                                    <>
                                    <h5>FTP</h5>
                                    
                                    <Form.Item className="group-floating-label" hasFeedback name='sftp' rules={[{ required: true, message: 'Select for SFTP!' }]} valuePropName="checked">
                                        <Checkbox>Secure (SFTP)</Checkbox>
                                    </Form.Item>                                    

                                    <Form.Item className="group-floating-label" hasFeedback name='host' label='' rules={[{ required: true, message: 'Please input your host!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Host' suffix={<label className="floating-label" htmlFor="name">Host</label>}/>
                                    </Form.Item>

                                    <Form.Item className="group-floating-label" hasFeedback name='port' label='' rules={[{ required: true, message: 'Please input your port!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Port' suffix={<label className="floating-label" htmlFor="name">Port</label>}/>
                                    </Form.Item>

                                    <Form.Item className="group-floating-label" hasFeedback name='user' label='' rules={[{ required: true, message: 'Please input your username!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Username' suffix={<label className="floating-label" htmlFor="name">Username</label>}/>
                                    </Form.Item>
            
                                    <Form.Item className="group-floating-label" hasFeedback name='password' label='' rules={[{ required: true, message: 'Please input your password!' }]}>
                                        <Input type='password' size="large" className="has-float-label" placeholder='Password' suffix={<label className="floating-label" htmlFor="name">Password</label>}/>
                                    </Form.Item>

                                    <Form.Item className="group-floating-label" hasFeedback name='folder' label='' rules={[{ required: true, message: 'Please input your folder!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Folder' suffix={<label className="floating-label" htmlFor="name">Folder</label>}/>
                                    </Form.Item>

                                    <hr/>
                                    </>
                                )
                            }
                            {
                                (methodSelect.includes('2')) && (
                                    <>
                                    <h5>Email</h5>            
                                    <Form.Item className="group-floating-label" hasFeedback name='sendEmail' label='' rules={[{ type: 'email', message: 'The input is not a valid email'},{ required: true, message: 'Please input your email!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Email Address' suffix={<label className="floating-label" htmlFor="name">Email Address</label>}/>  
                                    </Form.Item>
                                    <hr/>
                                    </>
                                )
                            }
                            {
                                (methodSelect.includes('3')) && (
                                    <>
                                    <h5>Weblink</h5>
                                    <Form.Item className="group-floating-label" hasFeedback name='url' label='' rules={[{ required: true, message: 'Please input your url!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='URL' suffix={<label className="floating-label" htmlFor="name">URL</label>}/>
                                    </Form.Item>
                                    <hr/>
                                    </>
                                )
                            }
                            {
                                (methodSelect.includes('4')) && (
                                    <>
                                    <h5>Azure</h5>
                                    <Form.Item className="group-floating-label" hasFeedback name='accountKey' label='' rules={[{ required: true, message: 'Please input your account key!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Account Key' suffix={<label className="floating-label" htmlFor="name">Account Key</label>}/>
                                    </Form.Item>
            
                                    <Form.Item className="group-floating-label" hasFeedback name='accountName' label='' rules={[{ required: true, message: 'Please input your account name!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Account Name' suffix={<label className="floating-label" htmlFor="name">Account Name</label>}/>
                                    </Form.Item>
            
                                    <Form.Item className="group-floating-label" hasFeedback name='container' label='' rules={[{ required: true, message: 'Please input your container!' }]}>
                                        <Input size="large" className="has-float-label" placeholder='Container' suffix={<label className="floating-label" htmlFor="name">Container</label>}/>
                                    </Form.Item>
                                    <hr/>
                                    </>
                                )
                            }
                        </Col>
                    </Row>

                    <Row className='marginTop40px'>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>Delivery Time</h2>
                            <p>Select the time(s) of day you would like your Data Feed delivered.</p>

                            <Checkbox.Group className='flexColumn' value={deliveryTime} options={plainOptions} onChange={onChangeDeliveryTime}/>
                        </Col>
                    </Row>

                    <Row className='marginTop25px'>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>Data Columns</h2>
                            <p>Pick the columns you want in your data feed.</p>
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAllDataColumn}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group className='flexColumn' value={selectedColumns} style={{maxHeight: '450px', flexWrap: 'wrap'}} options={dataOptions} onChange={onChangeDataColumns}/>
                        </Col>
                    </Row>

                    <Row className='marginTop25px'>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>File Format</h2>
                            <p>Select the format of the content of your Data Feed.</p>

                            <div className='flexCenter'>
                                <div className={'typeOption' + (typeSelect.includes('CSV') ? ' active': '')} data-value='CSV' onClick={handleSelectFileType}>
                                    <FileExcelOutlined />
                                    <div className='fontSize16px textCenter'>CSV</div>
                                </div>
                                <div className={'typeOption' + (typeSelect.includes('TAB') ? ' active': '')} data-value='TAB' onClick={handleSelectFileType}>
                                    <FileTextOutlined />
                                    <div className='fontSize16px textCenter'>TAB</div>
                                </div>
                                <div className={'typeOption' + (typeSelect.includes('XML') ? ' active': '')} data-value='XML' onClick={handleSelectFileType}>
                                    <FileDoneOutlined />
                                    <div className='fontSize16px textCenter'>XML</div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='marginTop40px'>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>CONTACT DETAILS</h2>
                            <p>Enter the details of how you may be contacted in the event of problems or issues that may arise regarding your Data Feed.</p>
                                <Form.Item className="group-floating-label" hasFeedback name='fullname' label='' rules={[{ required: true, message: 'Please input your name!' }]}>
                                    <Input size="large" className="has-float-label" placeholder='Full Name' suffix={<label className="floating-label" htmlFor="name">Full Name</label>}/>  
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='phone' label='' rules={[{ required: true, message: 'Please input your phone number!' }]}>
                                    <Input size="large" className="has-float-label" placeholder='Phone Number' suffix={<label className="floating-label" htmlFor="name">Phone Number</label>}/>  
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='email' label='' rules={[{ type: 'email', message: 'The input is not a valid email'},{ required: true, message: 'Please input your email!' }]}>
                                    <Input size="large" className="has-float-label" placeholder='Email Address' suffix={<label className="floating-label" htmlFor="name">Email Address</label>}/>  
                                </Form.Item>
                                <div className='textCenter'>
                                    <Button type='primary' htmlType='submit' style={{width: '230px'}}>Subscribe</Button>
                                </div>
                                <Form.Item name="checkbox-group" className='marginTop20px'>
                                    <Row>
                                        <Col span={24}>
                                            <Checkbox checked={isAgree} onChange={handleAgree}>
                                                I agree with the terms and conditions of the data feed.
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Form.Item>                    
                        </Col>
                    </Row>
                    </div>
                </Form>
            }
        </MainLayoutWithoutFooter>
  );
}

export default DataFeed;
