import React from 'react';

const OneTabComponent = () => {

    window.alert("Sorry, Cellnet Connect is a single tab application at this time. Please note products will open in the custom product tabs for your convenience.")
    window.close();
    return (
        <>
        </>
    );
};

export default OneTabComponent;    