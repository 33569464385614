import React from 'react';
import { Row, Col, Image } from 'antd';
import { dateStringFormat, formatCurrency, totalQty } from '../../utils';
import { motion, AnimatePresence } from "framer-motion";
import { DownOutlined } from '@ant-design/icons';
import { Order, OrderLine } from '../../RESTAPI';
import { Link } from 'react-router-dom';
import { imageUrl, orderNoStatus } from '../../Constants';
import { ProductContext } from '../../contexts/ProductContext';
import _ from 'lodash';

const OrderStatusObject: React.FC<{order: Order, orderLine: Array<OrderLine> | undefined}> = ({order, orderLine}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const {productState} = React.useContext(ProductContext);

    return (
        <div className='order'>
            <p className='noMarginBottom text-secondaryColor'><strong>{orderNoStatus[order.lowstatus]}</strong></p>
            <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <p>
                        {dateStringFormat(order.orderdate)} <br/>
                        <Link to={'/your-account/your-orders/' + order.orderno}>
                            <strong>{order.orderno}</strong>
                        </Link><br/>
                        {order.customerref}
                    </p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    Total
                    <p><strong>{formatCurrency(order.baseprice)}</strong><br/><strong>{totalQty(orderLine)}</strong></p>
                </Col>
            </Row>
            <DownOutlined className='animation collapseButton' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />
            <AnimatePresence initial={false}>
                {isOpen && (
                <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    {
                        orderLine !== undefined && orderLine.map((line, key) => {
                            return (
                                <Row gutter={15} key={key} className='marginBottom10px'>
                                    <Col span={6}>
                                        <Image preview={false} src={imageUrl + productState.productIdToProduct.get(line.itemno)?.product.image}/>
                                    </Col>
                                    <Col span={18}>
                                        <p className='noMarginBottom'><strong>{line.itemname}</strong></p>
                                        <p className='noMarginBottom'>{line.itemdesc}</p>
                                        <p className='noMarginBottom text-grayColor'><strong>{line.orderedqty} pc @ {formatCurrency(line.unitprice)}</strong></p>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </motion.section>)}
            </AnimatePresence>
        </div>
    );
}

export default OrderStatusObject;
