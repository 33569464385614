import { Button, Drawer, Row, Col, Form, Input, Select, message} from 'antd';
import React from 'react';
import { UserContext } from '../contexts/UserContext';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';
import { AddressesMessage, MethodCallback } from '../RESTAPI';
import { optionStates } from '../Constants';

const AddNewAddress: React.FC<{visible: boolean, setVisible: (visible: boolean) => void}> = ({visible = false, setVisible}) => {
  const {userState, setAddresses} = React.useContext(UserContext);

  const handleSaveAddress = (values: any) => {
    restClient.addAddress(values.addressName, values.company === undefined ? '' : values.company, values.name, values.address1, values.address2 === undefined ? '' : values.address2, values.suburb, values.city === undefined ? '' : values.city, values.state === undefined ? '' : values.state, values.postcode, AddAddressCallback, {setAddresses});
  }

  const AddAddressCallback: MethodCallback<AddressesMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(returnMessage: AddressesMessage, context?: any): void {
        if(!returnMessage.authenticated) {
            logout();
        } else {

            message.loading({ content: 'Adding address...', key: 'updatable' });

            if(returnMessage.error) {
                setTimeout(() => {
                    message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                }, 200);
            } else {
                setTimeout(() => {
                    message.success({ content: 'Saved address!', key: 'updatable', duration: 2 });
                    context.setAddresses(returnMessage.addresses);
                    setVisible(false);
                }, 200);
            }
        }
    }
  }

    return (
        <Drawer
          title="Add Address"
          width='100%'
          height='100vh'
          placement='bottom'
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Form layout="vertical" hideRequiredMark onFinish={(values) => {handleSaveAddress(values)}}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='addressName' label='' rules={[{required: true, message: 'Please input your address name!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Address Name' suffix={<label className="floating-label" htmlFor="addressName">Address Name</label>}/> 
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='name' label='' rules={[{required: true, message: 'Please input your name!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Name' suffix={<label className="floating-label" htmlFor="name">Name</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='company' label='' rules={[{required: false, message: 'Please input your company!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Company' suffix={<label className="floating-label" htmlFor="company">Company</label>}/> 
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='address1' label='' rules={[{required: true, message: 'Please input your address!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="address1">Address Line 1</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item hasFeedback name='address2' label='' rules={[{required: false, message: 'Please input your address!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="address2">Address Line 2</label>}/> 
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item hasFeedback name='postcode' label='' rules={[{required: true, message: 'Please input your postcode!' }]}> 
                    <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="postcode">Postcode</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item hasFeedback name='suburb' label='' rules={[{required: true, message: 'Please input your suburb!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="suburb">Suburb</label>}/> 
                </Form.Item>
              </Col>
              {
                userState.currentCustomer.country.includes('NZ') ?
                (   
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item hasFeedback name='city' label='' rules={[{required: true, message: 'Please input your city!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="city">City</label>}/> 
                    </Form.Item>
                  </Col>                             
                )
                :
                (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item hasFeedback name='state' label='' rules={[{required: true, message: 'Please input your state!' }]}> 
                        <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
            <div className='footerBtnDrawer form'>
                <Button htmlType='submit' type="primary">
                    Done
                </Button>
                <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
            </div>
          </Form>
        </Drawer>
    );
}

export default AddNewAddress;
