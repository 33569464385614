import React from 'react';
import {Row, Col, Typography} from 'antd';
import WizardForm from '../components/WizardForm';
import {loginSteps, Location} from '../Constants';
import PageLink from '../elements/PageLink/PageLink';
import LoginFooter from '../components/LoginFooter';
import CellnetLogo from '../elements/Logo/Logo';
import HomeBg from '../components/HomeBg';
import { pageVariants } from '../Constants';
import { motion } from "framer-motion";
import {myAuth} from '../elements/PrivateRoute/PrivateRoute';
import { Redirect, useLocation, useHistory  } from 'react-router-dom';
import { ProductContext } from '../contexts/ProductContext';
import { getPrevProp } from '../utils';

const { Title } = Typography;

function Login() {
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [direction, setDirection] = React.useState<number>(1);
  const [isStaff, setIsStaff] = React.useState<boolean>(false);
  const {setLoadingState, setFinishLoading} = React.useContext(ProductContext);

  let history = useHistory();
  let location = useLocation<Location>();
  let prevProp = getPrevProp(location.state)

  const handleSetCurrentStep = (direction: number, values?: any) => {
    if(currentStep === loginSteps.length) {
      // submit 
      if(values) {
        let email = values.email ? values.email : '';
        let password = values.password ? values.password : '';
        let staffVerification = values.staff ? values.staff : '';
        let stayLoggenIn = values.stayLoggedIn ? values.stayLoggedIn : false;

        if(isStaff) {
          if(staffVerification === '') {
            alert('Please input staff verification.')
          } else {
            setLoadingState();
            myAuth.authenticate(email, password, staffVerification, stayLoggenIn, () => {history.push(prevProp)}, () => {setFinishLoading()}, (flag: boolean) => {setIsStaff(flag)});
          }
        } else {
          setLoadingState();
          myAuth.authenticate(email, password, staffVerification, stayLoggenIn, () => {history.push(prevProp)}, () => {setFinishLoading()}, (flag: boolean) => {setIsStaff(flag)});
        }
      }
    } else {
      setCurrentStep(currentStep + direction);
      setDirection(direction);
    }
  }

  // stop user from accessing login page again
  if (myAuth.isAuthenticated) {
    return (
        <Redirect
            to={{
                pathname: prevProp
            }}
        />
    )
  }

  return (
    <Row justify='center' align='middle' className='fullHeight overflowHidden'>
        <HomeBg/>
        <Col xs={24} sm={24} md={8} lg={8} xl={13} className='overflowHidden padding30px fullHeight verticalCenter flexColumn whiteBg'>
          <motion.div className='marginTopAuto' initial='initial' animate="in" exit="out" variants={pageVariants}>
            <CellnetLogo to='/' className="textCenter marginBottom40px"/>
            <Title level={5} className='textCenter marginBottom25px'>Login to your account</Title>
            <WizardForm isStaff={isStaff} currentValues={{}} className='maxWidth300px' finalButton='Login' title='Sign In' steps={loginSteps} currentStep={currentStep} direction={direction} setStep={(direction: number, values?: any) => handleSetCurrentStep(direction, values)}/>
            <div className='textCenter marginTop25px'>
              <PageLink to="/forgot-password" bold className='marginRight10px'>Forgotten Password?</PageLink>
              •
              { window.location.href.includes("connect.cellnet.co.nz") ?
              <a href="https://old.webcell.co.nz/DealerApplication.do?task=entry" target='_blank' rel='noopener noreferrer' className='bold text-primaryColor marginLeft10px'>Sign up as new user?</a>
                :              
              <PageLink to="/register" bold className='marginLeft10px'>Sign up as new user?</PageLink>
              }
            </div>
          </motion.div>

          <LoginFooter className='marginTopAuto'/>
        </Col>
    </Row>
  );
}

export default Login;
