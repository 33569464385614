import React from 'react';
import { Select, Divider, Image, Tag, Button, Drawer, TreeSelect, Checkbox} from 'antd';
import FilterSelect from '../elements/FilterSelect/FilterSelect';
import { blankFilter, FilterObject } from '../Constants';
import { ProductContext } from '../contexts/ProductContext';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { formatText, getImage, removeElementFromArray } from '../utils';

const { Option } = Select;
const { TreeNode } = TreeSelect;

const BrandCompatFilterBar: React.FC<{handleFilterList: (filterList: FilterObject) => void, handleSorting: (value: string) => void}> = ({handleFilterList, handleSorting}) => {
    const [filterList, setFilterList] = React.useState<FilterObject>(blankFilter);
    const [filterDrawer, setFilterDrawer] = React.useState<boolean>(false);
    const [totalFilter, setTotalFilter] = React.useState<Array<string>>([]);
    const [inStockOnly, setInStockOnly] = React.useState<boolean>(false);

    const {productState} = React.useContext(ProductContext);
    const {brandId, catId, subcatIds, vendorId, deviceIds} = useParams<{brandId: string, catId: string, subcatIds: string, vendorId: string, deviceIds: string}>();
    const location = useLocation();
    const history = useHistory();

    const cateChildren = productState.categories.map((cate, key) => {
        return (
            <TreeNode value={cate.name} title={cate.name} key={cate.name} isLeaf={false}>
                {
                    cate.subList?.map((subCate, k) => {
                        return (
                            <TreeNode value={cate.name + ' · ' + subCate.name} title={subCate.name} key={cate.name + ' · ' + subCate.name} isLeaf/>
                        )
                    })
                }
            </TreeNode>
        )
    });

    const deviceChildren = productState.deviceVendors.map((device, key) => {
        return (
            <TreeNode value={device.name} title={device.name} key={device.name} isLeaf={false}>
                {
                    device.subList?.map((subCate, k) => {
                        return (
                            <TreeNode value={device.name + ' · ' + subCate.name} title={subCate.name} key={device.name + ' · ' + subCate.name} isLeaf/>
                        )
                    })
                }
            </TreeNode>
        )
    });
    

    const brandChildren = productState.brands.map((brand, key) => {
        return (
            <Option className='brandSelect' value={brand.name} key={key}>
                <Image preview={false} src={getImage(brand.name, 'brands', '200x200')}/>
            </Option>
        )
    });

    const filterMap = (type: string) => {

      if (type.toLowerCase() === 'brands') {
        return filterList.brands.map((filter, key) => {
          return (
              <Tag closable key={key} onClose={(e) => {e.preventDefault(); handleFilterDelete(filter, type)}}>{filter}</Tag>
          )
      })
      }
      else if (type.toLowerCase() === 'categories') {
        return filterList.categories.map((filter, key) => {
          return (
              <Tag closable key={key} onClose={(e) => {e.preventDefault(); handleFilterDelete(filter, type)}}>{filter}</Tag>
          )
      })
      }
      else if (type.toLowerCase().startsWith('compat')) {
        return filterList.devices.map((filter, key) => {
          return (
              <Tag closable key={key} onClose={(e) => {e.preventDefault(); handleFilterDelete(filter, type)}}>{filter}</Tag>
          )
      })
      }   
    } 

    const handleUpdateFilter = (value: Array<string>, type: string) => {
        let tempList = {...filterList};
        if (type.toLowerCase() === 'brands') {
          tempList.brands = value;
        }
        else if (type.toLowerCase() === 'categories') {
          tempList.categories = value;
        }
        else if (type.toLowerCase().startsWith('compat')) {
          tempList.devices = value;
        }                

        setFilterList(tempList);
    }

    const handleFilterDelete = (filter: string, type: string) => {
        let tempList = {...filterList};

        if (type.toLowerCase() === 'brands') {
          removeElementFromArray(tempList.brands, filter);
        }
        else if (type.toLowerCase() === 'categories') {
          removeElementFromArray(tempList.categories, filter);
        }
        else if (type.toLowerCase().startsWith('compat')) {
          removeElementFromArray(tempList.devices, filter);
        }       
        
        setFilterList(tempList);
    }

    const handleOnClose = () => {
        setFilterDrawer(false);
    }

    const onChangeSorting = (value: any) => {
        handleSorting(value);
    }

    const handleInStockOnlyCheckboxChange = (value: any) => {
      filterList.inStockOnly = !inStockOnly;
      setInStockOnly(!inStockOnly);
      setFilterList({...filterList});
    }

    React.useEffect(() => {
        filterList.categories = [];
        filterList.brands = [];
        filterList.devices = [];        
        if(productState && productState.brands && productState.brands.length > 0 && productState.categories && productState.categories.length > 0 && productState.deviceVendors && productState.deviceVendors.length > 0) {
            if(brandId) {
                const obj = _.find(productState.brands, (o) => {
                    return formatText(o.link) === brandId;
                })

                if(obj) {
                    filterList.brands = [obj.name];
                }
            }
            if (catId) {
                const obj = _.find(productState.categories, (o) => {
                    return formatText(o.link) === catId;
                })

                if(obj) {
                    if(subcatIds) {
                        let subcats: Array<string> = subcatIds.split("_");
                        for (var subcat of subcats) {                        
                            const subList = _.find(obj?.subList, (list) => {
                                return formatText(list.link) === subcat;
                            })

                            if(subList) {
                                filterList.categories.push(obj.name + ' · ' + subList.name);
                            }
                        }
                    }
                    if (filterList.categories.length === 0) {
                        filterList.categories = [obj.name];
                    }
                }
            }
            if (vendorId) {
                const obj = _.find(productState.deviceVendors, (o) => {
                    return formatText(o.link) === vendorId;
                })
                if(obj) {
                    if (deviceIds) {
                        let devices: Array<string> = deviceIds.split("_");
                        for (var device of devices) {
                            const subList = _.find(obj?.subList, (list) => {
                                return formatText(list.link) === device;
                            })
                            if(subList) {
                                filterList.devices.push(obj.name + ' · ' + subList.name);
                            }                            
                        }
                    }
                    if (filterList.devices.length === 0) {
                        filterList.devices = [obj.name];
                    }
                }
            }
        }

        filterList.inStockOnly = inStockOnly;
        
        setFilterList({...filterList});

    }, [brandId, catId, subcatIds, vendorId, deviceIds, location, productState.brands, productState.categories, productState.deviceVendors])

    React.useEffect(() => {
        handleFilterList(filterList);
        
        let blankArray: Array<string> = [];
        filterList.brands.forEach((value) => {
            blankArray.push(value);
        })

        filterList.categories.forEach((value) => {
            blankArray.push(value);
        })

        filterList.devices.forEach((value) => {
            blankArray.push(value);
        })

        setTotalFilter(blankArray);

    }, [filterList])

    return (
        <div className='filterBar'>
            <div className='filter onlyDesktop'>
                <FilterSelect isTreeSelect={false} selected={filterList.brands} virtual={false} dropdownClassName='brandDropdown' placeholder='Brands' handleUpdateFilter={handleUpdateFilter}>
                    {brandChildren}
                </FilterSelect>

                <FilterSelect isTreeSelect virtual={true} selected={filterList.categories} dropdownClassName='filterDropdown' placeholder='Categories' handleUpdateFilter={handleUpdateFilter}>
                    {cateChildren}
                </FilterSelect>

                <FilterSelect isTreeSelect virtual={true} selected={filterList.devices} dropdownClassName='filterDropdown' placeholder='Compatible Devices' handleUpdateFilter={handleUpdateFilter}>
                    {deviceChildren}
                </FilterSelect>
            </div>
            <div className='filter onlyMobile'>
                <FilterSelect isTreeSelect={false} setFilterDrawer={(flag: boolean) => setFilterDrawer(flag)} disableClick selected={totalFilter} virtual={false} dropdownClassName='' placeholder='Filter By' handleUpdateFilter={handleUpdateFilter}/>

                <Drawer
                    title="Filter By"
                    placement="right"
                    closable={true}
                    visible={filterDrawer}
                    className='filterBar filterBarDrawer'
                    onClose={handleOnClose}
                >
                    <div className='filter'>
                        <FilterSelect isTreeSelect={false} selected={filterList.brands} virtual={false} dropdownClassName='brandDropdown' placeholder='Brands' handleUpdateFilter={handleUpdateFilter}>
                                {brandChildren}
                        </FilterSelect>

                        <FilterSelect isTreeSelect virtual={false} selected={filterList.categories} dropdownClassName='filterDropdown' placeholder='Categories' handleUpdateFilter={handleUpdateFilter}>
                            {cateChildren}
                        </FilterSelect>

                        <FilterSelect isTreeSelect virtual={false} selected={filterList.devices} dropdownClassName='filterDropdown' placeholder='Compatible Devices' handleUpdateFilter={handleUpdateFilter}>
                            {deviceChildren}
                        </FilterSelect>
                    </div>
                </Drawer>
            </div>
            <div className='filterSort'>
                <Select placeholder='Sorted By' className='filterSelect' onChange={onChangeSorting}>
                    <Option value="price-asc">Price Ascending</Option>
                    <Option value="price-desc">Price Descending</Option>
                    <Option value="mag-asc">Margin Ascending</Option>
                    <Option value="mag-desc">Margin Descending</Option>
                    <Option value="name-asc">Name Ascending</Option>
                    <Option value="name-desc">Name Descending</Option>
                    <Option value="date-asc">Date Ascending</Option>
                    <Option value="date-desc">Date Descending</Option>
                </Select>
            </div>

            <Divider/>

            <div className='filterDashboard' style={{float: "left"}}>
                {filterMap('brands')}
                {filterMap('categories')}
                {filterMap('compatibility')}
                {
                    (filterList.brands.length > 0 || filterList.categories.length > 0 || filterList.devices.length > 0) && <Button onClick={() => history.push("/shop")} className='clearAllBtn' type="text">
                        Clear All
                    </Button>
                }
            </div>

            <div style={{float: "right"}}><Checkbox checked={inStockOnly} onChange={handleInStockOnlyCheckboxChange}>In Stock</Checkbox></div>
        </div>
    );
}

export default BrandCompatFilterBar;
