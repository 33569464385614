import React from 'react';
import { Row, Col, Tabs, Button} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import { Link, useHistory } from 'react-router-dom';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { myAuth } from '../../../elements/PrivateRoute/PrivateRoute';
import { UserContext } from '../../../contexts/UserContext';
import { ReturnRequest } from '../../../RESTAPI';
import { doneStatus } from '../../../Constants';
import _ from 'lodash';
import ReturnList from '../../../components/ReturnList';

const { TabPane } = Tabs;

const YourReturn: React.FC = () => {
    const {userState} = React.useContext(UserContext);
    const history = useHistory();

    const [incompleteReturns, setIncompleteReturns] = React.useState<Array<ReturnRequest>>([]);
    const [submittedReturns, setSubmittedReturns] = React.useState<Array<ReturnRequest>>([]);

    React.useEffect(() => {
        if(userState.returns) {
            const returnList = userState.returns.returns;

            setIncompleteReturns(_.filter(returnList, (r) => {
                return r.status === 'IN' && r.customerno == userState.currentCustomer.id;
            }));

            setSubmittedReturns(_.filter(returnList, (r) => {
              return r.status !== 'IN' && r.customerno == userState.currentCustomer.id;
            }));

        }
    }, [userState.returns])

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20} className='marginBottom40px'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className='flexSpaceBetween'>
                            <div>
                                <h2 className='headerTitle onlyDesktop'>Service Requests</h2>
                                <h2 className='headerTitle onlyMobile textCenter'>Service Requests</h2>
                            </div>
                            <div className='onlyDesktop'>
                                <Button type='primary' onClick={() => {history.push('/your-account/new-request')}}>Create Request</Button>
                            </div>
                            <div className='onlyMobile'>
                                <Button style={{padding: 0, height: '40px'}} type='link' onClick={() => {history.push('/your-account/new-request')}}>Create Request</Button>
                            </div>
                        </div>
                        <MobileAccountMenu site='Returns'/>
                        <div className='orderSection'>
                            <Tabs defaultActiveKey="1" className='onlyDesktop'>
                                <TabPane tab="Incomplete" key="1" className='orderPane'>
                                    <ReturnList returns={incompleteReturns}/>
                                </TabPane>
                                <TabPane tab="Submitted" key="2" className='orderPane'>
                                    <ReturnList returns={submittedReturns}/>
                                </TabPane>
                            </Tabs>
                        </div>

                        <div className='accountSection onlyMobile'>
                            <div className='accountSectionContainer'>
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="Incomplete" key="1" className='orderPane'>
                                        <ReturnList returns={incompleteReturns}/>
                                    </TabPane>                                  
                                    <TabPane tab="Submitted" key="2" className='orderPane'>
                                        <ReturnList returns={submittedReturns}/>
                                    </TabPane>
                                </Tabs>
                            </div>
                            <div className='signoutMobile onlyMobile'>
                                <Link to='/' onClick={(e: React.MouseEvent) => {e.preventDefault(); myAuth.signout()}}><strong>Sign out</strong></Link>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default YourReturn;
