import React from 'react';
import { Carousel, Image, Button } from 'antd';
import { ConnectBanner } from '../RESTAPI';
import { bannerImageUrl } from '../Constants';
import { useHistory, Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import InnerHTML from 'dangerously-set-html-content'

const HomeSlider: React.FC<{homeContent: string, bannerList: Array<ConnectBanner>}> = ({homeContent = '', bannerList = []}) => {
    const {userState} = React.useContext(UserContext);
    const history = useHistory();
    return (
      <Carousel autoplay swipeToSlide={true} touchMove={true} accessibility={true} draggable={true} className='homeSlider'>
      {
        bannerList && bannerList.map((banner, index) => (
          <>
            {banner.content && (
              <div key={index} className='content-wrapper'>
                <div className='slideItem' style={{backgroundColor: '#' + banner.backgroundcolor}}>
                  <div className='centeredDiv'>
                    <InnerHTML html={banner.content} />
                  </div>
                </div>
              </div>
            )}
            {!banner.content && banner.linkorhref && banner.linkorhref.startsWith('http') && banner.image1170x50 && banner.image375x575 && (
              <div key={index} className='homeSliderContentWrapper'>
                <div style={{width: '100%', backgroundColor: '#' + banner.backgroundcolor}}>
                  <a href={banner.linkorhref} target='_blank' rel='noopener noreferrer'>
                    <div className='fullWidthRow'>
                      <img className="gaplessImg" src={banner.image1170x50} />
                      <img className="gaplessImgMobile" src={banner.image375x575} />
                    </div>
                  </a>
                </div>                
              </div>
            )}
            {!banner.content && banner.linkorhref && !banner.linkorhref.startsWith('http') && banner.image1170x50 && banner.image375x575 && (
              <div key={index} className='homeSliderContentWrapper'>
                <div style={{width: '100%', backgroundColor: '#' + banner.backgroundcolor}}>
                  <Link to={banner.linkorhref}>
                    <div className='fullWidthRow'>
                      <img className="gaplessImg" src={banner.image1170x50} />
                      <img className="gaplessImgMobile" src={banner.image375x575} />
                    </div>
                  </Link>
                </div>                
              </div>
            )}
            {!banner.content && banner.image1170x50 && banner.image375x575 && !banner.linkorhref && (
              <div key={index} className='homeSliderContentWrapper'>
                <div style={{width: '100%', backgroundColor: '#' + banner.backgroundcolor}}>
                  <div className='fullWidthRow'>
                    <img className="gaplessImg" src={banner.image1170x50} />
                    <img className="gaplessImgMobile" src={banner.image375x575} />
                  </div>
                </div>                
              </div>
            )}            
          </>
        ))
      } 
      </Carousel>
    );
}

export default HomeSlider;


