import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'antd';
import cellnetLogo from '../../assets/images/cellnet_logo.svg';
import cellnetLogoWhite from '../../assets/images/cellnet_logo_white.svg';

const CellnetLogo: React.FC<{className?: string, to?: string, white?: boolean}> = ({className = '', to = '', white = false}) => {
    // "textCenter marginBottom40px marginTopAuto"
    return (
        <div className={className}>
            {
                to ? (
                    <Link to={to}>
                        <Image src={white ? cellnetLogoWhite : cellnetLogo} alt='cellnet logo' width='130px' preview={false}/>
                    </Link>
                ) : <Image src={white ? cellnetLogoWhite : cellnetLogo} alt='cellnet logo' width='130px' preview={false}/>
            }
        </div>
    )
}
export default CellnetLogo;
