import React from 'react';
import {Layout} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

const { Sider } = Layout;

const MobileAccount: React.FC<{openAccountMobile: boolean, setOpenAccountMobile: (flag: boolean) => void, content: React.ReactNode}> = ({openAccountMobile = false, setOpenAccountMobile, content}) => {
    return (
        <Sider
            className={'mobileAccount' + (openAccountMobile === false ? '' : ' zIndex2')}
            collapsible collapsed={openAccountMobile === false ? true : false}
            collapsedWidth="0"
            width='100%'
            zeroWidthTriggerStyle={{display: 'none'}}
            >
            <div className='fixedHeader subMenu'>
                <strong>Your Account</strong>
                <CloseOutlined className='closeSearch' onClick={() => setOpenAccountMobile(false)} />
            </div>
            
            {content}
        </Sider>
    );
}

export default MobileAccount;