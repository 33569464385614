import React from 'react';
import { Image, Row, Col, Carousel } from 'antd';
import { BrandDeviceCateObject, priceListDownload, tabletSize } from '../Constants';
import { motion, AnimatePresence } from "framer-motion";
import { formatText, getImage, useWindowSize } from '../utils';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const LogoList: React.FC<{list: Array<BrandDeviceCateObject>, title: string, isExport?: boolean}> = ({list, title, isExport = false}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const size = useWindowSize();
    
    return (
        <>
        {size.width >= tabletSize ? <h2 className='headerTitle textCenter'>Browse By {title}</h2> : <h2 className='headerTitle'>{title}</h2>}
        <Row>
            {size.width < tabletSize && <DownOutlined className='animation collapseButton absolute top5px right15px' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />}
            <Col xs={0} sm={0} md={0} lg={0} xl={24} >
                <div className='logoList'>
                {
                    list.map((logo, key) => {
                        return (
                            <div className='logo' key={key}>
                                {
                                    isExport ? (
                                        //https://test2.webcell.com.au/PriceListServlet?format=byCategory&vendor=APPL&vendor=3SIXT&vendor=BEATS&category=Audio&category=SomethingElse
                                        <a href={priceListDownload + '?format=byBrand&brand=' + logo.link} target='_blank' rel='noopener noreferrer'>
                                            <Image preview={false} alt={logo.name} src={getImage(logo.name, 'brands', '200x200')} fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + logo.name}/>
                                        </a>
                                    ) : (
                                        <Link to={'/shop/brands/' + formatText(logo.link)}>
                                            <Image preview={false} alt={logo.name} src={getImage(logo.name, 'brands', '200x200')} fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + logo.name}/>
                                        </Link>
                                    )
                                }                 
                            </div>
                        )
                    })
                }
                </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={0} >
                <AnimatePresence initial={false}>
                    {(isOpen || size.width >= tabletSize) && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <Carousel slidesToScroll={12} dotPosition='bottom' swipeToSlide={true} touchMove={true} accessibility={true} draggable={true} className='logoSlide' slidesToShow={8} responsive= {[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 8,
                                    slidesToScroll: 8
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 6,
                                    slidesToScroll: 6
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 4
                                }
                            }
                            ]}>
                                {
                                    list.map((logo, key) => {
                                        return (
                                            <div className='logo' key={key}>
                                                <Link to={'/shop/brands/' + formatText(logo.link)}>
                                                    <Image preview={false} alt={logo.name} src={getImage(logo.name, 'brands', '200x200')} fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + logo.name}/>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                        </Carousel>
                    </motion.section>)}
                </AnimatePresence>
            </Col>
        </Row>
        </>
    )
}

export default LogoList;
