import React from 'react';
import { Row, Col, Skeleton, List, Button, Form, Input, DatePicker} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { UserContext } from '../../../contexts/UserContext';
import { ProductContext } from '../../../contexts/ProductContext';
import { invoiceDownload, InvoiceObj } from '../../../Constants';
import { Invoice, InvoiceLine, ProductExt } from '../../../RESTAPI';
import { paginate, dateStringFormat, formatCurrency, getStatus, calcBalance } from '../../../utils';
import {Link} from 'react-router-dom';
import _ from 'lodash';

const pageSize = 5;

const { RangePicker } = DatePicker;

const Invoices: React.FC = () => {
    const [ initLoading ] = React.useState<boolean>(false);
    const [ currentPage, setCurrentPage] = React.useState<number>(1);
    const [ maxPage, setMaxPage] = React.useState<number>(1);
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const [ list, setList] = React.useState<Array<InvoiceObj>>([]);
    const [ invoices, setInvoices ] = React.useState<Array<Invoice>>([]);

    const [search, setSearch] = React.useState<string>('');
    const [amount, setAmount] = React.useState<number>(0);
    const [dateRange, setDateRange] = React.useState<[string, string]>(['','']);

    const { userState } = React.useContext(UserContext);
    const { productState } = React.useContext(ProductContext);
    
    React.useEffect(() => {
        if(userState.invoices && userState.transactionsAndPayments && userState.invoices.invoices) {
            if(userState.invoices.invoices.length > 0) {
                const currentTotalInvoices = filterList(userState.invoices.invoices);

                currentTotalInvoices.sort((a, b) => (a.invoicedate > b.invoicedate) ? -1 : (a.invoicedate === b.invoicedate) ? ((a.invoiceno > b.invoiceno) ? -1 : 1) : 1)
                
                setInvoices(currentTotalInvoices);
                
                setList(paginate(currentTotalInvoices, pageSize, 1));
                setMaxPage(Math.ceil(currentTotalInvoices.length / 5));
            }
        }
    }, [userState.invoices, userState.transactionsAndPayments, search, amount, dateRange])

    const loadMore =
      !initLoading && !loading && currentPage < maxPage ? (
        <div className='textCenter marginTop40px'>
          <Button onClick={() => onLoadMore()} type='primary'>Load More</Button>
        </div>
      ) : null;

    const onLoadMore = () => {
        const nextPage = currentPage + 1;
        const nextList = paginate(invoices, pageSize, nextPage);
        setLoading(true);
        setCurrentPage(currentPage + 1);

        const currentList = [...list];
        for (let index = 0; index < nextList.length; index++) {
            currentList.push({
                ...nextList[index],
                loading: true
            }); 
        }
        setList([...currentList]);
        
        setTimeout(() => {
            const current = [...currentList];
            const newList:Array<InvoiceObj> = [];

            current.forEach((value, key) => {
                value.loading = false;
                
                newList.push(value);
            })

            setList([...newList]);
            setLoading(false);
        }, 50)
    };

    const filterList = (invoices: Array<Invoice>) => {
        return _.filter(invoices, (i) => {

            if(search !== '') {
                let found: boolean = false;
                let searchLC: string = search.toLowerCase();
                if (i.invoiceno.toString().includes(search) || i.orderno.toString().includes(search) || i.custref.toLowerCase().includes(searchLC)) {
                    found = true;
                }
                else if (userState.invoices.invoiceNoToInvoiceLines) {
                    let lines: InvoiceLine[] = userState.invoices.invoiceNoToInvoiceLines[i.invoiceno];
                    if (lines) {
                        for (let line of lines) {
                            if (line.itemno.includes(searchLC)) {
                                found = true;
                                break;
                            }
                            else if (productState && productState.productIdToProduct) {
                                let product : ProductExt | undefined = productState.productIdToProduct.get(line.itemno);
                                if (product && (product.product.description.toLowerCase().includes(searchLC) ||
                                        product.product.name.toLowerCase().includes(searchLC))) {
                                    found = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!found) {
                    return false;
                }
            }

            if(dateRange !== ['',''] && (new Date(dateRange[0]) >= new Date(dateStringFormat(i.invoicedate, false, true)) || new Date(dateRange[1]) <= new Date(dateStringFormat(i.invoicedate, false, true)))) {
                return false
            }
            return true;
        })
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }

    const handleDateChange = (value: any, formatString: [string, string]) => {
        setDateRange(formatString);
    }
    
    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20}>
                    <Col span={24}>
                        <h2 className='headerTitle textCenter'>Invoices</h2>
                        <MobileAccountMenu site='Invoices'/>
                        <div className='accountSection fontSize16px mobileMarginBottom20px' style={{margin: '0 auto'}}>
                            <div className='accountSectionContainer'>
                                <Form>
                                    <Row gutter={20}>
                                        <Col xs={8} md={8} lg={6}>
                                            <Form.Item className="group-floating-label" name='search' label=''>
                                                <Input size="large" onChange={handleSearchChange} className="has-float-label" placeholder='Search' suffix={<label className="floating-label" htmlFor="name">Search</label>}/>  
                                            </Form.Item>
                                        </Col>

                                        <Col xs={16} md={16} lg={12}>
                                            <div className='datePicker' style={{marginTop: 0}}>
                                            <Form.Item className="group-floating-label" name='dateRange' label=''>
                                                <RangePicker picker='date' className="has-float-label" style={{margin: 0}} onChange={handleDateChange}/>
                                            </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <List
                                    className="fontSize16px"
                                    loading={initLoading}
                                    itemLayout="horizontal"
                                    loadMore={loadMore}
                                    dataSource={list}
                                    renderItem={item => (
                                    <List.Item
                                        actions={[]}
                                    >
                                        <Skeleton avatar title={false} loading={item.loading ? item.loading : false} active>
                                        <List.Item.Meta
                                            title={<p className='noMarginBottom'>{dateStringFormat(item.invoicedate.toString())}<br/><Link to={'/your-account/your-invoices/' + item.invoiceno}><strong>{item.invoiceno}</strong></Link><br/><span className='fontSize14px'>Due Date: {dateStringFormat(item.duedate.toString())}</span><br/><span className='fontSize14px'>Cutomer Ref: {item.custref}</span><br/><span className='fontSize14px'>Total: {formatCurrency(item.amount)}</span><br/><span className='fontSize14px'>Balance: {formatCurrency(calcBalance(item, userState?.transactionsAndPayments?.invoiceNoToTransactions, userState?.transactionsAndPayments?.invoiceNoToAllocationPayments))}</span><br/><span className='fontSize14px'>Status: {getStatus(item, userState?.transactionsAndPayments?.invoiceNoToTransactions, userState?.transactionsAndPayments?.invoiceNoToAllocationPayments)}</span></p>}
                                        />
                                            <div>
                                                <Button className='text-secondaryColor' type='link' target='_blank' href={invoiceDownload + item.invoiceno}><strong>Download</strong></Button>
                                            </div>
                                        </Skeleton>
                                    </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default Invoices;
