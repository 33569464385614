import React from 'react';
import { Carousel, Image } from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';
import { imageUrl } from '../Constants';

const ProductImageSlider: React.FC<{images: Array<string | undefined>}> = ({images = []}) => {
    const carouselRef = React.useRef<CarouselRef>(null);

    const PrevArrow: React.FC<{className: string}> = ({className}) => {
        return (
          <div className={className}>
              <ArrowLeftOutlined onClick={handlePrev} />
          </div>
        );
    }
    
    const NextArrow: React.FC<{className: string}> = ({className}) => {
        return (
            <div className={className}>
                <ArrowRightOutlined onClick={handleNext}/>
            </div>
        );
    }

    const handleNext = () => {
        if(carouselRef && carouselRef.current) {
            carouselRef.current.next();
        }
    }

    const handlePrev = () => {
        if(carouselRef && carouselRef.current) {
            carouselRef.current.prev();
        }
    }

    function handleImageClick(selectedImageUrl: string)
    {
      var imagePopupImage = document.getElementById('imagePopupImage') as HTMLImageElement;    
      var imagePopupContainer = document.getElementById('imagePopupContainer');
      var imagePopupDiv = document.getElementById('imagePopupDiv');
      if (imagePopupImage && imagePopupContainer && imagePopupDiv) {
        imagePopupImage.setAttribute("src", selectedImageUrl);
        imagePopupContainer.style.display = 'flex';
        document.body.style.overflow = "hidden";
        var verticalOffset = (imagePopupContainer.clientHeight - imagePopupImage.height) / 2;
        var horizontalOffset = (imagePopupContainer.clientWidth - imagePopupImage.width) / 2;
        imagePopupContainer.scrollTop = -verticalOffset;
        imagePopupContainer.scrollLeft = -horizontalOffset;        
      }
    }

    function closePopup()
    {
      var imagePopupContainer = document.getElementById('imagePopupContainer');
      if (imagePopupContainer) {
        imagePopupContainer.style.display = 'none';  
        document.body.style.overflow = "auto";         
      }
    }    

    return (
        <>
            <Carousel ref={carouselRef} arrows={true} nextArrow={<NextArrow className='nextArrow arrow' />} prevArrow={<PrevArrow className='prevArrow arrow'/>} dotPosition='bottom' swipeToSlide={true} touchMove={true} accessibility={true} draggable={true} infinite className='productImageSlider' slidesToShow={1}>
            {
                images[0] == '' ? <Image key={1} preview={false} src={imageUrl + 'unavailable'}/> :
                images.map((i, k) => {
                            return i !== '' ? <Image key={k} preview={false} src={imageUrl + i} onClick={() => handleImageClick(imageUrl + i)}/> : null
                })
            }
            </Carousel>
            <div id="imagePopupContainer" onClick={() => closePopup()}
                style={{display: "none", cursor: "pointer", position: "fixed", zIndex: 10001, top: "0px", left: "0px", right: "0px", bottom: "0px", backgroundColor: "#ffffff", justifyContent: "center", alignItems: "center", overflow: "auto", opacity: 1.0}}>
            <div id="imagePopupDiv"
                style={{maxWidth: "100%", maxHeight: "100%", position: "relative"}}>
                <img id="imagePopupImage"/>
            </div>
            </div>
        </>  
    );
}

export default ProductImageSlider;