import React from 'react';
import {Layout, Menu, Image } from 'antd';
import {RightOutlined} from '@ant-design/icons';
import {MenuObject, pageVariants} from '../../Constants'
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';
import { formatText, getImage } from '../../utils';

const { Sider } = Layout;

const MobileMenu: React.FC<{ submenuOpen: boolean, setSubmenuOpen: React.SetStateAction<any>, handleSetCollapsed: React.SetStateAction<any>, collapsed: boolean, menu: Array<MenuObject>, extraMenu: Array<MenuObject> }> = ({ submenuOpen, handleSetCollapsed, setSubmenuOpen, collapsed = true, menu = [], extraMenu = []}) => {
    const [selectedSubmenu, setSelectedSubmenu] = React.useState<string>('');

    const handleOpenSub = (name: string) => {
        if(submenuOpen === false) {
            setSubmenuOpen(true);
            setSelectedSubmenu(name);
        } else {
            setSubmenuOpen(false);
            setSelectedSubmenu('');
        }
    }

    React.useEffect(() => {
        if(!submenuOpen) {
            setSelectedSubmenu('');
        }
    }, [submenuOpen])

    return (
        <Sider
            className={'mobileMenu' + (collapsed ? '' : ' zIndex1')}
            collapsible collapsed={collapsed}
            collapsedWidth="0"
            width='100%'
            zeroWidthTriggerStyle={{display: 'none'}}
            >
            <div className='fixedHeader'></div>
            <Menu mode="vertical"  multiple={false} selectable={false}>
                {
                    menu.length > 0 && 
                        menu.map((value, key) => {
                            if(value.hasChild) {
                                return (
                                    <React.Fragment key={key}>
                                        <Menu.Item className='hasSubmenu' key={key} data-name={value.name} onClick={() => handleOpenSub(value.name)}>
                                            {value.name}
                                            <RightOutlined />
                                        </Menu.Item>
                                        <Sider
                                            className={'mobileSubMenu' + (submenuOpen && selectedSubmenu === value.name ? ' zIndex1' : '')}
                                            collapsible collapsed={!submenuOpen && selectedSubmenu !== value.name}
                                            collapsedWidth="0"
                                            width='100%'
                                            zeroWidthTriggerStyle={{display: 'none'}}
                                        >
                                            <div className='fixedHeader subMenu'>
                                                <motion.div initial='initial' animate="in" exit="out" variants={pageVariants}>
                                                    <strong>{selectedSubmenu}</strong>
                                                </motion.div>
                                            </div>
                                            <Menu mode="inline" multiple={false} selectable={false} className={value.name === 'Brands' ? 'brandLogoContainer':''}>
                                                {value.childMenu?.map((v, k) => {
                                                    return (
                                                        <Menu.Item onClick={() => handleSetCollapsed(false)} key={k} className={value.name === 'Brands' ? 'brand-logo':''}>
                                                            <Link onClick={() => {setSubmenuOpen(false); setSelectedSubmenu(''); }} to={'/shop/' + formatText(value.name) + '/' + (v.link ? formatText(v.link) : '/')}>
                                                            {
                                                                value.name === 'Brands' ? (
                                                                    <Image src={getImage(v.name, 'brands', '200x200')} preview={false}/>
                                                                ) : (
                                                                    <>{v.name}</>
                                                                )
                                                            }
                                                            </Link>
                                                        </Menu.Item>
                                                    )
                                                })}
                                            </Menu>
                                        </Sider>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <Menu.Item onClick={() => handleSetCollapsed(false)} key={key}><Link to={'/' + (value.link ? value.link : '')}>{value.name}</Link></Menu.Item>
                                )
                            }
                        })
                }
            </Menu>
            <Menu mode="inline" className='extraMenu'>
                {
                    extraMenu.map((value, key) => {
                        return (
                            <Menu.Item onClick={() => handleSetCollapsed(false)} key={key}><Link to={value.link ? value.link : '/'}>{value.name}</Link></Menu.Item>
                        )
                    })
                }
            </Menu>
        </Sider>
    );
}

export default MobileMenu;