import React from 'react';
import { Row, Col, Typography } from 'antd';
import MainLayout from '../../layouts/MainLayout';

const { Paragraph, Title } = Typography;

const TermsConditions: React.FC = () => {
  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '800px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <h2 className='headerTitle textCenter marginTop20px'>Terms & Conditions</h2>
                <Title level={5}>
                    1.	Parties, Obligations and the Websites
                </Title>
                <Paragraph>
                    <ol>
                        <li>The www.cellnet.com.au, www.connect.cellnet.com.au, www.webcell.com.au, www.cellnet.co.nz, www.connect.cellnet.co.nz and www.webcell.co.nz websites ("Websites") are owned and operated by the Cellnet Group Limited (ABN 97 010 721 749) and its related entities ("Cellnet").</li>
                        <li>Access to the secure areas of these Websites is restricted to persons who are authorised customers of Cellnet. Access by unauthorised persons is strictly prohibited.</li>
                        <li>By accessing, browsing and/or using these Websites, each Customer is expressly agreeing to abide by the terms and conditions referred to in this document ("Terms of Use").</li>
                        <li>Each Customer should read the Terms of Use prior to using these Websites.</li>
                        <li>Although Cellnet may notify Customers of a change in the Terms of Use it is under no obligation to provide such notice and reserves the right to amend the Terms of Use at any time without notice.</li>
                        <li>It is the Customer's responsibility to read and be familiar with these Website Terms of Use.</li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    2.	General Commercial Terms
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            These Websites are provided for the purposes of allowing the Customer to do the following with an industry standard browser:
                            <ol>
                                <li>access as presented on these Websites information about Cellnet, the terms of the relationship between Cellnet and the Customer, and the goods and/or services presented on these Websites; and</li>
                                <li>communicate to Cellnet the Customer's desire to purchase goods and/or services presented on these Websites.</li>
                            </ol>
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    3.	The Customer must use the Websites solely for authorised purposes
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            Upon registration and the opening of an account with Cellnet, Cellnet will authorise the Customer to become a Registered User. Customers will be notified once their Cellnet account has become fully activated.
                        </li>
                        <li>
                            Registered Users will have access to secure areas of the Websites and may place orders with Cellnet for goods and/or services presented on these Websites.
                        </li>
                        <li>
                            Notwithstanding anything in the Terms of Use or any other agreement between the Customer and Cellnet, all orders are subject to the acceptance of Cellnet and no order will be deemed to be accepted by Cellnet until such acceptance is communicated to the Customer.
                        </li>
                        <li>
                            In addition to any other information Cellnet thinks relevant, in deciding whether to accept an order placed by the Customer, Cellnet may have regard to:
                            <ol>
                                <li>the Customer's account with Cellnet;</li>
                                <li>the Customer's credit status; and</li>
                                <li>any previous dealings between Cellnet and the Customer.</li>
                            </ol>
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    4.	The use of 'Bots' is prohibited
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            The Customer is prohibited from making use of automated systems (bots) to access the Websites posing as Users (a practice known as 'site scraping').
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    5.	Cellnet reserves the right to delay or cancel any order at any time
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            The Customer may print a hard copy of an order placed on the Websites. The presentation of an order confirmation indicates only that Cellnet has received the order.
                        </li>
                        <li>
                            If the Customer wishes to cancel an order it must do so by telephoning a Cellnet customer sales representative, accounts manager or other authorised person and quote the order number. Orders may not be cancelled after 60 minutes of order placement or if the goods have left Cellnet's storage facilities.
                        </li>
                        <li>
                            Orders for goods not ordinarily stocked by Cellnet are irrevocable.
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    6.	Prices and Products are subject to Change
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            All prices (which are listed in AUD), products and services displayed on these Websites and offered by Cellnet are subject to change without notice. It is the responsibility of the Customer to check the price of a product or service before placing an order for it.
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    7.	Supply of Goods and/or Services
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            Upon receiving payment from the Customer or if the Customer is a credit Customer, in accordance with our standard terms of credit (as varied from time to time), Cellnet will deliver the goods to the address nominated by you in accordance with the invoice.
                        </li>
                        <li>
                            It is the Customer's responsibility to provide Cellnet with access to the premise at the time nominated for delivery. Cellnet reserves the right to charge the Customer for all costs and expenses including expenses of storage for redelivery of the goods.
                        </li>
                        <li>
                            Cellnet will take all reasonable steps to achieve delivery on or about the date nominated for delivery. However, Cellnet will not be liable for any delay or failure to deliver the goods on the nominated date or for any loss or liability of the Customer to a third party.
                        </li>
                        <li>The Customer will inspect the goods on delivery and to the extent permitted by law will be deemed to have accepted the goods as being in accordance with the required quantity, merchantability and fitness for the purpose which they are required, safety standards and product specifications.</li>
                        <li>
                            The Customer otherwise accepts terms as identified on the invoice provided on the supply of goods.
                        </li>
                        <li>
                            Risk in the products passes to the Customer on delivery of the goods. However, Cellnet retains ownership in all goods until full payment has been received.
                        </li>
                        <li>
                            The Customer will allow Cellnet access to enter their premises and remove goods at any time prior to Cellent receiving full payment for the goods. All proceeds of sale for goods supplied by Cellnet will be held on trust for Cellnet until Cellnet receives full payment for the goods.
                        </li>
                        <li>
                            In the event that payment is not received in accordance with the terms of the invoice, the Customer agrees that if a demand is made on them they will immediately execute a mortgage and /or caveat over property owned by them as required by Cellnet. If a Customer fails to respond to the request within 21 days the Customer authorises Cellnet to appoint a credit manager or solicitor to be the true and lawful attorney of the Customer for the purpose executing and registering such instruments.
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    8.	Cellnet Terms of Credit
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            The Customer agrees that Cellnet may:
                            <ol>
                                <li>
                                    require any future dales transactions be conducted on a cash before delivery basis;
                                </li>
                                <li>
                                    require that all amounts owing to Cellnet for any reason become immediately due and payable without deduction or demand;
                                </li>
                                <li>
                                    if the Customer is a corporation, require the directors or other nominated third parties to give a guarantee and indemnity in the form required by Cellnet;
                                </li>
                                <li>
                                    charge an account service fee of 1.5 per cent per month until full payment is received;
                                </li>
                                <li>
                                    reserve its right to rescind all discounted rates and to recalculate outstanding charges;
                                </li>
                                <li>
                                    require that the Customer pay all costs, commissions and legal expenses whatsoever arising from the collection of any overdue monies. Such Interest, costs and commissions and legal expenses may be recovered as a liquidated debt.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    9.	Customer Responsibilities
                </Title>
                <Paragraph>
                    <ol>
                        <li>
                            The Customer must:
                            <ol>
                                <li>use these Websites in accordance with the Terms of Use;</li>
                                <li>if under the age of 18, obtain a parent or guardian's consent prior to using the Websites;</li>
                            </ol>
                        </li>
                        <li>The Customer is responsible for maintenance and supply of all their own hardware and software used to access the Websites and the Internet.</li>
                        <li>The Customer must not:
                            <ol>
                                <li>use the Websites to post or transmit any information or materials in breach of any laws or regulations;</li>
                                <li>use the Websites to infringe another parties copyright;</li>
                                <li>use the Websites to infringe a third party's privacy;</li>
                                <li>use the Websites to defame, harasses, threaten, menace or offend another person;</li>
                                <li>post illegal, obscene, indecent, inflammatory or pornographic material or other material that could give rise to civil or criminal proceedings;</li>
                                <li>use the Websites to send unsolicited, commercial or bulk email;</li>
                                <li>tamper with the settings of the Websites;</li>
                                <li>use the Websites to do anything that constitutes an offence under any law or regulation;</li>
                                <li>aid or assist any person to do any of the above acts.</li>
                            </ol>
                        </li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    10.	Security
                </Title>
                <Paragraph>
                    <ol>
                        <li>It is the Customer's responsibility to ensure that all details including all telephone, email and address details are correct and current.</li>
                        <li>The Customer must maintain confidentiality on all maters communicated to them by Cellnet.</li>
                        <li>The Customer must comply and obey all instructions and directions issued to it by Cellnet regarding the use of the Websites or membership details.</li>
                        <li>The Customer must not send or disclose it's membership details to any other person or store it in a manner that would reasonably allow another person or entity to obtain access to it unless the details are disclosed to:
                            <ol>
                                <li>an authorised agent or employee ("Authorised Person") of the Customer;</li>
                                <li>and that Authorised person agrees to be bound by the Terms of Use.</li>
                            </ol>
                        </li>
                        <li>The Customer must immediately notify Cellnet in writing if they become aware that the confidentiality of the membership details have been compromised or there has been an unauthorised use of the Customer's Cellnet account. Notice given to Cellnet does not release the Customer from the Terms of Use.</li>
                        <li>In the event of an unauthorised use, Cellnet will take reasonable steps to deactivate the Customer's Account and issue the Customer with new membership details within a reasonable time.</li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    11.	Privacy
                </Title>
                <Paragraph>
                    <ol>
                        <li>The Customer agrees that Cellnet may use and provide personal information about it's Customers that is collected on the Websites. The collection and provision of collected information is subject to privacy laws and Cellnet will comply with the its Privacy Statement in dealing with any personal information.</li>
                        <li>Cellnet may release to an Authorised Person information collected by Cellnet from the Websites.</li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    12.	Disclaimer
                </Title>
                <Paragraph>
                    <ol>
                        <li>The content, products and services on these Websites provided on an 'as is' basis. Cellnet does not represent the contents of these Websites as being accurate, comprehensive, complete, verified or error free.</li>
                        <li>Cellnet gives no guarantee that the Websites will always be accessible and available and/or fault or virus free.</li>
                        <li>Prior to using any download Cellnet recommends the user carry out an appropriate virus check as Cellnet does not accept any liability for any computer viruses transmitted in connection with the use of this website.</li>
                        <li>Subject to any applicable law which cannot be excluded, the Terms of Use expressly set out in this document form the entire agreement. No other terms apply.</li>
                        <li>All trademarks displayed on this website belong to their respective owners unless otherwise indicated.</li>
                        <li>Visitors to this website are under an obligation to take all reasonable steps to minimise the extent of loss suffered as result.</li>
                        <li>Cellnet owns the copyright in the content on this website and reserves its full rights for any unauthorised use by you.</li>
                        <li>The Customer releases from all liability whether in contract or tort (including negligence), to the maximum extent permitted by law as to the quality and fitness of purpose of the goods and services available on this website.</li>
                        <li>The Customer releases from all liability whether in contract or tort (including negligence), to the maximum extent permitted by law for any loss or damaged cause by factors reasonably considered to be the fault of third party conduct or equipment.</li>
                        <li>The Customer releases from all liability whether in contract or tort (including negligence), to the maximum extent permitted by law for any loss whatsoever of data, profits or revenue arising as a result of the use of these Websites.</li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    13.	Links to other websites and third party content
                </Title>
                <Paragraph>
                    <ol>
                        <li>The Website may contain links to other web sites operated by third parties ("Third Party Web Sites"). Cellnet does not endorse, or approve of the operators of those Third Party Web Sites, or the information, graphics and material on those Third Party Web Sites ("Third Party Material").</li>
                        <li>Subject to any applicable law which cannot be excluded, Cellnet makes no warranties or representations:
                            <ol>
                                <li>regarding the quality, accuracy, merchantability or fitness for purpose of Third Party Material or products or services available through Third Party Web Sites;</li>
                                <li>or that Third Party Material does not infringe the intellectual property rights of any person. Cellnet is not authorising the reproduction of Third Party Material by linking Material on this web site to Third Party Material.</li>
                            </ol>
                        </li>
                        <li>All offers to sell and statements relating to goods and services available on Third Party Web Sites are the responsibility of and given by the Third Party Web Site operator. Cellnet expressly disclaims acting in any other respect on behalf of Third Party Web Site operators.</li>
                        <li>Cellnet may receive payments, fees and/or commissions from third party operators for goods or services supplied by the operator as a result of you linking to the Third Party Web Site from the Websites. The Customer consents fully to Cellnet receiving such payments, fees and/or commissions.</li>
                        <li>Third party products, services and information ("Third Party Content") are not provided or endorsed by Cellnet. Where it is apparent that Third Party Content is not provided by Cellnet or the Customer has left the Websites, the Customer's legal relationship in respect of the Third Party Content is with the third party provider.</li>
                        <li>Cellnet does not check the accuracy or completeness of Third Party Content. It is the Customer's responsibility to make any enquires regarding Third Party Content before relying on that content or before entering into a transaction in relation to Third Party Content supplied via the Websites.</li>
                        <li>Cellnet may receive payments, fees and/or commissions from third party operators for listing Third Party Content on the Websites. The Customer consents fully to Cellnet receiving such payments, fees and/or commissions.</li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    14.	Change of the Terms of Use
                </Title>
                <Paragraph>
                    <ol>
                        <li>These are the current Terms of Use. They replace any other terms of use for the Websites. Cellnet may at any time vary the Terms of Use by publishing the varied Terms of Use on the Websites. The Customer accepts that by doing this, Cellnet has provided sufficient notice of the variation.</li>
                        <li>Cellnet reserves any rights not expressly granted in these Terms of Use.</li>
                    </ol>
                </Paragraph>

                <Title level={5}>
                    15.	Suspension and Termination
                </Title>
                <Paragraph>
                    <ol>
                        <li>The Customer may stop using the Websites at anytime, for any reason.</li>
                        <li>Cellnet may immediately suspend or limit the Customer's access to the Websites if:
                    <ol>
                                <li>the Customer is in breach of the Terms of Use and the breach in cannot be remedied or the customer has failed to remedy breach within thirty (30) days of being notified of the breach;</li>
                                <li>Cellnet has reasonable grounds to believe that there is a real risk of loss or damage to us or another party unless Cellnet suspends or limit usage;</li>
                                <li>the law requires Cellnet to do so;</li>
                                <li>access to the Websites becomes illegal;</li>
                                <li>the Australian Competition and Consumer Commission issues a competition notice relating to the Websites or Cellnet has reasonable grounds to believe it will do so; or</li>
                                <li>there is an emergency.</li>
                            </ol>
                        </li>
                        <li>The Terms of Use are effective until terminated by Cellnet.</li>
                        <li>Cellnet may terminate this agreement and the Customers Access to the Websites at anytime without notice.</li>
                        <li>In the event of termination, the Customer is no longer authorised to access the Websites, but all restrictions, disclaimers and limitations of liability remain enforceable.</li>
                        <li>Termination will not affect any legal right that may have accrued to Cellnet against the Customer up to the date of termination.</li>
                        <li>The law in force in the State of Queensland, Australia governs this agreement and the parties irrevocably submit to the exclusive jurisdiction of the courts of Queensland.</li>
                        <li>If any of the terms in this Terms of Use are invalid for whatever reasons, they will be struck out and the remaining terms will remain in force.</li>
                        <li>A failure by Cellnet to pursue a breach of the Terms of Use by the Customer does not waive any rights Cellnet has to pursue the Customer for a similar or subsequent breach.</li>
                    </ol>
                </Paragraph>
            </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default TermsConditions;
