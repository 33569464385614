import React from 'react';
import { Image, Row, Col } from 'antd';
import { BrandDeviceCateObject, priceListDownload, tabletSize } from '../Constants';
import { motion, AnimatePresence } from "framer-motion";
import { formatText, getImage, useWindowSize } from '../utils';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const CateList: React.FC<{list: Array<BrandDeviceCateObject>, title: string, isExport?: boolean}> = ({list, title, isExport = false }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const size = useWindowSize();

    let urlConstruct = '?format=byCategory&category=';
    let type = 'cates';
    let link = title;

    if(title.includes('Device') || title.includes('Compatibility')) {
        urlConstruct = '?format=byVendor&vendor=';
        type = 'deviceVendors';
        link = 'compatibility';
    }
    return (
        <>
        {size.width >= tabletSize ? <h2 className='headerTitle textCenter'>Browse By {title}</h2> : <h2 className='headerTitle'>{title}</h2>}
        <Row>
            {size.width < tabletSize && <DownOutlined className='animation collapseButton absolute top5px right15px' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <AnimatePresence initial={false}>
                    {(isOpen || size.width >= tabletSize) && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <Row className='cateList' gutter={[30, 30]}>
                        {
                            list.map((cate, key) => {
                                return (
                                    <Col key={key} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        {
                                            isExport ? (
                                                //https://test2.webcell.com.au/PriceListServlet?format=byCategory&vendor=APPL&vendor=3SIXT&vendor=BEATS&category=Audio&category=SomethingElse
                                                <a href={priceListDownload + urlConstruct + cate.link} target='_blank' rel='noopener noreferrer'>
                                                    <div className='cate'>
                                                        <div>
                                                            <Image className='logoHeight100px' preview={false} src={getImage(cate.name, type, '200x200')} fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + cate.name}/>
                                                        </div>
                                                        <h3>
                                                            {cate.name}
                                                        </h3>
                                                    </div>
                                                </a>) : (
                                                <Link to={'/shop/' + formatText(link) + '/' + cate.link}>
                                                    <div className='cate'>
                                                        <div>
                                                            <Image className='logoHeight100px' preview={false} src={getImage(cate.name, type, '200x200')} fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + cate.name} />
                                                        </div>
                                                        <h3>
                                                            {cate.name}
                                                        </h3>
                                                    </div>
                                                </Link>
                                            )
                                        }

                                    </Col>
                                )
                            })
                        }
                        </Row>
                    </motion.section>)}
                </AnimatePresence>
            </Col>
        </Row>
        </>
    )
}

export default CateList;
