import React from 'react';
import { Row, Col, Typography } from 'antd';
import MainLayout from '../../layouts/MainLayout';

const { Paragraph, Text, Title } = Typography;

const Help: React.FC = () => {
  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '800px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <h2 className='headerTitle textCenter'>Help</h2>
                <Title level={5}>What are Cellnet's trading hours?</Title>
                <Paragraph>
                Our office hours are 8.30am to 5.00pm AEST Monday to Friday, excluding public holidays.
                </Paragraph>
                <Title level={5}>What are Cellnet's trading terms?</Title>
                <Paragraph>
                We have no minimum order value nor order processing fee, however freight and credit card charges may apply.
                Payment is required prior to shipment or upon collection, unless an account application form has been lodged and approved. We accept Mastercard, Visa, AMEX, cheque and cash for purchases. For all credit card transactions, a copy of the front and back of the credit card must be supplied with every purchase order for verification purposes. There may be a service fee for using credit card facilites. Cheques over $2,000 will be delayed for a minimum of 3 business days to allow for clearance of funds.
                Orders may be emailed or telephoned through. Online orders may be entered via Cellnet Connect.
                Title of ownership of the goods does not pass to the customer until the goods have been paid for in full and all funds are clear.
                All prices given verbally, shown in our price list, promotional flyers, web site or quotations are given in good faith. However, all prices are subject to change without notice.
                Our full terms and conditions of purchase can be found by visiting www.cellnet.com.au or www.cellnet.co.nz.
                </Paragraph>
                <Title level={5}>Does Cellnet accept credit card payments?</Title>
                <Paragraph>
                Cellnet currently accepts Mastercard, Visa and AMEX for purchases however please note that a service fee will be applied to the total of the payment.
                </Paragraph>
                <Title level={5}>What are Cellnet's banking details?</Title>
                <Paragraph>
                Our banking details for Australia are:
                Bank:
                Westpac Banking Corporation
                
                Account Name:
                Cellnet Group
                
                BSB Number:
                034-002
                
                Account Number:
                396-155
                
                Please email confirmation of your deposit to Cellnet AR Australia accounts.receivable@cellnet.com.au once it has been completed.
                Our banking details for New Zealand are:
                Bank:
                Westpac New Zealand
                
                Account Name:
                Cellnet (NZ) Limited
                
                Bank Number:
                03
                
                Branch Number:
                1322
                
                Account Number:
                0156598-000
                
                Please email confirmation of your deposit to ARNZ Cellnet cellnet.ar@cellnet.co.nz once it has been completed.
                </Paragraph>
                <Title level={5}>What is Cellnet's ABN/ Company Number?</Title>
                <Paragraph>
                Australia
                ACN
                010 721 749
                
                ABN
                97 010 721 749
                
                New Zealand
                Company Number
                646209
                </Paragraph> 
                <Title level={5}>How do I add a product to my favourites?</Title>
                <Paragraph>
                Adding a product to your Favourites is easy. Once you have found your desired product by either searching or entering the product code, simply click the heart shaped icon which will turn red once added.  Next time you wish to view the product you only need to go to your favourites and click the link.
                </Paragraph>
                <Title level={5}>How do I change my Cellnet Connect password?</Title>
                <Paragraph>
                To change your email address please visit  Account Overview &gt; Login &amp; Security. Next to your password there is an edit button, click the edit button to change your password enter your new password and confirm it then click on save password.
                </Paragraph>
                <Title level={5}>How do I change my email address?</Title>
                <Paragraph>
                To change your email address please visit  Account Overview &gt; Login &amp; Security. Next to your email address there is an edit button, click the edit button to change your email address.
                </Paragraph>
                <Title level={5}>How do I get a copy of an invoice?</Title>
                <Paragraph>
                You can download a particular invoice by visiting Account Overview &gt; Invoices. Once you have found the invoice that you are looking for, click on it. If you do not have access to this feature, you may need to contact your Security Administrator.
                </Paragraph>
                <Title level={5}>If I have problems with Cellnet Connect who should I contact?</Title>
                <Paragraph>
                In the first instance you should contact your Security Administrator to ensure that you have the access that you require. If you are then unable to resolve your problem, please send an email to sales@cellnet.com.au
                </Paragraph>
          </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default Help;
