import React from 'react';
import {Layout, Spin} from 'antd';
import { pageVariants } from '../Constants';
import { motion } from "framer-motion";
import MainFooter from '../components/MainFooter';
import CheckoutHeader from '../components/CheckoutHeader';
import { ProductContext } from '../contexts/ProductContext';

const {Content} = Layout;

const CheckoutLayout: React.FC<{children?: React.ReactNode }> = ({children}) => {
  const {productState} = React.useContext(ProductContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    setLoading(productState.loading);
  }, [productState.loading])
  
  return (
    <motion.div className='fullHeight' initial='initial' animate="in" exit="out" variants={pageVariants}>
      <Spin tip="Loading..." spinning={loading}>
        <Layout className="layout" style={{minHeight: '100%'}}>
          <CheckoutHeader/>
          <Content>
              {children}
          </Content>
          <MainFooter/>
        </Layout>
      </Spin>
    </motion.div>
  );
}

export default CheckoutLayout;
