import React from 'react';
import { Image } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import BoldSubText from '../BoldSubText/BoldSubText';
import { ProductExt } from '../../RESTAPI';
import { imageUrl } from '../../Constants';

const ProductSearchOption: React.FC<{product: ProductExt, searchValue: string }> = ({product, searchValue}) => {
    return (
        <div className='result-option'>
            <Image style={{height: '50px', width: 'auto'}} preview={false} src={imageUrl + product.product.image} />
            <div className='flex2 marginLeft26px'>
                <div>
                    <strong>{BoldSubText(product.product.name, searchValue)}</strong>
                </div>
                <div>
                    {product.product.shortName}
                </div>
                <RightOutlined className='product-arrow'/>
            </div>
            
        </div>
    )
}

export default ProductSearchOption;