import React from 'react';
import { Row, Col, List, Image } from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import { Link } from 'react-router-dom';
import ProductListSlider from '../../../components/ProductListSlider';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { myAuth } from '../../../elements/PrivateRoute/PrivateRoute';
import { ProductContext } from '../../../contexts/ProductContext';
import { UserContext } from '../../../contexts/UserContext';
import _ from 'lodash';
import { ClientHelper, UserAccess } from '../../../RESTAPI';
import { invoiceDownload } from '../../../Constants';
import {
    UserOutlined
} from '@ant-design/icons';

const accountMenu = [
    // {
    //     name: 'Recent Activities',
    //     image: 'payment-small.svg',
    //     link: '/your-account',
    //     permission: 'C'
    // },    
    {
        name: 'Your Orders',
        image: 'orders-small.svg',
        link: '/your-account/your-orders',
        permission: 'H'
    },
    {
        name: 'Back Orders',
        image: 'orders-small.svg',
        link: '/your-account/back-orders',
        permission: 'H'
    },
    {
        name: 'Login & Security',
        image: 'security-small.svg',
        link: '/your-account/login-detail',
        permission: ''
    },
    {
        name: 'Invoices',
        image: 'invoices-small.svg',
        link: '/your-account/your-invoices',
        permission: 'I'
    },
    {
        name: 'Service Requests',
        image: 'return-small.svg',
        link: '/your-account/returns',
        permission: 'L'
    },
    {
        name: 'Addresses',
        image: 'address-small.svg',
        link: '/your-account/addresses',
        permission: 'P'
    },
    {
        name: 'Credit Summary/Make Payment',
        image: 'payment-small.svg',
        link: '/your-account/credit-summary',
        permission: 'C'
    },
    {
        name: 'Manage Users',
        image: 'manage-users-small.svg',
        link: '/your-account/manage-users',
        permission: 'A'
    },
    {
        name: 'Download Price Lists',
        image: 'price-list-small.svg',
        link: '/your-account/pricelist',
        permission: 'DR'
    },
    {
        name: 'Data Feed',
        image: 'data_feed.svg',
        link: '/data-feed',
        permission: 'D',
    },    
    {
        name: 'Download Files',
        image: 'price-list-small.svg',
        link: '/your-account/files',
        permission: ''
    }
];

const AccountPage: React.FC = () => {
    const { productState } = React.useContext(ProductContext);
    const { userState, setHomeContent, setBanners, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile } = React.useContext(UserContext);

    const handleSwitchAccount = (accountId: string) => {
        if(accountId) {
            myAuth.switchAccount(accountId, setHomeContent, setBanners, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile)
        }
    }
    
    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20} className='marginBottom40px'>
                    <Col xs={0} sm={0} md={16} lg={16} xl={16}>
                        <h2 className='headerTitle'>Your Account</h2>
                        <div>
                            <Row gutter={[6,6]}>
                                {
                                    accountMenu.map((menu, key) => {
                                        if (menu.name === 'Credit Summary/Make Payment') {
                                            if (userState && userState.currentCustomer && userState.currentPayer && (ClientHelper.isCashCustomer(userState.currentCustomer, userState.currentPayer) || userState.currentCustomer.country.includes('NZ'))) {
                                                return null;
                                            }
                                        }
                                        if (menu.name === 'Switch Account') {
                                            if(userState?.userAccounts?.length < 2 && !(userState?.userProfile?.type === 'S' && userState?.currentUserAccount?.extrapermissions?.includes(UserAccess.CUSTOMER_MASQUERADE))) {
                                                return null;
                                            }
                                        }                                        
                                        const checkPermission = menu.permission.split('');
                                        for (let index = 0; index < checkPermission.length; index++) {
                                            const element = checkPermission[index];
                                            
                                            // permission = ABCDER
                                            if(userState?.currentUserAccount?.permissions?.includes(element)) {
                                                if(menu.name === 'Data Feed') {
                                                    if( userState?.userProfile?.type === 'C' && userState?.currentUserAccount?.extrapermissions?.includes(element) ) {
                                                        return null;
                                                    } else {
                                                        return (<Col span={12} key={key}>
                                                            <Link to={menu.link}>
                                                            <div className='whiteBg accountLink'>
                                                                {menu.name}
                                                                <Image src={require('../../../assets/images/' + menu.image).default} preview={false}/>
                                                            </div>
                                                            </Link>
                                                            
                                                        </Col>)
                                                    }
                                                } else if (menu.name === 'Credit Summary/Make Payment') {
                                                    if (userState && userState.currentCustomer && userState.currentCustomer.country.includes('NZ')) {
                                                        return (<Col span={12} key={key}>
                                                            <Link to={menu.link}>
                                                            <div className='whiteBg accountLink'>
                                                                Credit Summary
                                                                <Image src={require('../../../assets/images/' + menu.image).default} preview={false}/>
                                                            </div>
                                                            </Link>
                                                            
                                                        </Col>)
                                                    }
                                                } else {
                                                    return (<Col span={12} key={key}>
                                                        <Link to={menu.link}>
                                                        <div className='whiteBg accountLink'>
                                                            {menu.name}
                                                            <Image src={require('../../../assets/images/' + menu.image).default} preview={false}/>
                                                        </div>
                                                        </Link>
                                                        
                                                    </Col>)
                                                }
                                                
                                            } else {
                                                return null
                                            }
                                        }

                                        return (<Col span={12} key={key}>
                                            <Link to={menu.link}>
                                            <div className='whiteBg accountLink'>
                                                {menu.name}
                                                <Image src={require('../../../assets/images/' + menu.image).default} preview={false}/>
                                            </div>
                                            </Link>
                                            
                                        </Col>)
                                    })
                                }
                                
                            </Row>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <h2 className='headerTitle onlyDesktop'>Recent Activity</h2>
                        <h2 className='headerTitle onlyMobile textCenter'>Your Account</h2>
                        <MobileAccountMenu site='Recent Activities'/>
                        <div className='accountSection'>
                            <div className='accountSectionContainer'>
                            <List
                                itemLayout="horizontal"
                                locale={{emptyText: <div>No Recent Activity</div>}}
                                dataSource={userState.recentActivities}
                                renderItem={item => (
                                    <div>
                                        {
                                            <List.Item>
                                                <List.Item.Meta
                                                avatar={<UserOutlined />}
                                                title={<>
                                                    <p className='fontSize16px noMarginBottom'>{item.description}</p>
                                                    <p className='text-primaryColor'>
                                                    {
                                                        item.type === 'P' && <Link to={'/shop/' + (productState.productIdToProduct && _.filter([...productState.productIdToProduct.values()], function (product) {
                                                            return product.product.id === item.parameters.split(':|')[0].replace('productId:=', '');
                                                        })[0]?.product.name)}>Go to Product</Link>
                                                    }
                                                    {
                                                        (item.type === 'O' || item.type === 'B') && <Link to={'/your-account/your-orders/' + item.parameters.split(':|')[0].replace('selectedOrderId:=', '')}>Go to Order</Link>
                                                    }
                                                    {
                                                        item.type === 'R' && <Link to={'/your-account/returns/' + item.parameters.split(':|')[0].replace('selectedReturnId:=', '')}>Go to Return</Link>
                                                    }
                                                    {
                                                        item.type === 'I' && <a target='_blank' rel="noreferrer" href={invoiceDownload + item.parameters.split(':|')[1].replace('selectedTxId:=', '')}>Download Invoice</a>
                                                    }
                                                    {
                                                        item.type === 'A' && item.parameters.split(':|')[0].replace('accountId:=', '') !== userState.currentCustomer.id && <Link to='' onClick={(e) => { e.preventDefault(); handleSwitchAccount(item.parameters.split(':|')[0].replace('accountId:=', '')) }}>Switch to Account { item.parameters.split(':|')[0].replace('accountId:=', '') }</Link>
                                                    }                                                    
                                                    </p>
                                                </>}
                                                />
                                            </List.Item>
                                        }
                                    </div>
                                )}
                            />
                            </div>
                            <div className='signoutMobile onlyMobile'>
                                <Link to='/' onClick={(e: React.MouseEvent) => {e.preventDefault(); myAuth.signout()}}><strong>Sign out</strong></Link>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
                <Row className='marginTop40px'>
                    <Col span={24}>
                        <ProductListSlider mobile productList={productState.special} title='Featured Products'/>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default AccountPage;
