import React from 'react';
import { Row, Col, Affix } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import ProductList from '../../components/ProductList';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import FilterBar from '../../components/FilterBar';
import { blankFilter, BrandDeviceCateObject, FilterObject, tabletSize } from '../../Constants';
import { useWindowSize, calcMargin } from '../../utils';
import { ProductContext } from '../../contexts/ProductContext';
import { ProductExt, MethodCallback, SearchMessage } from '../../RESTAPI';
import { restClient, logout } from '../../elements/PrivateRoute/PrivateRoute';
import _ from 'lodash';

const topOffsetDefault = 158;
const topOffsetMobileDefault = 64;

const SearchPage: React.FC = () => {
    const [sortingOrder, setSortingOrder] = React.useState<null | 'price-asc' | 'price-desc' | 'mag-asc' | 'mag-desc' | 'name-asc' | 'name-desc' | 'date-asc' | 'date-desc'>(null);
    const [currentProductList, setCurrentProductList] = React.useState<Array<ProductExt>>([]);
    const {productState} = React.useContext(ProductContext);

    const {searchParam, selectedCate} = useParams<{searchParam: string, selectedCate: string}>();
    const location = useLocation();

    const [filterList, setFilterList] = React.useState<FilterObject>(blankFilter);
    const [searchResults, setSearchResults] = React.useState<ProductExt[]>([]);
    let title = '';


    const SearchCallback:MethodCallback<SearchMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: SearchMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                if(message.error !== null) {
                    setSearchResults([]);
                } else {
                    
                    let res: ProductExt[] = [];
                    if(message.results.length > 0) {
                        message.results.map((result, key) => {
                          let productExt: ProductExt | undefined = productState.productIdToProduct.get(result.productId);
                          if (productExt) {
                            res.push(productExt);
                          }
                        })
                    }
                    setSearchResults(res);
                }
                
            }
        }
    }   

    React.useEffect(() => {
        restClient.search(searchParam, 200, 1, SearchCallback)
    }, [searchParam])

    React.useEffect(() => {
        if(productState.productIdToProduct.size > 0) {
            let tempBrandProducts = [] as Array<ProductExt>;
            let tempCateProducts = [] as Array<ProductExt>;
            let tempDeviceProducts = [] as Array<ProductExt>;
            let arrayOfArrays:Array<Array<ProductExt>> = [];
            let tempList = [] as Array<ProductExt>;
            
            let masterList:Array<BrandDeviceCateObject> = [];

            // with brand products
            if(location.pathname.includes('brands')) {
                masterList = productState.brands;
            } else if(location.pathname.includes('categories')) {
                masterList = productState.categories;
            } else if(location.pathname.includes('device-vendors')) {
                masterList = productState.deviceVendors;
            }

            if(masterList.length > 0) {
                masterList.forEach(list => {
                    list.subList?.forEach(subList => {
                        tempList = [...new Set([...tempList, ...subList.products])];
                    });
                });
            } else {
                if(location.pathname.includes('top-sellers')) {
                    tempList = productState.topSeller;
                } else if(location.pathname.includes('clearance')) {
                    tempList = productState.clearance;
                } else if(location.pathname.includes('new')) {
                    tempList = productState.new;
                } else if(location.pathname.includes('special')) {
                    tempList = productState.special;
                } else {
                    tempList = [...productState.productIdToProduct.values()];
                }
            }
            
            if(filterList !== blankFilter) {
                if(filterList.brands.length === 0 && filterList.categories.length === 0 && filterList.devices.length === 0) {
                    tempList = [...tempList]; // nothing change
                } else {
                    let brandList:Array<BrandDeviceCateObject>;
                    let cateList:Array<BrandDeviceCateObject>;
                    let deviceList:Array<BrandDeviceCateObject>;
                    
                    if(filterList.brands.length > 0) {
                        brandList = productState.brands.filter(value => 
                            filterList.brands.includes(value.name)
                        )

                        brandList.forEach(brand => {
                            brand.subList?.forEach(subBrand => {
                                tempBrandProducts = [...new Set([...tempBrandProducts, ...subBrand.products])];
                            })
                        });

                        if(tempBrandProducts.length > 0) {
                            arrayOfArrays.push(tempBrandProducts);
                        }
                    }
                    
                    if(filterList.categories.length > 0) {
                        cateList = productState.categories.filter(value => 
                            filterList.categories.includes(value.name)
                        )

                        cateList.forEach(cate => {
                            cate.subList?.forEach(subCate => {
                                tempCateProducts = [...new Set([...tempCateProducts, ...subCate.products])];
                            })
                        });

                        if(tempCateProducts.length > 0) {
                            arrayOfArrays.push(tempCateProducts);
                        }
                    }

                    if(filterList.devices.length > 0) {                        
                        deviceList = productState.deviceVendors.filter(value => 
                            filterList.devices.includes(value.name)
                        )
                        
                        deviceList.forEach(device => {
                            device.subList?.forEach(subDevice => {
                                tempDeviceProducts = [...new Set([...tempDeviceProducts, ...subDevice.products])];
                            })
                        });

                        if(tempDeviceProducts.length > 0) {
                            arrayOfArrays.push(tempDeviceProducts);
                        }
                    }

                    // get products that satisfy all filter criteria
                    arrayOfArrays.push(tempList);
                    let temp = _.intersectionWith(...arrayOfArrays, _.isEqual);
                    
                    if(temp.length > 0) {
                        // intersect with the current tempList
                        tempList = temp;
                    }
                }   
            }

            // if(searchParam) {
                
            //     //const regex = RegExp('^'+searchParam.toUpperCase().replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'), 'g');
            //     // clear value
            //     const searchTerm = searchParam.toUpperCase().replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
            //     // if searchTerm is multiple word
            //     const searchTermArrays = searchTerm.split(' ');

            //     // regex: /\b(?=.*\w*3SIXT\w*)(?=.*\w*CHARGER\w*).*$\b/gi
            //     const searchReg = '(?=.*\\w*' + (searchTermArrays.length > 1 ? searchTermArrays.join('\\w*)(?=.*\\w*') : searchTermArrays.toString()) + '\\w*).*$';
            //     const regex = RegExp('\\b' + searchReg + '\\b', 'gi');
            //     let res: ProductExt[] = [];
                
            //     tempList.forEach(productExt => {                    
            //         // test product name and description and ean and compapility
            //         if(regex.test(productExt.product.name.toUpperCase()) || regex.test(productExt.product.description.toUpperCase()) || regex.test(productExt.product.ean.toUpperCase()) || regex.test(productExt.vendorDevices?.toString().toUpperCase())) {
            //             if(selectedCate !== undefined && selectedCate !== '') {
            //                 if(selectedCate === 'all') {
            //                     res.push(productExt);
            //                 } else {
            //                     if (selectedCate === productExt.product.procurementGroupDesc) {
            //                         res.push(productExt);
            //                     }
            //                 }
            //             } else {
            //                 res.push(productExt);
            //             }  
            //         }
            //     });

            //     tempList = res;
            // }
            if(searchParam) {
                let res: ProductExt[] = [];

                searchResults.forEach(productExt => {                    
                    let foundProductExt = _.find(tempList, (p) => {
                        return p.product.id === productExt.product.id
                    })

                    if(foundProductExt) {
                        if(selectedCate !== undefined && selectedCate !== '') {
                            if(selectedCate === 'all') {
                                res.push(productExt);
                            } else {
                                if (selectedCate === productExt.product.procurementGroupDesc) {
                                    res.push(productExt);
                                }
                            }
                        } else {
                            res.push(productExt);
                        }  
                    }
              });                
              tempList = res;
          }            

            if(sortingOrder !== null) {
                let sorting = sortingOrder.split('-');
                let ordering:boolean | 'asc' | 'desc' = 'asc';

                if(sorting.length > 1) {
                    switch (sorting[1]) {
                        case 'asc':
                            ordering = 'asc';
                            break;

                        case 'desc':
                            ordering = 'desc';
                            break;
                    
                        default:
                            ordering = 'asc';
                            break;
                    }

                    if(sorting.includes('mag')) {
                        tempList = _.orderBy(tempList, item => calcMargin(item.retailPrice, item.priceBreakInfo.priceBreaks[0].price),[ordering]);
                    } else if(sorting.includes('name')) {
                        tempList = _.orderBy(tempList, ['product.name'],[ordering]);
                    } else if(sorting.includes('date')) {
                        tempList = _.orderBy(tempList, ['product.registeredDate'],[ordering]);
                    } else {
                        tempList = _.orderBy(tempList, ['priceBreakInfo.priceBreaks[0].price'],[ordering]);
                    }
                }
            }
            
            setCurrentProductList(tempList);
        }
    }, [productState.productIdToProduct, productState.brands, productState.categories, productState.deviceVendors, productState.clearance, productState.new, productState.special, location, filterList, sortingOrder, selectedCate, searchResults])
    
    const size = useWindowSize();

    const handleFilterList = (filterList: FilterObject) => {
        setFilterList(filterList);
    }

    const handleSortingOrder = (value: any) => {
        setSortingOrder(value);
    }

    if(location.pathname.includes('search') && searchParam) {
        title = 'Shop - Search For ' + searchParam;
    } else {
        title = 'Shop All';
    }
    let filterTitle = '';

    if(filterList.brands.length > 0 || filterList.categories.length > 0 || filterList.devices.length > 0 ) {
        filterTitle = '';
        filterList.brands.forEach((value) => {
            if(filterTitle === '') {
                filterTitle += value;
            } else {
                filterTitle += ' · ' + value;
            }
        })

        filterList.categories.forEach((value) => {
            if(filterTitle === '') {
                filterTitle += value;
            } else {
                filterTitle += ' · ' + value;
            }
        })

        filterList.devices.forEach((value) => {
            if(filterTitle === '') {
                filterTitle += value;
            } else {
                filterTitle += ' + ' + value;
            }
        })
    }

    title = title + (filterTitle.length > 0 ? (' - ' + filterTitle) : '');
    if(location.pathname.includes(':searchParam')) {
        return  <Redirect
            to={{
                pathname: "/shop"
            }}
        />;
    } else {
        return (
            <MainLayout>
                <div className='content-wrapper marginBottom40px'>
                    <Row gutter={30}>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter marginTop40px'>{title}</h2>
                            <Affix offsetTop={size.width > tabletSize ? topOffsetDefault : topOffsetMobileDefault} className='changeBackgroundGray'>
                                <FilterBar handleFilterList={handleFilterList} handleSorting={handleSortingOrder}/>
                            </Affix>
                            <ProductList loading={productState.loading} filter productList={currentProductList} title=''/>
                        </Col>
                    </Row>
                </div>
            </MainLayout>
        );
    }
    
}

export default SearchPage;
