import React from 'react';
import { BrandDeviceCateObject, ProductState } from '../Constants';
import { ProductExt } from '../RESTAPI';
import { ProductReducer } from './ProductReducer';

type ProductContextObject = {
    setProducts: (payload:Map<string,ProductExt>) => void;
    setBrands: (payload:Array<BrandDeviceCateObject>) => void;
    setHighlightedBrands: (payload:Array<string>) => void,
    setCategories: (payload:Array<BrandDeviceCateObject>) => void;
    setDeviceVendors: (payload:Array<BrandDeviceCateObject>) => void;
    setClearance: (payload:Array<ProductExt>) => void;
    setNew: (payload:Array<ProductExt>) => void;
    setSpecial: (payload:Array<ProductExt>) => void;
    setTopSeller: (payload:Array<ProductExt>) => void;
    setLoadingState: () => void;
    setFinishLoading: () => void;
    setFavouriteProductId: (payload:Array<string>) => void;
    addFavouriteProductId: (payload:string) => void;
    removeFavouriteProductId: (payload:string) => void;
    setProductIdToMinOrderQty: (payload:{ [index: string]: number }) => void;
    setProductIdToOrderMultiple: (payload:{ [index: string]: number }) => void;
    setTabbedProducts: (payload:Array<ProductExt>) => void;
    productState: ProductState;
}

const initialState: ProductState = { 
    brands: [],
    categories: [],
    deviceVendors: [],
    clearance: [],
    new: [],
    special: [], 
    topSeller: [],
    productIdToProduct: new Map(),
    favourites: [],
    highlightedBrands: [],
    productIdToMinOrderQty: {},
    productIdToOrderMultiple: {},
    tabbedProducts: [],
    loading: false
};

export const ProductContext = React.createContext<ProductContextObject>({
    setProducts: () => null,
    setBrands: () => null,
    setHighlightedBrands: () => null,
    setCategories: () => null,
    setDeviceVendors: () => null,
    setClearance: () => null,
    setNew: () => null,
    setSpecial: () => null,
    setTopSeller: () => null,
    setLoadingState: () => null,
    setFinishLoading: () => null,
    setFavouriteProductId: () => null,
    addFavouriteProductId: () => null,
    removeFavouriteProductId: () => null,
    setProductIdToMinOrderQty: () => null,
    setProductIdToOrderMultiple: () => null,
    setTabbedProducts: () => null,
    productState: initialState
});

const ProductContextProvider: React.FC = ({children}) => {

    const [productState, dispatch] = React.useReducer(ProductReducer, initialState)

    const setProducts = (payload: Map<string,ProductExt>) => {
        dispatch({type: 'SET_PRODUCT', payload})
    }

    const setBrands = (payload: Array<BrandDeviceCateObject>) => {
        dispatch({type: 'SET_BRAND', payload})
    }

    const setHighlightedBrands = (payload: Array<string>) => {
        dispatch({type: 'SET_HIGHLIGHTED_BRAND', payload})
    }

    const setCategories = (payload: Array<BrandDeviceCateObject>) => {
        dispatch({type: 'SET_CATEGORY', payload})
    }

    const setDeviceVendors = (payload: Array<BrandDeviceCateObject>) => {
        dispatch({type: 'SET_DEVICE_VENDOR', payload})
    }

    const setClearance = (payload: Array<ProductExt>) => {
        dispatch({type: 'SET_CLEARANCE', payload})
    }

    const setNew = (payload: Array<ProductExt>) => {
        dispatch({type: 'SET_NEW', payload})
    }

    const setSpecial = (payload: Array<ProductExt>) => {
        dispatch({type: 'SET_SPECIAL', payload})
    }

    const setTopSeller = (payload: Array<ProductExt>) => {
        dispatch({type: 'SET_TOP_SELLER', payload})
    }

    const setLoadingState = () => {
        dispatch({type: 'LOADING_STATE', payload: true})
    }

    const setFinishLoading = () => {
        dispatch({type: 'FINISH_LOADING_STATE', payload: false})
    }

    const setFavouriteProductId = (payload: Array<string>) => {
        dispatch({type: 'SET_FAVOURITE_PRODUCT', payload})
    }

    const addFavouriteProductId = (payload: string) => {
        dispatch({type: 'ADD_FAVOURITE_PRODUCT', payload})
    }

    const removeFavouriteProductId = (payload: string) => {
        dispatch({type: 'REMOVE_FAVOURITE_PRODUCT', payload})
    }

    const setProductIdToMinOrderQty = (payload: { [index: string]: number }) => {
        dispatch({type: 'SET_PRODUCT_ID_TO_MIN_ORDER_QTY', payload})
    }    

    const setProductIdToOrderMultiple = (payload: { [index: string]: number }) => {
        dispatch({type: 'SET_PRODUCT_ID_TO_ORDER_MULTIPLE', payload})
    }    

    const setTabbedProducts = (payload: Array<ProductExt>) => {
        dispatch({type: 'SET_TABBED_PRODUCTS', payload})
    }    

    const contextValues = {
        setProducts,
        setBrands,
        setHighlightedBrands,
        setCategories,
        setDeviceVendors,
        setClearance,
        setNew,
        setSpecial,
        setTopSeller,
        setLoadingState,
        setFinishLoading,
        setFavouriteProductId,
        addFavouriteProductId,
        removeFavouriteProductId,
        setProductIdToMinOrderQty,
        setProductIdToOrderMultiple,
        setTabbedProducts,
        productState
    } 

    return ( 
        <ProductContext.Provider value={contextValues} >
            { children }
        </ProductContext.Provider>
     );
}
 
export default ProductContextProvider;
