import Cookies from 'universal-cookie';
import { mainUrl } from './Constants';
import { stringify } from 'querystring';

const cookies = new Cookies();

export interface MethodCallback<T> {

  onFailure(error: string, context?: any): void;

  onProgress(loaded: number, total: number): void;

  onSuccess(message: T, context?: any): void;
}

export class UserAccess
{
  // Base Access Options
  public static readonly ADD_NEW_USERS: string = 'A';
  public static readonly VIEW_BACKORDERS: string = 'B';
  public static readonly VIEW_CREDIT_DATA: string = 'C';
  public static readonly VIEW_DEALER_PRICES: string = 'D';
  public static readonly REQUIRE_ORDER_APPROVAL: string = 'E';
  public static readonly APPROVE_ORDERS: string = 'F';
  public static readonly VIEW_ORDER_HISTORY: string = 'H';
  public static readonly VIEW_INVOICES: string = 'I';
  public static readonly LODGE_RETURN_REQUESTS: string = 'L';
  public static readonly CANCEL_BACKORDER_ITEMS: string = 'O';
  public static readonly PLACE_ORDERS: string = 'P';
  public static readonly PRODUCT_CATALOG: string = 'Q';
  public static readonly VIEW_RETAIL_PRICES: string = 'R';
  public static readonly STOCK_ROTATIONS: string = 'S';
  public static readonly SHOPPING_CART: string = 'T';
  public static readonly VERSION5: string = 'V';
  public static readonly CUSTOMER_SPECIAL_ACCESS: string = 'X';

  // Cellnet Staff Options
  public static readonly ACCOUNTS_DEPT: string = 'A';
  public static readonly CALL_CENTRE: string = 'C';
  public static readonly GRADING_FUNCTIONS: string = 'D';
  public static readonly EBAY_FUNCTIONS: string = 'E';
  public static readonly GRADING_MANAGER: string = 'G';
  public static readonly IT_DEPT: string = 'I';
  public static readonly CAREERS_MANAGER: string = 'J';
  public static readonly KIOSK_ACCESS: string = 'K';
  public static readonly CUSTOMER_MASQUERADE: string = 'M';
  public static readonly NEWS_EVENTS_MANAGER: string = 'N';
  public static readonly SERVICE_COORDINATOR: string = 'O';
  public static readonly PRODUCTS_DEPT: string = 'P';
  public static readonly WEB_PREVIEWS: string = 'Q';
  public static readonly SERVICE_DEPT: string = 'R';
  public static readonly SALES_DEPT: string = 'S';
  public static readonly SENIOR_MANAGERS: string = 'T';
  public static readonly SERVICE_SUPERVISOR: string = 'U';
  public static readonly WEB_MAINTENANCE: string = 'W';
  public static readonly EXTERNAL_ACCESS: string = 'X';
  public static readonly SECURITY_ADMIN_GENERATION: string = 'Z';

  // Customer Extra Access Options
  public static readonly ENTER_ORDER_COMMENTS: string = 'A';
  public static readonly TELSTRACLEAR_ACCESS: string = 'C';
  public static readonly DATA_FEED_NOACCESS: string = 'D';
  public static readonly CHECKOUT_FIXED_ADDRESS: string = 'F';
  public static readonly TELSTRA_KIOSK_ORDERS: string = 'K';
  public static readonly CREDIT_CARD_ONLY: string = 'O';
  public static readonly PLACE_TELSTRA_ORDERS: string = 'P';
  public static readonly CUSTOMER_WEB_PREVIEWS: string = 'Q';
  public static readonly HIDE_ACCESS_SETTINGS: string = 'S';
  public static readonly VIEW_TELECOM_STOCK_LEVELS: string = 'T';
  public static readonly VHA_ACCESS: string = 'V';

  // Status Types
  public static readonly ACTIVE_STATUS: string = "20";
  public static readonly INACTIVE_STATUS: string = "90";
}

export class FreightMethodBean
{
  private id: string;
  private name: string;
  private charge: number;
  private errorCode: number;
  private sequence: number;
  private code: string;
  private checkoutDefault: boolean;

  private static methods: Map<string,FreightMethodBean>;

  public static UNSPECIFIED: FreightMethodBean = new FreightMethodBean("", "Unspecified", 0, 0.00, "");

  public static LOCAL: FreightMethodBean = new FreightMethodBean("LOCAFR", "Local", 2, 0.00, "LFR");
  public static ROAD: FreightMethodBean = new FreightMethodBean("ROADFR", "Road", 3, 0.00, "RFR");
  public static AIR: FreightMethodBean = new FreightMethodBean("AIRFRT", "Air", 1, 0.00, "AFR");
//  public static POINT: FreightMethodBean = new FreightMethodBean("PTPFRT", "Point to Point", 4, "PUP");
  public static OLDPICKUP: FreightMethodBean = new FreightMethodBean("OLDPICKUP", "Customer Pickup", 5, 0.0, "PTP");
  public static PICKUP: FreightMethodBean = new FreightMethodBean("PICKUP", "Customer Pickup", 5, 0.0, "PUP");
  public static OWNCOURIER: FreightMethodBean = new FreightMethodBean("OWNCUR", "Customer Courier", 6, 0.00, "OWN");

  // New Types (replaces LOCAL, ROAD and AIR)
  public static STANDARD: FreightMethodBean = new FreightMethodBean("STD", "Standard", 1, 0.00, "STD");
  public static EXPRESS: FreightMethodBean = new FreightMethodBean("EXPRSS", "Express", 2, 0.00, "EXP");

  public static NZ_STANDARD: FreightMethodBean = new FreightMethodBean("COURPO", "Standard", 1, 0.00, "COP");

  public static FRT5: FreightMethodBean = new FreightMethodBean("FRT5", "Fixed", 1, 10.00, "TEX");
  
  // M3 types
  public static AUFLAT: FreightMethodBean = new FreightMethodBean("AUFLAT", "Freight", 1, 13.64, "STD");
  public static AUEXPRESS: FreightMethodBean = new FreightMethodBean("AUEXPRESS", "Freight", 2, 13.64, "EXP");
  public static NZFLAT: FreightMethodBean = new FreightMethodBean("NZFLAT", "Standard", 1, 6.09, "STD");
  public static FREEEXP: FreightMethodBean = new FreightMethodBean("FREEEXP", "Free Freight", 3, 0.00, "EXP");
  public static FREESTD: FreightMethodBean = new FreightMethodBean("FREESTD", "Free Freight", 3, 0.00, "STD");
  
  static initialize(businessGroup: string): void
  {
    // Define freight methods. This should be in a table.
    FreightMethodBean.methods = new Map<string, FreightMethodBean>();
    if (businessGroup.startsWith("NZ"))
    {
      FreightMethodBean.methods.set(FreightMethodBean.NZ_STANDARD.getId(), FreightMethodBean.NZ_STANDARD);
      FreightMethodBean.methods.set(FreightMethodBean.OWNCOURIER.getId(), FreightMethodBean.OWNCOURIER);
      
      FreightMethodBean.methods.set(FreightMethodBean.NZFLAT.getId(), FreightMethodBean.NZFLAT);
      FreightMethodBean.methods.set(FreightMethodBean.FREESTD.getId(), FreightMethodBean.FREESTD);
      FreightMethodBean.methods.set(FreightMethodBean.PICKUP.getId(), FreightMethodBean.PICKUP);
      FreightMethodBean.NZFLAT.setCheckoutDefault(true);
    }
    else
    {
      FreightMethodBean.methods.set(FreightMethodBean.STANDARD.getId(), FreightMethodBean.STANDARD);
      FreightMethodBean.methods.set(FreightMethodBean.EXPRESS.getId(), FreightMethodBean.EXPRESS);
      FreightMethodBean.methods.set(FreightMethodBean.LOCAL.getId(), FreightMethodBean.LOCAL);
      FreightMethodBean.methods.set(FreightMethodBean.ROAD.getId(), FreightMethodBean.ROAD);
      FreightMethodBean.methods.set(FreightMethodBean.AIR.getId(), FreightMethodBean.AIR);
      FreightMethodBean.methods.set(FreightMethodBean.OLDPICKUP.getId(), FreightMethodBean.OLDPICKUP);
      FreightMethodBean.methods.set(FreightMethodBean.PICKUP.getId(), FreightMethodBean.PICKUP);
      FreightMethodBean.methods.set(FreightMethodBean.OWNCOURIER.getId(), FreightMethodBean.OWNCOURIER);
      
      FreightMethodBean.methods.set(FreightMethodBean.AUFLAT.getId(), FreightMethodBean.AUFLAT);
      FreightMethodBean.methods.set(FreightMethodBean.AUEXPRESS.getId(), FreightMethodBean.AUEXPRESS);
      FreightMethodBean.methods.set(FreightMethodBean.FREESTD.getId(), FreightMethodBean.FREESTD);
      FreightMethodBean.methods.set(FreightMethodBean.FREEEXP.getId(), FreightMethodBean.FREEEXP);
      FreightMethodBean.AUFLAT.setCheckoutDefault(true);
    }
  }

  constructor(id: string, name: string, sequence: number, charge: number, code: string)
  {
    this.charge = charge;
    this.id = id;
    this.name = name;
    this.sequence = sequence;
    this.errorCode = 0;
    this.code = code;
    this.checkoutDefault = false;
  }

  public getCharge(): number
  {
    return this.charge;
  }

  public setCharge(charge: number): void
  {
    this.charge = charge;
  }

  public  getName(): string
  {
    return this.name;
  }

  public setName(name: string): void
  {
    this.name = name;
  }

  public getErrorCode(): number
  {
    return this.errorCode;
  }

  public setErrorCode(errorCode: number): void
  {
    this.errorCode = errorCode;
  }

  public getId(): string
  {
    return this.id;
  }

  public setId(id: string): void
  {
    this.id = id;
  }

  public getSequence(): number
  {
    return this.sequence;
  }

  public setSequence(sequence: number): void
  {
    this.sequence = sequence;
  }

  public isPickup(): boolean
  {
    return (this.id === "PICKUP" || this.id === "OWNCUR");
  }

  public compareTo(otherMethod: FreightMethodBean): number
  {
    return (this.sequence - otherMethod.sequence);
  }

  public static getMethod(id: string): FreightMethodBean | undefined
  {
    return FreightMethodBean.methods.get(id);
  }

  public getCode(): string
  {
    return this.code;
  }

  public setCode(code: string): void
  {
    this.code = code;
  }

  public isCheckoutDefault(): boolean
  {
    return this.checkoutDefault;
  }

  public setCheckoutDefault(checkoutDefault: boolean): void
  {
    this.checkoutDefault = checkoutDefault;
  }

  public static getFreightMethodsForOrder(businessGroup: string, customer: Customer, orderBasePriceIncTax: number): FreightMethodBean[]
  {
    let methods: FreightMethodBean[] = [];
    
    if (businessGroup.startsWith("NZ"))
    {
      if ("FEX" === customer.deliveryTerms || orderBasePriceIncTax >= 50.0)
      {
        methods.push(FreightMethodBean.FREESTD);
      }
      else
      {
        methods.push(FreightMethodBean.NZFLAT);
      }
      methods.push(FreightMethodBean.PICKUP);      
    }
    else
    {
      if ("FEX" === customer.deliveryTerms || orderBasePriceIncTax >= 200.0)
      {
        if ("EXP" === customer.deliveryMethod)
        {
          methods.push(FreightMethodBean.FREEEXP);
        }
        else
        {
          methods.push(FreightMethodBean.FREESTD);
        }
      }
      else if ("EXP" === customer.deliveryMethod)
      {
        methods.push(FreightMethodBean.AUEXPRESS);
      }    
      else
      {
        methods.push(FreightMethodBean.AUFLAT);
      }
    }
    
    return methods;    
  }  
}
FreightMethodBean.initialize("AU");

export class PaymentMethodBean
{
  private static methods: Map<string,PaymentMethodBean>;

  private id: string;
  private description: string;
  private externalCheckoutFlow: boolean;
  private surchargePct: number;

  public static MASTERCARD_CREDIT: PaymentMethodBean = new PaymentMethodBean("MASTERCARD_CREDIT", "Mastercard Credit", true, 0.009);
  public static MASTERCARD_DEBIT: PaymentMethodBean = new PaymentMethodBean("MASTERCARD_DEBIT", "Mastercard Debit", true, 0.005);
  public static VISA_CREDIT: PaymentMethodBean = new PaymentMethodBean("VISA_CREDIT", "VISA Credit", true, 0.0092);
  public static VISA_DEBIT: PaymentMethodBean = new PaymentMethodBean("VISA_DEBIT", "VISA Debit", true, 0.0048);
  public static AMEX: PaymentMethodBean = new PaymentMethodBean("AMEX", "American Express", true, 0.02145);
  public static DIRECT_DEPOSIT: PaymentMethodBean = new PaymentMethodBean("DIRECT", "Direct deposit", false, 0.0);
  public static CUSTOMER_PICKUP: PaymentMethodBean = new PaymentMethodBean("PICKUP", "Pay upon pickup", false, 0.0);
  public static PAYPAL: PaymentMethodBean = new PaymentMethodBean("PAYPAL", "PayPal", true, 0.02);

  static initialize(): void
  {
    PaymentMethodBean.methods = new Map<string, PaymentMethodBean>();
    PaymentMethodBean.methods.set(PaymentMethodBean.MASTERCARD_CREDIT.getId(), PaymentMethodBean.MASTERCARD_CREDIT);
    PaymentMethodBean.methods.set(PaymentMethodBean.MASTERCARD_DEBIT.getId(), PaymentMethodBean.MASTERCARD_DEBIT);
    PaymentMethodBean.methods.set(PaymentMethodBean.VISA_CREDIT.getId(), PaymentMethodBean.VISA_CREDIT);
    PaymentMethodBean.methods.set(PaymentMethodBean.VISA_DEBIT.getId(), PaymentMethodBean.VISA_DEBIT);
    PaymentMethodBean.methods.set(PaymentMethodBean.AMEX.getId(), PaymentMethodBean.AMEX);
    PaymentMethodBean.methods.set(PaymentMethodBean.DIRECT_DEPOSIT.getId(), PaymentMethodBean.DIRECT_DEPOSIT);
    PaymentMethodBean.methods.set(PaymentMethodBean.CUSTOMER_PICKUP.getId(), PaymentMethodBean.CUSTOMER_PICKUP);
    PaymentMethodBean.methods.set(PaymentMethodBean.PAYPAL.getId(), PaymentMethodBean.PAYPAL);
  }

  constructor(id: string, description: string, isExternal: boolean, surchargePct: number)
  {
    this.id = id;
    this.description = description;
    this.externalCheckoutFlow = isExternal;
    this.surchargePct = surchargePct;
  }

  public getId(): string
  {
    return this.id;
  }

  public isExternalCheckoutFlow(): boolean
  {
    return this.externalCheckoutFlow;
  }

  public getSurchargePct(): number
  {
    return this.surchargePct;
  }

  public getDescription(): string
  {
    return this.description;
  }

  public static get(id: string): PaymentMethodBean | undefined
  {
    return PaymentMethodBean.methods.get(id.toUpperCase());
  }
}

PaymentMethodBean.initialize();

export class OrderStatusBean
{
  private static statuses: Map<string, OrderStatusBean>;

  private id: string;
  private name: string;

  public static PENDING: OrderStatusBean = new OrderStatusBean("00", "Pending");
  public static ERROR: OrderStatusBean = new OrderStatusBean("01", "Processing Error");
  public static UNKNOWN: OrderStatusBean = new OrderStatusBean("02", "Unknown");
  public static QUOTE: OrderStatusBean = new OrderStatusBean("05", "Quotation");
  public static PRELIM: OrderStatusBean = new OrderStatusBean("10", "Preliminary");
  public static REGISTERED: OrderStatusBean = new OrderStatusBean("20", "Registered");
  public static BACKORDER_PENDING: OrderStatusBean = new OrderStatusBean("22", "Stock is on backorder");
  public static BACKORDER_ALLOCATED_PARTIAL: OrderStatusBean = new OrderStatusBean("23", "On backorder, partially received");
  public static BACKORDER_PICKED_PARTIAL: OrderStatusBean = new OrderStatusBean("24", "On backorder, partially received");
  public static BACKORDER_DELIVERED_PARTIAL: OrderStatusBean = new OrderStatusBean("26", "On backorder, partially fulfilled & shipped");
  public static BACKORDER_INVOICED_PARTIAL: OrderStatusBean = new OrderStatusBean("27", "On backorder, partially fulfilled & shipped");
  public static ALLOCATED: OrderStatusBean = new OrderStatusBean("33", "Stock has been allocated, but not picked");
  public static ALLOCATED_PARTIAL: OrderStatusBean = new OrderStatusBean("37", "Order has been partially fulfilled & shipped");
  public static PICKED: OrderStatusBean = new OrderStatusBean("44", "Pick in progress");
  public static PICKED_PARTIAL: OrderStatusBean = new OrderStatusBean("47", "Order has been partially fulfilled & shipped");
  public static DELIVERED: OrderStatusBean = new OrderStatusBean("66", "Stock has been picked,but not shipped");
  public static INVOICED: OrderStatusBean = new OrderStatusBean("77", "Order has been shipped");
  public static INVOICED_CANCELLED_PARTIAL: OrderStatusBean = new OrderStatusBean("79", "Order has been partially shipped & cancelled");
  public static DELETED: OrderStatusBean = new OrderStatusBean("90", "Deleted");
  public static CANCELLED: OrderStatusBean = new OrderStatusBean("99", "Cancelled");
  public static VARIES: OrderStatusBean = new OrderStatusBean("XX", "In Progress");

  static initialize(): void
  {
    OrderStatusBean.statuses = new Map<string, OrderStatusBean>();
    OrderStatusBean.statuses.set(OrderStatusBean.PENDING.getId(), OrderStatusBean.PENDING);
    OrderStatusBean.statuses.set(OrderStatusBean.ERROR.getId(), OrderStatusBean.ERROR);
    OrderStatusBean.statuses.set(OrderStatusBean.UNKNOWN.getId(), OrderStatusBean.UNKNOWN);
    OrderStatusBean.statuses.set(OrderStatusBean.QUOTE.getId(), OrderStatusBean.QUOTE);
    OrderStatusBean.statuses.set(OrderStatusBean.PRELIM.getId(), OrderStatusBean.PRELIM);
    OrderStatusBean.statuses.set(OrderStatusBean.BACKORDER_PENDING.getId(), OrderStatusBean.BACKORDER_PENDING);
    OrderStatusBean.statuses.set(OrderStatusBean.BACKORDER_ALLOCATED_PARTIAL.getId(), OrderStatusBean.BACKORDER_ALLOCATED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.BACKORDER_PICKED_PARTIAL.getId(), OrderStatusBean.BACKORDER_PICKED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.BACKORDER_DELIVERED_PARTIAL.getId(), OrderStatusBean.BACKORDER_DELIVERED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.BACKORDER_INVOICED_PARTIAL.getId(), OrderStatusBean.BACKORDER_INVOICED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.ALLOCATED.getId(), OrderStatusBean.ALLOCATED);
    OrderStatusBean.statuses.set(OrderStatusBean.ALLOCATED_PARTIAL.getId(), OrderStatusBean.ALLOCATED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.PICKED.getId(), OrderStatusBean.PICKED);
    OrderStatusBean.statuses.set(OrderStatusBean.PICKED_PARTIAL.getId(), OrderStatusBean.PICKED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.DELIVERED.getId(), OrderStatusBean.DELIVERED);
    OrderStatusBean.statuses.set(OrderStatusBean.INVOICED.getId(), OrderStatusBean.INVOICED);
    OrderStatusBean.statuses.set(OrderStatusBean.INVOICED_CANCELLED_PARTIAL.getId(), OrderStatusBean.INVOICED_CANCELLED_PARTIAL);
    OrderStatusBean.statuses.set(OrderStatusBean.DELETED.getId(), OrderStatusBean.DELETED);
    OrderStatusBean.statuses.set(OrderStatusBean.CANCELLED.getId(), OrderStatusBean.CANCELLED);
    OrderStatusBean.statuses.set(OrderStatusBean.VARIES.getId(), OrderStatusBean.VARIES);
  }

  constructor(id: string, name: string)
  {
    this.id = id;
    this.name = name;
  }

  public getId(): string
  {
    return this.id;
  }

  public getName(): string
  {
    return this.name;
  }

  public setId(id: string): void
  {
    this.id = id;
  }

  public setName(name: string): void
  {
    this.name = name;
  }

  public static get(id: string): OrderStatusBean
  {
    var status: OrderStatusBean | undefined = OrderStatusBean.statuses.get(id);
    if (status === undefined)
    {
      status = OrderStatusBean.UNKNOWN;
    }
    return status;
  }
}
OrderStatusBean.initialize();

export class ShoppingCartStatusBean
{
  private id: string;
  private name: string;

  private static statuses: Map<String, ShoppingCartStatusBean>;

  public static ACTIVE: ShoppingCartStatusBean = new ShoppingCartStatusBean("", "Active");
  public static CLOSED: ShoppingCartStatusBean = new ShoppingCartStatusBean("CL", "Closed");
  public static DELETED: ShoppingCartStatusBean = new ShoppingCartStatusBean("DL", "Deleted");
  public static PENDING: ShoppingCartStatusBean = new ShoppingCartStatusBean("PE", "Pending");
  public static WAIT_APPROVAL: ShoppingCartStatusBean = new ShoppingCartStatusBean("WA", "Awaiting Approval");
  public static APPROVED: ShoppingCartStatusBean = new ShoppingCartStatusBean("AP", "Approved - Order Placed");
  public static REJECTED: ShoppingCartStatusBean = new ShoppingCartStatusBean("RJ", "Rejected");
  public static UNKNOWN: ShoppingCartStatusBean = new ShoppingCartStatusBean("UK", "Unknown");

  static initialize(): void
  {
    ShoppingCartStatusBean.statuses = new Map<String, ShoppingCartStatusBean>();
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.ACTIVE.getId(), ShoppingCartStatusBean.ACTIVE);
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.CLOSED.getId(), ShoppingCartStatusBean.CLOSED);
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.DELETED.getId(), ShoppingCartStatusBean.DELETED);
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.PENDING.getId(), ShoppingCartStatusBean.PENDING);
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.WAIT_APPROVAL.getId(), ShoppingCartStatusBean.WAIT_APPROVAL);
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.APPROVED.getId(), ShoppingCartStatusBean.APPROVED);
    ShoppingCartStatusBean.statuses.set(ShoppingCartStatusBean.REJECTED.getId(), ShoppingCartStatusBean.REJECTED);
  }

  public static get(id: string): ShoppingCartStatusBean
  {
    let status: ShoppingCartStatusBean | undefined = ShoppingCartStatusBean.statuses.get(id);
    return (status === undefined ? ShoppingCartStatusBean.UNKNOWN : status);
  }

  constructor(id: string, name: string)
  {
    this.id = id;
    this.name = name;
  }

  public getId(): string
  {
    return this.id;
  }

  public getName(): string
  {
    return this.name;
  }
}
ShoppingCartStatusBean.initialize();

enum ActivityType {
  PRODUCT = "P",
  INVOICE = "I",
  ORDER = "O",
  BACKORDER = "B",
  RETURN = "R",
  DEVICE = "D",
  ACCOUNT = "A"  
}


export class ClientHelper 
{
  // private static DateTimeFormat dateTimeFmt = DateTimeFormat.getFormat("dd-MMM-yyyy HH:mm:ss");
  // private static DateTimeFormat movexDateTimeFormat = DateTimeFormat.getFormat("yyyyMMddHHmmss");
  public static returnStatusToDesc: Map<String,String> = new Map<String,String>([
      ["AP", "Approved"],
      ["TP", "Approved"],
      ["UP", "Approved"],
      ["CL", "Closed"],
      ["IP", "In Progress"],
      ["IN", "Incomplete"],
      ["PE", "Pending"],
      ["RJ", "Rejected"],
      ["EX", "Expired"],
      ["UA", "Unassigned"],
      ["OP", "Order Pending"]]);


  public static stringToDoubleNullToZero(doubleString: string | null | undefined): number
  {
    if (doubleString === null || doubleString === undefined)
    {
      return 0.0;
    }
    return parseFloat(doubleString);
  }
  
  public static getCreditLimit(customer: Customer, payer: Customer | null | undefined): number
  {
    let creditLimit: number = this.stringToDoubleNullToZero(customer.creditLimit3);
    if (creditLimit <= 0.01)
      creditLimit = this.stringToDoubleNullToZero(customer.creditLimit2);
    if (payer && customer.id !== payer.id)
      creditLimit = this.getCreditLimit(payer, null);
    return creditLimit;
  }
  
  public static isCashCustomer(customer: Customer, payer: Customer): boolean
  {
    // Those with no Credit Limit or on 7Day or COD Terms are to be treated as
    // Cash Customers
    return ((this.getCreditLimit(customer, payer) <= 1.00) || "07I" === customer.paymentTermsId || "COD" === customer.paymentTermsId);
  }
  
  public static isPayerCustomer(customer: Customer): boolean
  {
    return customer.payerId === null || customer.payerId === undefined || customer.payerId === customer.id;
  }

  public static isCustomAddressesAllowed(customer: Customer): boolean
  {
    return "Y" === customer.customAddresses;
  }   
  
  // public static Date javaDateTime(String dateStr)
  // {
  //   try
  //   {
  //     return movexDateTimeFormat.parse(dateStr);
  //   }
  //   catch (Exception e)
  //   {
  //     return null;
  //   }
  // }  
  
  // public static String formattedDateTime(Date value)
  // {
  //   if (value === null)
  //   {
  //     return "";
  //   }
  //   return dateTimeFmt.format(value);
  // }
  
  // public static String formattedDateTime(String dateStr)
  // {
  //   if (dateStr === null)
  //   {
  //     return "";
  //   }
  //   Date date = javaDateTime(dateStr);
  //   if (date === null)
  //   {
  //     return "";
  //   }
  //   return dateTimeFmt.format(date);
  // }  
  
  public static nullToEmpty(inString: string | null | undefined): string
  {
    return (inString === null || inString === undefined) ? "" : inString;
  }  
  
  public static nullToEmptyTrimmed(inString: string | null | undefined): string
  {
    return (inString === null || inString === undefined) ? "" : inString.trim();
  }
  
  public static decimalToPercentage(value: number): string {
    const percentage = (value * 100).toLocaleString('en-AU', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  
    return percentage + "%";
  }  
}

export type DataFeedDeliveryTime = "AM11" | "PM3" | "PM7";

export type DataFeedColumns = "ID" | "ProductName" | "VendorName" | "VendorSKU" | "Category" | "SubCategory" | "ShortDescription" | "LongDescription" | "ImageURL" | "Image2URL" | "Image3URL" | "FullImageURL" | "FullImage2URL" | "FullImage3URL" | "EAN" | "Price" | "RRP" | "ProductWeight" | "ProductVolume" | "ProductLength" | "ProductHeight" | "ProductWidth" | "StockLevel" | "CustomerSKU" | "Colour" | "ETA" | "PriceBreaks";

export type DataFeedFileFormat = "CSV" | "TAB" | "XML";


export interface AccountMessage {
  authenticated: boolean;
  error: string;
  invoiceAddress: Address;
  publicDetail: PublicDetails;
  fileDetails: FileDetails[];
  orderApprovers: UserProfile[];
  creditSummary: CreditSummary;
}

export interface RecentActivityMessage {
    authenticated: boolean;
    error: string;
    recentActivities: RecentActivity[];
}

export interface AddressesMessage {
  authenticated: boolean;
  error: string;
  addresses: Address[];
}

export interface FilesMessage {
  authenticated: boolean;
  error: string;
  fileDetails: FileDetails[];
}

export interface FavouritesMessage {
  authenticated: boolean;
  error: string;
  productIds: string[];
}

export interface ProductTabMessage {
  authenticated: boolean;
  error: string;
  productIds: string[];
}

export interface ReturnMessage {
  authenticated: boolean;
  error: string;
  returnRequest: ReturnRequest;
  lines: ReturnRequestLine[];
  documents: ReturnRequestDocument[];
}

export interface UsersMessage {
  authenticated: boolean;
  error: string;
  managedUserAccounts: UserAccountExt[];
  managedUserProfiles: UserProfile[];
}

export interface AlertsMessage {
  authenticated: boolean;
  error: string;
  readAlerts: Alert[];
  unreadAlerts: Alert[];
}

export interface AllocateInvoicePaymentsMessage {
    authenticated: boolean;
    error: string;
    warnings: string[];
    allocationToken: string;
    ccPaymentId: string;
    payPalPayment: string;
    payPalClientId: string;
    surcharge: number;
    discount: number;
    totalPayment: number;    
}

export interface BackOrdersMessage {
  authenticated: boolean;
  error: string;
  orders: Order[];
  orderNoToOrderLines: { [index: string]: OrderLine[] };
}

export interface CancelBackOrderMessage {
  authenticated: boolean;
  error: string;
  info: string;
}

export interface InitMessage {
  authenticated: boolean;
  error: string;
  firstName: string;
  lastName: string;
  login: string;
  userId: string;
  email: string;
  phone: string;
  type: string;
  token: string;
  check: string;
  userAccounts: UserAccountExt[];
  customers: Customer[];
  payers: Customer[];
  currentUserAccount: UserAccountExt;
  currentCustomer: Customer;
  currentPayer: Customer;
  businessGroup: string;
  homeContent: string;
  banners: ConnectBanner[];
  production: boolean;
}

export interface CheckCCAllocationPaymentMessage {
    authenticated: boolean;
    error: string;
    status: string;
    allocationStatus: string;
}

export interface CheckCCOrderPaymentMessage {
    authenticated: boolean;
    error: string;
    status: string;
    movexOrderNo: string;
    movexTempOrderNo: string;
    order: Order;
    orderLines: OrderLine[];    
}

export interface CheckoutMessage {
    authenticated: boolean;
    error: string;
    warnings: string[];
    webOrderNo: string;
    orderBaseTotalExGST: number;
    freightChargeExGST: number;
    adminFeeExGST: number;
    totalGST: number;
    totalIncGST: number;    
    ccPaymentId: string;
    payPalPayment: string;
    payPalClientId: string;
    status: string;
    movexOrderNo: string;
    movexTempOrderNo: string;
    order: Order;
    orderLines: OrderLine[];    
}

export interface CheckPayPalPaymentMessage {
  authenticated: boolean;
  error: string;
  status: string;
  movexOrderNo: string;
}

export interface UpdatePayPalAllocationPaymentMessage {
    authenticated: boolean;
    error: string;
    status: string;
}

export interface UpdatePayPalOrderPaymentMessage {
    authenticated: boolean;
    error: string;
    status: string;
    movexOrderNo: string;
    movexTempOrderNo: string;
    order: Order;
    orderLines: OrderLine[];    
}

export interface ShoppingCartsMessage {
  authenticated: boolean;
  error: string;
  currentShoppingCartId: string;
  shoppingCartIdToShoppingCart: { [index: string]: ShoppingCart };
  shoppingCartIdToLines: { [index: string]: ShoppingCartline[] };
}

export interface DataFeedMessage {
  authenticated: boolean;
  error: string;
  deliveryMethodFTP: DataFeedDeliveryMethodFTP;
  deliveryMethodEmail: DataFeedDeliveryMethodEmail;
  deliveryMethodWebLink: DataFeedDeliveryMethodWebLink;
  deliveryMethodAzure: DataFeedDeliveryMethodAzure;
  deliveryTimes: DataFeedDeliveryTime[];
  selectedColumns: DataFeedColumns[];
  fileFormat: DataFeedFileFormat;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

export interface DeleteReturnMessage {
  authenticated: boolean;
  error: string;
}

export interface DeliveryAddressesMessage {
  authenticated: boolean;
  error: string;
  addressIdToDeliveryAddress: { [index: string]: Address };
}

export interface InvoicesMessage {
  authenticated: boolean;
  error: string;
  invoices: Invoice[];
  invoiceNoToInvoiceLines: { [index: string]: InvoiceLine[] };
}

export interface DueInvoicesMessage {
  authenticated: boolean;
  error: string;
  invoices: Invoice[];
}

export interface OrdersMessage {
  authenticated: boolean;
  error: string;
  orders: Order[];
  orderNoToOrderLines: { [index: string]: OrderLine[] };
}

export interface TrackingDatasMessage {
  authenticated: boolean;
  error: string;
  shipmentNoToTrackingDatas: { [index: string]: TrackingDataExt[] };
}

export interface TransactionsAndPaymentsMessage {
  authenticated: boolean;
  error: string;
  invoiceNoToTransactions: { [index: string]: Transaction[] };
  invoiceNoToAllocationPayments: { [index: string]: AllocationPayment[] };
}

export interface LoginMessage {
  error: string;
  token: string;
}

export interface LogoutMessage {
  error: string;
}

export interface PreferencesMessage {
  authenticated: boolean;
  error: string;
  preferenceNameToPreference: { [index: string]: Preference };
}

export interface ProductsMessage {
    authenticated: boolean;
    error: string;
    productExts: ProductExt[];
    handsetItems: HandsetItem[];
    topSellerProductIds7Days: string[];
    topSellerProductIds30Days: string[];
    favouriteProductIds: string[];
    highlightedBrands: string[];
    vendorDeviceToIntroductionDate: { [index: string]: number };
    productIdToMinOrderQty: { [index: string]: number };
    productIdToOrderMultiple: { [index: string]: number };
    productTabs: string[];
}

export interface RecentActivityMessage {
  authenticated: boolean;
  error: string;
  recentActivities: RecentActivity[];
}

export interface ResetPasswordMessage {
  error: string;
}

export interface ReturnsMessage {
  authenticated: boolean;
  error: string;
  returns: ReturnRequest[];
  lines: { [index: string]: ReturnRequestLine[] };
  documents: { [index: string]: ReturnRequestDocument[] };
  allReasonCodeToReason: { [index: string]: ReturnReason };
  customerReasonCodes: string[];
}

export interface SearchAccountMessage {
  authenticated: boolean;
  error: string;
  results: SearchAccountResult[];
}

export interface SearchMessage {
  authenticated: boolean;
  error: string;
  results: SearchResult[];
  total: number;
}

export interface PathMessage {
  authenticated: boolean;
  lastPath: string;
}

export interface PublicPagePreferencesMessage {
  authenticated: boolean;
  error: string;
  publicDetails: PublicDetails;
}

export interface CreditSummary {
    id: string;
    balanceCurrent: number;
    balance30Days: number;
    balance60Days: number;
    balance90Days: number;
    balance120Days: number;
    totalDue: number;
    overdue: number;
    outstanding: number;
    currency: string;
}

export interface Order {
  orderno: string;
  division: string;
  customerid: string;
  customerref: string;
  ordertype: string;
  orderdate: number;
  highstatus: string;
  lowstatus: string;
  baseprice: number;
  priority: number;
  facility: string;
  deliveryaddressid: string;
  deliveryspecification: string;
}

export interface OrderLine {
  id: number;
  suffix: number;
  orderno: string;
  status: string;
  warehouse: string;
  itemno: string;
  itemname: string;
  itemdesc: string;
  orderedqty: number;
  deliveredqty: number;
  invoicedqty: number;
  remainingqty: number;
  allocatedqty: number;
  unitprice: number;
  date: number;
  customerid: number;
}

export interface Address {
  addressId: string;
  addressName: string;
  contactName: string;
  contactPhone: string;
  contactFax: string;
  contactEmail: string;
  companyName: string;
  postcode: string;
  suburb: string;
  city: string;
  state: string;
  country: string;
  lines: string[];
}

export interface Invoice {
  invoiceno: number;
  originalinvoiceno: number;
  custref: string;
  invoicedate: number;
  duedate: number;
  amount: number;
  lineamountexgst: number;
  gst: number;
  amountexgst: number;
  freighttype: string;
  freightexgst: number;
  payer: string;
  recipient: string;
  custno: string;
  invyear: number;
  orderno: string;
  dlvindex: number;
  companyname: string;
  deliveryaddress1: string;
  deliveryaddress2: string;
  deliveryaddress3: string;
  deliveryaddress4: string;
  deliverysuburb: string;
  deliverypostcode: string;
  deliverytwocharstate: string;
  deliverycountry: string;
  custcompanyname: string;
  custdeliveryaddress1: string;
  custdeliveryaddress2: string;
  custdeliveryaddress3: string;
  custdeliveryaddress4: string;
  custdeliverysuburb: string;
  custdeliverypostcode: string;
  custdeliverytwocharstate: string;
  custdeliverycountry: string;
}

export interface InvoiceLine {
  serialno: string;
  custno: string;
  invoiceno: number;
  invoicedate: number;
  invyear: number;
  orderno: string;
  dlvindex: number;
  qty: number;
  warehouse: string;
  itemno: string;
  orderlineno: number;
  orderlinesuffix: number;
  saleprice: number;
}

export interface TrackingData {
  orderno: string;
  date: number;
  connote: string;
  parcels: number;
  shipmentno: number;
  carrier: string;
  carrierShortDesc: string;
  carrierLongDesc: string;
}

export interface TrackingDataExt {
  trackingData: TrackingData;
  url: string;
}

export interface PublicDetails {
  customerid: string;
  companyname: string;
  contactname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  suburb: string;
  city: string;
  state: string;
  postcode: string;
  countrycode: string;
  phoneno: string;
  faxno: string;
  logopath: string;
  registereddate: number;
  registeredtime: number;
  userid: string;
}

export interface FileDetails {
  name: string;
  length: number;
  modifiedDate: string;
}

export interface UserProfile {
  id: string;
  login: string;
  alias: string;
  firstname: string;
  lastname: string;
  email: string;
  type: string;
  changetoken: string;
  contactphone: string;
  contactfax: string;
  registered: string;
  logintokenid: string;
  activated: string;
  deactivated: string;
  skinid: number;
  sliderid: number;
  language: string;
  passwordhash: string;
}

export interface Transaction {
    invoiceNo: string;
    orderNo: string;
    raiserAccountId: string;
    division: string;
    accountYear: string;
    invoiceDate: string;
    dueDate: string;
    serialNo: string;
    qty: number;
    itemNo: string;
    orderLineNo: number;
    orderLineSuffix: number;
    salesAmount: number;
    taxPaid: number;
    paidAmount: number;
    itemName: string;
    itemDescription: string;
    reconciliationFlag: string;
    warehouse: string;
}

export interface AllocationPayment {
  invoiceNo: string;
  paidAmount: number;
}

export interface IsSerializable {
}

export interface RecentActivity {
    userid: string;
    sequence: number;
    type: string;
    description: string;
    timestamp: string;
    businessunit: string;
    parameters: string;
}

export interface ReturnRequest {
  requestno: number;
  rano: number;
  reason: string;
  customerno: string;
  companyname: string;
  contactname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  suburb: string;
  city: string;
  state: string;
  postcode: string;
  customerref: string;
  status: string;
  contactphone: string;
  contactfax: string;
  contactemail: string;
  triagetime: number;
  traigedby: string;
  assignedstaffid: string;
  triagenotes: string;
  restockfee: string;
  registeredtime: number;
  registeredby: string;
  rotationorder: string;
  closedtime: number;
}

export interface ReturnRequestLine {
  requestno: number;
  linno: number;
  linesuffix: number;
  serialno: string;
  reason: string;
  invoiceno: number;
  invoiceyear: number;
  itemno: string;
  vendorref: string;
  qty: number;
  arrivedqty: number;
  closedqty: number;
  maxqty: number;
  saleprice: number;
  faultfreq: string;
  faultdesc: string;
  faulttest: string;
  cardsoft: string;
  faulttime: string;
  popdate: number;
  bundleline: number;
  settingflags: string;
  returninstructionoverride: string;
  productreturninstruction: string;
  unsaleableqty: number;
  rejectedqty: number;
  movexclosereasoncode: string;
  warehouse: string;
  freightdropped: string;
}

export interface ReturnRequestDocument {
  id: number;
  requestno: number;
  uploadedfilename: string;
  originalfilename: string;
  uploaddate: number;
  uploadtime: number;
  userid: string;
}

export interface UserAccountExt {
  id: number;
  userid: string;
  permissions: string;
  accountid: string;
  businessarea: string;
  status: string;
  type: string;
  managerid: string;
  registered: string;
  registeredby: string;
  lastlogin: string;
  skinid: number;
  sliderid: number;
  restricttothemeid: number;
  extrapermissions: string;
}

export interface Alert {
  id: number;
  sequence: number;
  type: string;
  expirydate: number;
  messageline: string;
}

export interface Customer {
  id: string;
  name: string;
  deliveryId: string;
  payerId: string;
  status: string;
  country: string;
  creditLimit2: string;
  creditLimit3: string;
  paymentTermsId: string;
  deliveryTerms: string;
  deliveryMethod: string;
  restrictedCatalog: string;
  salesDistrict1: string;
  salesDistrict2: string;
  salesDistrict3: string;
  customerGroup: string;
  creditStop: string;
  defaultOrderType: string;
  defaultWarehouse: string;
  creditGroup: string;
  salesPerson: string;
  storeId: string;
  createdDate: number;
  assortmentOnly: string;
  consignment: string;
  customAddresses: string;
}

export interface Banner {
  smallbannertitle: string;
  smallbannerdescription: string;
  smallbannerfile: string;
  smalllinkurl: string;
  largebannertitle: string;
  largebannerdescription: string;
  largebannerfile: string;
  largelinkurl: string;
}

export interface ConnectBanner {
	businessgroup: string;
	sortno: number;
	active: string;
	backgroundcolor: string;
	linkorhref?: string;
	image375x575?: string;
	image1170x50?: string;
  content: string;
}

export interface ShoppingCart {
  id: string;
  type: string;
  status: string;
  name: string;
  registered: string;
  weborder: string;
  approvedorder: string;
  approver: string;
  approved: string;
  userid: string;
  accountid: string;
  linkedaddress: number;
}

export interface ShoppingCartline {
  cartid: string;
  productid: string;
  quantity: number;
  linkedproductid: string;
  linkedquantitycalcs: string;
  linkedunitprice: number;
  registered: string;
}

export interface DataFeedDeliveryMethodFTP {
  host: string;
  user: string;
  password: string;
  folder: string;
  port: number;
  sftp: boolean;
}

export interface DataFeedDeliveryMethodEmail {
  email: string;
}

export interface DataFeedDeliveryMethodWebLink {
  url: string;
}

export interface DataFeedDeliveryMethodAzure {
  accountName: string;
  accountKey: string;
  container: string;
  https: boolean;
}

export interface Preference {
  name: string;
  type: string;
  value: string;
}

export interface ProductExt {
  product: Product;
  retailPrice: number;
  wasPrice: number;
  priceBreakInfo: PriceBreakInfo;
  available: string;
  vendorDevices: string[];
  attributes: Attribute[];
  isRotatable: boolean;
}

export interface HandsetItem {
  vendorid: string;
  vendorname: string;
  handsetname: string;
  productgroup: string;
  productid: string;
  genuine: string;
}

export interface ReturnReason {
  code: string;
  name: string;
  termsconditions: string;
  addressrequired: string;
  doa: string;
  goodstock: string;
  stockrotation: string;
  restockfeerequired: string;
  pickupavailable: string;
  staffuseonly: string;
  donotreturn: string;
  sendtosony: string;
  bestfor: string;
  toptip: string;
}

export interface SearchResult {
  type: string;
  score: number;
  productId: string;
  shardIndex: number;
  title: string;
  description: string;
}

export interface SearchAccountResult {
  id: string;
  name: string;
}

export interface Product {
  id: string;
  name: string;
  description: string;
  weight: number;
  volume: number;
  vendor: string;
  procurementGroup: string;
  itemClass: string;
  serialised: number;
  serialLength: number;
  type: string;
  status: string;
  image: string;
  image2: string;
  image3: string;
  invAccounting: number;
  lotNumberMethod: number;
  invAccountMethod: number;
  techClass: string;
  webStatus: string;
  assortmentFlag: number;
  ean: string;
  shortName: string;
  packagingCode: string;
  registeredDate: number;
  popDateRequired: string;
  businessArea: string;
  colour: string;
  itemCategory: string;
  vendorName: string;
  procurementGroupDesc: string;
  bundle: number;
  statusLabel: string;
}

export interface PriceBreakInfo {
  priceBreaks: PriceBreak[];
}

export interface Attribute {
  name: string;
  value: string;
}

export interface PriceBreak {
  quantity: number;
  price: number;
}

export interface DealerApplication {
  applicationid: number;
  accounttype: string;
  mainemail: string;
  monthlyspend?: number;
  creditlimit?: number;
  registeredname?: string;
  tradingname?: string;
  entitytype?: string;
  companyregistrationdate?: any;
  abn?: string;
  acnarbn?: string;
  authorisedsharecapital?: string;
  paidsharedcapital?: string;
  businesspremises?: string;
  yearstrading?: number;
  postalsameasregisteredaddress?: string;
  shippingsameasregisteredaddress?: string;
  primarytelephone?: string;
  primaryemail?: string;
  primaryfacsimile?: string;
  companywebsite?: string;
  primarycellular?: string;
  segment?: string;
  storetype?: string;
  primarycontactname?: string;
  primarycontactemail?: string;
  primarycontacttelephone?: string;
  salessame?: string;
  salescontactname?: string;
  salescontactemail?: string;
  salescontacttelephone?: string;
  marketingsame?: string;
  marketingcontactname?: string;
  marketingcontactemail?: string;
  marketingcontacttelephone?: string;
  purchasingsame?: string;
  purchasingcontactname?: string;
  purchasingcontactemail?: string;
  purchasingcontacttelephone?: string;
  accountspayablesame?: string;
  accountspayablecontactname?: string;
  accountspayablecontactemail?: string;
  accountspayablecontacttelephone?: string;
  shippingadvicesame?: string;
  shippingadvicecontactname?: string;
  shippingadvicecontactemail?: string;
  shippingadvicecontacttelephone?: string;
  nominatedadministratorname?: string;
  nominatedadministratortelephone?: string;
  nominatedadministratoremail?: string;
  nominatedadministratorfacsimile?: string;
  nominatedadministratorposition?: string;
  firstdirectorsurname?: string;
  firstdirectorgivenname?: string;
  firstdirectordob?: any;
  firstdirectorplaceofbirth?: string;
  firstdirectorhomestatus?: string;
  firstdirectorpositiontitle?: string;
  firstdirectortelephone?: string;
  firstdirectorfacsimile?: string;
  firstdirectorcellular?: string;
  firstdirectoremail?: string;
  firstdirectordriverslicense?: string;
  firstdirectorpassportnumber?: string;
  seconddirectorsurname?: string;
  seconddirectorgivenname?: string;
  seconddirectordob?: any;
  seconddirectorplaceofbirth?: string;
  seconddirectorhomestatus?: string;
  seconddirectorpositiontitle?: string;
  seconddirectortelephone?: string;
  seconddirectorfacsimile?: string;
  seconddirectorcellular?: string;
  seconddirectoremail?: string;
  seconddirectordriverslicense?: string;
  seconddirectorpassportnumber?: string;
  firsttraderefsuppliername?: string;
  firsttraderefcontactname?: string;
  firsttradereftelephone?: string;
  firsttradereffacsimile?: string;
  firsttraderefemail?: string;
  firsttraderefcreditlimit?: number;
  firsttradereftradingterms?: string;
  secondtraderefsuppliername?: string;
  secondtraderefcontactname?: string;
  secondtradereftelephone?: string;
  secondtradereffacsimile?: string;
  secondtraderefemail?: string;
  secondtraderefcreditlimit?: number;
  secondtradereftradingterms?: string;
  thirdtraderefsuppliername?: string;
  thirdtraderefcontactname?: string;
  thirdtradereftelephone?: string;
  thirdtradereffacsimile?: string;
  thirdtraderefemail?: string;
  thirdtraderefcreditlimit?: number;
  thirdtradereftradingterms?: string;
  fourthtraderefsuppliername?: string;
  fourthtraderefcontactname?: string;
  fourthtradereftelephone?: string;
  fourthtradereffacsimile?: string;
  fourthtraderefemail?: string;
  fourthtraderefcreditlimit?: number;
  fourthtradereftradingterms?: string;
  accountantdetailcompanyname?: string;
  accountantdetailtelephone?: string;
  accountantdetailemail?: string;
  accountantdetailcontactname?: string;
  accountantdetailfacsimile?: string;
  revenuecurrentyear?: number;
  revenueprioryear?: number;
  cogscurrentyear?: number;
  cogsprioryear?: number;
  grosscurrentyear?: number;
  grossprioryear?: number;
  ebitcurrentyear?: number;
  ebitprioryear?: number;
  interestcurrentyear?: number;
  interestprioryear?: number;
  depreciationcurrentyear?: number;
  depreciationprioryear?: number;
  profitbeforetaxcurrentyear?: number;
  profitbeforetaxprioryear?: number;
  stockonhandcurrentyear?: number;
  stockonhandprioryear?: number;
  debtorscurrentyear?: number;
  debtorsprioryear?: number;
  currentassetscurrentyear?: number;
  currentassetsprioryear?: number;
  noncurrentassetscurrentyear?: number;
  noncurrentassetsprioryear?: number;
  creditorscurrentyear?: number;
  creditorsprioryear?: number;
  currentliabilitiescurrentyear?: number;
  currentliabilitiesprioryear?: number;
  noncurrentliabilitiescurrentyear?: number;
  noncurrentliabilitiesprioryear?: number;
  shareholdersequitycurrentyear?: number;
  shareholdersequityprioryear?: number;
  businessarea: string;
  status: string;
  accountid?: string;
  applicationdate: string;
  approvedcreditlimit?: number;
}

export interface DealerApplicationAddress {
  addressid: number;
  applicationid: number;
  addresstype: string;
  addressline1?: string;
  addressline2?: string;
  addressline3?: string;
  addressline4?: string;
  suburb?: string;
  city?: string;
  state?: string;
  postcode?: string;
}

export interface DealerApplicationLocation {
  locationid: number;
  applicationid: number;
  addressid?: number;
  primarycontactname?: string;
  primarycontactemail?: string;
  primarycontacttelephone?: string;
  salescontactname?: string;
  salescontactemail?: string;
  salescontacttelephone?: string;
  shippingcontactname?: string;
  shippingcontactemail?: string;
  shippingcontacttelephone?: string;
  accountid?: string;
  locationname?: string;
  marketingcontactname?: string;
  marketingcontactemail?: string;
  marketingcontacttelephone?: string;
  purchasingcontactname?: string;
  purchasingcontactemail?: string;
  purchasingcontacttelephone?: string;
}

export interface DealerApplicationMessage
{
  error: string;
  dealerApplication: DealerApplication;
  locations: DealerApplicationLocation[];
  addresses: DealerApplicationAddress[];
  registeredDomains: string[];
}

export interface ServiceMainREST {

  /**
   * HTTP GET /rest/accountFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.accountFromServer
   */
  accountFromServer(callback: MethodCallback<AccountMessage>, context?: any): void;

  /**
   * HTTP POST /rest/addAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.addAddress
   */
  addAddress(addressName: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<AddressesMessage>, context?: any): void;

  /**
   * HTTP POST /rest/addFavouriteProductId
   * Java method: com.cellnet.connect.client.ServiceMainREST.addFavouriteProductId
   */
  addFavouriteProductId(productId: string, callback: MethodCallback<FavouritesMessage>, context?: any): void;

  /**
   * HTTP POST /rest/addProductTab
   * Java method: com.cellnet.connect.client.ServiceMainREST.addProductTab
   */
  addProductTab(productId: string, callback: MethodCallback<ProductTabMessage>, context?: any): void;

  /**
   * HTTP POST /rest/addReturnRequestProduct
   * Java method: com.cellnet.connect.client.ServiceMainREST.addReturnRequestProduct
   */
  addReturnRequestProduct(requestNo: number, productId: string, quantity: number, faultDescription: string, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP POST /rest/addUser
   * Java method: com.cellnet.connect.client.ServiceMainREST.addUser
   */
  addUser(firstName: string, lastName: string, email: string, phone: string, password: string, defaultAccountId: string, activateDateTime: string, permissions: string, managerUserId: string, callback: MethodCallback<UsersMessage>, context?: any): void;

  /**
   * HTTP POST /rest/addUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.addUserAccount
   */
  addUserAccount(userId: string, accountId: string, permissions: string, managerUserId: string, callback: MethodCallback<UsersMessage>, context?: any): void;

  /**
   * HTTP GET /rest/addressesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.addressesFromServer
   */
  addressesFromServer(callback: MethodCallback<AddressesMessage>, context?: any): void;

  /**
   * HTTP GET /rest/alertsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.alertsFromServer
   */
  alertsFromServer(callback: MethodCallback<AlertsMessage>, context?: any): void;

  /**
   * HTTP POST /rest/allocateInvoicePayments
   * Java method: com.cellnet.connect.client.ServiceMainREST.allocateInvoicePayments
   */
  allocateInvoicePayments(invoiceIds: string[], paymentAllocations: number[], paymentMethodId: string, justCalculatePrices: boolean, callback: MethodCallback<AllocateInvoicePaymentsMessage>): void;

  /**
   * HTTP GET /rest/backOrdersFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.backOrdersFromServer
   */
  backOrdersFromServer(callback: MethodCallback<BackOrdersMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/cancelBackOrder
   * Java method: com.cellnet.connect.client.ServiceMainREST.cancelBackOrder
   */
  cancelBackOrder(orderId: string, orderLineIds: number[], callback: MethodCallback<CancelBackOrderMessage>): void;

  /**
   * HTTP POST /rest/changeToAccountOnServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.changeToAccountOnServer
   */
  changeToAccountOnServer(accountId: string, callback: MethodCallback<InitMessage>, context?: any): void;

  /**
   * HTTP GET /rest/checkCCAllocationPaymentStatus
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkCCAllocationPaymentStatus
   */
  checkCCAllocationPaymentStatus(allocationToken: string, ccPaymentId: string, callback: MethodCallback<CheckCCAllocationPaymentMessage>, context?: any): void;

  /**
   * HTTP GET /rest/checkCCOrderPaymentStatus
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkCCOrderPaymentStatus
   */
  checkCCOrderPaymentStatus(webOrderId: string, ccPaymentId: string, callback: MethodCallback<CheckCCOrderPaymentMessage>): void;

  /**
   * HTTP GET /rest/checkPayPalPaymentStatus
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkPayPalPaymentStatus
   */
  checkPayPalPaymentStatus(webOrderId: string, payPalPaymentId: string, callback: MethodCallback<CheckPayPalPaymentMessage>, context?: any): void;

  /**
   * HTTP POST /rest/checkoutApprovalShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutApprovalShoppingCart
   */
  checkoutApprovalShoppingCart(cartId: string, callback: MethodCallback<CheckoutMessage>): void;

  /**
   * HTTP PUT /rest/checkoutConfirm
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutConfirm
   */
  checkoutConfirm(cartId: string, callback: MethodCallback<CheckoutMessage>, context?: any): void;

  /**
   * HTTP POST /rest/checkoutCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutCurrentShoppingCart
   */
  checkoutCurrentShoppingCart(callback: MethodCallback<CheckoutMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/checkoutSetConfirmEmailCustRef
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetConfirmEmailCustRef
   */
  checkoutSetConfirmEmailCustRef(cartId: string, confirmationEmail: string, customerReference: string, callback: MethodCallback<CheckoutMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/checkoutSetDeliveryAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetDeliveryAddress
   */
  checkoutSetDeliveryAddress(cartId: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, addressLine3: string, addressLine4: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<CheckoutMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/checkoutSetFreightBackOrderOptions
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetFreightBackOrderOptions
   */
  checkoutSetFreightBackOrderOptions(cartId: string, freightOptionId: string, allowBackorders: boolean, callback: MethodCallback<CheckoutMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/checkoutSetPaymentMethod
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetPaymentMethod
   */
  checkoutSetPaymentMethod(cartId: string, paymentMethodId: string, justCalculatePrices: boolean, callback: MethodCallback<CheckoutMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/clearCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.clearCurrentShoppingCart
   */
  clearCurrentShoppingCart(callback: MethodCallback<ShoppingCartsMessage>): void;

  /**
   * HTTP POST /rest/createReturnRequestAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.createReturnRequestAddress
   */
  createReturnRequestAddress(reasonCode: string, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, companyName: string, addressLine1: string, addressLine2: string, suburb: string, state: string, postcode: string, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP POST /rest/createReturnRequestContact
   * Java method: com.cellnet.connect.client.ServiceMainREST.createReturnRequestContact
   */
  createReturnRequestContact(reasonCode: string, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP POST /rest/createReturnRequestContactRTS
   * Java method: com.cellnet.connect.client.ServiceMainREST.createReturnRequestContactRTS
   */
  createReturnRequestContactRTS(reasonCode: string, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, rtsInvoiceNo: string, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP GET /rest/dataFeedDetailsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.dataFeedDetailsFromServer
   */
  dataFeedDetailsFromServer(callback: MethodCallback<DataFeedMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/deactivateUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.deactivateUserAccount
   */
  deactivateUserAccount(userId: string, accountId: string, callback: MethodCallback<UsersMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/deactivateUserProfile
   * Java method: com.cellnet.connect.client.ServiceMainREST.deactivateUserProfile
   */
  deactivateUserProfile(userId: string, callback: MethodCallback<UsersMessage>, context?: any): void;

  /**
   * HTTP DELETE /rest/deleteAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteAddress
   */
  deleteAddress(addressId: number, callback: MethodCallback<AddressesMessage>): void;

  /**
   * HTTP DELETE /rest/deleteAllReturnRequestLines
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteAllReturnRequestLines
   */
  deleteAllReturnRequestLines(requestNo: number, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP DELETE /rest/deleteFavouriteProductId
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteFavouriteProductId
   */
  deleteFavouriteProductId(productId: string, callback: MethodCallback<FavouritesMessage>, context?: any): void;

  /**
   * HTTP DELETE /rest/deleteIncompleteReturnRequest
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteIncompleteReturnRequest
   */
  deleteIncompleteReturnRequest(requestNo: number, callback: MethodCallback<DeleteReturnMessage>): void;

  /**
   * HTTP DELETE /rest/deleteProductTab
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteProductTab
   */
  deleteProductTab(productId: string, callback: MethodCallback<ProductTabMessage>, context?: any): void;

  /**
   * HTTP DELETE /rest/deleteReturnRequestDocument
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteReturnRequestDocument
   */
  deleteReturnRequestDocument(requestNo: number, documentId: number, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP DELETE /rest/deleteReturnRequestLine
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteReturnRequestLine
   */
  deleteReturnRequestLine(requestNo: number, lineNo: number, callback: MethodCallback<ReturnMessage>, context?: any): void;

  /**
   * HTTP DELETE /rest/deleteSavedShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteSavedShoppingCart
   */
  deleteSavedShoppingCart(cartId: string, callback: MethodCallback<ShoppingCartsMessage>): void;

  /**
   * HTTP GET /rest/deliveryAddressesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.deliveryAddressesFromServer
   */
  deliveryAddressesFromServer(callback: MethodCallback<DeliveryAddressesMessage>, context?: any): void;

  /**
    * HTTP GET /rest/dueInvoicesFromServer
    * Java method: com.cellnet.connect.client.ServiceMainREST.dueInvoicesFromServer
    */
  dueInvoicesFromServer(callback: MethodCallback<DueInvoicesMessage>, context?: any): void;

  /**
   * HTTP GET /rest/filesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.filesFromServer
   */
  filesFromServer(callback: MethodCallback<FilesMessage>): void;

  /**
   * HTTP GET /rest/initFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.initFromServer
   */
  initFromServer(callback: MethodCallback<InitMessage>, context?: any): void;

  /**
   * HTTP GET /rest/invoicesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.invoicesFromServer
   */
  invoicesFromServer(callback: MethodCallback<InvoicesMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/loadSavedShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.loadSavedShoppingCart
   */
  loadSavedShoppingCart(cartId: string, callback: MethodCallback<ShoppingCartsMessage>): void;

  /**
   * HTTP POST /rest/loginServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.loginServer
   */
  loginServer(usernameOrEmailOrAlias: string, password: string, staffVerificationCode: string, callback: MethodCallback<LoginMessage>, context?: any): void;

  /**
   * HTTP POST /rest/login
   * Java method: com.cellnet.connect.client.ServiceMainREST.login
   */
  login(usernameOrEmailOrAlias: string, password: string, staffVerificationCode: string, stayLoggedIn: boolean, callback: MethodCallback<LoginMessage>, context?: any): void;

  /**
   * HTTP GET /rest/logout
   * Java method: com.cellnet.connect.client.ServiceMainREST.logout
   */
  logout(callback: MethodCallback<LogoutMessage>): void;

  /**
   * HTTP GET /rest/managedUsersFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.managedUsersFromServer
   */
  managedUsersFromServer(callback: MethodCallback<UsersMessage>, context?: any): void;

  /**
   * HTTP GET /rest/ordersFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.ordersFromServer
   */
  ordersFromServer(callback: MethodCallback<OrdersMessage>, context?: any): void;

  /**
   * HTTP GET /rest/preferencesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.preferencesFromServer
   */
  preferencesFromServer(callback: MethodCallback<PreferencesMessage>, context?: any): void;

  /**
   * HTTP GET /rest/productsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.productsFromServer
   */
  productsFromServer(callback: MethodCallback<ProductsMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/reactivateUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.reactivateUserAccount
   */
  reactivateUserAccount(userId: string, accountId: string, callback: MethodCallback<UsersMessage>): void;

  /**
   * HTTP PUT /rest/reactivateUserProfile
   * Java method: com.cellnet.connect.client.ServiceMainREST.reactivateUserProfile
   */
  reactivateUserProfile(userId: string, email: string, callback: MethodCallback<UsersMessage>): void;  

  /**
   * HTTP GET /rest/recentActivitiesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.recentActivitiesFromServer
   */
  recentActivitiesFromServer(callback: MethodCallback<RecentActivityMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/rejectApprovalShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.rejectApprovalShoppingCart
   */
  rejectApprovalShoppingCart(cartId: string, callback: MethodCallback<CheckoutMessage>): void;

  /**
   * HTTP POST /rest/requestPasswordReset
   * Java method: com.cellnet.connect.client.ServiceMainREST.requestPasswordReset
   */
  requestPasswordReset(usernameOrEmailOrAlias: string, callback: MethodCallback<ResetPasswordMessage>): void;

  /**
   * HTTP GET /rest/returnsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.returnsFromServer
   */
  returnsFromServer(callback: MethodCallback<ReturnsMessage>, context?: any): void;

  /**
   * HTTP POST /rest/saveCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.saveCurrentShoppingCart
   */
  saveCurrentShoppingCart(cartName: string, callback: MethodCallback<ShoppingCartsMessage>): void;

  /**
   * HTTP GET /rest/search
   * Java method: com.cellnet.connect.client.ServiceMainREST.search
   */
  search(searchText: string, hitsPerPage: number, pageNo: number, callback: MethodCallback<SearchMessage>): void;

  /**
   * HTTP GET /rest/search
   * Java method: com.cellnet.connect.client.ServiceMainREST.search
   */
  searchRestrictingCategory(searchText: string, searchCategory: string, hitsPerPage: number, pageNo: number, callback: MethodCallback<SearchMessage>): void;  

  /**
   * HTTP GET /rest/searchForAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.searchForAccount
   */
  searchForAccount(searchText: string, callback: MethodCallback<SearchAccountMessage>): void;

  /**
   * HTTP PUT /rest/setAlertRead
   * Java method: com.cellnet.connect.client.ServiceMainREST.setAlertRead
   */
  setAlertRead(id: number, callback: MethodCallback<AlertsMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/setApproverForCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.setApproverForCurrentShoppingCart
   */
  setApproverForCurrentShoppingCart(approverUserId: string, cartName: string, callback: MethodCallback<ShoppingCartsMessage>): void;

  /**
   * HTTP GET /rest/shoppingCartsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.shoppingCartsFromServer
   */
  shoppingCartsFromServer(callback: MethodCallback<ShoppingCartsMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/submitIncompleteReturnRequest
   * Java method: com.cellnet.connect.client.ServiceMainREST.submitIncompleteReturnRequest
   */
  submitIncompleteReturnRequest(requestNo: number, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP GET /rest/trackingDatasFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.trackingDatasFromServer
   */
  trackingDatasFromServer(callback: MethodCallback<TrackingDatasMessage>, context?: any): void;

  /**
   * HTTP GET /rest/transactionsAndPaymentsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.transactionsAndPaymentsFromServer
   */
  transactionsAndPaymentsFromServer(callback: MethodCallback<TransactionsAndPaymentsMessage>, context?: any): void;  

  /**
   * HTTP PUT /rest/updateAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateAddress
   */
  updateAddress(addressId: number, addressName: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<AddressesMessage>): void;

  /**
   * HTTP PUT /rest/updateDataFeedAzure
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedAzure
   */
  updateDataFeedAzure(accountName: string, accountKey: string, container: string, https: boolean, deliveryTimes: string[], selectedColumns: string[], fileFormat: string, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void;

  /**
   * HTTP PUT /rest/updateDataFeedEmail
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedEmail
   */
  updateDataFeedEmail(email: string, deliveryTimes: string[], selectedColumns: string[], fileFormat: string, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void;

  /**
   * HTTP PUT /rest/updateDataFeedFTP
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedFTP
   */
    updateDataFeedFTP(host: string, user: string, password: string, folder: string, sftp: boolean, port: number, deliveryTimes: DataFeedDeliveryTime[], selectedColumns: DataFeedColumns[], fileFormat: DataFeedFileFormat, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void;

  /**
   * HTTP PUT /rest/updateDataFeedWebLink
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedWebLink
   */
  updateDataFeedWebLink(url: string, deliveryTimes: string[], selectedColumns: string[], fileFormat: string, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void;

  /**
   * HTTP PUT /rest/updatePassword
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePassword
   */
  updatePassword(changeToken: string, password: string, callback: MethodCallback<LoginMessage>): void;

  /**
   * HTTP PUT /rest/updatePath
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePath
   */
  updatePath(path: string, callback: MethodCallback<PathMessage>): void;

  /**
   * HTTP PUT /rest/updatePayPalAllocationPayment
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePayPalAllocationPayment
   */
  updatePayPalAllocationPayment(allocationToken: string, payPalOrderId: string, payPalPayerId: string, callback: MethodCallback<UpdatePayPalAllocationPaymentMessage>): void;

  /**
   * HTTP PUT /rest/updatePayPalOrderPayment
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePayPalOrderPayment
   */
  updatePayPalOrderPayment(webOrderId: string, payPalOrderId: string, payPalPayerId: string, callback: MethodCallback<UpdatePayPalOrderPaymentMessage>): void;

  /**
   * HTTP PUT /rest/updatePreference
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePreference
   */
  updatePreference(name: string, value: string, callback: MethodCallback<PreferencesMessage>): void;

  /**
   * HTTP PUT /rest/updatePublicPagePreferences
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePublicPagePreferences
   */
  updatePublicPagePreferences(customerId: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, suburb: string, state: string, city: string, postcode: string, phone: string, callback: MethodCallback<PublicPagePreferencesMessage>): void;

  /**
   * HTTP PUT /rest/updateReturnRequestAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateReturnRequestAddress
   */
  updateReturnRequestAddress(requestNo: number, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, companyName: string, addressLine1: string, addressLine2: string, suburb: string, state: string, postcode: string, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP PUT /rest/updateReturnRequestContact
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateReturnRequestContact
   */
  updateReturnRequestContact(requestNo: number, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, callback: MethodCallback<ReturnMessage>): void;

  /**
   * HTTP PUT /rest/updateShoppingCartProductQuantity
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateShoppingCartProductQuantity
   */
  updateShoppingCartProductQuantity(productId: string, quantity: number, callback: MethodCallback<ShoppingCartsMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/updateUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateUserAccount
   */
  updateUserAccount(userId: string, accountId: string, permissions: string, managerUserId: string, callback: MethodCallback<UsersMessage>, context?:any): void;

  /**
   * HTTP PUT /rest/updateUserProfile
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateUserProfile
   */
  updateUserProfile(userId: string, alias: string, firstName: string, lastName: string, email: string, phone: string, password: string, callback: MethodCallback<UsersMessage>, context?:any): void;

  /**
   * HTTP POST /rest/addActivityViewBackOrder
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewBackOrder
   */
  addActivityViewBackOrder(orderId: string, callback: MethodCallback<RecentActivityMessage>): void;

  /**
   * HTTP POST /rest/addActivityViewInvoice
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewInvoice
   */
  addActivityViewInvoice(invoiceId: string, callback: MethodCallback<RecentActivityMessage>): void;

  /**
   * HTTP POST /rest/addActivityViewOrder
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewOrder
   */
  addActivityViewOrder(orderId: string, callback: MethodCallback<RecentActivityMessage>): void;

  /**
   * HTTP POST /rest/addActivityViewProduct
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewProduct
   */
  addActivityViewProduct(productId: string, callback: MethodCallback<RecentActivityMessage>): void;

  /**
   * HTTP POST /rest/addActivityViewReturn
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewReturn
   */
  addActivityViewReturn(requestNo: number, callback: MethodCallback<RecentActivityMessage>): void;

  /**
   * HTTP POST /rest/getDealerApplication
   * Java method: com.cellnet.connect.client.ServiceMainREST.getDealerApplication
   */
  getDealerApplication(emailAddress: string, accountType: string, callback: MethodCallback<DealerApplicationMessage>, context?: any): void;

  /**
   * HTTP PUT /rest/updateDealerApplication
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDealerApplication
   */
  updateDealerApplication(dealerApplication: DealerApplication, callback: MethodCallback<DealerApplicationMessage>, context?: any): void;

  /**
   * HTTP DELETE /rest/deleteDealerApplication
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteDealerApplication
   */
  deleteDealerApplication(emailAddress: string, accountType: string, callback: MethodCallback<DealerApplicationMessage>, context?: any): void;

  /**
   * HTTP POST /rest/addDealerApplicationAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.addDealerApplicationAddress
   */  
  addDealerApplicationAddress(emailAddress: string, accountType: string, applicationId: number, addressType: string, addressLine1: string,
      addressLine2: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<DealerApplicationMessage>, context?: any): void;
  
  /**
   * HTTP PUT /rest/updateDealerApplicationAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDealerApplicationAddress
   */      
  updateDealerApplicationAddress(dealerApplicationAddress: DealerApplicationAddress, callback: MethodCallback<DealerApplicationMessage>, context?: any): void;

  /**
   * HTTP DELETE /rest/deleteDealerApplicationAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteDealerApplicationAddress
   */  
  deleteDealerApplicationAddress(emailAddress: string, accountType: string, addressId: number, callback: MethodCallback<DealerApplicationMessage>, context?: any): void;
    
}

export class HttpClient {

  token: string = "";
  server: string = mainUrl;

  request<T>(method: string, url: string, callback: MethodCallback<T>, formData?: URLSearchParams, context?: any) {
    var xhttp = new XMLHttpRequest();
    var completeURL = method === "GET" && formData != null ? url + "?" + formData.toString() : url;
    xhttp.open(method, this.server + completeURL, true);
    if (callback) {
      xhttp.onerror = function () {
        callback.onFailure("Network Error", context);
      };
      xhttp.onprogress = function (event: ProgressEvent) {
        callback.onProgress(event.loaded, event.total);
      };
      xhttp.onload = function () {
        if (this.status >= 200 && this.status < 400) {
          callback.onSuccess(JSON.parse(this.response), context);
        }
        else {
          callback.onFailure("Status " + this.status + " recieved");
        }
      };

    }
    xhttp.withCredentials = true;
    xhttp.setRequestHeader("Accept", "application/json");
    xhttp.setRequestHeader("token-X", cookies.get('token')); //this.token
    //xhttp.setRequestHeader("token-X", this.token);
    if (formData !== null && method !== "GET") {
      xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhttp.send(formData);
    }
    else {
      xhttp.send();
    }
  }

  requestPassingJSON<T>(method: string, url: string, callback: MethodCallback<T>, jsonString?: string, context?: any) {
    var xhttp = new XMLHttpRequest();
    var completeURL = method === "GET" && jsonString != null ? url + "?" + jsonString.toString() : url;
    xhttp.open(method, this.server + completeURL, true);
    if (callback) {
      xhttp.onerror = function () {
        callback.onFailure("Network Error", context);
      };
      xhttp.onprogress = function (event: ProgressEvent) {
        callback.onProgress(event.loaded, event.total);
      };
      xhttp.onload = function () {
        if (this.status >= 200 && this.status < 400) {
          callback.onSuccess(JSON.parse(this.response), context);
        }
        else {
          callback.onFailure("Status " + this.status + " recieved");
        }
      };

    }
    xhttp.withCredentials = true;
    xhttp.setRequestHeader("Accept", "application/json");
    xhttp.setRequestHeader("token-X", cookies.get('token')); //this.token
    //xhttp.setRequestHeader("token-X", this.token);
    if (jsonString !== null && method !== "GET") {
      xhttp.setRequestHeader("Content-Type", "application/json");
      xhttp.send(jsonString);
    }
    else {
      xhttp.send();
    }
  }  

  setToken(token: string) {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }  

  setServer(server: string) {
    this.server = server;
  }  

  getServer(): string {
    return this.server;
  }

}

export class ServiceMainRESTClient implements ServiceMainREST {
  httpClient: HttpClient = new HttpClient();

  /**
   * HTTP GET /rest/accountFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.accountFromServer
   */
  accountFromServer(callback: MethodCallback<AccountMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/accountFromServer", callback, undefined, context);
  }

  /**
   * HTTP POST /rest/addActivityViewBackOrder
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewBackOrder
   */
  addActivityViewBackOrder(orderId: string, callback: MethodCallback<RecentActivityMessage>): void {
      var formData = new URLSearchParams();
      formData.append('orderId', orderId);
      this.httpClient.request("POST", "rest/addActivityViewBackOrder", callback, formData);
  }

  /**
   * HTTP POST /rest/addActivityViewInvoice
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewInvoice
   */
  addActivityViewInvoice(invoiceId: string, callback: MethodCallback<RecentActivityMessage>): void {
      var formData = new URLSearchParams();
      formData.append('invoiceId', invoiceId);
      this.httpClient.request("POST", "rest/addActivityViewInvoice", callback, formData);
  }

  /**
   * HTTP POST /rest/addActivityViewOrder
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewOrder
   */
  addActivityViewOrder(orderId: string, callback: MethodCallback<RecentActivityMessage>): void {
      var formData = new URLSearchParams();
      formData.append('orderId', orderId);
      this.httpClient.request("POST", "rest/addActivityViewOrder", callback, formData);
  }

  /**
   * HTTP POST /rest/addActivityViewProduct
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewProduct
   */
  addActivityViewProduct(productId: string, callback: MethodCallback<RecentActivityMessage>): void {
      var formData = new URLSearchParams();
      formData.append('productId', productId);
      this.httpClient.request("POST", "rest/addActivityViewProduct", callback, formData);
  }

  /**
   * HTTP POST /rest/addActivityViewReturn
   * Java method: com.cellnet.connect.client.ServiceMainREST.addActivityViewReturn
   */
  addActivityViewReturn(requestNo: number, callback: MethodCallback<RecentActivityMessage>): void {
      var formData = new URLSearchParams();
      formData.append('requestNo', "" + requestNo);
      this.httpClient.request("POST", "rest/addActivityViewReturn", callback, formData);
  }

  /**
   * HTTP POST /rest/addAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.addAddress
   */
  addAddress(addressName: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<AddressesMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('addressName', addressName);
    formData.append('companyName', companyName);
    formData.append('contactName', contactName);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('suburb', suburb);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('postcode', postcode);
    this.httpClient.request("POST", "rest/addAddress", callback, formData, context);
  }

  /**
   * HTTP POST /rest/addFavouriteProductId
   * Java method: com.cellnet.connect.client.ServiceMainREST.addFavouriteProductId
   */
  addFavouriteProductId(productId: string, callback: MethodCallback<FavouritesMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('productId', productId);
    this.httpClient.request("POST", "rest/addFavouriteProductId", callback, formData, context);
  }

  /**
   * HTTP POST /rest/addProductTab
   * Java method: com.cellnet.connect.client.ServiceMainREST.addProductTab
   */
  addProductTab(productId: string, callback: MethodCallback<ProductTabMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('productId', productId);
    this.httpClient.request("POST", "rest/addProductTab", callback, formData, context);
  }  

  /**
   * HTTP POST /rest/addReturnRequestProduct
   * Java method: com.cellnet.connect.client.ServiceMainREST.addReturnRequestProduct
   */
  addReturnRequestProduct(requestNo: number, productId: string, quantity: number, faultDescription: string, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    formData.append('productId', productId);
    formData.append('quantity', "" + quantity);
    formData.append('faultDescription', faultDescription);
    this.httpClient.request("POST", "rest/addReturnRequestProduct", callback, formData);
  }

  /**
   * HTTP POST /rest/addUser
   * Java method: com.cellnet.connect.client.ServiceMainREST.addUser
   */
  addUser(firstName: string, lastName: string, email: string, phone: string, password: string, defaultAccountId: string, activateDateTime: string, permissions: string, managerUserId: string, callback: MethodCallback<UsersMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('defaultAccountId', defaultAccountId);
    formData.append('activateDateTime', activateDateTime);
    formData.append('permissions', permissions);
    formData.append('managerUserId', managerUserId);
    this.httpClient.request("POST", "rest/addUser", callback, formData, context);
  }

  /**
   * HTTP POST /rest/addUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.addUserAccount
   */
  addUserAccount(userId: string, accountId: string, permissions: string, managerUserId: string, callback: MethodCallback<UsersMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    formData.append('accountId', accountId);
    formData.append('permissions', permissions);
    formData.append('managerUserId', managerUserId);
    this.httpClient.request("POST", "rest/addUserAccount", callback, formData, context);
  }

  /**
   * HTTP GET /rest/addressesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.addressesFromServer
   */
  addressesFromServer(callback: MethodCallback<AddressesMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/addressesFromServer", callback, undefined, context);
  }

  /**
   * HTTP GET /rest/alertsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.alertsFromServer
   */
  alertsFromServer(callback: MethodCallback<AlertsMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/alertsFromServer", callback, undefined, context);
  }

  /**
   * HTTP POST /rest/allocateInvoicePayments
   * Java method: com.cellnet.connect.client.ServiceMainREST.allocateInvoicePayments
   */
  allocateInvoicePayments(invoiceIds: string[], paymentAllocations: number[], paymentMethodId: string, justCalculatePrices: boolean, callback: MethodCallback<AllocateInvoicePaymentsMessage>): void {
    var formData = new URLSearchParams();
    for (let i of invoiceIds) {
      formData.append('invoiceIds', i);
    }
    for (let i of paymentAllocations) {
      formData.append('paymentAllocations', "" + i);
    }
    formData.append('paymentMethodId', paymentMethodId);
    formData.append('justCalculatePrices', "" + justCalculatePrices);
    this.httpClient.request("POST", "rest/allocateInvoicePayments", callback, formData);
  }

  /**
   * HTTP GET /rest/backOrdersFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.backOrdersFromServer
   */
  backOrdersFromServer(callback: MethodCallback<BackOrdersMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/backOrdersFromServer", callback, undefined, context);
  }  

  /**
   * HTTP PUT /rest/cancelBackOrder
   * Java method: com.cellnet.connect.client.ServiceMainREST.cancelBackOrder
   */
  cancelBackOrder(orderId: string, orderLineIds: number[], callback: MethodCallback<CancelBackOrderMessage>): void {
    var formData = new URLSearchParams();
    formData.append('orderId', orderId);
    for (let i of orderLineIds) {
      formData.append('orderLineIds', "" + i);
    }
    this.httpClient.request("PUT", "rest/cancelBackOrder", callback, formData);
  }

  /**
   * HTTP POST /rest/changeToAccountOnServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.changeToAccountOnServer
   */
  changeToAccountOnServer(accountId: string, callback: MethodCallback<InitMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('accountId', accountId);
    this.httpClient.request("POST", "rest/changeToAccountOnServer", callback, formData, context);
  }

  /**
   * HTTP GET /rest/checkCCAllocationPaymentStatus
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkCCAllocationPaymentStatus
   */
  checkCCAllocationPaymentStatus(allocationToken: string, ccPaymentId: string, callback: MethodCallback<CheckCCAllocationPaymentMessage>): void {
      var formData = new URLSearchParams();
      formData.append('allocationToken', allocationToken);
      formData.append('ccPaymentId', ccPaymentId);
      this.httpClient.request("GET", "rest/checkCCAllocationPaymentStatus", callback, formData);
  }

  /**
   * HTTP GET /rest/checkCCOrderPaymentStatus
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkCCOrderPaymentStatus
   */
  checkCCOrderPaymentStatus(webOrderId: string, ccPaymentId: string, callback: MethodCallback<CheckCCOrderPaymentMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('webOrderId', webOrderId);
    formData.append('ccPaymentId', ccPaymentId);
    this.httpClient.request("GET", "rest/checkCCOrderPaymentStatus", callback, formData, context);
  }

  /**
   * HTTP GET /rest/checkPayPalPaymentStatus
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkPayPalPaymentStatus
   */
  checkPayPalPaymentStatus(webOrderId: string, payPalPaymentId: string, callback: MethodCallback<CheckPayPalPaymentMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('webOrderId', webOrderId);
    formData.append('payPalPaymentId', payPalPaymentId);
    this.httpClient.request("GET", "rest/checkPayPalPaymentStatus", callback, formData, context);
  }

  /**
   * HTTP POST /rest/checkoutApprovalShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutApprovalShoppingCart
   */
  checkoutApprovalShoppingCart(cartId: string, callback: MethodCallback<CheckoutMessage>): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    this.httpClient.request("POST", "rest/checkoutApprovalShoppingCart", callback, formData);
  }

  /**
   * HTTP PUT /rest/checkoutConfirm
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutConfirm
   */
  checkoutConfirm(cartId: string, callback: MethodCallback<CheckoutMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    this.httpClient.request("PUT", "rest/checkoutConfirm", callback, formData, context);
  }

  /**
   * HTTP POST /rest/checkoutCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutCurrentShoppingCart
   */
  checkoutCurrentShoppingCart(callback: MethodCallback<CheckoutMessage>, context?: any): void {
    this.httpClient.request("POST", "rest/checkoutCurrentShoppingCart", callback, undefined, context);
  }

  /**
   * HTTP PUT /rest/checkoutSetConfirmEmailCustRef
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetConfirmEmailCustRef
   */
  checkoutSetConfirmEmailCustRef(cartId: string, confirmationEmail: string, customerReference: string, callback: MethodCallback<CheckoutMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    formData.append('confirmationEmail', confirmationEmail);
    formData.append('customerReference', customerReference);
    this.httpClient.request("PUT", "rest/checkoutSetConfirmEmailCustRef", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/checkoutSetDeliveryAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetDeliveryAddress
   */
  checkoutSetDeliveryAddress(cartId: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, addressLine3: string, addressLine4: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<CheckoutMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    formData.append('companyName', companyName);
    formData.append('contactName', contactName);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('addressLine3', addressLine3);
    formData.append('addressLine4', addressLine4);
    formData.append('suburb', suburb);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('postcode', postcode);
    this.httpClient.request("PUT", "rest/checkoutSetDeliveryAddress", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/checkoutSetFreightBackOrderOptions
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetFreightBackOrderOptions
   */
  checkoutSetFreightBackOrderOptions(cartId: string, freightOptionId: string, allowBackorders: boolean, callback: MethodCallback<CheckoutMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    formData.append('freightOptionId', freightOptionId);
    formData.append('allowBackorders', "" + allowBackorders);
    this.httpClient.request("PUT", "rest/checkoutSetFreightBackOrderOptions", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/checkoutSetPaymentMethod
   * Java method: com.cellnet.connect.client.ServiceMainREST.checkoutSetPaymentMethod
   */
  checkoutSetPaymentMethod(cartId: string, paymentMethodId: string, justCalculatePrices: boolean, callback: MethodCallback<CheckoutMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    formData.append('paymentMethodId', paymentMethodId);
    formData.append('justCalculatePrices', "" + justCalculatePrices);
    this.httpClient.request("PUT", "rest/checkoutSetPaymentMethod", callback, formData, context);
  }  

  /**
   * HTTP PUT /rest/clearCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.clearCurrentShoppingCart
   */
  clearCurrentShoppingCart(callback: MethodCallback<ShoppingCartsMessage>): void {
    this.httpClient.request("PUT", "rest/clearCurrentShoppingCart", callback);
  }

  /**
   * HTTP POST /rest/createReturnRequestAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.createReturnRequestAddress
   */
  createReturnRequestAddress(reasonCode: string, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, companyName: string, addressLine1: string, addressLine2: string, suburb: string, state: string, postcode: string, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('reasonCode', reasonCode);
    formData.append('customerRef', customerRef);
    formData.append('contactName', contactName);
    formData.append('contactEmail', contactEmail);
    formData.append('contactPhone', contactPhone);
    formData.append('companyName', companyName);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('suburb', suburb);
    formData.append('state', state);
    formData.append('postcode', postcode);
    this.httpClient.request("POST", "rest/createReturnRequestAddress", callback, formData);
  }

  /**
   * HTTP POST /rest/createReturnRequestContact
   * Java method: com.cellnet.connect.client.ServiceMainREST.createReturnRequestContact
   */
  createReturnRequestContact(reasonCode: string, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('reasonCode', reasonCode);
    formData.append('customerRef', customerRef);
    formData.append('contactName', contactName);
    formData.append('contactEmail', contactEmail);
    formData.append('contactPhone', contactPhone);
    this.httpClient.request("POST", "rest/createReturnRequestContact", callback, formData);
  }

  /**
   * HTTP POST /rest/createReturnRequestContactRTS
   * Java method: com.cellnet.connect.client.ServiceMainREST.createReturnRequestContactRTS
   */
  createReturnRequestContactRTS(reasonCode: string, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, rtsInvoiceNo: string, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('reasonCode', reasonCode);
    formData.append('customerRef', customerRef);
    formData.append('contactName', contactName);
    formData.append('contactEmail', contactEmail);
    formData.append('contactPhone', contactPhone);
    formData.append('rtsInvoiceNo', rtsInvoiceNo);
    this.httpClient.request("POST", "rest/createReturnRequestContactRTS", callback, formData);
  }  

  /**
   * HTTP GET /rest/dataFeedDetailsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.dataFeedDetailsFromServer
   */
  dataFeedDetailsFromServer(callback: MethodCallback<DataFeedMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/dataFeedDetailsFromServer", callback, undefined, context);
  }

  /**
   * HTTP PUT /rest/deactivateUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.deactivateUserAccount
   */
  deactivateUserAccount(userId: string, accountId: string, callback: MethodCallback<UsersMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    formData.append('accountId', accountId);
    this.httpClient.request("PUT", "rest/deactivateUserAccount", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/deactivateUserProfile
   * Java method: com.cellnet.connect.client.ServiceMainREST.deactivateUserProfile
   */
  deactivateUserProfile(userId: string, callback: MethodCallback<UsersMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    this.httpClient.request("PUT", "rest/deactivateUserProfile", callback, formData, context);
  }

  /**
   * HTTP DELETE /rest/deleteAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteAddress
   */
  deleteAddress(addressId: number, callback: MethodCallback<AddressesMessage>): void {
    var formData = new URLSearchParams();
    formData.append('addressId', "" + addressId);
    this.httpClient.request("DELETE", "rest/deleteAddress", callback, formData);
  }

  /**
   * HTTP DELETE /rest/deleteAllReturnRequestLines
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteAllReturnRequestLines
   */
  deleteAllReturnRequestLines(requestNo: number, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    this.httpClient.request("DELETE", "rest/deleteAllReturnRequestLines", callback, formData);
  }

  /**
   * HTTP DELETE /rest/deleteFavouriteProductId
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteFavouriteProductId
   */
  deleteFavouriteProductId(productId: string, callback: MethodCallback<FavouritesMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('productId', productId);
    this.httpClient.request("DELETE", "rest/deleteFavouriteProductId", callback, formData, context);
  }

  /**
   * HTTP DELETE /rest/deleteIncompleteReturnRequest
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteIncompleteReturnRequest
   */
  deleteIncompleteReturnRequest(requestNo: number, callback: MethodCallback<DeleteReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    this.httpClient.request("DELETE", "rest/deleteIncompleteReturnRequest", callback, formData);
  }

  /**
   * HTTP DELETE /rest/deleteProductTab
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteProductTab
   */
  deleteProductTab(productId: string, callback: MethodCallback<ProductTabMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('productId', productId);
    this.httpClient.request("DELETE", "rest/deleteProductTab", callback, formData, context);
  }

  /**
   * HTTP DELETE /rest/deleteReturnRequestDocument
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteReturnRequestDocument
   */
  deleteReturnRequestDocument(requestNo: number, documentId: number, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    formData.append('documentId', "" + documentId);
    this.httpClient.request("DELETE", "rest/deleteReturnRequestDocument", callback, formData);
  }  

  /**
   * HTTP DELETE /rest/deleteReturnRequestLine
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteReturnRequestLine
   */
  deleteReturnRequestLine(requestNo: number, lineNo: number, callback: MethodCallback<ReturnMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    formData.append('lineNo', "" + lineNo);
    this.httpClient.request("DELETE", "rest/deleteReturnRequestLine", callback, formData, context);
  }

  /**
   * HTTP DELETE /rest/deleteSavedShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteSavedShoppingCart
   */
  deleteSavedShoppingCart(cartId: string, callback: MethodCallback<ShoppingCartsMessage>): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    this.httpClient.request("DELETE", "rest/deleteSavedShoppingCart", callback, formData);
  }

  /**
   * HTTP GET /rest/deliveryAddressesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.deliveryAddressesFromServer
   */
  deliveryAddressesFromServer(callback: MethodCallback<DeliveryAddressesMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/deliveryAddressesFromServer", callback, undefined, context);
  }

  /**
   * HTTP GET /rest/dueInvoicesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.dueInvoicesFromServer
   */
  dueInvoicesFromServer(callback: MethodCallback<DueInvoicesMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/dueInvoicesFromServer", callback, undefined, context);
  }

  /**
   * HTTP GET /rest/filesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.filesFromServer
   */
  filesFromServer(callback: MethodCallback<FilesMessage>): void {
    this.httpClient.request("GET", "rest/filesFromServer", callback);
  }

  /**
   * HTTP GET /rest/initFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.initFromServer
   */
  initFromServer(callback: MethodCallback<InitMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/initFromServer", callback, undefined, context);
  }

  /**
   * HTTP GET /rest/invoicesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.invoicesFromServer
   */
  invoicesFromServer(callback: MethodCallback<InvoicesMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/invoicesFromServer", callback, undefined, context);
  }  

  /**
   * HTTP PUT /rest/loadSavedShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.loadSavedShoppingCart
   */
  loadSavedShoppingCart(cartId: string, callback: MethodCallback<ShoppingCartsMessage>): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    this.httpClient.request("PUT", "rest/loadSavedShoppingCart", callback, formData);
  }

  /**
   * HTTP POST /rest/loginServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.loginServer
   */
  loginServer(usernameOrEmailOrAlias: string, password: string, staffVerificationCode: string, callback: MethodCallback<LoginMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('usernameOrEmailOrAlias', usernameOrEmailOrAlias);
    formData.append('password', password);
    formData.append('staffVerificationCode', staffVerificationCode);
    this.httpClient.request("POST", "rest/loginServer", callback, formData, context);
  }

  /**
   * HTTP POST /rest/login
   * Java method: com.cellnet.connect.client.ServiceMainREST.login
   */
  login(usernameOrEmailOrAlias: string, password: string, staffVerificationCode: string, stayLoggedIn: boolean, callback: MethodCallback<LoginMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('usernameOrEmailOrAlias', usernameOrEmailOrAlias);
    formData.append('password', password);
    formData.append('staffVerificationCode', staffVerificationCode);
    formData.append('stayLoggedIn', "" + stayLoggedIn);
    this.httpClient.request("POST", "rest/login", callback, formData, context);
  }  

  /**
   * HTTP GET /rest/logout
   * Java method: com.cellnet.connect.client.ServiceMainREST.logout
   */
  logout(callback: MethodCallback<LogoutMessage>): void {
    this.httpClient.request("GET", "rest/logout", callback);
    this.httpClient.setToken("");
  }

  /**
   * HTTP GET /rest/managedUsersFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.managedUsersFromServer
   */
  managedUsersFromServer(callback: MethodCallback<UsersMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/managedUsersFromServer", callback, undefined, context);
  }


  /**
   * HTTP GET /rest/ordersFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.ordersFromServer
   */
  ordersFromServer(callback: MethodCallback<OrdersMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/ordersFromServer", callback, undefined, context);
  }  

  /**
   * HTTP GET /rest/preferencesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.preferencesFromServer
   */
  preferencesFromServer(callback: MethodCallback<PreferencesMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/preferencesFromServer", callback, undefined, context);
  }

  /**
   * HTTP GET /rest/productsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.productsFromServer
   */
  productsFromServer(callback: MethodCallback<ProductsMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/productsFromServer", callback, undefined, context);
  }

  /**
   * HTTP PUT /rest/reactivateUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.reactivateUserAccount
   */
  reactivateUserAccount(userId: string, accountId: string, callback: MethodCallback<UsersMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    formData.append('accountId', accountId);
    this.httpClient.request("PUT", "rest/reactivateUserAccount", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/reactivateUserProfile
   * Java method: com.cellnet.connect.client.ServiceMainREST.reactivateUserProfile
   */
  reactivateUserProfile(userId: string, email: string, callback: MethodCallback<UsersMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    formData.append('email', email);
    this.httpClient.request("PUT", "rest/reactivateUserProfile", callback, formData, context);
  }

  /**
   * HTTP GET /rest/recentActivitiesFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.recentActivitiesFromServer
   */
  recentActivitiesFromServer(callback: MethodCallback<RecentActivityMessage>, context ?: any): void {
    this.httpClient.request("GET", "rest/recentActivitiesFromServer", callback, undefined, context);
  }

  /**
   * HTTP PUT /rest/rejectApprovalShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.rejectApprovalShoppingCart
   */
  rejectApprovalShoppingCart(cartId: string, callback: MethodCallback<CheckoutMessage>): void {
    var formData = new URLSearchParams();
    formData.append('cartId', cartId);
    this.httpClient.request("PUT", "rest/rejectApprovalShoppingCart", callback, formData);
  }

  /**
   * HTTP POST /rest/requestPasswordReset
   * Java method: com.cellnet.connect.client.ServiceMainREST.requestPasswordReset
   */
  requestPasswordReset(usernameOrEmailOrAlias: string, callback: MethodCallback<ResetPasswordMessage>): void {
    var formData = new URLSearchParams();
    formData.append('usernameOrEmailOrAlias', usernameOrEmailOrAlias);
    this.httpClient.request("POST", "rest/requestPasswordReset", callback, formData);
  }

  /**
   * HTTP GET /rest/returnsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.returnsFromServer
   */
  returnsFromServer(callback: MethodCallback<ReturnsMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/returnsFromServer", callback, undefined, context);
  }

  /**
   * HTTP POST /rest/saveCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.saveCurrentShoppingCart
   */
  saveCurrentShoppingCart(cartName: string, callback: MethodCallback<ShoppingCartsMessage>): void {
    var formData = new URLSearchParams();
    formData.append('cartName', cartName);
    this.httpClient.request("POST", "rest/saveCurrentShoppingCart", callback, formData);
  }

  /**
   * HTTP GET /rest/search
   * Java method: com.cellnet.connect.client.ServiceMainREST.search
   */
  search(searchText: string, hitsPerPage: number, pageNo: number, callback: MethodCallback<SearchMessage>): void {
    var formData = new URLSearchParams();
    formData.append('searchText', searchText);
    formData.append('hitsPerPage', "" + hitsPerPage);
    formData.append('pageNo', "" + pageNo);
    this.httpClient.request("GET", "rest/search", callback, formData);
  } 
  
  /**
   * HTTP GET /rest/search
   * Java method: com.cellnet.connect.client.ServiceMainREST.search
   */
  searchRestrictingCategory(searchText: string, searchCategory: string, hitsPerPage: number, pageNo: number, callback: MethodCallback<SearchMessage>): void {
    var formData = new URLSearchParams();
    formData.append('searchText', searchText);
    formData.append('searchCategory', searchCategory);
    formData.append('hitsPerPage', "" + hitsPerPage);
    formData.append('pageNo', "" + pageNo);
    this.httpClient.request("GET", "rest/searchRestrictingCategory", callback, formData);
  }   

  /**
   * HTTP GET /rest/searchForAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.searchForAccount
   */
  searchForAccount(searchText: string, callback: MethodCallback<SearchAccountMessage>): void {
    var formData = new URLSearchParams();
    formData.append('searchText', searchText);
    this.httpClient.request("GET", "rest/searchForAccount", callback, formData);
  }
  

  /**
   * HTTP PUT /rest/setAlertRead
   * Java method: com.cellnet.connect.client.ServiceMainREST.setAlertRead
   */
  setAlertRead(id: number, callback: MethodCallback<AlertsMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('id', "" + id);
    this.httpClient.request("PUT", "rest/setAlertRead", callback, formData, context);
  }
  
  /**
   * HTTP PUT /rest/setApproverForCurrentShoppingCart
   * Java method: com.cellnet.connect.client.ServiceMainREST.setApproverForCurrentShoppingCart
   */
  setApproverForCurrentShoppingCart(approverUserId: string, cartName: string, callback: MethodCallback<ShoppingCartsMessage>): void {
    var formData = new URLSearchParams();
    formData.append('approverUserId', approverUserId);
    formData.append('cartName', cartName);
    this.httpClient.request("PUT", "rest/setApproverForCurrentShoppingCart", callback, formData);
  }
  
  /**
   * HTTP GET /rest/shoppingCartsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.shoppingCartsFromServer
   */
  shoppingCartsFromServer(callback: MethodCallback<ShoppingCartsMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/shoppingCartsFromServer", callback, undefined, context);
  }

  /**
   * HTTP PUT /rest/submitIncompleteReturnRequest
   * Java method: com.cellnet.connect.client.ServiceMainREST.submitIncompleteReturnRequest
   */
  submitIncompleteReturnRequest(requestNo: number, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    this.httpClient.request("PUT", "rest/submitIncompleteReturnRequest", callback, formData);
  }

  /**
   * HTTP GET /rest/trackingDatasFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.trackingDatasFromServer
   */
  trackingDatasFromServer(callback: MethodCallback<TrackingDatasMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/trackingDatasFromServer", callback, undefined, context);
  }

  /**
   * HTTP GET /rest/transactionsAndPaymentsFromServer
   * Java method: com.cellnet.connect.client.ServiceMainREST.transactionsAndPaymentsFromServer
   */
  transactionsAndPaymentsFromServer(callback: MethodCallback<TransactionsAndPaymentsMessage>, context?: any): void {
    this.httpClient.request("GET", "rest/transactionsAndPaymentsFromServer", callback, undefined, context);
  }  

  /**
   * HTTP PUT /rest/updateAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateAddress
   */
  updateAddress(addressId: number, addressName: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<AddressesMessage>): void {
    var formData = new URLSearchParams();
    formData.append('addressId', "" + addressId);
    formData.append('addressName', addressName);
    formData.append('companyName', companyName);
    formData.append('contactName', contactName);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('suburb', suburb);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('postcode', postcode);
    this.httpClient.request("PUT", "rest/updateAddress", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateDataFeedAzure
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedAzure
   */
  updateDataFeedAzure(accountName: string, accountKey: string, container: string, https: boolean, deliveryTimes: string[], selectedColumns: string[], fileFormat: string, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void {
    var formData = new URLSearchParams();
    formData.append('accountName', accountName);
    formData.append('accountKey', accountKey);
    formData.append('container', container);
    formData.append('https', "" + https);
    for (let i of deliveryTimes) {
      formData.append('deliveryTimes', i);
    }
    for (let i of selectedColumns) {
      formData.append('selectedColumns', i);
    }
    formData.append('fileFormat', fileFormat);
    formData.append('contactName', contactName);
    formData.append('contactPhone', contactPhone);
    formData.append('contactEmail', contactEmail);
    this.httpClient.request("PUT", "rest/updateDataFeedAzure", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateDataFeedEmail
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedEmail
   */
  updateDataFeedEmail(email: string, deliveryTimes: string[], selectedColumns: string[], fileFormat: string, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void {
    var formData = new URLSearchParams();
    formData.append('email', email);
    for (let i of deliveryTimes) {
      formData.append('deliveryTimes', i);
    }
    for (let i of selectedColumns) {
      formData.append('selectedColumns', i);
    }
    formData.append('fileFormat', fileFormat);
    formData.append('contactName', contactName);
    formData.append('contactPhone', contactPhone);
    formData.append('contactEmail', contactEmail);
    this.httpClient.request("PUT", "rest/updateDataFeedEmail", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateDataFeedFTP
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedFTP
   */
    updateDataFeedFTP(host: string, user: string, password: string, folder: string, sftp: boolean, port: number, deliveryTimes: DataFeedDeliveryTime[], selectedColumns: DataFeedColumns[], fileFormat: DataFeedFileFormat, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void {
    var formData = new URLSearchParams();
    formData.append('host', host);
    formData.append('user', user);
    formData.append('password', password);
    formData.append('folder', folder);
    formData.append('sftp', "" + sftp);
    formData.append('port', "" + port);
    for (let i of deliveryTimes) {
      formData.append('deliveryTimes', i);
    }
    for (let i of selectedColumns) {
      formData.append('selectedColumns', i);
    }
    formData.append('fileFormat', fileFormat);
    formData.append('contactName', contactName);
    formData.append('contactPhone', contactPhone);
    formData.append('contactEmail', contactEmail);
    this.httpClient.request("PUT", "rest/updateDataFeedFTP", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateDataFeedWebLink
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDataFeedWebLink
   */
  updateDataFeedWebLink(url: string, deliveryTimes: string[], selectedColumns: string[], fileFormat: string, contactName: string, contactPhone: string, contactEmail: string, callback: MethodCallback<DataFeedMessage>): void {
    var formData = new URLSearchParams();
    formData.append('url', url);
    for (let i of deliveryTimes) {
      formData.append('deliveryTimes', i);
    }
    for (let i of selectedColumns) {
      formData.append('selectedColumns', i);
    }
    formData.append('fileFormat', fileFormat);
    formData.append('contactName', contactName);
    formData.append('contactPhone', contactPhone);
    formData.append('contactEmail', contactEmail);
    this.httpClient.request("PUT", "rest/updateDataFeedWebLink", callback, formData);
  }

  /**
   * HTTP PUT /rest/updatePassword
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePassword
   */
  updatePassword(changeToken: string, password: string, callback: MethodCallback<LoginMessage>): void {
    var formData = new URLSearchParams();
    formData.append('changeToken', changeToken);
    formData.append('password', password);
    this.httpClient.request("PUT", "rest/updatePassword", callback, formData);
  }

  /**
   * HTTP PUT /rest/updatePath
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePath
   */
   updatePath(path: string, callback: MethodCallback<PathMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('path', path);
    this.httpClient.request("PUT", "rest/updatePath", callback, formData, context);
  }  

    /**
     * HTTP PUT /rest/updatePayPalAllocationPayment
     * Java method: com.cellnet.connect.client.ServiceMainREST.updatePayPalAllocationPayment
     */
    updatePayPalAllocationPayment(allocationToken: string, payPalOrderId: string, payPalPayerId: string, callback: MethodCallback<UpdatePayPalAllocationPaymentMessage>): void {
        var formData = new URLSearchParams();
        formData.append('allocationToken', allocationToken);
        formData.append('payPalOrderId', payPalOrderId);
        formData.append('payPalPayerId', payPalPayerId);
        this.httpClient.request("PUT", "rest/updatePayPalAllocationPayment", callback, formData);
    }

  /**
   * HTTP PUT /rest/updatePayPalOrderPayment
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePayPalOrderPayment
   */
    updatePayPalOrderPayment(webOrderId: string, payPalOrderId: string, payPalPayerId: string, callback: MethodCallback<UpdatePayPalOrderPaymentMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('webOrderId', webOrderId);
    formData.append('payPalOrderId', payPalOrderId);
    formData.append('payPalPayerId', payPalPayerId);
    this.httpClient.request("PUT", "rest/updatePayPalOrderPayment", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/updatePreference
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePreference
   */
  updatePreference(name: string, value: string, callback: MethodCallback<PreferencesMessage>): void {
    var formData = new URLSearchParams();
    formData.append('name', name);
    formData.append('value', value);
    this.httpClient.request("PUT", "rest/updatePreference", callback, formData);
  }

  /**
   * HTTP PUT /rest/updatePublicPagePreferences
   * Java method: com.cellnet.connect.client.ServiceMainREST.updatePublicPagePreferences
   */
  updatePublicPagePreferences(customerId: string, companyName: string, contactName: string, addressLine1: string, addressLine2: string, suburb: string, state: string, city: string, postcode: string, phone: string, callback: MethodCallback<PublicPagePreferencesMessage>): void {
    var formData = new URLSearchParams();
    formData.append('customerId', customerId);
    formData.append('companyName', companyName);
    formData.append('contactName', contactName);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('suburb', suburb);
    formData.append('state', state);
    formData.append('city', city);
    formData.append('postcode', postcode);
    formData.append('phone', phone);
    this.httpClient.request("PUT", "rest/updatePublicPagePreferences", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateReturnRequestAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateReturnRequestAddress
   */
  updateReturnRequestAddress(requestNo: number, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, companyName: string, addressLine1: string, addressLine2: string, suburb: string, state: string, postcode: string, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    formData.append('customerRef', customerRef);
    formData.append('contactName', contactName);
    formData.append('contactEmail', contactEmail);
    formData.append('contactPhone', contactPhone);
    formData.append('companyName', companyName);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('suburb', suburb);
    formData.append('state', state);
    formData.append('postcode', postcode);
    this.httpClient.request("PUT", "rest/updateReturnRequestAddress", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateReturnRequestContact
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateReturnRequestContact
   */
  updateReturnRequestContact(requestNo: number, customerRef: string, contactName: string, contactEmail: string, contactPhone: string, callback: MethodCallback<ReturnMessage>): void {
    var formData = new URLSearchParams();
    formData.append('requestNo', "" + requestNo);
    formData.append('customerRef', customerRef);
    formData.append('contactName', contactName);
    formData.append('contactEmail', contactEmail);
    formData.append('contactPhone', contactPhone);
    this.httpClient.request("PUT", "rest/updateReturnRequestContact", callback, formData);
  }

  /**
   * HTTP PUT /rest/updateShoppingCartProductQuantity
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateShoppingCartProductQuantity
   */
  updateShoppingCartProductQuantity(productId: string, quantity: number, callback: MethodCallback<ShoppingCartsMessage>, context?: any): void {
    var formData = new URLSearchParams();
    formData.append('productId', productId);
    formData.append('quantity', "" + quantity);
    this.httpClient.request("PUT", "rest/updateShoppingCartProductQuantity", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/updateUserAccount
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateUserAccount
   */
  updateUserAccount(userId: string, accountId: string, permissions: string, managerUserId: string, callback: MethodCallback<UsersMessage>, context?:any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    formData.append('accountId', accountId);
    formData.append('permissions', permissions);
    formData.append('managerUserId', managerUserId);
    this.httpClient.request("PUT", "rest/updateUserAccount", callback, formData, context);
  }

  /**
   * HTTP PUT /rest/updateUserProfile
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateUserProfile
   */
   updateUserProfile(userId: string, alias: string, firstName: string, lastName: string, email: string, phone: string, password: string, callback: MethodCallback<UsersMessage>, context?:any): void {
    var formData = new URLSearchParams();
    formData.append('userId', userId);
    formData.append('alias', alias);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    this.httpClient.request("PUT", "rest/updateUserProfile", callback, formData, context);
  }

  /**
   * HTTP POST /rest/getDealerApplication
   * Java method: com.cellnet.connect.client.ServiceMainREST.getDealerApplication
   */
  getDealerApplication(emailAddress: string, accountType: string, callback: MethodCallback<DealerApplicationMessage>, context?: any): void
  {
    var formData = new URLSearchParams();
    formData.append('emailAddress', emailAddress);
    formData.append('accountType', accountType);
    this.httpClient.request("POST", "rest/getDealerApplication", callback, formData, context);    
  }

  /**
   * HTTP PUT /rest/updateDealerApplication
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDealerApplication
   */
  updateDealerApplication(dealerApplication: DealerApplication, callback: MethodCallback<DealerApplicationMessage>, context?: any): void
  {
    this.httpClient.requestPassingJSON("PUT", "rest/updateDealerApplication", callback, JSON.stringify(dealerApplication), context);
  }

  /**
   * HTTP DELETE /rest/deleteDealerApplication
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteDealerApplication
   */
  deleteDealerApplication(emailAddress: string, accountType: string, callback: MethodCallback<DealerApplicationMessage>, context?: any): void
  {
    var formData = new URLSearchParams();
    formData.append('emailAddress', emailAddress);
    formData.append('accountType', accountType);
    this.httpClient.request("DELETE", "rest/deleteDealerApplication", callback, formData, context);       
  }

  /**
   * HTTP POST /rest/addDealerApplicationAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.addDealerApplicationAddress
   */  
  addDealerApplicationAddress(emailAddress: string, accountType: string, applicationId: number, addressType: string, addressLine1: string,
    addressLine2: string, suburb: string, city: string, state: string, postcode: string, callback: MethodCallback<DealerApplicationMessage>, context?: any): void
  {
    var formData = new URLSearchParams();
    formData.append('emailAddress', emailAddress);
    formData.append('accountType', accountType);
    formData.append('applicationId', '' + applicationId);
    formData.append('addressType', addressType);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('suburb', suburb);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('postcode', postcode);
    this.httpClient.request("POST", "rest/addDealerApplicationAddress", callback, formData, context);    
  }

  /**
   * HTTP PUT /rest/updateDealerApplicationAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.updateDealerApplicationAddress
   */      
  updateDealerApplicationAddress(dealerApplicationAddress: DealerApplicationAddress, callback: MethodCallback<DealerApplicationMessage>, context?: any): void
  {
    this.httpClient.requestPassingJSON("PUT", "rest/updateDealerApplicationAddress", callback, JSON.stringify(dealerApplicationAddress), context);
  }

  /**
   * HTTP DELETE /rest/deleteDealerApplicationAddress
   * Java method: com.cellnet.connect.client.ServiceMainREST.deleteDealerApplicationAddress
   */  
  deleteDealerApplicationAddress(emailAddress: string, accountType: string, addressId: number, callback: MethodCallback<DealerApplicationMessage>, context?: any): void
  {
    var formData = new URLSearchParams();
    formData.append('emailAddress', emailAddress);
    formData.append('accountType', accountType);
    formData.append('addressId', '' + addressId);
    this.httpClient.request("DELETE", "rest/deleteDealerApplicationAddress", callback, formData, context);       
  }

}

