import React from 'react';
import { productPageSize } from '../Constants';
import ProductCard from '../elements/ProductCard/ProductCard';
import { ProductExt } from '../RESTAPI';
import { Button } from 'antd';
import {paginate} from '../utils';

//const skeletonNum = 22;
//const blankProduct = {} as ProductExt;

const ProductList: React.FC<{title: string, filter?: boolean, productList: Array<ProductExt>, loading: boolean}> = ({loading = false, title, productList = [], filter = true}) => {
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [maxPage, setMaxPage] = React.useState<number>(1);
    const [currentProductList, setCurrentProductList] = React.useState<Array<ProductExt>>([]);

    React.useEffect(() => {
        if(productList.length > 0) {
            setMaxPage(Math.ceil(productList.length / productPageSize));
            setCurrentPage(1);

            //setCurrentProductList(currentProductList.concat(paginate(productList, productPageSize, currentPage)))
            setCurrentProductList(paginate(productList, productPageSize, 1));
        } else {
            setMaxPage(1);
            setCurrentPage(1);
        }
    }, [productList])

    const renderProductList = currentProductList.map((product, key) => {
        return (
            <div className='productList' key={key}>
                <ProductCard product={product} loading={false}/>
            </div>
        )
    })

    /*const renderBlankProduct = () => {
        let count = 0;
        let result = [];

        while (count < skeletonNum) {
            count++;
            result.push(
                <div className='productList' key={count}>
                    <ProductCard product={blankProduct} loading={loading}/>
                </div>
            )
        }

        return result;
    }*/

    const onClick = (e: React.MouseEvent) => {
        if(currentPage < maxPage) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);

            setCurrentProductList([...currentProductList, ...paginate(productList, productPageSize, nextPage)]);
        }
    }

    return (
        <>
        
        <h2 className={'headerTitle ' + (filter ? ' textCenter' : '')}>{title}</h2>
        <div className='productListWrapper'>
            {
                productList.length > 0 ? renderProductList : (
                    <div>
                        There is no matching products
                    </div>
                )
            }
        </div>
        {
            currentPage < maxPage && 
            <div className='maxWidth300px marginTop25px'>
                <Button ghost block onClick={onClick}>LOAD MORE</Button>
            </div>
        }
        </>
    );
}

export default ProductList;
