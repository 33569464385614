import React from 'react';
import { Carousel } from 'antd';
import ProductCard from '../elements/ProductCard/ProductCard';
import { ProductExt } from '../RESTAPI';

const ProductListSlider: React.FC<{bottomPosition?: boolean, textCenter?: boolean, title: string, slideToShow?: number, mobile?: boolean, productList: Array<ProductExt>}> = ({slideToShow = 6, title, productList = [], mobile = false, textCenter = false, bottomPosition = false}) => {

    return (
        <>
        <h2 className={'headerTitle ' + (mobile || textCenter ? ' textCenter' : '')}>{title}</h2>
        <Carousel dotPosition={mobile || bottomPosition ? 'bottom':'top'} swipeToSlide={true} touchMove={true} accessibility={true} draggable={true} infinite={false} className='productListSlider' slidesToShow={slideToShow} responsive= {[
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                dots: true
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                dots: true
            }
        },
        ]}>
            {
                productList.map((product, key) => {
                    if(key < 7) {
                        return (
                            <div className='slideItem' key={key}>
                                <ProductCard product={product} key={key}/>
                            </div>
                        )
                    } else {
                        return null
                    }
                    
                })
            }
        </Carousel>
        </>
    );
}

export default ProductListSlider;
