import React from 'react';
import { Result, Input, Form, Button, Select, Row, Col, Checkbox } from 'antd';
import { motion, AnimatePresence } from "framer-motion";
import { EyeInvisibleOutlined, EyeTwoTone, MinusCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import successIcon from '../assets/images/success_icon.svg';
import successPW from '../assets/images/success_pw.svg';
import PageLink from '../elements/PageLink/PageLink';
import { pageVariants, forgotPasswordStep, changePasswordStep, FormStep, FormInput, PasswordLength, tabletSize} from '../Constants';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { ProductContext } from '../contexts/ProductContext';
import { UserContext } from '../contexts/UserContext';
import { useWindowSize } from '../utils';

const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
        zIndex: 0,
        transition: {duration: 0.6}
      };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
        transition: {duration: 0.6}
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
        transition: {duration: 0.6}
      };
    }
};

const { Option } = Select;

const WizardForm = (props: { currentValues: any, reason?: any, className?: string, finalButton: string; title: String; steps: Array<FormStep>, currentStep: number, direction: number, isStaff?: boolean, setStep: (direction: number, values?: any) => void, handleSaveField?: (p: any[]) => void}) => {
    const {className, finalButton, steps, currentStep, direction, setStep, handleSaveField, isStaff, currentValues, reason } = props;
    const [height, setHeight] = React.useState<number | undefined>(0);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [fieldQty, setFieldQty] = React.useState<number>(0);
    const [formValues, setValues] = React.useState<Array<any>>([]);
    const sectionForm = React.useRef<HTMLInputElement>(null);
    
    const {productState} = React.useContext(ProductContext);
    const {userState} = React.useContext(UserContext);

    const size = useWindowSize();
    let defaultDesktopHeight = 81;
    const defaultTypeHeight = 81;

    if(size.width < tabletSize) {
        defaultDesktopHeight = 316;
    } else {
        defaultDesktopHeight = 81;
    }

    React.useEffect(() => {
        if(size.width < tabletSize) {
            defaultDesktopHeight = 316;            
        } else {
            defaultDesktopHeight = 81;
        }

        if(fieldQty > 0) {
            setHeight(73 + (fieldQty * defaultDesktopHeight));
        }
        
    }, [size.width])

    React.useEffect(() => {
        if(isStaff && height) {
            setHeight(defaultDesktopHeight + height);
        }
        
    }, [isStaff])

    // render input
    const renderInput = (input: FormInput, key: number) => {
        switch (input.inputType) {
            case 'email':
                return <Form.Item hasFeedback key={key} name={input.name} label='' rules={[{ type: 'email', message: 'The input is not a valid email'}, {required: input.isValidate, message: 'Please input your E-mail!' }]}> 
                    <Input size="large" className="has-float-label" placeholder={input.placeholder} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/> 
                </Form.Item>

            case 'reasons':
                const options: Array<any> = [];
                
                if(userState.returns) {
                    Object.keys(userState.returns.allReasonCodeToReason).forEach(key => {
                        const reasonObj = userState.returns.allReasonCodeToReason[key];
                        if(userState.returns.customerReasonCodes) {
                            if(userState.returns.customerReasonCodes.includes(reasonObj.code)) {
                                options.push({
                                    value: reasonObj.code,
                                    label: reasonObj.name
                                })
                            }
                        }
                    });
                }
                
                return <Form.Item className="group-floating-label" hasFeedback key={key} name={input.name} label='' rules={[{required: input.isValidate, message: 'Please select your reason' }]}>
                    <Select showSearch={true} size='large' placeholder="Please select a reason" options={options}/>
                </Form.Item>

            case 'phone':
                return <Form.Item className="group-floating-label" hasFeedback key={key} name={input.name} label='' rules={[{required: input.isValidate, message: 'Please input your phone number' }]}>
                    <Input size="large" className="has-float-label" placeholder={input.placeholder} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/> 
                </Form.Item>

            case 'number':
                return <Form.Item className="group-floating-label" hasFeedback key={key} name={input.name} label='' rules={[({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please input a valid number!');
                    },
                })]}>
                    <Input size="large" className="has-float-label" placeholder={input.placeholder} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/> 
                </Form.Item>

            case 'password':
                return <Form.Item className="group-floating-label" key={key} hasFeedback name={input.name} label='' rules={[({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value.length >= PasswordLength) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Please input a valid password!');
                    },
                })]}>
                    <Input type={visible ? "text" : "password"} size="large" className="has-float-label" placeholder={input.placeholder} prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisible(!visible)} > {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/>
                </Form.Item>

            case 'confirm_password':
                return <Form.Item className="group-floating-label" key={key} dependencies={['password']} hasFeedback name={input.name} label='' rules={[{  required: input.isValidate, message: 'Please confirm your password!' }, ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                })]}>
                    <Input type={visible ? "text" : "password"} size="large" className="has-float-label" placeholder={input.placeholder} prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisible(!visible)} > {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/>
                </Form.Item>

            case 'text-staff':
                if(isStaff) {
                    return <Row gutter={[20, 20]} align='top'>
                            <Col span={24}>
                                <Form.Item className="group-floating-label" hasFeedback key={key} name={input.name} label='' rules={[{ required: isStaff, message: 'Please input your ' + input.label + '!' }]}>
                                    <Input disabled={!isStaff} size="large" className="has-float-label" placeholder={input.placeholder} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/>  
                                </Form.Item>
                            </Col>
                        </Row>
                } else {
                    return null
                }
            case 'checkbox':
                return <Form.Item className="group-floating-label" hasFeedback key={key} name={input.name} valuePropName="checked" label=''>
                    <Checkbox>{input.label}</Checkbox>                    
                </Form.Item>               
            default:
                return <Form.Item className="group-floating-label" hasFeedback key={key} name={input.name} label='' rules={[{ required: input.isValidate, message: 'Please input your ' + input.label + '!' }]}>
                    <Input size="large" className="has-float-label" placeholder={input.placeholder} suffix={<label className="floating-label" htmlFor="name">{input.label}</label>}/>  
                </Form.Item>
        }
    }

    // render out input fields
    const renderInputs = (inputs: Array<FormInput>) => {
        return inputs.map((input, key) => {
            return renderInput(input, key)
        })      
    }

    const renderNavigation = () => {
        if(steps.length > 2) {
            return (<motion.div variants={pageVariants} initial="initial" animate="in" exit="out" className='flexSpaceBetween'>
                        {
                            currentStep !== steps.length ? (
                                <>
                                <Button size='large' icon={<ArrowLeftOutlined />} type='link' onClick={() => {setStep(-1);}} disabled={currentStep === 1 || currentStep === steps.length ? true : false} className={'ordered-icon ' + (currentStep === 1 || currentStep === steps.length ? 'hidden' : '')}>Back</Button>
                                <Button size='large' className='reverse-icon' type={currentStep === steps.length - 1 ? 'primary':'link'}  htmlType='submit' icon={currentStep === steps.length - 1 ? '' : <ArrowRightOutlined />}>{currentStep === steps.length - 1 ? finalButton:'Next'}</Button>
                                </>
                            ) : null
                        }
                        
                    </motion.div>)
        } else {
            if(steps.length === currentStep && steps === forgotPasswordStep) {
                return (
                    <motion.div className='textCenter marginTop25px' variants={pageVariants} initial="initial" animate="in" exit="out">
                        <strong>Didn't receive the email?</strong>
                        <PageLink onClick={(e) => {e.preventDefault(); window.location.reload();}} bold className='marginLeft10px text-secondaryColor'>Send Again</PageLink>
                    </motion.div>
                );
            } else if(steps.length === currentStep && steps === changePasswordStep) {
                return (
                    <motion.div className='textCenter marginTop25px fontSize16px' variants={pageVariants} initial="initial" animate="in" exit="out">
                        Your password has now been changed, you can <br/><PageLink to="/" bold className='text-secondaryColor'>sign in</PageLink>
                    </motion.div>
                );
            } else {
                return <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
                    <Button htmlType="submit" loading={productState.loading} type="primary" block>{finalButton}</Button>
                </motion.div>
            } 
        }
    }

    const onAnimationStart = () => {
        const currentHeight = sectionForm.current?.offsetHeight;

        if(currentHeight) {
            setHeight(currentHeight);
        }
        
    }

    const onAnimationComplete = () => {
        if(sectionForm.current?.offsetHeight && height && height <= sectionForm.current.offsetHeight) {
            const currentHeight = sectionForm.current.offsetHeight;
            if(currentHeight) {
                setHeight(currentHeight);
            }
        }
    }

    // render out steps
    const renderSteps = (step: FormStep) => {
        let successLogo = successIcon;
        if(step.isSuccessLogo && step.isSuccessLogo === 'success-pw') {
            successLogo = successPW
        }

        return (
            <div style={{height: height}}>
                <AnimatePresence initial={false} custom={direction}>
                <motion.section
                    style={{position: 'absolute', width: '100%'}}
                    key={step.step}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: "tween", stiffness: 2000, damping: 30 },
                        opacity: { duration: 0.8 }
                    }}
                    ref={sectionForm}
                    onAnimationStart={() => onAnimationStart()}
                    onAnimationComplete={() => onAnimationComplete()}
                >
                    {
                        step.isSuccess && (<Result
                            icon={<img height='158' alt='success' src={successLogo} />}
                            title={step.description}
                        />)
                    }
                    {
                        step.formInputs.length > 0 && renderInputs(step.formInputs)
                    }
                </motion.section>
                </AnimatePresence>
            </div>
        );
    };

    return <Form className={className} scrollToFirstError onFinish={(values) => setStep(1, values)} onValuesChange={(_, values) => {
        setValues(values)
        }}>
            {renderSteps(steps[currentStep - 1])}          
            {renderNavigation()}
    </Form>
    
}


export default WizardForm;
