import React from 'react';

function BoldSubText(str: string, query: string) {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return str; // bail early
    }
    const l = q.length;
    
    return <>
            {str.substr(0, x)}<span className='text-primaryColor'>{str.substr(x, l)}</span>{str.substr(x + l)}
        </>
}

export default BoldSubText