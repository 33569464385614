import React from 'react';
import {Row, Col, Typography} from 'antd';
import WizardForm from '../components/WizardForm';
import {forgotPasswordStep, Location} from '../Constants';
import {restClient, logout, myAuth} from '../elements/PrivateRoute/PrivateRoute';
import PageLink from '../elements/PageLink/PageLink';
import LoginFooter from '../components/LoginFooter';
import CellnetLogo from '../elements/Logo/Logo';
import HomeBg from '../components/HomeBg';
import { motion } from "framer-motion";
import { pageVariants } from '../Constants';
import { Redirect, useLocation  } from 'react-router-dom';
import { MethodCallback, ResetPasswordMessage, ServiceMainRESTClient } from '../RESTAPI';
import { getPrevProp } from '../utils';

const { Title } = Typography;

function ForgotPassword() {
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [direction, setDirection] = React.useState<number>(1);

  const ResetPasswordCallback:MethodCallback<ResetPasswordMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: ResetPasswordMessage, context: any): void {
        
    }
  }

  const handleSetCurrentStep = (direction: number, values?: any) => {
    const nextStep = currentStep + direction;
    setDirection(direction);
    setCurrentStep(currentStep + direction);
    
    if(nextStep === forgotPasswordStep.length) {
      restClient.requestPasswordReset(values.accountName, ResetPasswordCallback);
    }
  }

  let location = useLocation<Location>();
  let prevProp = getPrevProp(location.state)
  
  // stop user from accessing login page again
  if (myAuth.isAuthenticated) {
    return (
        <Redirect
            to={{
                pathname: prevProp
            }}
        />
    )
  }

  return (
    <Row justify='center' align='middle' className='fullHeight overflowHidden'>
        <HomeBg/>
        <Col xs={24} sm={24} md={8} lg={8} xl={13} className='overflowHidden padding30px fullHeight verticalCenter flexColumn whiteBg'>
            <motion.div className='marginTopAuto' initial="initial" animate="in" exit="out" variants={pageVariants}>
                <CellnetLogo to='/' className="textCenter marginBottom40px"/>
                <Title level={5} className='textCenter marginBottom25px'>Forgotten Password</Title>
                <WizardForm currentValues={{}} className='maxWidth300px' finalButton='Done' title='Forgotten Password' steps={forgotPasswordStep} currentStep={currentStep} direction={direction} setStep={(direction: number, values?: any) => handleSetCurrentStep(direction, values)}/>
                
                {
                    currentStep !== forgotPasswordStep.length && (
                        <div className='textCenter marginTop25px'>
                            <strong>Remembered?</strong>
                            <PageLink to="/" bold className='marginLeft10px text-secondaryColor'>Sign In</PageLink>
                        </div>
                    )
                }
            </motion.div>

            <LoginFooter className='marginTopAuto'/>
        </Col>
    </Row>
  );
}

export default ForgotPassword;
