import React from 'react';
import { Image, Skeleton } from 'antd';
import { formatCurrency, formatText, calcMargin, isFirstCharNumber } from '../../utils';
import { Link } from 'react-router-dom';
import FavouriteButton from '../../components/FavouriteButton';
import AddToCart from '../../components/AddToCart';
import { ProductExt } from '../../RESTAPI';
import { imageUrl, productStatus } from '../../Constants';
import { ProductContext } from '../../contexts/ProductContext';

const ProductCard: React.FC<{product: ProductExt, loading?: boolean}> = ({product, loading = false}) => {
    const {productState} = React.useContext(ProductContext);
    return (
        product !== undefined ? 
            <div className='productCard'>
                <Skeleton loading={loading} active avatar title paragraph={{rows: 8}}>
                    <div>
                        <Link to={'/shop/' + formatText(product.product ? product.product.name : '')}>
                        <Image className='paddingTop20p' preview={false} src={imageUrl + (product.product ? product.product.image : '')}/>
                        </Link>
                    </div>
                    <div className='flexColumn'>
                        <FavouriteButton id={product.product.id} favourite={productState.favourites.includes(product.product.id)} className='topMinus18px'/>
                        <Link to={'/shop/' + formatText(product.product ? product.product.name : '')}>
                        <h3 className='truncateText1Line noMarginBottom'>{product.product ? product.product.name : ''}</h3>
                        <p className='truncateText'>{product.product ? product.product.shortName : ''}</p>
                        </Link>
                        <p className='productAttributeTitle'>{(product && isFirstCharNumber(product.available)) ? (<>Stock On Hand <strong>{ product.available }</strong></>)
                         : (product ? product.available : '')}</p>
                        <p className='noMarginBottom fontSize14px'>RRP <strong>{formatCurrency(product.retailPrice ? product.retailPrice : 0)}</strong><br/>Margin <strong>{product ? calcMargin(product.retailPrice, product.priceBreakInfo.priceBreaks[0].price) + '%' : 'None'}</strong></p>
                        <p><span className='bold fontSize21px text-primaryColor'>{formatCurrency(product.priceBreakInfo ? product.priceBreakInfo.priceBreaks[0].price : 0)}</span>
                        { product.wasPrice &&
                            <span className='fontSize14px text-primaryColor' style={{position: "relative", top: "-7px", marginLeft: "10px", textDecoration: "line-through"}}>{formatCurrency(product.wasPrice)}</span>
                        }
                        </p>
                        <AddToCart qty={1} product={product}/>
                    </div>
                </Skeleton>
            </div>
        : null
    );
}

export default ProductCard;
