import React from 'react';
import { brandsDam, catesDam, deviceVendorsDam, UserState, Location, ProductState } from './Constants';
import { OrderLine, ReturnRequestLine, PriceBreak, Invoice, Transaction, AllocationPayment, ProductExt } from './RESTAPI';
import { message } from 'antd';
import _ from 'lodash';

export const getBreakPrice = (priceBreaks: PriceBreak[], quantity: number) => {
    let currentPrice = 0 as number;
    let previousBreakQuantity = 0;
    if (priceBreaks.length > 0) {
        priceBreaks.forEach((priceBreak) => {
            if (priceBreak.quantity <= quantity) {
                if (previousBreakQuantity <= quantity) {
                    currentPrice = priceBreak.price;
                    previousBreakQuantity = priceBreak.quantity;
                }
                
            }
        })
    }

    return currentPrice;
}

export function isFirstCharNumber(value: string): boolean
{
   return (value.trim().length > 0 && !isNaN(Number(value.substring(0, 1))));
}

export function isNumber(value: string): boolean
{
   return (value.trim().length > 0 && !isNaN(Number(value)));
}

export const getInputTextLength = (value: string) => {
    let text = document.createElement("span"); 
    document.body.appendChild(text); 

    text.style.font = "Source Sans Pro"; 
    text.style.fontSize = 16 + "px";
    text.style.fontWeight = 'bold';
    text.style.height = 'auto'; 
    text.style.width = 'auto'; 
    text.style.position = 'absolute'; 
    text.style.whiteSpace = 'no-wrap'; 
    text.innerHTML = value;
    
    let width = Math.ceil(text.clientWidth);  

    document.body.removeChild(text);
    return width;
}

export const calcMargin = (rrp: number, dealerPrice: number) => {
    let rrpMinusGST = rrp / 1.1;
    let profit = rrpMinusGST - dealerPrice;
    return Math.round((profit / rrpMinusGST)*100);
}

export function calculateGST(num: number | undefined, gst: number) {
  if(num) {
    if (isNaN(num)) {
      num = 0;
    }
  } else {
    return 0;
  }
  
  return num*gst;
}

export function formatCurrency(num: number | undefined, locale = 'en-AU', currency = 'AUD', minimumFractionDigits = 2) {
  if(num) {
    if (isNaN(num)) {
      num = 0;
    }

    return num.toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits });
  } else {
    return 0;
  }
};

export function roundPlaces(num: number, places: number): number
{
    let mult: number = Math.pow(10, places);
    return Math.round((num + Number.EPSILON) * mult) / mult;
}


export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function GetCardType(number: string)
{
    // visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return "Visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
        return "Mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "AMEX";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "Discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "Diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "Diners - Carte Blanche";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "JCB";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "Visa Electron";

    return "";
}

function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value
  }

  const issuer = GetCardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'AMEX':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`
      break
    case 'Diners':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC(value: string, cardNumber: string) {
  const clearValue = clearNumber(value)
  let maxLength = 4

  if (cardNumber) {
    const issuer = GetCardType(cardNumber);
    maxLength = issuer === 'AMEX' ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export function formatText(value: string) {
  return value.toLowerCase().replace(/[^a-zA-Z0-9]/g,'-'); 
}

export function paginate(array: Array<any>, page_size: number, page_number: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function getImage(name: string, type: string, size: string, extenstion: string = 'png')  {
  let link = '';
  switch (type) {
    case 'brands':
      link = brandsDam;
      break;

    case 'cates':
      link = catesDam;
      break;

    case 'deviceVendors':
      link = deviceVendorsDam;
      break;
  
    default:
      break;
  }

  return link + (name.replace(/[^A-Z0-9]/ig, '') + '_' + size + '.' + extenstion).toLowerCase();
}

export function isPaid(saleAmount: number, paidAmount: number) {
  if (saleAmount < 0)
    return true;
  if (saleAmount > Math.abs(paidAmount))
    return false;
  return true;
}

export function getStatus(invoice: Invoice, invoiceNoToTransactions: { [index: string]: Transaction[] }, invoiceNoToAllocationPayments: { [index: string]: AllocationPayment[] }) {

  if(invoice.amount < 0) {
    return "Unallocated Credit";
  }  
  let balance = calcBalance(invoice, invoiceNoToTransactions, invoiceNoToAllocationPayments);
  if (balance <= 0) {
    return 'Paid';
  }
  if(invoice.duedate == null) {
    return "New";
  }
  if(invoice.duedate < nowMovexDateNumber()) {
    return "Overdue";
  }
  return "Current";
  /*
    if (wrappedBean.getSalesAmount() < 0)
      return "Unallocated Credit";
    if (!wrappedBean.isPaid())
    {
      if (wrappedBean.getDueDate() == null)
        return "New";
      if (wrappedBean.getDueDate().before(new Date()))
        return "Overdue";
      return "Current";
    }
    return "Paid";
  */
}

// export function getSalesAmount(transactions: Transaction[]) {
//   let total = 0;

//   transactions.forEach(element => {
//     total = Math.abs(element.salesAmount);
//   });

//   return total;
// }

export function getPaidAmountFromTransactions(transactions: Transaction[]) {
  let total = 0;

  transactions.forEach(element => {
    total += Math.abs(element.paidAmount);
  });

  return total;
}

export function getPaidAmountFromAllocationPayments(allocationPayments: AllocationPayment[]) {
  let total = 0;

  allocationPayments.forEach(element => {
    total += Math.abs(element.paidAmount);
  });

  return total;
}

export function dateStringFormat(valueDate: string | number, showTime?: boolean, formatType?: boolean) { // YYYYMMDDHHmmss
  const separator = '/';

  const date = valueDate.toString();

  const year = date.substring(0,4);
  const month = date.substring(4,6);
  const day = date.substring(6,8);

  const hour = date.substring(8,10);
  const min = date.substring(10,12);
  const second = date.substring(12,14);

  if(showTime) {
    return day + separator + month + separator + year + ' ' + hour + ':' + min + ':' + second;
  }

  if(formatType) {
    return year + '-' + month + '-' + day;
  }
  
  return day + separator + month + separator + year;
}

export function getStateFromTwoCharState(twoCharState: string): string {
  switch (twoCharState.toUpperCase())  {
    case "QL":
      return "QLD";
    case "NS":
      return "NSW";
    case "VI":
      return "VIC";
    case "SA":
      return "SA";
    case "WA":
      return "WA";
    case "NT":
      return "NT";
    case "TA":
    case "TS":
      return "TAS";
    case "AC":
      return "ACT";
    default:
      return twoCharState;
  }
}

export const formatNewlineText = (text: string) => {
    text.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    })

    return null
}

export const totalQty = (lines: Array<OrderLine> | undefined) => {
  let countQty = 0;

  if(lines) {
    lines.map((line: OrderLine, k: any) => { 
          countQty += line.orderedqty;
          return null
      })
  }

  return countQty + (countQty > 1 ? ' products' : ' product')
}

export const totalReturnQty = (lines: Array<ReturnRequestLine> | undefined) => {
  let countQty = 0;

  if(lines) {
    lines.map((line: ReturnRequestLine, k: any) => { 
          countQty += line.qty;
          return null
      })
  }

  return countQty + (countQty > 1 ? ' products' : ' product')
}

export const totalReturnAmount = (lines: Array<ReturnRequestLine> | undefined) => {
    let totalAmount = 0.0;
  
    if(lines) {
      lines.map((line: ReturnRequestLine, k: any) => { 
            totalAmount += (line.saleprice * line.qty);
            return null
        })
    }
  
    return totalAmount;
  }

export const calcBalance = (invoice: Invoice, invoiceNoToTransactions: { [index: string]: Transaction[] }, invoiceNoToAllocationPayments: { [index: string]: AllocationPayment[] } ) => {
  let totalDue = 0;
  let transactionAmount = 0;
  let allocatedPaymentAmount = 0;

  if (invoiceNoToTransactions && invoiceNoToAllocationPayments) {
      let transactionsLinked = invoiceNoToTransactions[invoice.invoiceno.toString()];
      let allocationPaymentsLinked = invoiceNoToAllocationPayments[invoice.invoiceno.toString()];

      if (transactionsLinked && transactionsLinked.length > 0) {
          transactionsLinked.forEach(transaction => {
              transactionAmount -= transaction.paidAmount;
          })
      }

      if(allocationPaymentsLinked && allocationPaymentsLinked.length > 0) {
          allocationPaymentsLinked.forEach(allocatedPayment => {
              allocatedPaymentAmount += allocatedPayment.paidAmount;
          })
      }
  }  
  
  // check which one is higher
  totalDue = invoice.amount - Math.max(transactionAmount, allocatedPaymentAmount);

  return totalDue;
}

export const removeAll = (originalSet: any, toBeRemovedSet: any) => {
  [...toBeRemovedSet].forEach(function(v) {
    originalSet.delete(v); 
  });
}

export const removeElementFromArray = (originalArray: Array<any>, toBeRemoved: any) => {
  const index = originalArray.indexOf(toBeRemoved, 0);
  if (index > -1) {
    originalArray.splice(index, 1);
  }   
}

function pad(n: number) {
  var r = String(n);
  if (r.length === 1) {
    r = '0' + r;
  }
  return r;
}

export const nowMovexDateString = (): string => {
    let now: Date = new Date();
    return now.getUTCFullYear() + pad(now.getUTCMonth() + 1) + pad(now.getUTCDate());
};

export const nowMovexDateTimeString = (): string => {
    let now: Date = new Date();
    return now.getUTCFullYear() +
          pad(now.getUTCMonth() + 1) +
          pad(now.getUTCDate()) +
          pad(now.getUTCHours()) +
          pad(now.getUTCMinutes()) +
          pad(now.getUTCSeconds());
};

export const nowMovexDateNumber = (): number => {
  return +nowMovexDateString;
};

export const nowMovexDateTimeNumber = (): number => {
  return +nowMovexDateTimeString;
};

export const getPrevProp = (location: Location): string => {
  let prevProp = '/';
  if (location) {
    let fromPrefix = location.fromPath;
    if (fromPrefix.length > 1) {
      let fromHref = location.fromHref;
      let fromHrefDoubleSlashIndex = fromHref.indexOf("//");
      if (fromHrefDoubleSlashIndex > 0) {
        fromHref = fromHref.substring(fromHrefDoubleSlashIndex + 2);
      }
      let fromPathColonIndex = fromPrefix.indexOf(":");
      if (fromPathColonIndex > 0) {
        fromPrefix = fromPrefix.substring(0, fromPathColonIndex);
      }
      let fromHrefPrefixIndex = fromHref.indexOf(fromPrefix);
      if (fromHrefPrefixIndex > 0) {
        fromPrefix = fromHref.substring(fromHrefPrefixIndex);
      }
      prevProp = fromPrefix;
    }
  }  
  return prevProp;
}

export const getMinOrderQty = (currentProduct: ProductExt | undefined, productState: ProductState | undefined) => {
  let minOrderQty = 1;
  if (currentProduct && currentProduct.product && productState && productState.productIdToMinOrderQty) {
    let qty: number | undefined = productState.productIdToMinOrderQty[currentProduct.product.id];
    if (qty && qty > 1) {
      minOrderQty = qty;
    }
  }
  if (currentProduct && currentProduct.product && productState && productState.productIdToOrderMultiple) {
    let orderMultiple: number | undefined = productState.productIdToOrderMultiple[currentProduct.product.id];
    if (orderMultiple && orderMultiple > minOrderQty) {
      minOrderQty = orderMultiple;
    }
  }  
  return minOrderQty;
} 

export const getOrderMultiple = (currentProduct: ProductExt | undefined, productState: ProductState | undefined) => {
  if (currentProduct && currentProduct.product && productState && productState.productIdToOrderMultiple) {
    let orderMultiple: number | undefined = productState.productIdToOrderMultiple[currentProduct.product.id];
    if (orderMultiple && orderMultiple > 1) {
      return orderMultiple;
    }
  }
  return 1;
}

export const delayedMessage = (content: string, key: string, duration: number, delay: number) => {
  setTimeout(() => {  message.success({ content: content, key: key, duration: duration }); }, delay * 1000);
}