import  React from  "react";
import { Route, Redirect, RouteProps } from  "react-router-dom";
import Cookies from 'universal-cookie';
import { ClientHelper, UserAccess, RecentActivity, ServiceMainRESTClient, MethodCallback, LoginMessage, PathMessage, InitMessage, ProductsMessage, LogoutMessage, ProductExt, RecentActivityMessage, AccountMessage, OrdersMessage, BackOrdersMessage, InvoicesMessage, DueInvoicesMessage, TransactionsAndPaymentsMessage, DeliveryAddressesMessage, TrackingDatasMessage, ReturnsMessage, AddressesMessage, UsersMessage, DataFeedMessage, AlertsMessage, ShoppingCartsMessage, PreferencesMessage } from '../../RESTAPI';
import { ProductContext } from '../../contexts/ProductContext';
import { AlertObj, BrandDeviceCateObject, MyUserProfile, SubObject } from "../../Constants";
import { UserContext } from "../../contexts/UserContext";
import { CartContext } from "../../contexts/CartContext";
import _ from 'lodash';

const cookies = new Cookies();
export const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

export const logout = () => {
    restClient.logout(LogoutCallback);
}

const ProductCallback: MethodCallback<ProductsMessage> = {
    onFailure(error: string): void {
        alert(error);
    },

    onProgress(loaded: number, total: number): void {},

    onSuccess(message: ProductsMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // state, setLoadingState, setProducts, setBrands, setCategories, setDeviceVendors, setClearance, setNew, setSpecial
            let productIdToProduct: Map<string,ProductExt> = new Map(message.productExts.map(product => [product.product.id, product]));
            context.setProducts(productIdToProduct);

            let newProducts:Array<ProductExt> = [];
            let specialsProducts:Array<ProductExt> = [];
            let topSellerProducts:Array<ProductExt> = [];
            let clearanceProducts:Array<ProductExt> = [];
            let brandToProducts:Array<BrandDeviceCateObject> = [];
            let cateToProducts:Array<BrandDeviceCateObject> = [];
            let deviceVendors:Array<BrandDeviceCateObject> = [];

            const topProductIds = message.topSellerProductIds30Days;

            // loop each product to get info
            message.productExts.forEach(productExt => {
                let newDateBigDecimal: number = Date.now() - (30 * 1000 * 60 * 60 * 24);
                
                if(topProductIds.includes(productExt.product.id)) {
                    topSellerProducts.push(productExt);
                }

                if (productExt.product.statusLabel === "Special") { // SPECIAL
                    specialsProducts.push(productExt);
                }
                
                if (productExt.product.statusLabel === "Clearance") { // CLEARANCE
                    clearanceProducts.push(productExt);
                }
                
                // build brand sublist
                const subObject: SubObject = {
                    name: productExt.product.itemCategory,
                    link: productExt.product.itemCategory.toLowerCase(),
                    products: [productExt]
                }

                // create new brand object
                const brandObject: BrandDeviceCateObject = {
                    name: productExt.product.vendorName,
                    link: productExt.product.vendor.toLowerCase(),
                    image: '',
                    logo: '',
                    subList: [subObject]
                }

                // build brand list
                if (brandToProducts.length === 0) {
                    brandToProducts.push(brandObject)
                } else {
                    // check if the brand is in
                    const dupBrand:Array<BrandDeviceCateObject> = brandToProducts.filter((value) => {return value.link === productExt.product.vendor.toLowerCase()});

                    if(dupBrand.length > 0) {
                        // yes, brand already in there
                        // get the brand sublist
                        const dupSubList:Array<SubObject> | undefined = dupBrand[0].subList?.filter((value) => {return value.link === productExt.product.itemCategory.toLowerCase()});

                        if(dupSubList && dupSubList.length > 0) {
                            // the sublist is already in there
                            // add the current sublist
                            dupSubList[0].products.push(productExt);
                        } else {
                            // no it is not in
                            // add it in then
                            dupBrand[0].subList?.push(subObject);
                        }
                    } else {
                        brandToProducts.push(brandObject)
                    }
                }

                // build brand sublist
                const subBrandObject: SubObject = {
                    name: productExt.product.itemCategory,
                    link: productExt.product.itemCategory.toLowerCase(),
                    products: [productExt]
                }

                // create new category object
                const cateObject: BrandDeviceCateObject = {
                    name: productExt.product.procurementGroupDesc,
                    link: productExt.product.procurementGroup.toLowerCase(),
                    image: '',
                    logo: '',
                    subList: [subBrandObject]
                }

                // build category list
                if (cateToProducts.length === 0) {
                    cateToProducts.push(cateObject)
                } else {
                    // check if the cate is in
                    const dupCate:Array<BrandDeviceCateObject> = cateToProducts.filter((value) => {return value.link === productExt.product.procurementGroup.toLowerCase()});

                    if(dupCate.length > 0) {
                        // cate already in there
                        const dupSubCateList:Array<SubObject> | undefined = dupCate[0].subList?.filter((value) => {return value.link === productExt.product.itemCategory.toLowerCase()});

                        if(dupSubCateList && dupSubCateList.length > 0) {
                            dupSubCateList[0].products.push(productExt);
                        } else {
                            dupCate[0].subList?.push(subBrandObject);
                        }
                    } else {
                        cateToProducts.push(cateObject)
                    }
                }
            });

            message.handsetItems.forEach(handsetItem => {
                let productFromIdArray = message.productExts.filter((productExt) => {return productExt.product.id === handsetItem.productid});
                let currProduct = productFromIdArray.length > 0 ? productFromIdArray[0] : null;

                // check if the brand is in
                let handsetObject: BrandDeviceCateObject | undefined = _.find(deviceVendors, (value) => {return value.link === handsetItem.vendorid.toLowerCase()});
                if(!handsetObject) {
                    handsetObject = {
                        name: handsetItem.vendorname,
                        link: handsetItem.vendorid.toLowerCase(),
                        image: '',
                        logo: '',
                        subList: []
                    }      
                    deviceVendors.push(handsetObject);             
                }
                let subObject: SubObject | undefined = _.find(handsetObject.subList, (value) => { return value.link === handsetItem.handsetname.toLowerCase() });
                if (!subObject) {
                    subObject = {
                        name: handsetItem.handsetname,
                        link: handsetItem.handsetname.toLowerCase(),
                        products: currProduct && currProduct !== undefined ? [currProduct] : []
                    }
                    handsetObject.subList?.push(subObject);
                }
                if (currProduct) {
                    subObject.products.push(currProduct);
                }
            });

            // sort devices by introduction date
            deviceVendors.forEach(vendorMenu => {
              vendorMenu.subList?.sort((aDevice, bDevice) => {
                let aIntroductionDate: number | undefined = message.vendorDeviceToIntroductionDate[vendorMenu.link + "|" + aDevice.link];
                if (aIntroductionDate === undefined) {
                  aIntroductionDate = 99999999;
                }
                let bIntroductionDate: number | undefined = message.vendorDeviceToIntroductionDate[vendorMenu.link + "|" + bDevice.link];
                if (bIntroductionDate === undefined) {
                  bIntroductionDate = 99999999;
                }
                return bIntroductionDate - aIntroductionDate;
              });
            });

            newProducts = message.productExts.filter((productExt: ProductExt) => productExt.product.statusLabel === "New Product");
            newProducts = _.sortBy(newProducts, [function(o) { return o.product.registeredDate; }]);
            newProducts.reverse();

            context.setClearance(clearanceProducts);
            context.setNew(newProducts);
            context.setSpecial(specialsProducts);
            context.setTopSeller(topSellerProducts);
            context.setBrands(_.sortBy(brandToProducts, [function(o) { return o.name; }]));
            context.setHighlightedBrands(message.highlightedBrands);
            context.setCategories(_.sortBy(cateToProducts, [function(o) { return o.name; }]));
            context.setDeviceVendors(_.sortBy(deviceVendors, [function(o) { return o.name; }]));
            context.setFavouriteProductId(message.favouriteProductIds);

            context.setProductIdToMinOrderQty(message.productIdToMinOrderQty);
            context.setProductIdToOrderMultiple(message.productIdToOrderMultiple);

            let tabbedProducts = new Array<ProductExt>();
            message.productTabs.forEach(productId => {
                let tabbedProduct: ProductExt | undefined = productIdToProduct.get(productId);
                if (tabbedProduct) {
                    tabbedProducts.push(tabbedProduct);
                }
            });
            context.setTabbedProducts(tabbedProducts);
        }
    }
}

const LoginCallback: MethodCallback<LoginMessage> = {
    onFailure(error: string, context: any): void {
        context.setFinishLoading();
        alert(error);
    },

    onProgress(loaded: number, total: number): void {},

    onSuccess(message: LoginMessage, context: any): void {
        if (message != null) {
            if (message.error == null) {
                // save token to cookie
                cookies.set('token', message.token, {path: '/', maxAge: 86400});
                myAuth.isAuthenticated = true;

                context.pushState();
            }
            else {
                context.setFinishLoading();
                if(message.error.includes('verification') || message.error.includes('staff')) {
                    context.setIsStaff(true);
                }
                alert(message.error);
            }
        }
        else {
            context.setFinishLoading();
            alert('Server not responding correctly - please try again later');
        }
    }
}

export const LogoutCallback: MethodCallback<LogoutMessage> = {
    onFailure(error: string): void {
        cookies.remove('token', {path: '/'});
        cookies.remove('SESSIONKEY', {path: '/'});
        window.location.reload();
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: LogoutMessage): void {
        cookies.remove('token', {path: '/'});
        cookies.remove('SESSIONKEY', {path: '/'});
        window.location.reload();
    }
}

const InitCallback: MethodCallback<InitMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: InitMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setCurrentCustomer(message.currentCustomer); 
            context.setCurrentPayer(message.currentPayer); 
            context.setUserAccounts(message.userAccounts);
            context.setCustomers(message.customers);
            context.setPayers(message.payers);
            context.setHomeContent(message.homeContent);
            context.setBanners(message.banners);

            const userProfile:MyUserProfile = {
                id: message.userId,
                login: message.login,
                alias: message.login,
                firstname: message.firstName,
                lastname: message.lastName,
                email: message.email,
                type: message.type,
                changetoken: '',
                contactphone: message.phone,
                contactfax: '',
                registered: '',
                logintokenid: '',
                activated: '',
                deactivated: '',
                skinid: 0,
                sliderid: 0,
                language: '',
                passwordhash: '',
                businessGroup: message.businessGroup
            }
            context.setUserProfile(userProfile);
            context.setCurrentUserAccount(message.currentUserAccount); // do this last since it trigger an event below
        }
    }
}

const InitCallbackSwitch: MethodCallback<InitMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: InitMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setCurrentCustomer(message.currentCustomer); 
            context.setCurrentPayer(message.currentPayer); 
            context.setCurrentUserAccount(message.currentUserAccount);
            context.setUserAccounts(message.userAccounts);
            context.setCustomers(message.customers);
            context.setPayers(message.payers);
            context.setHomeContent(message.homeContent);
            context.setBanners(message.banners);

            const userProfile:MyUserProfile = {
                id: message.userId,
                login: message.login,
                alias: message.login,
                firstname: message.firstName,
                lastname: message.lastName,
                email: message.email,
                type: message.type,
                changetoken: '',
                contactphone: message.phone,
                contactfax: '',
                registered: '',
                logintokenid: '',
                activated: '',
                deactivated: '',
                skinid: 0,
                sliderid: 0,
                language: '',
                passwordhash: '',
                businessGroup: message.businessGroup
            }
            context.setUserProfile(userProfile);

            window.location.reload();
        }
    }
}

const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: RecentActivityMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setRecentActivities(message.recentActivities);
        }
    }
}

// const RecentActivityFinishLoadingCallback: MethodCallback<RecentActivityMessage> = {

//   onFailure(error: string): void {
//       alert(error);
//   },
//   onProgress(loaded: number, total: number): void {},
//   onSuccess(message: RecentActivityMessage, context: any): void {
//       if(!message.authenticated) {
//           logout();
//       } else {
//           context.setRecentActivities(message.recentActivities);
//           context.checkFinishedLoading("RecentActivity");
//       }
//   }
// }

const AccountCallback: MethodCallback<AccountMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: AccountMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setAccount(message);
        }
    }
}

// const AccountFinishLoadingCallback: MethodCallback<AccountMessage> = {

//   onFailure(error: string): void {
//       alert(error);
//   },
//   onProgress(loaded: number, total: number): void {},
//   onSuccess(message: AccountMessage, context: any): void {
//       if(!message.authenticated) {
//           logout();
//       } else {
//           context.setAccount(message);
//           context.checkFinishedLoading("Account");
//       }
//   }
// }

const OrdersCallback: MethodCallback<OrdersMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: OrdersMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setOrders(message);
        }
    }
}

const BackOrdersCallback: MethodCallback<BackOrdersMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: BackOrdersMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setBackOrders(message);
        }
    }
}

const InvoicesCallback: MethodCallback<InvoicesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: InvoicesMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setInvoices(message);
        }
    }
}

export const DueInvoicesCallback: MethodCallback<DueInvoicesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: DueInvoicesMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setDueInvoices(message);
        }
    }
}

export const TransactionsAndPaymentsCallback: MethodCallback<TransactionsAndPaymentsMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: TransactionsAndPaymentsMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setTransactionsAndPayments(message);
        }
    }
}

const DeliveryAddressesCallback: MethodCallback<DeliveryAddressesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: DeliveryAddressesMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setDeliveryAddresses(message);
        }
    }
}

// const DeliveryAddressesFinishLoadingCallback: MethodCallback<DeliveryAddressesMessage> = {

//     onFailure(error: string): void {
//         alert(error);
//     },
//     onProgress(loaded: number, total: number): void {},
//     onSuccess(message: DeliveryAddressesMessage, context: any): void {
//         if(!message.authenticated) {
//             logout();
//         } else {
//             context.setDeliveryAddresses(message);
//             context.checkFinishedLoading("DeliveryAddresses");
//         }
//     }
// }

const TrackingDatasCallback: MethodCallback<TrackingDatasMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: TrackingDatasMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setTrackingDatas(message);
        }
    }
}

const PreferenceCallback: MethodCallback<PreferencesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: PreferencesMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setPreferences(message);
        }
    }
}

const PathCallback: MethodCallback<PathMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: PathMessage, context: any): void {
        // if gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            context.setLastPath(message.lastPath);
        }
    }
}

export const ReturnCallback: MethodCallback<ReturnsMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: ReturnsMessage, context: any): void {
        // if gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setReturns(message);
        }
    }
}

const AddressCallback: MethodCallback<AddressesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: AddressesMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setAddresses(message.addresses);
        }
    }
}

const ManageUserCallback: MethodCallback<UsersMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: UsersMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setManagedUserAccounts(message.managedUserAccounts);
            context.setManagedUserProfiles(message.managedUserProfiles);
        }
    }
}

const DatafeedCallback: MethodCallback<DataFeedMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: DataFeedMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setDatafeeds(message);
        }
    }
}

const AlertCallback: MethodCallback<AlertsMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: AlertsMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            const alertArray:Array<AlertObj> = [];

            message.unreadAlerts.forEach(r => {
                alertArray.push({
                    ...r,
                    isRead: false
                })
            });

            message.readAlerts.forEach(r => {
                alertArray.push({
                    ...r,
                    isRead: true
                })
            });

            context.setNotifications(alertArray);
        }
    }
}

const ShoppingCartCallback: MethodCallback<ShoppingCartsMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: ShoppingCartsMessage, context: any): void {
        if(!message.authenticated) {
            logout();
        } else {
            context.setShoppingCart(message);
        }
    }
}

export const myAuth = {
    isAuthenticated: cookies.get('token') ? true : false,
    authenticate(email: string, password: string, staffVerification: string, stayLoggenIn: boolean, pushState: () => void, setFinishLoading: () => void, setIsStaff: (flag: boolean) => void) {
        restClient.login(email, password, staffVerification, stayLoggenIn, LoginCallback, {pushState, setFinishLoading, setIsStaff});
    },
    signout() {
        restClient.logout(LogoutCallback);
        cookies.remove('token', {path: '/'});
        cookies.remove('SESSIONKEY', {path: '/'});
        window.location.reload();
    },
    switchAccount(id: string, setHomeContent: (payload: any) => void, setBanners: (payload: any) => void, setCurrentCustomer: (payload: any) => void, setCurrentPayer: (payload: any) => void, setCurrentUserAccount: (payload: any) => void, setUserAccounts: (payload: any) => void, setCustomers: (payload: any) => void, setPayers: (payload: any) => void, setUserProfile: (payload: any) => void ) {
        restClient.changeToAccountOnServer(id, InitCallbackSwitch, {setHomeContent, setBanners, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile});

    }
};

const PrivateRoute = (props: RouteProps) => {
    const [isAuthenticated] = React.useState<boolean>(myAuth.isAuthenticated);
    const {productState, setFinishLoading, setProducts, setBrands, setHighlightedBrands, setCategories, setDeviceVendors, setClearance, setNew, setSpecial, setTopSeller, setFavouriteProductId, setProductIdToMinOrderQty, setProductIdToOrderMultiple, setTabbedProducts} = React.useContext(ProductContext);
    const {userState, setPreferences, setLastPath, setHomeContent, setBanners, setNotifications ,setAddresses, setCurrentCustomer, setReturns, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setRecentActivities, setAccount, setOrders, setBackOrders, setInvoices, setDueInvoices, setTransactionsAndPayments, setDeliveryAddresses, setTrackingDatas, setUserProfile, setDatafeeds, setManagedUserAccounts, setManagedUserProfiles} = React.useContext(UserContext);
    const {cartState, setShoppingCart} = React.useContext(CartContext);
    const [lastPath, setOurLastPath] = React.useState<string>('');
    const [lastPathUpdateTime, setLastPathUpdateTime] = React.useState<number>(0);
    const [loadingStage, setLoadingStage] = React.useState<string>('zero');
    const [accountSet, setAccountSet] = React.useState<boolean>(false);
    const [recentActivitySet, setRecentActivitySet] = React.useState<boolean>(false);
    const [alertSet, setAlertSet] = React.useState<boolean>(false);
    const [addressSet, setAddressSet] = React.useState<boolean>(false);
    const [preferenceSet, setPreferenceSet] = React.useState<boolean>(false);
    const [datafeedSet, setDatafeedSet] = React.useState<boolean>(false);
    const [manageUserSet, setManageUserSet] = React.useState<boolean>(false);
    const [shoppingCartSet, setShoppingCartSet] = React.useState<boolean>(false);
    const [ordersSet, setOrdersSet] = React.useState<boolean>(false);
    const [backOrdersSet, setBackOrdersSet] = React.useState<boolean>(false);
    const [invoicesSet, setInvoicesSet] = React.useState<boolean>(false);

    const AccountFinishLoadingCallback: MethodCallback<AccountMessage> = {

      onFailure(error: string): void {
          alert(error);
      },
      onProgress(loaded: number, total: number): void {},
      onSuccess(message: AccountMessage, context: any): void {
          if(!message.authenticated) {
              logout();
          } else {
              setAccount(message);
              setAccountSet(true);
          }
      }
    }

    const RecentActivityFinishLoadingCallback: MethodCallback<RecentActivityMessage> = {

      onFailure(error: string): void {
          alert(error);
      },
      onProgress(loaded: number, total: number): void {},
      onSuccess(message: RecentActivityMessage, context: any): void {
          if(!message.authenticated) {
              logout();
          } else {
              setRecentActivities(message.recentActivities);
              setRecentActivitySet(true);
          }
      }
    }    

    const AlertFinishLoadingCallback: MethodCallback<AlertsMessage> = {
      onFailure(error: string): void {
          alert(error);
      },
      onProgress(loaded: number, total: number): void {},
      onSuccess(message: AlertsMessage, context: any): void {
          // if init gives authenticated as false
          // remove cookie, logout
          if(!message.authenticated) {
              logout();
          } else {
              // save something
              const alertArray:Array<AlertObj> = [];
    
              message.unreadAlerts.forEach(r => {
                  alertArray.push({
                      ...r,
                      isRead: false
                  })
              });
    
              message.readAlerts.forEach(r => {
                  alertArray.push({
                      ...r,
                      isRead: true
                  })
              });
    
              context.setNotifications(alertArray);
              setAlertSet(true);
          }
      }
    }    

    const AddressFinishLoadingCallback: MethodCallback<AddressesMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: AddressesMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                // save something
                context.setAddresses(message.addresses);
                setAddressSet(true);
            }
        }
    }    

    const PreferenceFinishLoadingCallback: MethodCallback<PreferencesMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: PreferencesMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setPreferences(message);
                setPreferenceSet(true);
            }
        }
    }

    const DatafeedFinishLoadingCallback: MethodCallback<DataFeedMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: DataFeedMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setDatafeeds(message);
                setDatafeedSet(true);
            }
        }
    }    
  
    const ManageUserFinishLoadingCallback: MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: UsersMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setManagedUserAccounts(message.managedUserAccounts);
                context.setManagedUserProfiles(message.managedUserProfiles);
                setManageUserSet(true);
            }
        }
    }
        
    const ShoppingCartFinishLoadingCallback: MethodCallback<ShoppingCartsMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: ShoppingCartsMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setShoppingCart(message);
                setShoppingCartSet(true);
            }
        }
    }

    const OrdersFinishLoadingCallback: MethodCallback<OrdersMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: OrdersMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setOrders(message);
                setOrdersSet(true);
            }
        }
    }    

    const BackOrdersFinishLoadingCallback: MethodCallback<BackOrdersMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: BackOrdersMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setBackOrders(message);
                setBackOrdersSet(true);
            }
        }
    }

    const InvoicesFinishLoadingCallback: MethodCallback<InvoicesMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: InvoicesMessage, context: any): void {
            if(!message.authenticated) {
                logout();
            } else {
                context.setInvoices(message);
                setInvoicesSet(true);
            }
        }
    }
        
    React.useEffect(() => {
        if(isAuthenticated && userState && userState.userProfile) {
            let finished: boolean = true;
            if (!accountSet) {
                finished = false;
            }
            if (!recentActivitySet) {
                finished = false;
            }
            if (!alertSet) {
                finished = false;
            }
            if (!addressSet) {
                finished = false;
            }
            if (!preferenceSet) {
                finished = false;
            }
            if (userState.currentUserAccount.extrapermissions === null || !userState.currentUserAccount.extrapermissions.includes(UserAccess.DATA_FEED_NOACCESS)) {
                if (!datafeedSet) {
                  finished = false;
                }
            }                 
            if (userState.currentUserAccount.permissions.includes(UserAccess.ADD_NEW_USERS)) {
                if (!manageUserSet) {
                  finished = false;
                }
            }                
            if (userState.currentUserAccount.permissions.includes(UserAccess.PLACE_ORDERS)) {
                if (!shoppingCartSet) {
                  finished = false;
                }
            }
            if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_ORDER_HISTORY)) {
                if (!ordersSet) {
                  finished = false;
                }
            } 
            if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_BACKORDERS)) {
                if (!backOrdersSet) {
                  finished = false;
                }
            }  
            if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_INVOICES)) {
                if (!invoicesSet) {
                  finished = false;
                }
            } 
            if (finished) {
                setFinishLoading();
                if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_INVOICES) ||
                        userState.currentUserAccount.permissions.includes(UserAccess.VIEW_ORDER_HISTORY) ||
                        userState.currentUserAccount.permissions.includes(UserAccess.VIEW_BACKORDERS)) {
                    restClient.deliveryAddressesFromServer(DeliveryAddressesCallback, { setDeliveryAddresses });
                }
                if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_INVOICES) ||
                        userState.currentUserAccount.permissions.includes(UserAccess.VIEW_ORDER_HISTORY)) {
                    restClient.trackingDatasFromServer(TrackingDatasCallback, { setTrackingDatas });
                }  
                if (userState.currentUserAccount.permissions.includes(UserAccess.LODGE_RETURN_REQUESTS)) {
                    restClient.returnsFromServer(ReturnCallback, { setReturns });
                }
                if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_CREDIT_DATA)) {
                    restClient.dueInvoicesFromServer(DueInvoicesCallback, { setDueInvoices });
                }
                if (userState.currentUserAccount.permissions.includes(UserAccess.VIEW_INVOICES) || ((!ClientHelper.isCashCustomer(userState.currentCustomer, userState.currentPayer)) && userState.currentUserAccount.permissions.includes(UserAccess.VIEW_CREDIT_DATA))) {
                    restClient.transactionsAndPaymentsFromServer(TransactionsAndPaymentsCallback, { setTransactionsAndPayments });
                }
            }
        }
    }, [accountSet, recentActivitySet, alertSet, addressSet, preferenceSet, datafeedSet, manageUserSet, shoppingCartSet, ordersSet, backOrdersSet, invoicesSet])

    React.useEffect(() => {
        if(!isAuthenticated) {
            setLoadingStage('zero');
        }
        else {

            if (userState.userProfile === undefined) {
                restClient.initFromServer(InitCallback, {setHomeContent, setBanners, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile});
                restClient.productsFromServer(ProductCallback, {productState, setProducts, setBrands, setHighlightedBrands , setCategories, setDeviceVendors, setClearance, setNew, setSpecial, setTopSeller, setFavouriteProductId, setProductIdToMinOrderQty, setProductIdToOrderMultiple, setTabbedProducts, setShoppingCart});
                // let ra: Array<RecentActivity> = [];
                // setRecentActivities(ra);
            }
            else if(loadingStage == 'zero') {
                setLoadingStage('one')
                restClient.accountFromServer(AccountFinishLoadingCallback, { setAccount });
                restClient.recentActivitiesFromServer(RecentActivityFinishLoadingCallback, {setRecentActivities});
                restClient.alertsFromServer(AlertFinishLoadingCallback, {setNotifications});
                restClient.addressesFromServer(AddressFinishLoadingCallback, {setAddresses});
                restClient.preferencesFromServer(PreferenceFinishLoadingCallback, {setPreferences});
                if(userState.dataFeeds === undefined && (userState.currentUserAccount.extrapermissions === null || userState.userProfile.type === 'S' || !userState.currentUserAccount.extrapermissions.includes(UserAccess.DATA_FEED_NOACCESS))) {
                    restClient.dataFeedDetailsFromServer(DatafeedFinishLoadingCallback, {setDatafeeds});
                }                
                if(userState.managedUserAccounts === undefined && userState.currentUserAccount.permissions.includes(UserAccess.ADD_NEW_USERS)) {
                    restClient.managedUsersFromServer(ManageUserFinishLoadingCallback, { setManagedUserAccounts, setManagedUserProfiles });
                }  
                if(cartState.shoppingCart === undefined && userState.currentUserAccount.permissions.includes(UserAccess.PLACE_ORDERS)) {
                    restClient.shoppingCartsFromServer(ShoppingCartFinishLoadingCallback, {setShoppingCart})
                } 
                if (userState.orders === undefined && userState.currentUserAccount.permissions.includes(UserAccess.VIEW_ORDER_HISTORY)) {
                    restClient.ordersFromServer(OrdersFinishLoadingCallback, { setOrders });
                }                                
                if (userState.backOrders === undefined && userState.currentUserAccount.permissions.includes(UserAccess.VIEW_BACKORDERS)) {
                    restClient.backOrdersFromServer(BackOrdersFinishLoadingCallback, { setBackOrders });
                }
                if (userState.invoices === undefined && userState.currentUserAccount.permissions.includes(UserAccess.VIEW_INVOICES)) {
                    restClient.invoicesFromServer(InvoicesFinishLoadingCallback, { setInvoices });
                } 
            }
        }
    }, [isAuthenticated, userState.currentUserAccount])

    React.useEffect(() => {
        if (isAuthenticated && props.location && props.location.pathname && (props.location.pathname !== lastPath || Date.now() - lastPathUpdateTime > 10000)) {
            setOurLastPath(props.location.pathname);
            setLastPathUpdateTime(Date.now());
            restClient.updatePath(props.location.pathname, PathCallback, {setLastPath});
        }         
    })
    
    if (isAuthenticated) {
        return (<Route path={props.path} exact={props.exact} component={props.component}/>);
    }
    else {
        return (<Redirect
            to={{
                pathname: "/login",
                state: { fromPath: props.path, fromHref: window.location.href }
            }}
        />);
    }

};
export default PrivateRoute;