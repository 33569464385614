import React from 'react';
import { Row, Col, List, Skeleton, Button, Form, Input} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import {PlusOutlined} from '@ant-design/icons';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import AddNewUser from '../../../components/AddNewUser';
import { UserContext } from '../../../contexts/UserContext';
import { UserAccess, UserAccountExt, UserProfile } from '../../../RESTAPI';
import { Redirect } from 'react-router-dom';
import { UserProfileObj } from '../../../Constants';
import { paginate } from '../../../utils';
import { Link } from 'react-router-dom';
import _ from 'lodash';

//const pageSize = 5;

const ManageUsers: React.FC = () => {
    const [ initLoading ] = React.useState<boolean>(false);
    const [ visibleAddNew, setVisibleAddNew] = React.useState<boolean>(false);
    // const [ currentPage, setCurrentPage] = React.useState<number>(1);
    // const [ maxPage, setMaxPage] = React.useState<number>(1);
    // const [ loading, setLoading ] = React.useState<boolean>(false);
    const [ list, setList] = React.useState<Array<UserProfileObj>>([]);
    const [ filter, setFilter] = React.useState<string>('');
    const {userState} = React.useContext(UserContext);

    //const userAccountComparitor()

    React.useEffect(() => {
        if(userState.managedUserAccounts && userState.managedUserProfiles) {

            let managedUserProfilesIds: Array<string>  = userState.managedUserAccounts.map(i => i.userid);
            let preFilteredManagedUserProfiles: Array<UserProfile> = userState.managedUserProfiles.filter(i => managedUserProfilesIds.includes(i.id));
            let managedUserProfiles: Array<UserProfile> = [];
            if (filter.trim().length == 0) {
                managedUserProfiles = Object.assign([], preFilteredManagedUserProfiles);
            } else {
                let tokens: Array<string> = filter.trim().toLocaleLowerCase().split(/\s+/);
                for (let profileIndex = 0; profileIndex < preFilteredManagedUserProfiles.length; profileIndex++) {
                    let userProfile: UserProfile = preFilteredManagedUserProfiles[profileIndex];
                    let allTokensFound: boolean = true;
                    for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
                        let token: string = tokens[tokenIndex];
                        if (!(userProfile.login.toLocaleLowerCase().indexOf(token) >= 0 ||
                                userProfile.firstname.toLocaleLowerCase().indexOf(token) >= 0 ||
                                userProfile.lastname.toLocaleLowerCase().indexOf(token) >= 0 ||
                                userProfile.email.toLocaleLowerCase().indexOf(token) >= 0 ||
                                userProfile.alias.toLocaleLowerCase().indexOf(token) >= 0)) {
                            allTokensFound = false;
                            break;
                        }
                    }
                    if (allTokensFound) {
                        managedUserProfiles.push(userProfile);
                    }
                }
            }

            managedUserProfiles.sort((a, b) => {
                if (a.type == 'C' && b.type == 'c')
                {
                  return -1;
                }
                else if (a.type == 'c' && b.type == 'C')
                {
                  return 1;
                }        
                if (a.login < b.login)
                {
                  return -1;
                }
                if (a.login > b.login)
                {
                  return 1;
                } 

                // if (a.firstname < b.firstname)
                // {
                //   return -1;
                // }
                // if (a.firstname > b.firstname)
                // {
                //   return 1;
                // }                
                // if (a.lastname < b.lastname)
                // {
                //   return -1;
                // }
                // if (a.lastname > b.lastname)
                // {
                //   return 1;
                // }
                // if (a.id < a.id)
                // {
                //   return -1;
                // }
                // if (a.id > a.id)
                // {
                //   return 1;
                // }
                return 0;
            })

            setList(managedUserProfiles);
            // setList(paginate(userState.managedUserAccounts, pageSize, 1));
            // setMaxPage(Math.ceil(userState.managedUserAccounts.length / 5));
        }

    }, [userState.managedUserAccounts, userState.managedUserProfiles, filter])

    // const loadMore =
    //   !initLoading && !loading && currentPage < maxPage ? (
    //     <div className='textCenter marginTop40px'>
    //       <Button onClick={() => onLoadMore()} type='primary'>Load More</Button>
    //     </div>
    //   ) : null;

    //   const onLoadMore = () => {
    //     const nextPage = currentPage + 1;
    //     const nextList = paginate(users, pageSize, nextPage);
    //     setLoading(true);
    //     setCurrentPage(currentPage + 1);

    //     const currentList = [...list];
    //     for (let index = 0; index < nextList.length; index++) {
    //         currentList.push({
    //             ...nextList[index],
    //             loading: true
    //         }); 
    //     }
    //     setList([...currentList]);
        
    //     setTimeout(() => {
    //         const current = [...currentList];
    //         const newList:Array<UserAccountObj> = [];

    //         current.forEach((value, key) => {
    //             value.loading = false;
    //             newList.push(value);
    //         })

    //         setList([...newList]);
    //         setLoading(false);
    //     }, 50)
    // };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    }    

    if(userState.currentUserAccount && userState.currentUserAccount.permissions.split('').includes(UserAccess.ADD_NEW_USERS)) {
        return (
            <MainLayout>
                <div className='content-wrapper marginBottom40px marginTop40px'> 
                    <Row gutter={20}>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>Manage Users</h2>
                            <MobileAccountMenu site='Manage Users'/>
                            <div className='accountSection fontSize16px mobileMarginBottom20px' style={{maxWidth: '775px', margin: '0 auto'}}>
                                <Form>
                                    <Row gutter={20}>
                                        <Col xs={16} md={16} lg={12}>
                                            <Form.Item className="group-floating-label" name='filter' label=''>
                                                <Input size="large" onChange={handleFilterChange} className="has-float-label" placeholder='Filter' suffix={<label className="floating-label" htmlFor="name">Filter</label>}/>  
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>                                  
                                <div className='accountSectionContainer'>
                                    <List
                                        className="fontSize16px"
                                        loading={initLoading}
                                        itemLayout="horizontal"
                                        // loadMore={loadMore}
                                        dataSource={list}
                                        renderItem={item => {
                                            let userProfile = item;

                                            if(userProfile) {
                                                return (
                                                    <List.Item
                                                        actions={[]}
                                                    >
                                                        <Skeleton avatar title={false} loading={item.loading} active>
                                                        <List.Item.Meta
                                                            title={<p className='noMarginBottom'>{userProfile.login} &nbsp;<strong>{userProfile.firstname + ' ' + userProfile.lastname}</strong>
                                                            <br/><span style={userProfile.type == 'c' ? {color: 'red'} : {}}>{userProfile.email}</span></p>}
                                                        />
                                                            <div>
                                                                <Link to={'/your-account/manage-users/' + userProfile.login}>
                                                                    <Button className='text-secondaryColor' type='link'><strong>Edit</strong></Button>
                                                                </Link>
                                                            </div>
                                                            {/*<div>
                                                                <Button className='text-secondaryColor' type='link'><strong>Remove</strong></Button>
                                                            </div>*/}
                                                        </Skeleton>
                                                    </List.Item>
                                                )
                                            } else {
                                                return null
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            
                            <Row style={{maxWidth: '775px', margin: '20px auto'}}>
                                <Col span={24}>
                                    <Button type="dashed" block className='addNewAddress' onClick={() => {setVisibleAddNew(true)}}>
                                        <PlusOutlined /><br/>
                                        <strong>Add User</strong>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <AddNewUser visible={visibleAddNew} setVisible={(flag) => setVisibleAddNew(flag)}/>
            </MainLayout>
        );
    } else {
      return <></>
    }
}

export default ManageUsers;
