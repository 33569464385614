import React from 'react';
import Result from './Result';
// @ts-ignore
import Quagga from 'quagga';
import { ProductContext } from '../contexts/ProductContext';
import { ProductExt } from '../RESTAPI';
import _ from 'lodash';

export interface ScannerProps {
    onDetected: (product: ProductExt) => void
}

const Scanner:React.FunctionComponent<ScannerProps> = (props) => {

    const { productState } = React.useContext(ProductContext);
    const [error, setError] = React.useState<String | undefined>(undefined);

    React.useEffect(() => {
        Quagga.init({
            inputStream: {
                type : "LiveStream",
                constraints: {
                    width: 640,
                    height: 480,
                    facingMode: "environment" // or user
                }
            },
            // locator: {
            //     patchSize: "medium",
            //     halfSample: true
            // },
            // numOfWorkers: 2,
            decoder: {
                readers : [ "code_128_reader", "ean_reader", "upc_reader" ]
            },
            locate: true
        }, function(err: any) {
            if (err) {
                console.log(err);
            } else {
                Quagga.start();
            }
        });
        Quagga.onDetected(_onDetected);
    });

    React.useLayoutEffect(() => {
        Quagga.offDetected(_onDetected);
    }, [])

    const _onDetected = (result: any) => {
      if (result && result.codeResult && result.codeResult.code && productState && productState.productIdToProduct) {
          let productExt = _.find([...productState.productIdToProduct.values()], (p) => {
              return p.product.ean === result.codeResult.code
          })
          if(productExt) {
              Quagga.offDetected(_onDetected);            
              Quagga.stop();
              props.onDetected(productExt);
          } else {
              setError("No product for EAN " + result.codeResult.code);             
          }
      }      
    }    
    

    return (
        <><div id="interactive" className="viewport"/>
        { error &&
        <div className="textCenter">
            <span>{ error }</span>
        </div>
        }
        </>
    );

};

export default Scanner;
