import React from 'react';
import {Layout, Menu, AutoComplete, Select, Image, Popover, Drawer, Avatar, List, Modal, Button, Divider } from 'antd';
import CellnetLogo from '../elements/Logo/Logo';
import {CateMega, VendorMega} from '../data';
import {formatText, getInputTextLength, useWindowSize, dateStringFormat, getImage} from '../utils';
import {MenuGroup, tabletSize, BrandDeviceCateObject, MenuObject, imageUrl, AlertObj} from '../Constants';
import ProductSearchOption from '../elements/ProductSearchOption/ProductSearchOptions';
import ShoppingCartIcon from '../assets/images/shopping_cart.svg';
import FavouriteIcon from '../assets/images/favourites.svg';
import NotificationIcon from '../assets/images/notifications.svg';
import SearchIcon from '../assets/images/search_button.svg';
import AccountIcon from '../assets/images/account.svg';
import BreadCrumbBar from '../elements/BreadCrumbBar/BreadCrumbBar';
import MobileMenu from '../elements/MobileMenu/MobileMenu';
import MobileSearch from '../elements/MobileSearch/MobileSearch';
import { Link, useHistory } from 'react-router-dom';
import MobileAccount from '../elements/MobileAccount/MobileAccount';
import IconAnimate from '../elements/IconAnimate/IconAnimate';
import IconWithNumber from '../elements/IconWithNumber/IconWithNumber';
import { CartContext } from '../contexts/CartContext';
import { myAuth } from '../elements/PrivateRoute/PrivateRoute';
import { ProductContext } from '../contexts/ProductContext';
import { AlertsMessage, MethodCallback, ProductExt, SearchAccountMessage, SearchMessage, ClientHelper, UserAccess } from '../RESTAPI';
import { UserContext } from '../contexts/UserContext';
import _ from 'lodash';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';

const { Header } = Layout;
const { Option } = AutoComplete;
const { SubMenu } = Menu;

const parentMenu = [
    {
        name: 'Your Cellnet',
        hasChild: false,
        link: 'your-account'
    },
    {
        name: 'Brands',
        hasChild: true,
        link: ''
    },
    {
        name: 'Categories',
        hasChild: true,
        link: ''
    },
    {
        name: 'Compatibility',
        hasChild: true,
        link: ''
    },
    {
        name: 'Top Sellers',
        hasChild: false,
        link: 'shop/top-sellers'
    },
    {
        name: 'Clearance',
        hasChild: false,
        link: 'shop/clearance'
    },
    {
        name: 'New',
        hasChild: false,
        link: 'shop/new'
    },
    {
        name: 'Special',
        hasChild: false,
        link: 'shop/special'
    }
]

const maxSubMenu = 5;

const ExtraMobile = [
    {
        name: 'My Account',
        hasChild: false,
        link: 'your-account'
    },
    {
        name: 'Upload CSV To Cart',
        hasChild: false,
        link: 'upload-csv-to-cart'
    },
    {
        name: 'Scan Barcode To Cart',
        hasChild: false,
        link: 'scan-barcode-to-cart'
    },    
    {
        name: 'Orders',
        hasChild: false,
        link: '/your-account/your-orders'
    },       
    {
        name: 'Invoices',
        hasChild: false,
        link: '/your-account/your-invoices'
    },
    {
        name: 'Service Requests',
        hasChild: false,
        link: '/your-account/returns'
    },
    {
        name: 'Help',
        hasChild: false,
        link: '/help'
    }    
];

const accountMenu = [
    {
        name: 'Account Overview',
        image: 'account-small.svg',
        link: '/your-account',
        permission: 'C'
    },
    {
        name: 'Your Orders',
        image: 'orders-small.svg',
        link: '/your-account/your-orders',
        permission: 'H'
    },
    {
        name: 'Back Orders',
        image: 'orders-small.svg',
        link: '/your-account/back-orders',
        permission: 'H'
    },
    {
        name: 'Login & Security',
        image: 'security-small.svg',
        link: '/your-account/login-detail',
        permission: ''
    },
    {
        name: 'Invoices',
        image: 'invoices-small.svg',
        link: '/your-account/your-invoices',
        permission: 'I'
    },
    {
        name: 'Service Requests',
        image: 'return-small.svg',
        link: '/your-account/returns',
        permission: 'L'
    },
    {
        name: 'Addresses',
        image: 'address-small.svg',
        link: '/your-account/addresses',
        permission: 'P'
    },
    {
        name: 'Credit Summary/Make Payment',
        image: 'payment-small.svg',
        link: '/your-account/credit-summary',
        permission: 'C'
    },
    {
        name: 'Manage Users',
        image: 'manage-users-small.svg',
        link: '/your-account/manage-users',
        permission: 'A'
    },
    {
        name: 'Download Price Lists',
        image: 'price-list-small.svg',
        link: '/your-account/pricelist',
        permission: 'DR'
    },
    {
        name: 'Data Feed',
        image: 'data_feed.svg',
        link: '/data-feed',
        permission: 'D',
    },
    {
        name: 'Download Files',
        image: 'price-list-small.svg',
        link: '/your-account/files',
        permission: '',
    },
    {
        name: 'Switch Account',
        image: 'switch_account.svg',
        link: '',
        permission: ''
    },    
    {
        name: 'Sign Out',
        image: 'signout-small.svg',
        link: '',
        permission: ''
    }
];

const selectOptions = [
    { label: 'All Categories', value: 'All Categories'}
];

const defaultOptionHeight = 70;
const defaultTopHeight = 60;
const defaultHeaderHeight = 118;
const maxHeight = 308;
const defaultPaddingBorder = 29;

function MenuHeader() {
    const [result, setResult] = React.useState<ProductExt[]>([]);
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [headerHeight, setHeaderHeight] =React.useState<number>(0);
    const [inputTextLength, setInputTextLength] = React.useState<number>(0);
    const [borderInputLength, setBorderInputLength] = React.useState<number>(0);
    const [mainMenu, setMainMenu] = React.useState<Array<MenuObject>>([]);
    const [brandMega, setBrandMega] = React.useState<Array<string>>([]);
    const [searchMenu, setSearchMenu] = React.useState<Array<any>>([...selectOptions]);
    const [selectedCate, setSelectedCate] = React.useState<string>('');
    const [fetching, setFetching] = React.useState(false);
    const [searchAccountResult, setSearchAccountResult] = React.useState([]);

    const [collapsed, setCollapsed] = React.useState<boolean>(true);
    const [submenuOpen, setSubmenuOpen] = React.useState<boolean>(false);
    const [openMobileSearch, setOpenMobileSearch] = React.useState<boolean>(false);
    const [openAccountMobile, setOpenAccountMobile] = React.useState<boolean>(false);

    const [notiBarOpen, setNotiBarOpen] = React.useState<boolean>(false);
    const [favouriteBarOpen, setFavouriteBarOpen] = React.useState<boolean>(false);

    const [modalSwitchAccount, setModalSwitchAccount] = React.useState<boolean>(false);
    const [accountId, setAccountId] = React.useState<string>();
    
    const inputFillRef = React.useRef<HTMLDivElement>(null);
    const inputAutocomplete = React.useRef<HTMLDivElement>(null);

    const { cartState } = React.useContext(CartContext);
    const { productState } = React.useContext(ProductContext);
    const { userState, setHomeContent, setBanners, setNotifications, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile } = React.useContext(UserContext);

    const [approvingCartsCount, setApprovingCartsCount] = React.useState<number>(0);

    const history = useHistory();
    const size = useWindowSize();

    const [accountSearchValue, setAccountSearchValue] = React.useState<string>('');

    const accountMenuContent = (key: any, value: any) => {
        return <div key={key} className='account-link'>
            {
                value.name === 'Sign Out' ? (
                    <Link to={value.link} onClick={(e: React.MouseEvent) => {e.preventDefault(); myAuth.signout()}}>
                        <Image src={require('../assets/images/' + value.image).default} preview={false}/>
                        {value.name}
                    </Link> 
                ) : (
                    value.name === 'Switch Account' ? (
                    <Link to={value.link} onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        setModalSwitchAccount(true);
                    }}>
                        <Image src={require('../assets/images/' + value.image).default} preview={false}/>
                        {value.name}
                    </Link> 
                    ) : (
                        <Link to={value.link}>
                            <Image src={require('../assets/images/' + value.image).default} preview={false}/>
                            {value.name}
                        </Link> 
                        )
                )
            }
        </div>
    }

    const content = (
        <div>
            <div className='account-info'>
                Login: <strong>{userState?.userProfile?.login}</strong>
            </div>
            <div className='account-info' style={{justifyContent: 'flex-end'}}>
                <strong>{userState.userProfile?.firstname} {userState.userProfile?.lastname}</strong>
            </div>
            <div className='account-info'>
                Account: <strong>{userState?.currentCustomer?.id}</strong>
            </div>
            <div className='account-info' style={{justifyContent: 'flex-end'}}>
                <strong>{userState?.currentCustomer?.name}</strong>
            </div>
            <Divider/>
            {
                accountMenu.map((value, key) => {
                    if (value.name === 'Credit Summary/Make Payment') {
                        if (userState && userState.currentCustomer && userState.currentPayer && ClientHelper.isCashCustomer(userState.currentCustomer, userState.currentPayer)) {
                            return null;
                        }
                        if (userState && userState.currentCustomer && userState.currentCustomer.country.includes('NZ')) {
                            return accountMenuContent(key, { ...value, name: 'Credit Summary' })
                        }
                    }
                    if (value.name === 'Addresses') {
                        if (userState && userState.currentCustomer && !ClientHelper.isCustomAddressesAllowed(userState.currentCustomer)) {
                            return null;
                        }
                    }                    
                    if (value.name === 'Switch Account') {
                        if(userState?.userAccounts?.length < 2 && !(userState?.userProfile?.type === 'S' && userState?.currentUserAccount?.extrapermissions?.includes(UserAccess.CUSTOMER_MASQUERADE))) {
                            return null;
                        }
                    }                    
                    const checkPermission = value.permission.split('');
                    for (let index = 0; index < checkPermission.length; index++) {
                        const element = checkPermission[index];
                        
                        // permission = ABCDER
                        if(userState?.currentUserAccount?.permissions?.includes(element)) {
                            if(value.name === 'Data Feed') {
                                if( userState?.userProfile?.type === 'C' && userState?.currentUserAccount?.extrapermissions?.includes(element) ) {
                                    return null;
                                } else {
                                    return accountMenuContent(key, value)
                                }
                            } else {
                                return accountMenuContent(key, value)
                            }
                            
                        } else {
                            return null
                        }
                    }
                    
                    return accountMenuContent(key, value)
                })
            }
        </div>
    );

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(searchValue.length >= 3) {
//                handleSearch(searchValue);
          restClient.searchRestrictingCategory(searchValue, selectedCate, 20, 1, SearchCallback)
            }
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])       

    const handleSearch = (value: string) => {

        let res: ProductExt[] = [];
        if (!value) {
            res = [];
        } else {
            // clear value
            const searchTerm = value.toUpperCase().replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
            // if searchTerm is multiple word
            const searchTermArrays = searchTerm.split(' ');

            // regex: /\b(?=.*\w*3SIXT\w*)(?=.*\w*CHARGER\w*).*$\b/gi
            const searchReg = '(?=.*\\w*' + (searchTermArrays.length > 1 ? searchTermArrays.join('\\w*)(?=.*\\w*') : searchTermArrays.toString()) + '\\w*).*$';
            const regex = RegExp('\\b' + searchReg + '\\b', 'gi');
            
            productState.productIdToProduct.forEach(productExt => {
                // setup regex. Remove and escape any special characters
                // test product name and description and ean and compapility
                if(regex.test(productExt.product.id) || regex.test(productExt.product.name.toUpperCase()) || regex.test(productExt.product.description.toUpperCase()) || regex.test(productExt.product.ean.toUpperCase()) || regex.test(productExt.vendorDevices?.toString().toUpperCase())) {
                    if(selectedCate === '') {
                        res.push(productExt);
                    } else {
                        if (selectedCate === productExt.product.procurementGroupDesc) {
                            res.push(productExt);
                        }
                    }
                }
            });
        }
        setResult(res);

        if(res.length > 0) {
            setHeaderHeight((res.length * defaultOptionHeight) + defaultTopHeight);
        } else {
            setHeaderHeight(0);
        }
    };

    const handleSearchAutoCompleteOnchange = (value: string) => {
        setInputTextLength(getInputTextLength(value))
        setBorderInputLength(inputFillRef!.current!.clientWidth - (defaultPaddingBorder + getInputTextLength(value)));
    }

    const handleSearchAutoCompleteOnSelect = (value: string) => {
        history.push('/shop/' + formatText(value));
        setInputTextLength(0);
        setBorderInputLength(0);
        setHeaderHeight(0);
        setResult([]);
    }

    const handleSearchAutoCompleteOnFocus = () => {
        if(inputTextLength === 0) {
            setBorderInputLength(inputFillRef!.current!.clientWidth - defaultPaddingBorder);
        } else {
            setBorderInputLength(inputFillRef!.current!.clientWidth - (defaultPaddingBorder + inputTextLength));
        }
    }

    const handleSearchAutoCompleteOnBlur = () => {
        if(inputTextLength === 0) {
            setBorderInputLength(0);
        } else {
            setBorderInputLength(inputFillRef!.current!.clientWidth - (defaultPaddingBorder + inputTextLength));
        }
        setHeaderHeight(0);
        setResult([]);
    }

    const handleCollapsed = () => {
        if(submenuOpen) {
            setSubmenuOpen(!submenuOpen);
        } else {
            setCollapsed(!collapsed);
        }
    }

    const handleSetSubmenuOpen = (flag: boolean) => {
        setSubmenuOpen(flag);
        if(flag === false) {
            setCollapsed(true);
        }
    }

    // setup menu
    const buildingMainMenu = [] as Array<MenuObject>;

    React.useEffect(() => {
        if(productState.brands.length > 0 && productState.categories.length > 0 && productState.deviceVendors.length > 0) {
            const searchCateOptions = [...selectOptions];
            parentMenu.forEach(menu => {
                if(!menu.hasChild) {
                    buildingMainMenu.push({
                        name: menu.name,
                        hasChild: menu.hasChild,
                        childMenu: [] as Array<MenuGroup>,
                        link: menu.link
                    });
                } else {
                    const menuObject:MenuObject = {
                        name: menu.name,
                        hasChild: menu.hasChild,
                        childMenu: [] as Array<MenuGroup>,
                        link: ''
                    };

                    let objArray = [] as Array<BrandDeviceCateObject>;

                    switch (menuObject.name) {
                        case 'Brands':
                            objArray = productState.brands;
                            break;

                        case 'Categories':
                            objArray = productState.categories;
                            break;

                        case 'Compatibility':
                            objArray = productState.deviceVendors;
                            break;
                    
                        default:
                            break;
                    }
                    objArray.forEach(obj => {

                        if(menuObject.name === 'Categories') {
                            searchCateOptions.push({
                                label: obj.name,
                                value: obj.name
                            });
                        }

                        let menuGroup: MenuGroup = {
                            name: obj.name ,
                            image: '',
                            imageMobile: '',
                            logo: '',
                            menuGroups: [] as Array<MenuGroup>,
                            link: obj.link
                        }

                        if (menuObject.name === 'Compatibility') {
                            if (obj.name === 'Apple') {
                                let items: Array<string> = ['iPhone', 'iPad', 'MacBook', 'AirPods', 'Apple Watch'];
                                items.forEach(item => {
                                    let subMenu:MenuGroup  = {
                                        name: item,
                                        menuGroups: [] as Array<MenuGroup>
                                    }
                                    menuGroup.menuGroups?.push(subMenu);
                                })
                            }
                            else if (obj.name === 'Samsung') {
                                let items: Array<string> = ['Galaxy A', 'Galaxy S', 'Galaxy Z', 'Galaxy Note', 'Galaxy Tab'];
                                items.forEach(item => {
                                    let subMenu:MenuGroup  = {
                                        name: item,
                                        menuGroups: [] as Array<MenuGroup>
                                    }
                                    menuGroup.menuGroups?.push(subMenu);
                                })
                            } 
                            else if (obj.name === 'Microsoft') {
                                let items: Array<string> = ['Surface Tablet', 'Surface Laptop'];
                                items.forEach(item => {
                                    let subMenu:MenuGroup  = {
                                        name: item,
                                        menuGroups: [] as Array<MenuGroup>
                                    }
                                    menuGroup.menuGroups?.push(subMenu);
                                })
                            }
                            else if (obj.name === 'Oppo') {
                                let items: Array<string> = ['A Series', 'X Series', 'Reno Series'];
                                items.forEach(item => {
                                    let subMenu:MenuGroup  = {
                                        name: item,
                                        menuGroups: [] as Array<MenuGroup>
                                    }
                                    menuGroup.menuGroups?.push(subMenu);
                                })
                            }                                                                                    
                        }                        

                        obj.subList?.forEach(subObj => {
                            if(subObj) {
                                let foundMenuGroup:MenuGroup | undefined = undefined;
                                if (menuObject.name === 'Compatibility' && obj.name === 'Apple') {
                                    if (subObj.name.toLocaleLowerCase().startsWith('iphone')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'iPhone';
                                        })
                                    }
                                    else if (subObj.name.toLocaleLowerCase().startsWith('ipad')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'iPad';
                                        })
                                    } 
                                    else if (subObj.name.toLocaleLowerCase().startsWith('macbook')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'MacBook';
                                        })
                                    } 
                                    else if (subObj.name.toLocaleLowerCase().startsWith('airpod')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'AirPods';
                                        })
                                    }
                                    else if (subObj.name.toLocaleLowerCase().startsWith('apple watch')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Apple Watch';
                                        })
                                    }                                    
                                }
                                else if (menuObject.name === 'Compatibility' && obj.name === 'Samsung') {
                                    if (subObj.name.toLocaleLowerCase().startsWith('galaxy a')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Galaxy A';
                                        })
                                    }
                                    else if (subObj.name.toLocaleLowerCase().startsWith('galaxy s')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Galaxy S';
                                        })
                                    } 
                                    else if (subObj.name.toLocaleLowerCase().startsWith('galaxy z')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Galaxy Z';
                                        })
                                    } 
                                    else if (subObj.name.toLocaleLowerCase().startsWith('galaxy note')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Galaxy Note';
                                        })
                                    }
                                    else if (subObj.name.toLocaleLowerCase().startsWith('galaxy tab')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Galaxy Tab';
                                        })
                                    }                                    
                                } 
                                else if (menuObject.name === 'Compatibility' && obj.name === 'Microsoft') {
                                    if (subObj.name.toLocaleLowerCase().startsWith('surface duo') || subObj.name.toLocaleLowerCase().startsWith('surface pro')
                                            || subObj.name.toLocaleLowerCase().startsWith('surface go') ) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Surface Tablet';
                                        })
                                    }
                                    else if (subObj.name.toLocaleLowerCase().startsWith('surface laptop') || subObj.name.toLocaleLowerCase().startsWith('surface book')
                                            || subObj.name.toLocaleLowerCase().startsWith('surface se')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Surface Laptop';
                                        })
                                    } 
                                }                                                                
                                else if (menuObject.name === 'Compatibility' && obj.name === 'Oppo') {
                                    if (subObj.name.toLocaleLowerCase().startsWith('a')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'A Series';
                                        })
                                    }
                                    else if (subObj.name.toLocaleLowerCase().startsWith('x')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'X Series';
                                        })
                                    } 
                                    else if (subObj.name.toLocaleLowerCase().includes('reno')) {
                                        foundMenuGroup = _.find(menuGroup.menuGroups, (menuItem) => {
                                            return menuItem.name === 'Reno Series';
                                        })
                                    } 
                                }                                                                
                                let subMenu:MenuGroup  = {
                                    name: subObj.name,
                                    link: subObj.link,
                                }
                                if (foundMenuGroup != undefined) {
                                    foundMenuGroup.menuGroups?.push(subMenu);
                                }
                                else {
                                    menuGroup.menuGroups?.push(subMenu);
                                }
                            }
                        });
                        menuObject.childMenu?.push(menuGroup);
                    });
                    buildingMainMenu.push(menuObject);
                }
            });

            setMainMenu([...buildingMainMenu]);
            setBrandMega([...productState.highlightedBrands])
            setSearchMenu([...searchCateOptions]);
        }

        if(userState.currentUserAccount) {
            setAccountId(userState.currentUserAccount.accountid);
        }
    }, [userState.currentUserAccount, productState.brands, productState.categories, productState.deviceVendors])

    const setAlertCallback:MethodCallback<AlertsMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: AlertsMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                // save something
                const alertArray:Array<AlertObj> = [];
    
                message.unreadAlerts.forEach(r => {
                    alertArray.push({
                        ...r,
                        isRead: false
                    })
                });
    
                message.readAlerts.forEach(r => {
                    alertArray.push({
                        ...r,
                        isRead: true
                    })
                });
    
                context.setNotifications(alertArray);
            }
        }
    }

    const SearchAccountCallback:MethodCallback<SearchAccountMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: SearchAccountMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                const tempAcc = [] as any;
                if(message.error !== null) {
                    setSearchAccountResult(tempAcc);
                } else {
                    
                    if(message.results.length > 0) {
                        message.results.map((account, key) => {
                            tempAcc.push({
                                value: account.id,
                                label: account.id + ' ' + account.name
                            })
                        })
                    }
                    setSearchAccountResult(tempAcc);
                }
                
            }
        }
    }

    const SearchCallback:MethodCallback<SearchMessage> = {
      onFailure(error: string): void {
          alert(error);
      },
      onProgress(loaded: number, total: number): void {},
      onSuccess(message: SearchMessage, context: any): void {
          // if init gives authenticated as false
          // remove cookie, logout
          if(!message.authenticated) {
              logout();
          } else {
              if(message.error !== null) {
                setResult([]);
              } else {
                  
                  let res: ProductExt[] = [];
                  if(message.results.length > 0) {
                      message.results.map((result, key) => {
                        let productExt: ProductExt | undefined = productState.productIdToProduct.get(result.productId);
                        if (productExt) {
                          res.push(productExt);
                        }
                      })
                  }

                  setResult(res);
          
                  if(res.length > 0) {
                      setHeaderHeight((res.length * defaultOptionHeight) + defaultTopHeight);
                  } else {
                      setHeaderHeight(0);
                  }
              }
              
          }
      }
  }    

    const onSelectCate = (value: any) => {
        setSelectedCate(value === selectOptions[0].value ? '' : value);
    }
    
    const handleSetRead = (id: number) => {
        restClient.setAlertRead(id, setAlertCallback, {setNotifications})
    }

    const handleMobileSearchAndAutoCompleteOnKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            // Cancel the default action, if needed
            e.preventDefault();
            // Trigger the button element with a click
            if(selectedCate !== '') {
                history.push('/search/' + e.target.value + '/' + selectedCate);
            } else {
                history.push('/search/' + e.target.value);
            }
            
            setInputTextLength(0);
            setBorderInputLength(0);
            setHeaderHeight(0);
            setResult([]);
            setOpenMobileSearch(false);
        }
    }

    const accountIdToName:Map<string,string> = new Map<string,string>();
    if(userState.customers) {
        userState.customers.map((u, k) => {

            accountIdToName.set(u.id, u.name);
        })
    }        

    const listAccounts:Array<any> = []
    if(userState.userAccounts) {
        userState.userAccounts.map((u, k) => {

            listAccounts.push({
                value: u.accountid,
                label: u.accountid + ' ' + accountIdToName.get(u.accountid)
            })
            return null;
        })
    }

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(accountSearchValue.length >= 3) {
                restClient.searchForAccount(accountSearchValue, SearchAccountCallback)
            }
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [accountSearchValue])    

    // const searchAccount = React.useMemo(() => {
    //     const loadOptions = (value: string) => {
    //         setAccountSearchValue(value);
    //         if(value.length >= 3) {
    //             setTimeout(() => { // we put this here so waits for a second before doing search
    //                 doSearch(value);
    //               }, 1000)
    //         }
    //     };
    
    //     return (loadOptions);
    // }, [listAccounts]);

    React.useEffect(() => {
        let newApprovingCartsCount = 0;
        if(cartState.shoppingCart?.shoppingCartIdToShoppingCart) {
            Object.keys(cartState.shoppingCart.shoppingCartIdToShoppingCart).forEach(key => {
                let checkCart = cartState.shoppingCart?.shoppingCartIdToShoppingCart[key];
                if(checkCart) {
                    if(checkCart.id !== cartState.shoppingCart?.currentShoppingCartId && checkCart.status === 'WA' && checkCart.type === 'T') {
                        newApprovingCartsCount++;
                    }
                }
            })
        }    
        if (approvingCartsCount !== newApprovingCartsCount) {
            setApprovingCartsCount(newApprovingCartsCount);
        }
    }, [cartState.shoppingCart])

    const onClickMenuItem = (value: string, childValue: string) => {
      history.push('/shop/' + formatText(value) + '/' + formatText(childValue));
    }

    return (
        <Layout className='headerContainer'>
            <Header className='myHeader'>
                <MobileMenu submenuOpen={submenuOpen} setSubmenuOpen={(flag: boolean) => handleSetSubmenuOpen(flag)} handleSetCollapsed={handleCollapsed} collapsed={collapsed} menu={mainMenu} extraMenu={ExtraMobile}/>
                <MobileSearch handleKeyDown={handleMobileSearchAndAutoCompleteOnKeyDown} setOpenMobileSearch={(flag: boolean) => setOpenMobileSearch(flag)} searchValue={searchValue} openMobileSearch={openMobileSearch} result={result} handleSearch={handleSearch}/>
                <MobileAccount openAccountMobile={openAccountMobile} setOpenAccountMobile={(flag: boolean) => setOpenAccountMobile(flag)} content={content}/>
                <div className='content-wrapper flexSpaceBetween heightAuto' style={{maxHeight: maxHeight + 'px', height: headerHeight <= defaultHeaderHeight ? defaultHeaderHeight+'px' : headerHeight}}>
                    <div className='flexSpaceBetween'>
                        <button className={"onlyMobile toggleButton hamburger hamburger--collapse" + (collapsed ? '': ' is-active') + (submenuOpen ? ' is-arrow': '')} type="button" onClick={handleCollapsed}>
                            <div className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </div>
                        </button>
                        <CellnetLogo className='maxWidth64px' to='/'/>
                    </div>
                    <div className='search-bar withmenu'>
                        <AutoComplete onKeyDown={handleMobileSearchAndAutoCompleteOnKeyDown} open className='autoComplete' ref={inputAutocomplete} onSelect={handleSearchAutoCompleteOnSelect} onChange={handleSearchAutoCompleteOnchange} dropdownClassName='autoComplete-dropdown' allowClear onBlur={handleSearchAutoCompleteOnBlur} onFocus={handleSearchAutoCompleteOnFocus} onSearch={(e) => setSearchValue(e)} placeholder="What are you looking for?" >
                            {result.map((product: ProductExt, key: number) => (
                                <Option key={key} value={product.product.name}>
                                    <ProductSearchOption product={product} searchValue={searchValue}/>
                                </Option>
                            ))}
                        </AutoComplete>
                        <div className='inputColour'>
                            <div className='inputColourNoFill'></div>
                            <div className='inputColourFill' ref={inputFillRef} style={{right: borderInputLength === 0 ? '100%' : borderInputLength + 'px'}}></div>
                        </div>
                        <Select className='search-cat' onChange={onSelectCate} defaultValue={searchMenu[0].value} bordered={false} options={searchMenu} dropdownClassName='catesearch-dropdown'/>
                        <Menu mode="horizontal" multiple={false} selectable={false}>
                            {
                                mainMenu.length > 0 && 
                                    mainMenu.map((value, key) => {
                                        if(value.hasChild) {
                                            let childMenus: Array<MenuGroup> = value.childMenu ? value.childMenu : [];
                                            
                                            let array:Array<any> = [];
                                            switch (value.name) {
                                                case 'Brands':
                                                    array = brandMega;
                                                    break;

                                                case 'Categories':
                                                    array = CateMega;
                                                    break;

                                                case 'Compatibility':
                                                    array = VendorMega;
                                                    break;
                                            
                                                default:
                                                    break;
                                            }
                                            let count = 0;
                                            if (value.name === 'Brands') {
                                                if (childMenus) {
                                                    let rowsPerCol = childMenus.length / 5;
                                                    let rowsPerColRemainder = childMenus.length % 5;
                                                    return (
                                                        <SubMenu key={key} title={value.name} popupClassName='brandsmegamenu' popupOffset={[0, 1]}>
                                                            <Menu.ItemGroup title=''>
                                                            {
                                                                childMenus?.map((child, k) => {
                                                                    if (k % 5 == 0 && child.link) { 
                                                                        count++;
                                                                        return (
                                                                            <Menu.Item key={k}>
                                                                                <Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link)}>
                                                                                    <Image preview={false} src={getImage(child.name, 'brands', '200x200')} />
                                                                                </Link>
                                                                            </Menu.Item>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </Menu.ItemGroup>                                                    
                                                            <Menu.ItemGroup title=''>
                                                            {
                                                                childMenus?.map((child, k) => {
                                                                    if (k % 5 == 1 && child.link) { 
                                                                        count++;
                                                                        return (
                                                                            <Menu.Item key={k}>
                                                                                <Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link)}>
                                                                                    <Image preview={false} src={getImage(child.name, 'brands', '200x200')} />
                                                                                </Link>
                                                                            </Menu.Item>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </Menu.ItemGroup>     
                                                            <Menu.ItemGroup title=''>
                                                            {
                                                                childMenus?.map((child, k) => {
                                                                    if (k % 5 == 2 && child.link) { 
                                                                        count++;
                                                                        return (
                                                                            <Menu.Item key={k}>
                                                                                <Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link)}>
                                                                                    <Image preview={false} src={getImage(child.name, 'brands', '200x200')} />
                                                                                </Link>
                                                                            </Menu.Item>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </Menu.ItemGroup>                                                    
                                                            <Menu.ItemGroup title=''>
                                                            {
                                                                childMenus?.map((child, k) => {
                                                                    if (k % 5 == 3 && child.link) { 
                                                                        count++;
                                                                        return (
                                                                            <Menu.Item key={k}>
                                                                                <Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link)}>
                                                                                    <Image preview={false} src={getImage(child.name, 'brands', '200x200')} />
                                                                                </Link>
                                                                            </Menu.Item>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </Menu.ItemGroup>                                                    
                                                            <Menu.ItemGroup title=''>
                                                            {
                                                                childMenus?.map((child, k) => {
                                                                    if (k % 5 == 4 && child.link) { 
                                                                        count++;
                                                                        return (
                                                                            <Menu.Item key={k}>
                                                                                <Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link)}>
                                                                                    <Image preview={false} src={getImage(child.name, 'brands', '200x200')} />
                                                                                </Link>
                                                                            </Menu.Item>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                            </Menu.ItemGroup>                                                    
                                                        </SubMenu>
                                                    ) 
                                                }
                                            }
                                            else {
                                                return (
                                                    <SubMenu key={key} title={value.name}>
                                                    {
                                                        childMenus?.map((child, k) => {
                                                            count++;
                                                            return (
                                                                <SubMenu key={key + '#' + k} title={child.name} onTitleClick={() => onClickMenuItem(value.name, child.link ? child.link : '')}>
                                                                {
                                                                    child.menuGroups?.map((subchild, s) => {
                                                                        count++;
                                                                        if (subchild.menuGroups && subchild.menuGroups.length > 0) {
                                                                            return (
                                                                                <SubMenu key={key + '#' + k + '#' + s} title={subchild.name}>
                                                                                {
                                                                                    subchild.menuGroups?.map((subsubchild, l) => {
                                                                                        count++;
                                                                                        return (
                                                                                            <Menu.Item key={k + '#' + s + '#' + l}><Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link ? child.link : '') + '/' + formatText(subsubchild.link ? subsubchild.link : '')}>{subsubchild.name}</Link></Menu.Item>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </SubMenu>
                                                                            )
                                                                        }
                                                                        else {
                                                                            count++;
                                                                            return (
                                                                                <Menu.Item key={k}><Link to={'/shop/' + formatText(value.name) + '/' + formatText(child.link ? child.link : '') + '/' + formatText(subchild.link ? subchild.link : '')}>{subchild.name}</Link></Menu.Item>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                                </SubMenu>
                                                            )
                                                        })
                                                    } 
                                                    </SubMenu>
                                                )                                                 
                                            }
                                        } else {
                                            return (
                                                <Menu.Item key={key}><Link to={'/' + (value.link ? value.link : '')}>{value.name}</Link></Menu.Item>
                                            )
                                        }
                                        
                                    })
                            }
                        </Menu>
                    </div>
                    <div style={{display: "block"}}>
                        {/* <div className='account-icons' style={{marginTop: "-10px"}}> */}
                        <div className='account-icons'>
                            <div onClick={() => setNotiBarOpen(true)}>
                                <IconWithNumber icon={NotificationIcon} className='iconAnimate' count={_.filter(userState.notifications, (n) => {
                                    return !n.isRead || n.isRead === undefined
                                }).length}/>
                            </div>
                            
                            <div>
                                <Popover placement="bottom" title='' content={content} trigger="click" className='onlyDesktop'>
                                    <IconAnimate imageSrc={AccountIcon} className='marginLeft20px leftMinus17px height50px'/>
                                </Popover>
                            </div>
                            <div>
                                <IconAnimate imageSrc={AccountIcon} onClick={() => setOpenAccountMobile(true)} className='marginLeft20px leftMinus17px onlyMobile'/>
                            </div>
                            
                            <div>
                                <IconWithNumber icon={FavouriteIcon} className='marginLeft20px leftMinus14px' count={productState.favourites?.length} onClick={() => setFavouriteBarOpen(true)}/>
                            </div>
                            
                            <div>
                                <Link to='/cart'><IconWithNumber icon={ShoppingCartIcon} className='marginLeft20px leftMinus15px' count={cartState.itemCount}/>
                                { approvingCartsCount > 0 ? <IconWithNumber icon={""} className='approval-icon-count' count={approvingCartsCount}/> : null }
                                </Link>
                            </div>
                            
                            <div>
                                <IconAnimate imageSrc={SearchIcon} onClick={() => setOpenMobileSearch(true)} className='marginLeft20px leftMinus15px onlyMobile'/>
                            </div>
                        </div>
                        <div className='account-info' style={{marginTop: "-89px", width: "100%", display: "flex", justifyContent: "flex-end"}}>
                            <span>{userState?.currentCustomer?.id.startsWith('S1111') ? userState?.currentCustomer?.name : ''} {userState?.currentCustomer?.id}</span>
                        </div> 
                    </div>                     
                </div>
                <BreadCrumbBar/>
                <Drawer
                    title="Notifications"
                    placement="right"
                    className='notificationBar'
                    closable={true}
                    onClose={() => setNotiBarOpen(false)}
                    visible={notiBarOpen}
                    width={size.width < tabletSize ? '100%' : '400px'}
                >
                    <List
                        itemLayout="horizontal"
                        locale={{emptyText: <div>No Recent Notifications</div>}}
                        dataSource={userState.notifications ? userState.notifications : []}
                        renderItem={item => (
                        <List.Item className={item.isRead ? 'isRead':''}>
                            <List.Item.Meta
                            avatar={<Avatar className={item.type === 'address' || item.type === 'request' ? 'icon' : ''} shape="square" size="large" src='' />}
                            title={<div className='fontSize16px noMarginBottom'><div dangerouslySetInnerHTML={{__html: item.messageline}} /> </div>}
                            description={<div>
                                {dateStringFormat(item.expirydate.toString())}<br/>
                                {
                                    !item.isRead && <Button type='text' className='noPadding noHover' onClick={() => handleSetRead(item.id)}>Set as Read</Button>
                                }
                            </div>}
                            />
                        </List.Item>
                        )}
                    />
                </Drawer>

                <Drawer
                    title="Favourites"
                    placement="right"
                    className='notificationBar'
                    closable={true}
                    onClose={() => setFavouriteBarOpen(false)}
                    visible={favouriteBarOpen}
                    width={size.width < tabletSize ? '100%' : '400px'}
                >
                    <List
                        itemLayout="horizontal"
                        locale={{emptyText: <div>No Favourite Items</div>}}
                        dataSource={productState.favourites}
                        renderItem={item => {
                            // get product
                            let product = productState.productIdToProduct.get(item);
                            return (
                                <Link to={'/shop/' + formatText(product ? product.product.name : '')}>
                                    <List.Item>
                                        <List.Item.Meta
                                        avatar={<Avatar shape="square" size="large" src={imageUrl + product?.product.image } />}
                                        title={<p className='fontSize16px noMarginBottom'>{product?.product.name}</p>}
                                        description={product?.product.shortName}
                                        />
                                    </List.Item>
                                </Link>
                            )}}
                    />
                </Drawer>
                <Modal
                    title="Switch Account"
                    centered
                    visible={modalSwitchAccount}
                    onOk={() => {
                        if(accountId) {
                            myAuth.switchAccount(accountId, setHomeContent, setBanners, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile)
                            setModalSwitchAccount(false);
                        }
                    }}
                    onCancel={() => setModalSwitchAccount(false)}
                    >
                        <div>
                            Current Account: <strong>{userState?.currentCustomer?.id}</strong>
                        </div>
                        <div>
                            Current Name: <strong>{userState.userProfile?.firstname} {userState.userProfile?.lastname}</strong>
                        </div>
                        <br/>
                        <div className='avatarChange'>
                            {
                                userState?.currentUserAccount?.extrapermissions?.includes('M') && userState?.userProfile?.type?.includes('S') ? 
                                <Select onSearch={(e) => setAccountSearchValue(e)} filterOption={false} onSelect={(option) =>setAccountId(option)} className='fullWidth' showSearch={true} dropdownMatchSelectWidth={false} defaultValue={userState?.currentCustomer?.id} size='large' allowClear placeholder="Please select an account" options={searchAccountResult}/>
                                :
                                <Select filterOption={(input, option) => {
                                    if(option) {
                                        if(option.label) {
                                            if(option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                                                return true
                                            } 
                                        }
                                    }
                                    return false
                                }} onSelect={(option) =>setAccountId(option)} className='fullWidth' showSearch={true} dropdownMatchSelectWidth={false} defaultValue={userState?.currentCustomer?.id} size='large' allowClear placeholder="Please select an account" options={listAccounts}/>
                            }
                            
                        </div>
                    </Modal>
            </Header>
    </Layout>
  );
}

export default MenuHeader;