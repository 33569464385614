import React from 'react';
import {orderPageSize} from '../Constants';
import {dateStringFormat, paginate} from '../utils';
import OrderDetails from '../elements/OrderDetails/OrderDetails';
import { Order, OrderLine, TrackingDataExt } from '../RESTAPI';
import _ from 'lodash';
import { UserContext } from '../contexts/UserContext';
import { Button, Form, Row, Col, DatePicker, Input } from 'antd';

const { RangePicker } = DatePicker;

const OrderList: React.FC<{orders: Array<Order>, isBackOrder: boolean}> = ({orders, isBackOrder }) => {
    const [orderLines, setOrderLines] = React.useState<{ [index: string]: OrderLine[] }>();
    const [shipmentLine, setShipmentLine] = React.useState<{ [index: string]: TrackingDataExt[] }>();
    const { userState } = React.useContext(UserContext);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [maxPage, setMaxPage] = React.useState<number>(1);
    const [currentOrders, setCurrentOrders] = React.useState<Array<Order>>([]);
    const [currentYearOrders, setCurrentYearOrders] = React.useState<Array<Order>>([]);

    const [years, setYears] = React.useState<Array<string>>([]);
    const [selectedYear, setSelectedYear] = React.useState<string>('');
    const [selectedDefaultYear, setDefaultYear] = React.useState<string>('');

    const [search, setSearch] = React.useState<string>('');
    const [dateRange, setDateRange] = React.useState<[string, string]>(['','']);

    React.useEffect(() => {
        if(userState.orders) {
            setOrderLines(userState.orders.orderNoToOrderLines);
        }
        if (userState.trackingDatas) {
            setShipmentLine(userState.trackingDatas.shipmentNoToTrackingDatas)
        }
    }, [userState.orders, userState.trackingDatas])

    React.useEffect(() => {
        if(orders.length > 0) {
            const current = filterList(orders);
            // getting years out of orders
            /*let temp:Array<string> = [];
            current.forEach((o) => {
                let year = o.orderdate.toString().substring(0, 4);;
                if(!temp.includes(year)) {
                   temp.push(year); 
                }
            })
            setYears(temp);*/

            let tempOrders:Array<Order> = current;

            /*if(selectedYear.length > 0) {
                tempOrders = _.filter(current, (o) => {
                    return o.orderdate.toString().substring(0, 4) === selectedYear
                })
            } else {
                if(temp.length > 0) {
                    tempOrders = _.filter(current, (o) => {
                        return o.orderdate.toString().substring(0, 4) === temp[0]
                    })
                    setDefaultYear(temp[0]);
                }
            }
            setCurrentYearOrders(tempOrders);*/

            tempOrders.sort((a, b) => (a.orderdate > b.orderdate) ? -1 : (a.orderdate === b.orderdate) ? ((a.orderno > b.orderno) ? -1 : 1) : 1)

            setCurrentOrders(paginate(tempOrders, orderPageSize, 1));

            setMaxPage(Math.ceil(tempOrders.length / orderPageSize));
            setCurrentPage(1);
        } else {
            setMaxPage(1);
            setCurrentPage(1);
        }
    }, [orders, selectedYear, search, dateRange])
    
    const onClick = (e: React.MouseEvent) => {
        if(currentPage < maxPage) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);

            if (currentYearOrders.length > 0) {
                setCurrentOrders([...currentOrders, ...paginate(currentYearOrders, orderPageSize, nextPage)]);
            } else {
                setCurrentOrders([...currentOrders, ...paginate(orders, orderPageSize, nextPage)]);
            }
        }
    }

    const hangleOnChange = (value: string) => {
        setSelectedYear(value);
    }

    const filterList = (orders: Array<Order>) => {
        return _.filter(orders, (i) => {

            if(search !== '') {
                let found: boolean = false;
                let searchLC: string = search.toLowerCase();
                if (i.orderno.toString().includes(search) || i.customerref.toLowerCase().includes(searchLC)) {
                    found = true;
                }
                else if (orderLines) {
                    let lines: OrderLine[] = orderLines[i.orderno];
                    if (lines) {
                        for (let line of lines) {
                            if (line.itemdesc.toLowerCase().includes(searchLC) ||
                                  line.itemname.toLowerCase().includes(searchLC) ||
                                  line.itemno.includes(searchLC)) {
                                found = true;
                                break;
                            }
                        }
                    }
                }
                if (!found) {
                    return false;
                }
            }

            if(dateRange !== ['',''] && (new Date(dateRange[0]) >= new Date(dateStringFormat(i.orderdate, false, true)) || new Date(dateRange[1]) <= new Date(dateStringFormat(i.orderdate, false, true)))) {
                return false
            }
            return true;
        })
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }

    const handleDateChange = (value: any, formatString: [string, string]) => {
        setDateRange(formatString);
    }

    return (
        <>
        {/*<OrderFilter title='Orders' total={currentYearOrders.length} years={years} handleOnChange={hangleOnChange} selectedYear={selectedYear} selectedDefaultYear={selectedDefaultYear}/>*/}
        <Form>
            <Row gutter={20}>
                <Col xs={8} md={8} lg={6}>
                    <Form.Item className="group-floating-label" name='search' label=''>
                        <Input size="large" onChange={handleSearchChange} className="has-float-label" placeholder='Search' suffix={<label className="floating-label" htmlFor="name">Search</label>}/>  
                    </Form.Item>
                </Col>

                <Col xs={16} md={16} lg={12}>
                    <div className='datePicker' style={{marginTop: 0}}>
                    <Form.Item className="group-floating-label" name='dateRange' label=''>
                        <RangePicker picker='date' className="has-float-label" style={{margin: 0}} onChange={handleDateChange}/>
                    </Form.Item>
                    </div>
                </Col>
            </Row>
        </Form>
        {
            currentOrders.length > 0 && currentOrders.map((order, key) => {
                return (
                    <OrderDetails isBackOrder={isBackOrder} order={order} key={key} shipmentLines={shipmentLine} orderLine={orderLines ? orderLines[order.orderno] : undefined}/>
                )
            })
        }
        {
            currentPage < maxPage && 
                <div className='maxWidth300px marginTop25px'>
                    <Button ghost block onClick={onClick}>LOAD MORE</Button>
                </div>
        }
        </>                     
    );
}

export default OrderList;
