import { UserState } from '../Constants';

type Action = {
    payload: any,
    type: string
}

export const UserReducer = (state: UserState, action: Action) => {
    switch (action.type) {
        case "SET_CURRENT_CUSTOMER":
            return {
                ...state,
                currentCustomer: action.payload
            }
        case "SET_CURRENT_PAYER":
            return {
                ...state,
                currentPayer: action.payload
            }
        case "SET_CURRENT_USER_ACCOUNT":
            return {
                ...state,
                currentUserAccount: action.payload
            }
        case "SET_USER_ACCOUNTS":
            return {
                ...state,
                userAccounts: action.payload
            }
        case "SET_CUSTOMERS":
            return {
                ...state,
                customers: action.payload
            }
        case "SET_PAYERS":
            return {
                ...state,
                payers: action.payload
            }
        case "SET_MANAGED_USER_ACCOUNTS":
            return {
                ...state,
                managedUserAccounts: action.payload
            }
        case "SET_MANAGED_USER_PROFILE":
            return {
                ...state,
                managedUserProfiles: action.payload
            }
        case "SET_ACCOUNT":
            return {
                ...state,
                account: action.payload
            }
        case "SET_ORDERS":
            return {
                ...state,
                orders: action.payload
            }            
        case "SET_BACK_ORDERS":
            return {
                ...state,
                backOrders: action.payload
            }            
        case "SET_INVOICES":
            return {
                ...state,
                invoices: action.payload
            }            
        case "SET_DUE_INVOICES":
            return {
                ...state,
                dueInvoices: action.payload
            } 
        case "SET_TRANSACTIONS_AND_PAYMENTS":
            return {
                ...state,
                transactionsAndPayments: action.payload
            }             
        case "SET_DELIVERY_ADDRESSES":
            return {
                ...state,
                deliveryAddresses: action.payload
            }            
        case "SET_TRACKING_DATAS":
            return {
                ...state,
                trackingDatas: action.payload
            }            
        case "SET_RECENT_ACTIVITIES":
            return {
                ...state,
                recentActivities: action.payload
            }
        case "SET_NOTIFICATIONS":
            return {
                ...state,
                notifications: action.payload
            }
        case "SET_RETURNS":
            return {
                ...state,
                returns: action.payload
            }
        case "SET_USER_PROFILE":
            return {
                ...state,
                userProfile: action.payload
            }
        case "SET_ADDRESSES":
            return {
                ...state,
                addresses: action.payload
            }
        case "SET_HOME_CONTENT":
            return {
                ...state,
                homeContent: action.payload
            }
        case "SET_BANNERS":
            return {
                ...state,
                banners: action.payload
            }            
        case "SET_PREFERENCES":
            return {
                ...state,
                preferences: action.payload
            }
        case "SET_LAST_PATH":
            return {
                ...state,
                lastPath: action.payload,
                lastPathUpdateTime: Date.now()
            }              
        case "SET_DATA_FEEDS":
            return {
                ...state,
                dataFeeds: action.payload
            }
        case "SET_FIRST_NAME":
            const changeUserProfileFirstName = { ...state.userProfile };
            changeUserProfileFirstName.firstname = action.payload;

            return {
                ...state,
                userProfile: changeUserProfileFirstName
            }
        case "SET_LAST_NAME":
            const changeUserProfileLastName = { ...state.userProfile };
            changeUserProfileLastName.lastname = action.payload;

            return {
                ...state,
                userProfile: changeUserProfileLastName
            }
        case "SET_EMAIL":
            const changeUserProfileEmail = { ...state.userProfile };
            changeUserProfileEmail.email = action.payload;

            return {
                ...state,
                userProfile: changeUserProfileEmail
            }
        case "SET_PHONE":
            const changeUserProfilePhone = { ...state.userProfile };
            changeUserProfilePhone.contactphone = action.payload;

            return {
                ...state,
                userProfile: changeUserProfilePhone
            }
        default:
            return {...state}
    }
}