import React from 'react';
import { Upload, Row, Col, Button, Select, Form, Typography, Input, Modal, message, List } from 'antd';
import { MinusCircleOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { formatCurrency } from '../../../utils';
import { DraggerProps } from 'antd/lib/upload';
import MainLayout from '../../../layouts/MainLayout';
import { UserContext } from '../../../contexts/UserContext';
import { MethodCallback, ReturnMessage, ReturnRequest, ReturnRequestLine, ReturnRequestDocument, ReturnsMessage, ReturnReason, InvoiceLine } from '../../../RESTAPI';
import { pageVariants, returnProductsNeedingReferenceNumber, returnRequestDocumentServlet } from '../../../Constants';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { restClient, logout } from '../../../elements/PrivateRoute/PrivateRoute';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { ProductContext } from '../../../contexts/ProductContext';
import Cookies from 'universal-cookie';

const { Paragraph, Title } = Typography;
const { Dragger } = Upload;

// dirty way to to this but this is Friday, cat is yewning

const EditReturn: React.FC = () => {
    const {userState, setReturns} = React.useContext(UserContext);
    const {productState} = React.useContext(ProductContext);

    const [currentRequestNo, setCurrentRequestNo] = React.useState<number>(0);
    const [currentRequestLines, setCurrentRequestLines] = React.useState<Array<ReturnRequestLine>>([]);
    const [currentRequestDocuments, setCurrentRequestDocuments] = React.useState<Array<ReturnRequestDocument>>([]);
    const [currentRequestObj, setCurrentRequestObj] = React.useState<ReturnRequest>();

    const [currentValues, setCurrentValues] = React.useState<any>({});
    const [productValue, setProductValue] = React.useState<any>({});

    const [productOptions, setProductOptions] = React.useState<Array<any>>([]);
    const [itemNoToInvoiceLines, setItemNoToInvoiceLines] = React.useState<{ [itemno: string]: InvoiceLine[] }>({}); // this will only hold products that exist in invoices but not productIdToProduct. We want it visible so we can get a salesPrices for these products 

    const [productModal, setProductModal] = React.useState<boolean>(false);
    const [addLoading, setAddLoading] = React.useState<boolean>(false);

    const [returnToSender, setReturnToSender] = React.useState<boolean>(false);
    const [stockRotation, setStockRotation] = React.useState<boolean>(false);

    const {returnId} = useParams<{returnId: string}>();
    const location = useLocation();

    const [form] = Form.useForm();
    const [mainForm] = Form.useForm();

    const history = useHistory();

    const cookies = new Cookies();

    const props: DraggerProps = {
        name: 'returnRequestDocument',
        action: returnRequestDocumentServlet + '/' + currentRequestNo,
        multiple: false,
        method: 'post',
        showUploadList: false,
        headers: {
          'Accept': 'application/json',
          'token-X': cookies.get('token')
        },
        onChange(info: any) {
          console.log(info);
          console.log(JSON.stringify(info));
          const { status } = info.file;
          if (status !== 'uploading') {
            //console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            let newReturnsMessage: ReturnsMessage = {...userState.returns};
            let existingReturnRequestIndex: number = _.findIndex(newReturnsMessage.returns, (r) => { return r.requestno === currentRequestNo});
            if (existingReturnRequestIndex != -1) {
                newReturnsMessage.documents[currentRequestNo] = info.file.response.documents;
                setReturns(newReturnsMessage);
            }
            setCurrentRequestDocuments(info.file.response.documents);            
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };    

    React.useEffect(() => {
        if(returnId && userState.returns) {
            const returnObj = _.find(userState.returns.returns, (r) => {
                return r.requestno.toString() === returnId;
            })

            if(returnObj) {
                setCurrentRequestNo(returnObj.requestno);
                setCurrentRequestObj(returnObj);
                if(userState.returns.lines[returnObj.requestno]) {
                    setCurrentRequestLines(userState.returns.lines[returnObj.requestno]);
                }
                if(userState.returns.documents[returnObj.requestno]) {
                  setCurrentRequestDocuments(userState.returns.documents[returnObj.requestno]);
                }    
                setReturnToSender('R89|R34'.includes(returnObj.reason));
                setStockRotation('R31' === returnObj.reason);                            
            }
        }
    }, [returnId, userState.returns])

    React.useEffect(() => {

        let newProductOptions: Array<any> = [];
        let newItemNoToInvoiceLines: { [itemno: string]: InvoiceLine[] } = ({});

        if(productState && productState.productIdToProduct) {
            productState.productIdToProduct.forEach((p, k) => {
                newProductOptions.push({
                    value: p.product.id,
                    label: (<div><strong>{p.product.id}</strong><br/>{p.product.name}<br/>{p.product.ean}<br/>{p.product.description}</div>),
                    desc: p.product.description ? p.product.description : '',
                    ean: p.product.ean ? p.product.ean : '',
                    name: p.product.name ? p.product.name : ''
                })
            })

            // Add products that exist in invoices but don't exist in productState.productIdToProduct
            if (userState && userState.invoices) {
                userState.invoices.invoices.forEach(invoice => {
                    if (userState.invoices.invoiceNoToInvoiceLines[invoice.invoiceno.toString()]) {
                        userState.invoices.invoiceNoToInvoiceLines[invoice.invoiceno.toString()].forEach(invoiceLine => {

                            if (!productState.productIdToProduct.get(invoiceLine.itemno)) {
                                // Check if the itemno already exists in itemNoToInvoiceLines, if not, create an empty array
                                if (!newItemNoToInvoiceLines[invoiceLine.itemno]) {
                                    newItemNoToInvoiceLines[invoiceLine.itemno] = [];
                                }
                                newItemNoToInvoiceLines[invoiceLine.itemno].push(invoiceLine);
                            }
                        });
                    }
                });  

                Object.keys(newItemNoToInvoiceLines).forEach(itemNo => {

                    newProductOptions.push({
                        value: itemNo,
                        label: (<div><strong>{itemNo}</strong><br/>From invoices - name unknown<br/>EAN unknown<br/>Description unknown</div>),
                        desc: itemNo,
                        ean: '',
                        name: itemNo
                    })
                });            
            } 
            
        }   
        setProductOptions(newProductOptions); 
        setItemNoToInvoiceLines(newItemNoToInvoiceLines);
    }, [productState.productIdToProduct, userState.invoices])    

    const DeleteLineCallback:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    let newReturnsMessage: ReturnsMessage = {...userState.returns};
                    let existingReturnRequestIndex: number = _.findIndex(newReturnsMessage.returns, (r) => { return r.requestno === currentRequestNo});
                    if (existingReturnRequestIndex != -1) {
                        newReturnsMessage.returns[existingReturnRequestIndex] = message.returnRequest;
                        newReturnsMessage.lines[currentRequestNo] = message.lines;
                        newReturnsMessage.documents[currentRequestNo] = message.documents;
                        setReturns(newReturnsMessage);
                    }                  
                    setCurrentRequestLines(message.lines);
                }
            } else {
                logout();
            }
        }
    }

    const DeleteDocumentCallback:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    let newReturnsMessage: ReturnsMessage = {...userState.returns};
                    let existingReturnRequestIndex: number = _.findIndex(newReturnsMessage.returns, (r) => { return r.requestno === currentRequestNo});
                    if (existingReturnRequestIndex != -1) {
                        newReturnsMessage.returns[existingReturnRequestIndex] = message.returnRequest;
                        newReturnsMessage.lines[currentRequestNo] = message.lines;
                        newReturnsMessage.documents[currentRequestNo] = message.documents;
                        setReturns(newReturnsMessage);
                    }
                    setCurrentRequestDocuments(message.documents);
                }
            } else {
                logout();
            }
        }
    }    

    const handleAddLine = (productValue: any) => {
        setAddLoading(true);
    
        if(currentRequestNo && productValue && productValue.productNo && productValue.productQty) {
            let faultDescription: string | undefined = productValue.faultDescription;
            if (returnProductsNeedingReferenceNumber.includes(productValue.productNo) && 
            (!productValue.faultDescription || !/^REF#[0-9a-zA-Z]+/.test(productValue.faultDescription))) {
              let refNo: string | null = prompt('Please enter the required service centre reference number');
              if (!refNo || !/^[0-9a-zA-Z]+/.test(refNo)) {
                alert('Sorry, this product requires a service centre reference number to be processed');
                setAddLoading(false);
                return;
              }
              faultDescription = 'REF#' + refNo + ' ' + productValue.faultDescription;
            }
            restClient.addReturnRequestProduct(currentRequestNo, productValue.productNo.toString(), parseInt(productValue.productQty), faultDescription ? faultDescription : '', addProductLineCallback)
        }
    }

    const addProductLineCallback:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    let newReturnsMessage: ReturnsMessage = {...userState.returns};
                    let existingReturnRequestIndex: number = _.findIndex(newReturnsMessage.returns, (r) => { return r.requestno === currentRequestNo});
                    if (existingReturnRequestIndex != -1) {
                        newReturnsMessage.returns[existingReturnRequestIndex] = message.returnRequest;
                        newReturnsMessage.lines[currentRequestNo] = message.lines;
                        newReturnsMessage.documents[currentRequestNo] = message.documents;
                        setReturns(newReturnsMessage);
                    }
                                        
                    setCurrentRequestLines(message.lines);

                    form.resetFields();
                    setProductModal(false);
                }
                setAddLoading(false);
            } else {
                logout();
            }
        }
    }

    const SubmitRequest:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    restClient.returnsFromServer(LocalReturnCallback, {setReturns});
                }
                setAddLoading(false);
            } else {
                logout();
            }
        }
    }

    const LocalReturnCallback: MethodCallback<ReturnsMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: ReturnsMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                // save something
                setReturns(message);
                history.push('/your-account/returns/');
            }
        }
    }

    const handleSubmitReturn = () => {
        if(currentRequestNo) {
            setAddLoading(true);
            restClient.submitIncompleteReturnRequest(currentRequestNo, SubmitRequest);
        } else {
            alert('Please add return products!');
        }
    }

    const optionReasons: Array<any> = [];
                
    if(userState.returns) {
        Object.keys(userState.returns.allReasonCodeToReason).forEach(key => {
            const reasonObj = userState.returns.allReasonCodeToReason[key];
            if(userState.returns.customerReasonCodes) {
                if(userState.returns.customerReasonCodes.includes(reasonObj.code)) {
                    optionReasons.push({
                        value: reasonObj.code,
                        label: reasonObj.name
                    })
                }
            }
        });
    }

    const productLine:Array<any> = [];
    if(currentRequestLines) {
        currentRequestLines.map((l, k) => {
            productLine.push({
                'productNo': l.itemno,
                'productQty': l.qty,
                'linno': l.linno,
                'requestNo': l.requestno,
                'faultDescription': l.faultdesc,
                'salePrice': l.saleprice,                
            })
            return null;
        })
    }

    if(location.pathname.includes(':returnId')) {
        return  <Redirect
            to={{
                pathname: "/your-account/returns"
            }}
        />;
    }

    const handleDeleteProductLine = (linno: number) => {
        if(currentRequestNo) {
            restClient.deleteReturnRequestLine(currentRequestNo, linno, DeleteLineCallback)
        }
    }

    const handleDeleteDocument = (document: ReturnRequestDocument) => {
        if(document) {
            restClient.deleteReturnRequestDocument(document.requestno, document.id, DeleteDocumentCallback)
      }        
    }     

    const handleOpenProductModal = () => {
        mainForm.validateFields().then(values => {
            setProductModal(true)
        }).catch(errorInfo => {

        })
    }

    const handleUpdateReturnRequestContact = (values: any) => {
        setAddLoading(true);
    
        if(values.customerRef && values.contactname && values.email && values.phone) {
            restClient.updateReturnRequestContact(currentRequestNo, values.customerRef, values.contactname, values.email, values.phone, UpdateReturnRequestContact)
        } else {
            if(currentRequestObj) {
                restClient.updateReturnRequestContact(currentRequestNo, currentRequestObj.customerref, currentRequestObj.contactname, currentRequestObj.contactemail, currentRequestObj.contactphone, UpdateReturnRequestContact)
            }
            
        }
    }

    const UpdateReturnRequestContact:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    let newReturnsMessage: ReturnsMessage = {...userState.returns};
                    let existingReturnRequestIndex: number = _.findIndex(newReturnsMessage.returns, (r) => { return r.requestno === currentRequestNo});
                    if (existingReturnRequestIndex != -1) {
                        newReturnsMessage.returns[existingReturnRequestIndex] = message.returnRequest;
                        newReturnsMessage.lines[currentRequestNo] = message.lines;
                        setReturns(newReturnsMessage);
                    }
                }
                setAddLoading(false);
            } else {
                logout();
            }
        }
    }

    const totalRequestAmount = (requestProductLines:Array<any>) => {
        let totalAmount = 0.0;
  
        if(requestProductLines) {
            requestProductLines.map((requestProductLine: any, k: any) => { 
                totalAmount += (requestProductLine.salePrice * requestProductLine.productQty);
                return null
            })
        }
      
        return totalAmount;
    }    

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20} className='marginBottom40px'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <motion.div className='marginTopAuto' initial='initial' animate="in" exit="out" variants={pageVariants}>
                            <Title level={5} className='textCenter marginBottom25px'>
                                Edit a Service Request                         
                            </Title>
                            <div className='textCenter marginTopNegative10px marginBottom10px' style={{ "fontWeight": "bold" }}>
                                <a href='https://dam.cellnet.com.au/Connect/pdf/CellnetServiceRAsAndBestPracticeOct2023.pdf' target='_blank' rel='noopener noreferrer'>Click here for instructions on dealing with RAs.</a>
                            </div>
                            {
                                currentRequestObj && (
                                    <Form form={mainForm} onFinish={(values) => {setCurrentValues(values); handleSubmitReturn()}} initialValues={{
                                        'customerRef': currentRequestObj.customerref,
                                        'contactname': currentRequestObj.contactname,
                                        'email': currentRequestObj.contactemail,
                                        'phone': currentRequestObj.contactphone,
                                        'reason': userState?.returns?.allReasonCodeToReason[currentRequestObj.reason] ? userState.returns.allReasonCodeToReason[currentRequestObj.reason].name : '',
                                        'products': productLine
                                    }} onValuesChange={(_, values) => {setCurrentValues(values); handleUpdateReturnRequestContact(values)} }>
                                        <Form.Item className="group-floating-label" hasFeedback name='customerRef' label='' rules={[{ required: true, message: 'Please input your customer reference!' }]}>
                                            <Input size="large" className="has-float-label" placeholder='Customer Reference' suffix={<label className="floating-label" htmlFor="name">Customer Reference</label>}/>  
                                        </Form.Item>

                                        <Form.Item className="group-floating-label" hasFeedback name='contactname' label='' rules={[{ required: true, message: 'Please input your contact name!' }]}>
                                            <Input size="large" className="has-float-label" placeholder='Contact Name' suffix={<label className="floating-label" htmlFor="name">Contact Name</label>}/>  
                                        </Form.Item>

                                        <Form.Item className="group-floating-label" hasFeedback name='email' label='' rules={[{ type: 'email', message: 'The input is not a valid email'}, {required: true, message: 'Please input your E-mail!' }]}>
                                            <Input size="large" className="has-float-label" placeholder='Email' suffix={<label className="floating-label" htmlFor="name">Email</label>}/>  
                                        </Form.Item>

                                        <Form.Item className="group-floating-label" hasFeedback name='phone' label='' rules={[{required: true, message: 'Please input your phone number' }]}>
                                            <Input size="large" className="has-float-label" placeholder='Phone' suffix={<label className="floating-label" htmlFor="name">Phone</label>}/> 
                                        </Form.Item>

                                        <Form.Item className="group-floating-label" hasFeedback name='reason' label='' rules={[{required: true, message: 'Please select your reason' }]}>
                                            <Select disabled showSearch={true} loading={addLoading} size='large' placeholder="Please select a reason" optionLabelProp="label" options={optionReasons}/>
                                        </Form.Item>

                                        { returnToSender && 
                                            <>
                                                <Title level={5} className='marginBottom25px'>Return To Sender Invoice</Title>
                                                <Form.Item className="group-floating-label" hasFeedback name='rtsInvoiceNo' label='' rules={[{required: true, message: 'Please input the RTS invoice number' }]}>
                                                    <Input size="large" readOnly={addLoading} className="has-float-label" placeholder='Invoice Number' suffix={<label className="floating-label" htmlFor="rtsInvoiceNo">Invoice Number</label>}/> 
                                                </Form.Item>
                                            </>
                                        }

                                        { !returnToSender && 
                                            <>
                                                <Title level={5} className='marginBottom25px'>Return Product(s)</Title>
                                                {
                                                    productLine.map((line, key) => 
                                                        <Row key={key} gutter={[30, 30]}>
                                                            <Col xs={24} sm={24} lg={7}>
                                                                <strong>ProductNo:</strong><br/>
                                                                {line.productNo}<br/>
                                                                Line: {line.linno}
                                                            </Col>
                                                            <Col xs={24} sm={24} lg={7}>
                                                                <strong>Product Qty:</strong><br/>
                                                                {line.productQty}
                                                            </Col>
                                                            <Col xs={24} sm={24} lg={6}>
                                                                <strong>Sale Price: </strong>{formatCurrency(line.salePrice)}<br/>
                                                                <strong>Line Total: </strong>{formatCurrency(line.productQty * line.salePrice)}<br/>
                                                            </Col>
                                                            <Col xs={24} sm={24} lg={4}>
                                                                <Button loading={addLoading} type='primary' block onClick={() => {handleDeleteProductLine(line.linno)}} icon={<MinusCircleOutlined/>}/>
                                                            </Col>
                                                            
                                                        </Row>
                                                    )
                                                }
                                                <Row gutter={[30,30]}>
                                                    <Col span={24}>
                                                        <strong>Request Total: </strong>{formatCurrency(totalRequestAmount(productLine))}<br/>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[30,30]}>
                                                    <Col span={24}>
                                                        <Button loading={addLoading} type="dashed" block onClick={() => {handleOpenProductModal()}} icon={<PlusOutlined />}>
                                                            Add Product
                                                        </Button>
                                                    </Col>
                                                </Row>                                        
                                            </>
                                        }

                                        { currentRequestNo > 0 &&
                                            <Row gutter={[30,30]}>
                                                <Col span={24}>
                                                    <h2 className='headerTitle textCenter marginTop20px'>UPLOAD DOCUMENT</h2>
                                                    <Paragraph className='textCenter'>
                                                        Upload your evidence documents below.<br/>
                                                    </Paragraph>
                                                    <div className='whiteBg padding20px uploadContainer' style={{maxWidth: '484px', margin: '0 auto'}}>
                                                        <Dragger {...props} listType='picture'>
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">Upload file or drag & drop</p>
                                                        </Dragger>

                                                        { currentRequestDocuments && currentRequestDocuments.length > 0 && 
                                                            <List
                                                                className="fontSize16px"
                                                                itemLayout="horizontal"
                                                                dataSource={currentRequestDocuments ? currentRequestDocuments : []}
                                                                renderItem={document => (
                                                                    <List.Item>
                                                                        <List.Item.Meta
                                                                            title={<div className='noMarginBottom floatLeft'>{document.originalfilename}</div>}
                                                                            description={<div className='floatRight'>
                                                                                <Button className='text-secondaryColor' type='link' onClick={() => handleDeleteDocument(document)}><strong>Remove</strong></Button>
                                                                            </div>}
                                                                            />
                                                                    </List.Item>
                                                                )}
                                                            />                                                    
                                                        }                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                        }                                        

                                        <Button loading={addLoading} size='large' className='reverse-icon' type='primary' htmlType='submit'>Submit Request</Button>
                                    </Form>
                                )
                            }
                            
                        </motion.div>
                    </Col>
                </Row>
            </div>

            <Modal
                title="Adding return product"
                centered
                visible={productModal}
                className='cartModal'
                onCancel={() => setProductModal(false)}
            >
                <Form onFinish={(values) => {setProductValue(values); handleAddLine(values)}} form={form} onValuesChange={(_, values) => setProductValue(values)}>
                    <Row gutter={[30,30]}>
                        <Col xs={24} sm={24} lg={8}>
                            <Form.Item name='productNo' className="group-floating-label" hasFeedback label='' rules={[{required: true, message: 'Please input your product no' }]}>
                                <Select loading={addLoading} filterOption={(input, option) => {
                                    if(option) {
                                        if(option.label && option.desc) {
                                            if(option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.desc.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.ean.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                                                return true
                                            } 
                                        }
                                    }
                                    return false
                                }} showSearch={true} size='large' placeholder="Please select a product" options={productOptions}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={8}>
                            <Form.Item name={'productQty'} fieldKey={'productQty'} className="group-floating-label" hasFeedback label='' rules={[{required: true, message: 'Please input your product quantity' }]}>
                                <Input readOnly={addLoading} size="large" type='number' className="has-float-label" placeholder='Product Quantity' suffix={<label className="floating-label">Product Quantity</label>}/> 
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={8}>
                            {
                                ('R20|R21|R80|R86'.includes(currentValues.reason) || (currentRequestObj?.reason && 'R20|R21|R80|R86'.includes(currentRequestObj?.reason))) &&
                                <Form.Item name={'faultDescription'} fieldKey={'faultDescription'} className="group-floating-label" hasFeedback label='' rules={[{required: true, message: 'Please input your fault description' }]}>
                                    <Input readOnly={addLoading} size="large" type='text' className="has-float-label" placeholder='Fault Description' suffix={<label className="floating-label">Fault Description</label>}/> 
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col span={24}>
                            <Button loading={addLoading} htmlType='submit' block type="primary" className='marginBottom20px'>ADD PRODUCT</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </MainLayout>
    );
}

export default EditReturn;
