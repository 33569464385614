import React from 'react';
import { Row, Col, Typography, message, Form, Input, Button, Select, List, Radio, DatePicker } from 'antd';
import moment from 'moment';
import { format } from 'date-fns';
import { enAU } from 'date-fns/locale';
import { RadioChangeEvent } from 'antd/es/radio/interface';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import LoginFooter from '../components/LoginFooter';
import AddNewDealerApplicationAddress from '../components/AddNewDealerApplicationAddress';
import { pageVariants, optionStates } from '../Constants';
import { motion } from "framer-motion";
import { useHistory } from 'react-router-dom';
import { ClientHelper, DealerApplication, DealerApplicationAddress, DealerApplicationLocation, DealerApplicationMessage, MethodCallback, ServiceMainRESTClient } from '../RESTAPI';

const { Paragraph, Title } = Typography;

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

function CashApplication() {

  const history = useHistory();

  const [form] = Form.useForm();
  const [dealerApplication, setDealerApplication] = React.useState<DealerApplication | undefined>(undefined);
  const [dealerApplicationAddresses, setDealerApplicationAddresses] = React.useState<Array<DealerApplicationAddress>>([]);
  const [dealerApplicationLocations, setDealerApplicationLocations] = React.useState<Array<DealerApplicationLocation>>([]);
  const [formValues, setValues] = React.useState<Array<any>>([]);

  const [isAddressBeingEdited, setIsAddressBeingEdited] = React.useState<boolean>(false);
  const [editingAddressIndex, setEditingAddressIndex] = React.useState<number>(0);
  const [addNewAddressVisible, setAddNewAddressVisible] = React.useState<boolean>(false);

  const [registeredDomains, setRegisteredDomains] = React.useState<string[]>([]);
  const [newRegisteredDomain, setNewRegisteredDomain] = React.useState<string>('');
  
  let changeCount: number = 0;

  const GetDealerApplicationCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage, context?: any): void {

      message.loading({ content: 'Retrieving dealer application...', key: 'updatable' });

      if (returnMessage.error) {
        setTimeout(() => {
          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
        }, 200);
      } else {
        setTimeout(() => {
          setDealerApplicationAddresses(returnMessage.addresses);
          setDealerApplicationLocations(returnMessage.locations);
          if (returnMessage.dealerApplication.companywebsite && returnMessage.dealerApplication.companywebsite.includes(';')) {
            const loadedRegisteredDomains: string[] = [];
            const actualCompanyWebsite = returnMessage.dealerApplication.companywebsite.split(';')[0];
            const registeredDomainsComaSeperated = returnMessage.dealerApplication.companywebsite.split(';')[1];
            loadedRegisteredDomains.push(...registeredDomainsComaSeperated.split(','));            
            setRegisteredDomains(loadedRegisteredDomains);
            returnMessage.dealerApplication.companywebsite = actualCompanyWebsite;
          }
          setDealerApplication(returnMessage.dealerApplication);
          message.success({ content: 'Dealer application retrieved!', key: 'updatable', duration: 2 });
        }, 200);
      }
    }
  }

  const prefixKeys = <T extends Record<string, any>>(obj: T | undefined, prefix: string) => {
    if (!obj) return {}; // Return an empty object if obj is undefined
    return Object.keys(obj).reduce((acc, key) => {
      acc[`${prefix}${key}`] = obj[key];
      return acc;
    }, {} as Record<string, any>);
  };

  React.useEffect(() => {
    if (dealerApplication) {

      const firstDirectorAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'TR01');
      const secondDirectorAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'TR02');
      const registeredAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'REGD');
      const postAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'SHIP');

      const combinedValues = {
        ...dealerApplication,
        ...prefixKeys(firstDirectorAddress, 'fd'),
        ...prefixKeys(secondDirectorAddress, 'sd'),
        ...prefixKeys(registeredAddress, 'rg'),
        ...prefixKeys(postAddress, 'post'),
      };
      if (dealerApplication.firstdirectordob) {
        combinedValues.firstdirectordob = moment(dealerApplication.firstdirectordob, 'YYYY-MM-DD', 'en-AU');
      }
      else {
        combinedValues.firstdirectordob = null;
      }
      if (combinedValues.seconddirectordob) {
        combinedValues.seconddirectordob = moment(dealerApplication.seconddirectordob, 'YYYY-MM-DD', 'en-AU');
      }
      else {
        combinedValues.seconddirectordob = null;
      }

      form.setFieldsValue(combinedValues);      
    }
  }, [dealerApplication, dealerApplicationAddresses, form]);  

  const handleSubmitMainEmail = (values?: any) => {
    if (values) {
      let email = values.mainEmail ? values.mainEmail : '';
      restClient.getDealerApplication(email, '01', GetDealerApplicationCallback, {});
    }
  }

  const UpdateDealerApplicationCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage, context?: any): void {

      message.loading({ content: 'Updating dealer application...', key: 'updatable' });

      if (returnMessage.error) {
        setTimeout(() => {
          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
        }, 200);
      } else {
        setTimeout(() => {
          if (returnMessage.dealerApplication.companywebsite && returnMessage.dealerApplication.companywebsite.includes(';')) {
            const loadedRegisteredDomains: string[] = [];
            const actualCompanyWebsite = returnMessage.dealerApplication.companywebsite.split(';')[0];
            const registeredDomainsComaSeperated = returnMessage.dealerApplication.companywebsite.split(';')[1];
            loadedRegisteredDomains.push(...registeredDomainsComaSeperated.split(','));            
            setRegisteredDomains(loadedRegisteredDomains);
            returnMessage.dealerApplication.companywebsite = actualCompanyWebsite;
          }
          setDealerApplication(returnMessage.dealerApplication);
          message.success({ content: 'Dealer application updated!', key: 'updatable', duration: 2 });
        }, 200);
      }
    }
  }

  const handleChangeAddressLine1 = (value: string) => {
    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      setDealerApplicationAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses[editingAddressIndex].addressline1 = value;
        return updatedAddresses;
      });
    }
  };

  const handleChangeAddressLine2 = (value: string) => {
    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      setDealerApplicationAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses[editingAddressIndex].addressline2 = value;
        return updatedAddresses;
      });
    }
  };

  const handleChangeSuburb = (value: string) => {
    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      setDealerApplicationAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses[editingAddressIndex].suburb = value;
        return updatedAddresses;
      });
    }
  };

  const handleChangeCity = (value: string) => {
    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      setDealerApplicationAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses[editingAddressIndex].suburb = value;
        return updatedAddresses;
      });
    }
  };

  const onSelectState = (value: any) => {
    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      setDealerApplicationAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses[editingAddressIndex].state = value;
        return updatedAddresses;
      });
    }
  };

  const handleChangePostcode = (value: string) => {
    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      setDealerApplicationAddresses((prevAddresses) => {
        const updatedAddresses = [...prevAddresses];
        updatedAddresses[editingAddressIndex].postcode = value;
        return updatedAddresses;
      });
    }
  };

  const deleteAddressCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage): void {
      message.loading({ content: 'Deleting address...', key: 'updatable' });

      if (returnMessage.error) {
        setTimeout(() => {
          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
        }, 200);
      } else {
        setTimeout(() => {
          setDealerApplicationAddresses(returnMessage.addresses);
          message.success({ content: 'Address deleted!', key: 'updatable', duration: 2 });
        }, 200);
      }
    }
  }

  const handleAddressRemove = (key: number) => {
    if (dealerApplication) {
      restClient.deleteDealerApplicationAddress(dealerApplication.mainemail, dealerApplication.accounttype, dealerApplicationAddresses[key].addressid, deleteAddressCallback);
    }
  }

  const updateAddressCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
      setIsAddressBeingEdited(false);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage): void {
      message.loading({ content: 'Updating address...', key: 'updatable' });

      if (returnMessage.error) {
        setTimeout(() => {
          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
        }, 200);
      } else {
        setTimeout(() => {
          setDealerApplicationAddresses(returnMessage.addresses);
          message.success({ content: 'Address updated!', key: 'updatable', duration: 2 });
        }, 200);
      }
      setIsAddressBeingEdited(false);
    }
  }

  const handleAddressSave = () => {

    if (isAddressBeingEdited && dealerApplicationAddresses && editingAddressIndex < dealerApplicationAddresses.length) {
      restClient.updateDealerApplicationAddress(dealerApplicationAddresses[editingAddressIndex], updateAddressCallback);
    }
  }

  const addRegisteredDomain = () => {
    if (dealerApplication && newRegisteredDomain.trim() !== '' && !registeredDomains.includes(newRegisteredDomain.trim())) {
      let updatedRegisteredDomains: string[] = [...registeredDomains, newRegisteredDomain.trim()];
      setRegisteredDomains(updatedRegisteredDomains);
      setNewRegisteredDomain('');
      let updatedDealerApplication: DealerApplication = { ...dealerApplication };
      updatedDealerApplication.companywebsite = ClientHelper.nullToEmptyTrimmed(dealerApplication.companywebsite) + ';' + updatedRegisteredDomains.join(',');
      restClient.updateDealerApplication(updatedDealerApplication, UpdateDealerApplicationCallback, {});
    }
  };

  const deleteRegisteredDomain = (registeredDomain: string) => {
    if (dealerApplication) {
      const updatedRegisteredDomains = registeredDomains.filter(item => item !== registeredDomain);
      setRegisteredDomains(updatedRegisteredDomains);
      let updatedDealerApplication: DealerApplication = { ...dealerApplication };
      updatedDealerApplication.companywebsite = ClientHelper.nullToEmptyTrimmed(dealerApplication.companywebsite) + ';' + updatedRegisteredDomains.join(',');
      restClient.updateDealerApplication(updatedDealerApplication, UpdateDealerApplicationCallback, {});
    }
  };  

  const handleEntityTypeRadioChange = (e: RadioChangeEvent) => {
  };

  const handleFormValuesChange = async (changedValues: any, allValues: any) => {
    changeCount++;
    if (changeCount % 16 === 0) {
      updateServerFromForm(false, allValues);
    }
  };  

  const doNothingAddressCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage): void {
      if (returnMessage.error) {
        alert(returnMessage.error);
      }
    }
  }   

  const finalUpdateAddressCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage): void {
      if (returnMessage.error) {
        alert(returnMessage.error);
      }
      else {
        setDealerApplicationAddresses(returnMessage.addresses);
      }
    }
  } 
  
  const finalAddAddressCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage): void {
      if (returnMessage.error) {
        alert(returnMessage.error);
      }
      else {
        setDealerApplicationAddresses(returnMessage.addresses);
      }      
    }
  }
  
  const finalUpdateDealerApplicationCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void { },
    onSuccess(returnMessage: DealerApplicationMessage): void {
      if (returnMessage.error) {
        alert(returnMessage.error);
      }
      else if (returnMessage.dealerApplication.status === 'C') {
        alert("That you for taking the time to apply to become a Cellnet customer! We'll get back to you as soon as possible");
        history.push('/login');
      }
    }
  }  

  const handleFormFinish = (values?: any) => {
    updateServerFromForm(true, values);
  }

  const updateServerFromForm = (finish: boolean, values?: any) => {

    if (dealerApplication) {

      const firstDirectorAddress: DealerApplicationAddress = {
        addressid: 0,
        applicationid: dealerApplication.applicationid,
        addresstype: 'TR01',
        addressline1: values.fdaddressline1,
        addressline2: values.fdaddressline2,
        suburb: values.fdsuburb,
        city: values.fdcity,
        state: values.fdstate,
        postcode: values.fdpostcode
      };

      const secondDirectorAddress: DealerApplicationAddress = {
        addressid: 0,
        applicationid: dealerApplication.applicationid,
        addresstype: 'TR02',
        addressline1: values.sdaddressline1,
        addressline2: values.sdaddressline2,
        suburb: values.sdsuburb,
        city: values.sdcity,
        state: values.sdstate,
        postcode: values.sdpostcode
      }; 
      
      const registeredAddress: DealerApplicationAddress = {
        addressid: 0,
        applicationid: dealerApplication.applicationid,
        addresstype: 'REGD',
        addressline1: values.rgaddressline1,
        addressline2: values.rgaddressline2,
        suburb: values.rgsuburb,
        city: values.rgcity,
        state: values.rgstate,
        postcode: values.rgpostcode
      };

      const postAddress: DealerApplicationAddress = {
        addressid: 0,
        applicationid: dealerApplication.applicationid,
        addresstype: 'SHIP',
        addressline1: values.postaddressline1,
        addressline2: values.postaddressline2,
        suburb: values.postsuburb,
        city: values.postcity,
        state: values.poststate,
        postcode: values.postpostcode
      };       
      
      // Remove director address fields from values object
      const { fdaddressline1, fdaddressline2, fdsuburb, fdcity, fdstate, fdpostcode, sdaddressline1, sdaddressline2, sdsuburb, sdcity, sdstate, sdpostcode,
        rgaddressline1, rgaddressline2, rgsuburb, rgcity, rgstate, rgpostcode, postaddressline1, postaddressline2, postsuburb, postcity, poststate, postpostcode, ...dealerApplicationValues } = values;

      // Create a new instance of DealerApplication with remaining fields
      const finalDealerApplication: DealerApplication = {
        ...dealerApplicationValues,
      };
      
      if (dealerApplicationValues.firstdirectordob) {
        finalDealerApplication.firstdirectordob = format(dealerApplicationValues.firstdirectordob.toDate(), 'yyyy-MM-dd', { locale: enAU });
      }
      if (dealerApplicationValues.seconddirectordob) {
        finalDealerApplication.seconddirectordob = format(dealerApplicationValues.seconddirectordob.toDate(), 'yyyy-MM-dd', { locale: enAU });
      }      

      const tr01DealerApplicationAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'TR01');
      if (tr01DealerApplicationAddress) {
        firstDirectorAddress.addressid = tr01DealerApplicationAddress.addressid;
        restClient.updateDealerApplicationAddress(firstDirectorAddress, finish ? finalUpdateAddressCallback: doNothingAddressCallback);
      }
      else if (fdaddressline1 && fdsuburb) {
        restClient.addDealerApplicationAddress(dealerApplication.mainemail, dealerApplication.accounttype, dealerApplication.applicationid, 'TR01', fdaddressline1,
        fdaddressline2 === undefined ? '' : fdaddressline2, fdsuburb, fdcity === undefined ? '' : fdcity, fdstate === undefined ? '' : fdstate, fdpostcode, finish ? finalAddAddressCallback : doNothingAddressCallback);
      }

      const tr02DealerApplicationAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'TR02');
      if (tr02DealerApplicationAddress) {
        secondDirectorAddress.addressid = tr02DealerApplicationAddress.addressid;
        restClient.updateDealerApplicationAddress(secondDirectorAddress, finish ? finalUpdateAddressCallback : doNothingAddressCallback);
      }
      else if (sdaddressline1 && sdsuburb) {
        restClient.addDealerApplicationAddress(dealerApplication.mainemail, dealerApplication.accounttype, dealerApplication.applicationid, 'TR02', sdaddressline1,
        sdaddressline2 === undefined ? '' : sdaddressline2, sdsuburb, sdcity === undefined ? '' : sdcity, sdstate === undefined ? '' : sdstate, sdpostcode, finish ? finalAddAddressCallback : doNothingAddressCallback);
      }

      const rgApplicationAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'REGD');
      if (rgApplicationAddress) {
        registeredAddress.addressid = rgApplicationAddress.addressid;
        restClient.updateDealerApplicationAddress(registeredAddress, finish ? finalUpdateAddressCallback : doNothingAddressCallback);
      }
      else if (rgaddressline1 && rgsuburb) {
        restClient.addDealerApplicationAddress(dealerApplication.mainemail, dealerApplication.accounttype, dealerApplication.applicationid, 'REGD', rgaddressline1,
        rgaddressline2 === undefined ? '' : rgaddressline2, rgsuburb, rgcity === undefined ? '' : rgcity, rgstate === undefined ? '' : rgstate, rgpostcode, finish ? finalAddAddressCallback : doNothingAddressCallback);
      }

      const postalApplicationAddress: DealerApplicationAddress | undefined = dealerApplicationAddresses.find(address => address.addresstype === 'SHIP');
      if (postalApplicationAddress) {
        postAddress.addressid = postalApplicationAddress.addressid;
        restClient.updateDealerApplicationAddress(postAddress, finish ? finalUpdateAddressCallback : doNothingAddressCallback);
      }
      else if (postaddressline1 && postsuburb) {
        restClient.addDealerApplicationAddress(dealerApplication.mainemail, dealerApplication.accounttype, dealerApplication.applicationid, 'SHIP', postaddressline1,
        postaddressline2 === undefined ? '' : postaddressline2, postsuburb, postcity === undefined ? '' : postcity, poststate === undefined ? '' : poststate, postpostcode, finish ? finalAddAddressCallback : doNothingAddressCallback);
      }    

      finalDealerApplication.applicationid = dealerApplication.applicationid;
      finalDealerApplication.mainemail = dealerApplication.mainemail;
      finalDealerApplication.accounttype = dealerApplication.accounttype;
      finalDealerApplication.businessarea = dealerApplication.businessarea;
      finalDealerApplication.status = finish ? 'C' : 'I';
      finalDealerApplication.applicationdate = dealerApplication.applicationdate;
      if (registeredDomains.length > 0) {
        finalDealerApplication.companywebsite = finalDealerApplication.companywebsite + ';' + registeredDomains.join(',');
      }
      restClient.updateDealerApplication(finalDealerApplication, finalUpdateDealerApplicationCallback, {});
    }
  }  

  return (
    <Row justify='center' align='middle' style={{ backgroundColor: 'white' }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className='content-wrapper marginBottom40px' style={{ maxWidth: '800px' }}>
          <img style={{ maxWidth: '100%' }} src="https://dam.cellnet.com.au/Connect/Misc/CellnetCashApplication.jpg" />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className='content-wrapper marginBottom40px' style={{ maxWidth: '800px' }}>
          <h2 className='headerTitle textCenter marginTop20px'>Account Application</h2>
          <Paragraph>
            This form is a trade account application designed for the purchase of products within Cellnet's product portfolio. This
            application specifically caters to physical retail or business establishments. Additionally, this application encompasses the
            provision of reselling items through the applicant's nominated registered domain(s).
          </Paragraph>
          <Paragraph>
            It is imperative that you provide information about all physical locations where you anticipate receiving and selling product. It
            should be noted that any addresses falling outside the realm of the specified/approved locations could potentially be
            considered a violation of the terms and conditions.
          </Paragraph>
          <Paragraph>
            If you intend to resell products online, please ensure that you provide details regarding the registered domain(s) through which
            you plan to resell.
          </Paragraph>
          <Paragraph>
            Cellnet serves as a representative for a multitude of local and international brands, many of which have established
            agreements and contracts with Cellnet. These agreements encompass endorsed channels for the distribution of brands and
            products. Often, these agreements necessitate a distinct approval from the respective brand to gain access. Thus, possessing
            an approved account does not inherently grant unrestricted access to all brands.
          </Paragraph>
          <Paragraph>
            It is important to highlight that the tactics governing the distribution of brands may experience changes on both a worldwide and
            local level. In order for Cellnet to adjust to these changes, there might be instances where access to specific products or
            brands could be withdrawn at any given time.
          </Paragraph>
          <Title level={5}>Value &amp; Payments</Title>
          <Paragraph>
            There is no minimum order value each time you place an order and no order processing fee, however standard freight charges
            will apply to orders less than $200 ex GST. There is a minimum order value each financial year of $6000. Accounts with
            purchases less than the minimum value may risk closure of account.
          </Paragraph>
          <Paragraph>
            Payment is required prior to shipment or upon collection, unless a credit account application form has been lodged and
            approved. There may be a service fee for using credit card facilities, alternatively our internal sales team can assist with a
            secure payment option.
          </Paragraph>
          {dealerApplication == null ?
            <>
              <Form className='maxWidth300px' scrollToFirstError onFinish={(values) => handleSubmitMainEmail(values)} onValuesChange={(_, values) => {
                setValues(values)
              }}>
                <Form.Item hasFeedback key={'mainEmail'} name='mainEmail' label='' rules={[{ type: 'email', message: 'The input is not a valid email' }, { required: true, message: 'Please input your E-mail!' }]}>
                  <Input size="large" className="has-float-label" placeholder='Main Email Address' suffix={<label className="floating-label" htmlFor="name">Main Email Address</label>} />
                </Form.Item>
                <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
                  <Button htmlType="submit" loading={false} type="primary" block>Submit</Button>
                </motion.div>
              </Form>
            </>
            :
            <>
              <div className='marginBottom40px marginTop40px'>
                <Row gutter={20}>
                  <Col span={24}>
                    <Title level={5}>Physical locations</Title>
                    <Row gutter={[15, 15]}>
                      {
                        dealerApplicationAddresses &&
                        dealerApplicationAddresses.map((address, key) => {
                          return address.addresstype !== 'LOCN' ? null : (
                            <Col key={key} xs={24} md={12} lg={6} xl={6}>
                              <div className='addressContainer'>
                                <div>
                                  <p>
                                    {
                                      isAddressBeingEdited && editingAddressIndex === key ? <br /> : null
                                    }
                                    <Typography.Text editable={{ editing: isAddressBeingEdited && editingAddressIndex === key, onChange: (value) => handleChangeAddressLine1(value) }}>{address.addressline1 !== 'undefined' ? address.addressline1 : ''}</Typography.Text><br />
                                    <Typography.Text editable={{ editing: isAddressBeingEdited && editingAddressIndex === key, onChange: (value) => handleChangeAddressLine2(value) }}>{address.addressline2 !== 'undefined' ? address.addressline2 : ''}</Typography.Text><br />
                                    <Typography.Text editable={{ editing: isAddressBeingEdited && editingAddressIndex === key, onChange: (value) => handleChangeSuburb(value) }}>{address.suburb}</Typography.Text><br />
                                    {
                                      dealerApplication.businessarea.includes('NZ') ?
                                        (
                                          <>
                                            <Typography.Text editable={{ editing: isAddressBeingEdited && editingAddressIndex === key, onChange: (value) => handleChangeCity(value) }}>{address.city !== 'undefined' ? address.city : ''}</Typography.Text><br />
                                          </>
                                        )
                                        :
                                        (
                                          isAddressBeingEdited && editingAddressIndex === key ? <Select size="middle" onChange={onSelectState} defaultValue={address.state} options={optionStates} /> :
                                            <Typography.Text editable={{ editing: false }} className='marginRight10px'>{address.state}</Typography.Text>
                                        )
                                    }
                                    <br />
                                    <Typography.Text editable={{ editing: isAddressBeingEdited && editingAddressIndex === key, onChange: (value) => handleChangePostcode(value) }}>{address.postcode}</Typography.Text> <br />
                                  </p>
                                </div>

                                <div>
                                  <Button type='text' className='linkUnderline' onClick={() => { setIsAddressBeingEdited(!isAddressBeingEdited); setEditingAddressIndex(key) }}>
                                    {isAddressBeingEdited && editingAddressIndex === key ? 'Exit Edit Mode' : 'Edit'}
                                  </Button>
                                  <Button type='text' disabled={dealerApplicationAddresses.length > 1 ? false : true} className='linkUnderline marginLeft20px' onClick={() => handleAddressRemove(key)}>Remove</Button>
                                  {
                                    (isAddressBeingEdited && editingAddressIndex === key) && <><br /><Button type='text' className='linkUnderline' onClick={() => { handleAddressSave() }}>
                                      Submit Change
                                    </Button><br /></>
                                  }

                                </div>
                              </div>
                            </Col>
                          )
                        })
                      }

                      <Col xs={24} md={12} lg={6} xl={6}>
                        <Button type="dashed" block className='addNewAddress' onClick={() => setAddNewAddressVisible(true)}>
                          <PlusOutlined /><br />
                          <strong>Add New</strong>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className='marginBottom40px marginTop40px'>
                <Row gutter={20}>
                  <Col span={24}>
                    <Title level={5}>Registered Domain(s)</Title>
                    <div>
                      <List
                        dataSource={registeredDomains}
                        renderItem={(item) => (
                          <List.Item
                            actions={[
                              <Button
                                type="text"
                                onClick={() => deleteRegisteredDomain(item)}
                                icon={<DeleteOutlined />}
                              />
                            ]}
                          >
                            {item}
                          </List.Item>
                        )}
                      />
                      <Input
                        size="large"
                        value={newRegisteredDomain}
                        onChange={(e) => setNewRegisteredDomain(e.target.value)}
                        onPressEnter={addRegisteredDomain}
                        placeholder="Enter Registered Domain"
                      />
                      <Button type="primary" onClick={addRegisteredDomain}>Add Registered Domain</Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <Form form={form} scrollToFirstError onFinish={handleFormFinish} onValuesChange={handleFormValuesChange}>
                <Title level={5}>Business Details</Title>
                <Form.Item className="group-floating-label" label="Entity Type" name="entitytype" rules={[{ required: true, message: 'Please select an option!' }]}>
                  <Radio.Group onChange={handleEntityTypeRadioChange}>
                    <Radio value={'Sole Trader'}>Sole Trader</Radio>
                    <Radio value={'Partnership'}>Partnership</Radio>
                    <Radio value={'Private Company'}>Private Company</Radio>
                    <Radio value={'Public Company'}>Public Company</Radio>
                    <Radio value={'Trust'}>Trust</Radio>
                  </Radio.Group>
                </Form.Item>     
                <Paragraph>Full names and addresses of sole traders/partners/directors:</Paragraph>
                <div className="numberCircle marginBottom30px">
                  <span className="numberText">1</span>
                </div>                
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'firstdirectorgivenname'} name='firstdirectorgivenname' label='' rules={[{ required: true, message: 'Please input given name!' }]}>
                        <Input size="large" className="has-float-label" placeholder='Given Name' suffix={<label className="floating-label" htmlFor="firstdirectorgivenname">Given Name</label>}/>  
                    </Form.Item>
                  </Col>               
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'firstdirectorsurname'} name='firstdirectorsurname' label='' rules={[{ required: true, message: 'Please input surname!' }]}>
                        <Input size="large" className="has-float-label" placeholder='Surname' suffix={<label className="floating-label" htmlFor="firstdirectorsurname">Surname</label>}/>  
                    </Form.Item>
                  </Col> 
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'firstdirectordob'} name='firstdirectordob' label='' rules={[{ required: true, message: 'Please input date of birth!' }]}>
                      <DatePicker size="large" format="YYYY-MM-DD" picker='date' className="has-float-label" placeholder="Date of Birth"/>
                    </Form.Item>
                  </Col>               
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'firstdirectordriverslicense'} name='firstdirectordriverslicense' label='' rules={[{ required: false, message: "Please input driver's license!" }]}>
                        <Input size="large" className="has-float-label" placeholder='Driver&#39;s License' suffix={<label className="floating-label" htmlFor="firstdirectordriverslicense">Driver&#39;s License</label>}/>  
                    </Form.Item>
                  </Col>                                 
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item className="group-floating-label" hasFeedback name='fdaddressline1' label='' rules={[{required: true, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="fdaddressline1">Address Line 1</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="group-floating-label" hasFeedback name='fdaddressline2' label='' rules={[{required: false, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="fdaddressline2">Address Line 2</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='fdpostcode' label='' rules={[{required: true, message: 'Please input postcode!' }]}> 
                        <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="fdpostcode">Postcode</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='fdsuburb' label='' rules={[{required: true, message: 'Please input suburb!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="fdsuburb">Suburb</label>}/> 
                    </Form.Item>
                  </Col>
                  {
                    dealerApplication.businessarea.includes('NZ') ?
                    (   
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item className="group-floating-label" hasFeedback name='fdcity' label='' rules={[{required: true, message: 'Please input city!' }]}> 
                            <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="fdcity">City</label>}/> 
                        </Form.Item>
                      </Col>                             
                    )
                    :
                    (
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item hasFeedback name='fdstate' label='' rules={[{required: true, message: 'Please input state!' }]}> 
                            <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                        </Form.Item>
                      </Col>
                    )
                  }
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key='firstdirectortelephone' name='firstdirectortelephone' label='' rules={[{required: true, message: 'Please input phone number' }]}>
                        <Input size="large" className="has-float-label" placeholder='Contact Number' suffix={<label className="floating-label" htmlFor="firstdirectortelephone">Contact Number</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key='firstdirectoremail' name='firstdirectoremail' label='' rules={[{required: true, message: 'Please input email' }]}>
                        <Input size="large" className="has-float-label" placeholder='Email Address' suffix={<label className="floating-label" htmlFor="firstdirectoremail">Email Address</label>}/> 
                    </Form.Item>
                  </Col>
                </Row>   
                <div className="numberCircle marginBottom30px">
                  <span className="numberText">2</span>
                </div>                
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'seconddirectorgivenname'} name='seconddirectorgivenname' label='' rules={[{ required: false, message: 'Please input given name!' }]}>
                        <Input size="large" className="has-float-label" placeholder='Given Name' suffix={<label className="floating-label" htmlFor="seconddirectorgivenname">Given Name</label>}/>  
                    </Form.Item>
                  </Col>               
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'seconddirectorsurname'} name='seconddirectorsurname' label='' rules={[{ required: false, message: 'Please input surname!' }]}>
                        <Input size="large" className="has-float-label" placeholder='Surname' suffix={<label className="floating-label" htmlFor="seconddirectorsurname">Surname</label>}/>  
                    </Form.Item>
                  </Col> 
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'seconddirectordob'} name='seconddirectordob' label='' rules={[{ required: false, message: 'Please input date of birth!' }]}>
                      <DatePicker size="large" format="YYYY-MM-DD" picker='date' className="has-float-label" placeholder="Date of Birth"/>
                    </Form.Item>
                  </Col>               
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key={'seconddirectordriverslicense'} name='seconddirectordriverslicense' label='' rules={[{ required: false, message: "Please input driver's license!" }]}>
                        <Input size="large" className="has-float-label" placeholder='Driver&#39;s License' suffix={<label className="floating-label" htmlFor="seconddirectordriverslicense">Driver&#39;s License</label>}/>  
                    </Form.Item>
                  </Col>                                 
                </Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item className="group-floating-label" hasFeedback name='sdaddressline1' label='' rules={[{required: false, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="sdaddressline1">Address Line 1</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="group-floating-label" hasFeedback name='sdaddressline2' label='' rules={[{required: false, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="sdaddressline2">Address Line 2</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='sdpostcode' label='' rules={[{required: false, message: 'Please input postcode!' }]}> 
                        <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="sdpostcode">Postcode</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='sdsuburb' label='' rules={[{required: false, message: 'Please input suburb!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="sdsuburb">Suburb</label>}/> 
                    </Form.Item>
                  </Col>
                  {
                    dealerApplication.businessarea.includes('NZ') ?
                    (   
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item className="group-floating-label" hasFeedback name='sdcity' label='' rules={[{required: false, message: 'Please input city!' }]}> 
                            <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="sdcity">City</label>}/> 
                        </Form.Item>
                      </Col>                             
                    )
                    :
                    (
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item hasFeedback name='sdstate' label='' rules={[{required: false, message: 'Please input state!' }]}> 
                            <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                        </Form.Item>
                      </Col>
                    )
                  }
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="marginBottom30px">
                    <Form.Item className="group-floating-label" hasFeedback key='seconddirectortelephone' name='seconddirectortelephone' label='' rules={[{required: false, message: 'Please input phone number' }]}>
                        <Input size="large" className="has-float-label" placeholder='Contact Number' suffix={<label className="floating-label" htmlFor="seconddirectortelephone">Contact Number</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback key='seconddirectoremail' name='seconddirectoremail' label='' rules={[{required: false, message: 'Please input email' }]}>
                        <Input size="large" className="has-float-label" placeholder='Email Address' suffix={<label className="floating-label" htmlFor="seconddirectoremail">Email Address</label>}/> 
                    </Form.Item>
                  </Col>
                </Row>          
                <Paragraph>More Details</Paragraph>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback name='tradingname' label='' rules={[{required: true, message: 'Please input trading as!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Trading As' suffix={<label className="floating-label" htmlFor="tradingname">Trading As</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item className="group-floating-label" hasFeedback name='registeredname' label='' rules={[{required: false, message: 'Please input name of registered company!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Name of Registered Company (if applicable)' suffix={<label className="floating-label" htmlFor="registeredname">Name of Registered Company (if applicable)</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback name='abn' label='' rules={[{required: true, message: 'Please input ' + (dealerApplication.businessarea.includes('NZ') ? 'Company Number' : 'ABN') + '!' }]}> 
                        <Input size="large" className="has-float-label" placeholder={dealerApplication.businessarea.includes('NZ') ? 'Company Number' : 'ABN'} suffix={<label className="floating-label" htmlFor="abn">{dealerApplication.businessarea.includes('NZ') ? 'Company Number' : 'ABN'}</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback name='acnarbn' label='' rules={[{required: true, message: 'Please input ' + (dealerApplication.businessarea.includes('NZ') ? 'GST Number' : 'ACN') + '!' }]}> 
                        <Input size="large" className="has-float-label" placeholder={dealerApplication.businessarea.includes('NZ') ? 'GST Number' : 'ACN'} suffix={<label className="floating-label" htmlFor="acnarbn">{dealerApplication.businessarea.includes('NZ') ? 'GST Number' : 'ACN'}</label>}/> 
                    </Form.Item>
                  </Col>                  
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item className="group-floating-label" hasFeedback name='segment' label='' rules={[{required: true, message: 'Please input type of business!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Type of Business' suffix={<label className="floating-label" htmlFor="segment">Type of Business</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item className="group-floating-label" hasFeedback name='yearstrading' label='' rules={[{required: true, message: 'Please input period of time company has been trading in years!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Years Company Has Been Trading' suffix={<label className="floating-label" htmlFor="yearstrading">Years Company Has Been Trading</label>}/> 
                    </Form.Item>
                  </Col>                 
                </Row> 
                <Paragraph>Registered Company Address</Paragraph>                                                                      
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item className="group-floating-label" hasFeedback name='rgaddressline1' label='' rules={[{required: true, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="rgaddressline1">Address Line 1</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item className="group-floating-label" hasFeedback name='rgaddressline2' label='' rules={[{required: false, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="rgaddressline2">Address Line 2</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='rgpostcode' label='' rules={[{required: true, message: 'Please input postcode!' }]}> 
                        <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="rgpostcode">Postcode</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='rgsuburb' label='' rules={[{required: true, message: 'Please input suburb!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="rgsuburb">Suburb</label>}/> 
                    </Form.Item>
                  </Col>
                  {
                    dealerApplication.businessarea.includes('NZ') ?
                    (   
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item className="group-floating-label" hasFeedback name='rgcity' label='' rules={[{required: true, message: 'Please input city!' }]}> 
                            <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="rgcity">City</label>}/> 
                        </Form.Item>
                      </Col>                             
                    )
                    :
                    (
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item hasFeedback name='rgstate' label='' rules={[{required: true, message: 'Please input state!' }]}> 
                            <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                        </Form.Item>
                      </Col>
                    )
                  }
                </Row>                  
                <Paragraph>Place of Business Address</Paragraph>     
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item className="group-floating-label" hasFeedback name='postaddressline1' label='' rules={[{required: true, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="postaddressline1">Address Line 1</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item className="group-floating-label" hasFeedback name='postaddressline2' label='' rules={[{required: false, message: 'Please input address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="postaddressline2">Address Line 2</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='postpostcode' label='' rules={[{required: true, message: 'Please input postcode!' }]}> 
                        <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="postpostcode">Postcode</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='postsuburb' label='' rules={[{required: true, message: 'Please input suburb!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="postsuburb">Suburb</label>}/> 
                    </Form.Item>
                  </Col>
                  {
                    dealerApplication.businessarea.includes('NZ') ?
                    (   
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item className="group-floating-label" hasFeedback name='postcity' label='' rules={[{required: true, message: 'Please input city!' }]}> 
                            <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="postcity">City</label>}/> 
                        </Form.Item>
                      </Col>                             
                    )
                    :
                    (
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item hasFeedback name='poststate' label='' rules={[{required: true, message: 'Please input state!' }]}> 
                            <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                        </Form.Item>
                      </Col>
                    )
                  }
                </Row>      
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='primarytelephone' label='' rules={[{required: true, message: 'Please input business contact phone!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Business Contact Phone' suffix={<label className="floating-label" htmlFor="primarytelephone">Business Contact Phone</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='primaryemail' label='' rules={[{required: true, message: 'Please input business email address!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Business Email Address' suffix={<label className="floating-label" htmlFor="primaryemail">Business Email Address</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='companywebsite' label='' rules={[{required: true, message: 'Please input business website!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Website' suffix={<label className="floating-label" htmlFor="companywebsite">Business Website</label>}/> 
                    </Form.Item>
                  </Col>
                </Row>
                <h2 className='headerTitle textCenter marginTop20px'>Security Administrator Nomination</h2>
                <Paragraph>
                This form is to be used for nominating a staff member within your organisation to take on the role of Cellnet Connect Security
Administrator. The individual you nominate will have full access to all areas of Cellnet Connect, including sensitive accounting
information such as invoices, orders and credit limits.<br/>
The Security Administrator role is tasked with the maintenance of Cellnet Connect User logins for your organisation so is pivotal in the
control of access to Cellnet Connect for your organisation
                </Paragraph>
                <Paragraph>
                The nominated individual should:
                <ul>
<li>Be senior enough to be able to view sensitive accounting information</li>
<li>Be available to manage your Cellnet Connect users as and when the need arises (particularly removal of non-current staff members)</li>
<li>Be unlikely to leave your firm in the short or medium term</li>
<li>Be prepared to accept responsibility for the control of Cellnet Connect access</li>
</ul>
If you choose not to nominate a Security Administrator, access to Cellnet Connect will not be available to your organisation.
                </Paragraph>
                <Title level={5}>Nominated Security Administrator</Title>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='nominatedadministratorname' label='' rules={[{required: false, message: 'Please input name!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Name' suffix={<label className="floating-label" htmlFor="nominatedadministratorname">Name</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='nominatedadministratorposition' label='' rules={[{required: false, message: 'Please input position!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Position' suffix={<label className="floating-label" htmlFor="nominatedadministratorposition">Position</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='nominatedadministratortelephone' label='' rules={[{required: false, message: 'Please input contact number!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Contact Number' suffix={<label className="floating-label" htmlFor="nominatedadministratortelephone">Contact Number</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='nominatedadministratorfacsimile' label='' rules={[{required: false, message: 'Please input fax number!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Fax Number' suffix={<label className="floating-label" htmlFor="nominatedadministratorfacsimile">Fax Number</label>}/> 
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item className="group-floating-label" hasFeedback name='nominatedadministratoremail' label='' rules={[{required: false, message: 'Please input contact email!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='Contact Email' suffix={<label className="floating-label" htmlFor="nominatedadministratoremail">Contact Email</label>}/> 
                    </Form.Item>
                  </Col>
                </Row>              
                <Title level={5}>Terms and Conditions</Title> 
                <Paragraph>
                Please read carefully.
                  <ol>
<li>As the Authorised Representative, you understand and agree to be bound by the Terms and Conditions stated herein and
those that apply to your Cellnet Group Limited account</li>
<li>As the Nominated Security Administrator, you understand and agree to be bound by the Terms and Conditions stated herein
and those that apply to your Cellnet Group Limited account</li>
<li>By nominating a Security Administrator, you agree to indemnify Cellnet Group Limited and it's agents from any losses or
damages, to the extent allowable by law, arising from unauthorised Cellnet Connect access by current or non-current staff
of your organisation using logins managed by your Security Administrator</li>
<li>You agree to indemnify Cellnet Group Limited from any losses or damages caused by the Nominated Security Administrator
leaving your organisation or otherwise becoming unavailable to manage Cellnet Connect logins in a timely manner</li>
<li>It is the responsibility of the Nominated Security Administrator to issue and revoke Cellnet Connect logins of individuals
within your organisation, including but not limited to new employees and employees that are no longer employed within your
organisation</li>
<li>The Nominated Security Administrator is responsible for the administration and security of Cellnet Connect login accounts
for their nominated company account</li>
<li>You accept that it is your responsibility to ensure that only those authorised representatives within your organisation are
given Cellnet Connect login accounts and that Cellnet Group Limited has no way of verifying the identity or authority of an
individual that has accessed Cellnet Connect with a validly issued Cellnet Connect login issued by the Nominated Security
Administrator</li>
<li>It is the responsibility of the Nominated Security Administrator to ensure that all activity undertaken while logged into
Webcell is in accordance with their company's policies and the Terms and Conditions that apply to your Cellnet Group
Limited account</li>
<li>Orders placed via Cellnet Connect will constitute a legally binding order upon Cellnet Group Limited</li>
<li>You accept that it is the responsibility of the Authorised Representative and or the Nominated Security Administrator to
immediately inform Cellnet Group Limited of any suspected fraudulent activity related to your Cellnet Group Limited
account or applicable Cellnet Connect logins</li>
<li>You acknowledge that this nominated security administrator will replace any and all existing nominated security
administrators created by Cellnet Group Limited</li>
<li>Up to date terms and conditions governing the usage can be found on the Cellnet Connect website</li>
                  </ol>
                </Paragraph> 
                <motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
                  <Button htmlType="submit" loading={false} type="primary" block>Submit</Button>
                </motion.div>
              </Form>
              <AddNewDealerApplicationAddress visible={addNewAddressVisible} setVisible={(flag) => setAddNewAddressVisible(flag)} dealerApplication={dealerApplication} setDealerApplicationAddresses={(addresses) => setDealerApplicationAddresses(addresses)} addressType={'LOCN'} />
            </>
          }
        </div>
        <LoginFooter className='marginTopAuto' />
      </Col>
    </Row>
  );
}

export default CashApplication;
