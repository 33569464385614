import React from 'react';
import {Upload, Image, Row, Col, Typography, Button, InputNumber } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import {InboxOutlined} from '@ant-design/icons';
import { CartContext } from '../../contexts/CartContext';
import { ProductContext } from '../../contexts/ProductContext';
import { MethodCallback, ServiceMainRESTClient, ShoppingCart, ShoppingCartsMessage, ProductExt } from '../../RESTAPI';
import { formatCurrency, getBreakPrice, useWindowSize } from '../../utils';
import { imageUrl, tabletSize } from '../../Constants';
import Cookies from 'universal-cookie';
import { restClient, logout } from '../../elements/PrivateRoute/PrivateRoute';
import { Link } from 'react-router-dom';
import Scanner from '../../components/Scanner';
import Result from '../../components/Result';
import _ from 'lodash';

const { Dragger } = Upload;
const { Paragraph, Title } = Typography;

const cookies = new Cookies();

class ProductCount {
  product: ProductExt;
  count: number;
  updatedTime: number;

  constructor(product: ProductExt) {
    this.product = product;
    this.count = 1;
    this.updatedTime = Date.now();
  }
}

const ScanBarcode: React.FC = () => {
  const { cartState, setShoppingCart, displayCartModal } = React.useContext(CartContext);  
  const [scanning, setScanning] = React.useState<boolean>(false);
  const [productCounts, setProductCounts] = React.useState<ProductCount[]>([]);

//        restClient.shoppingCartsFromServer(ShoppingCartCallback)

  const ShoppingCartCallback: MethodCallback<ShoppingCartsMessage> = {
    onFailure(error: string, context: any): void {
    },
  
    onProgress(loaded: number, total: number): void {},
  
    onSuccess(message: ShoppingCartsMessage, context: any): void {
        if(message.authenticated) {
            setShoppingCart(message);
        } else {
            logout()
        }
    }
  }  

  const _scan = () => {
    setScanning(!scanning);
  }

  const _onDetected = (product: ProductExt) => {
      if (scanning) {
          if (product) {
              setScanning(false);
              let newProductCounts: ProductCount[] = [...productCounts];
              let productCount = _.find(newProductCounts, (p) => {
                  return p.product.product.id === product.product.id
              })
              if(!productCount) {            
                  productCount = new ProductCount(product);
                  newProductCounts.push(productCount);
              }
              else {
                  let timeElapsed = Date.now() - productCount.updatedTime;
                  console.log(timeElapsed);
                  if (timeElapsed > 1000) {
                      productCount.count++;
                      productCount.updatedTime = Date.now();
                  }
              }
              setProductCounts(newProductCounts);
          }
      }
  }  

    const handleChange = (value:any, key:number) => {
        if(!isNaN(value) && value !== '' && value !== null && value > 0) {
            let newProductCounts: ProductCount[] = [...productCounts];
            newProductCounts[key].count = Number(value);
            setProductCounts(newProductCounts);
        }
    }

    const handleDelete = (key:number) => {
        let newProductCounts: ProductCount[] = [...productCounts];
        newProductCounts.splice(key, 1);
        setProductCounts(newProductCounts);
    }  

    const _addToCart = () => {
        if (productCounts.length > 0) {
            productCounts.map((productCount, key) => {
                restClient.updateShoppingCartProductQuantity(productCount.product.product.id, productCount.count, updateShoppingCallback);
            });
            setProductCounts([]);
        }
    }   
    
    const updateShoppingCallback: MethodCallback<ShoppingCartsMessage> = {
      onFailure(error: string, context: any): void {
        alert(error);
      },
  
      onProgress(loaded: number, total: number): void {},
  
      onSuccess(message: ShoppingCartsMessage, context: any): void {
          if(message.authenticated) {
              if (message.error) {
                  alert(message.error);
              } else {
                  setShoppingCart(message);
                  displayCartModal();
              }
          } else {
              logout()
          }
      }
    }    

  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '800px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
              <h2 className='headerTitle textCenter marginTop20px'>SCAN BARCODE TO CART</h2>
              <Paragraph className='textCenter'>
                Please click Scan and point your camera at the barcode<br/><br/>
                <Button block htmlType="button" type='primary' className='textCenter' style={{ width: '200px' }} onClick={_scan}>{scanning ? 'Stop' : 'Scan'}</Button>
              </Paragraph>
              <div className='centeredDiv'>
                  {scanning ? <Scanner onDetected={_onDetected}/> : null}                
                  <Row gutter={60}>
                      <Col span={24}>
                          {
                              (productCounts.length > 0) && productCounts.map((productCount, key) => {
                                  const currentPrice = getBreakPrice(productCount.product.priceBreakInfo.priceBreaks,productCount.count);
                                  return (
                                    <Row gutter={[30, 30]} key={key}>
                                    <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                                            {
                                                productCount.product.product.image && productCount.product.product.image !== '' ?  <Image fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + productCount.product.product.name} preview={false} src={imageUrl + (productCount.product ? productCount.product.product.image : '')}/> : ''
                                            }
                                    </Col>
                                    <Col xs={18} sm={18} md={18} lg={20} xl={20}>
                                        <Row gutter={20}>
                                            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                <h3 className='fontSize18px noMarginBottom'><strong>{productCount.product.product.name}</strong></h3>
                                                <p className='fontSize16px'>{productCount.product.product.shortName}</p>
                                                <Link to='' onClick={(e) => {e.preventDefault(); handleDelete(key)}} className='fontSize16px linkUnderline'>Delete</Link>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                                <InputNumber onChange={(value) => {handleChange(value, key)}} className='qty height50px' size="large" min={1} max={100000} value={productCount.count} />
                                            </Col>
                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} className='textAlignRight'>
                                                <p className='fontSize16px text-grayColor'>
                                                    {formatCurrency(currentPrice)} each<br/>
                                                    <strong>{formatCurrency(currentPrice * productCount.count)}</strong>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                  )
                              })
                          }
                      </Col>
                  </Row> 
                  {
                      (productCounts.length > 0) ?
                          <Paragraph className='textCenter'>
                              <Button block htmlType="button" type='primary' className='textCenter' style={{ width: '200px' }} onClick={_addToCart}>Add To Cart</Button>
                          </Paragraph>
                          : null
                  }
              </div>
          </Col>
      </Row>
      </div>
    </MainLayout>
  );
}

export default ScanBarcode;
