import React from 'react';
import { Row, Col, Layout, Divider, InputNumber, Button, message} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import { productPDFpublic, productPDFstandard, productPDFenhance, productStatus } from '../../Constants';
import MainLayout from '../../layouts/MainLayout';
import { formatCurrency, formatText, calcMargin, isFirstCharNumber, getMinOrderQty, getOrderMultiple } from '../../utils';
import ProductTabs from '../../elements/ProductTabs/ProductTabs';
import ProductListSlider from '../../components/ProductListSlider';
import ProductImageSlider from '../../components/ProductImageSlider';
import AddToCart from '../../components/AddToCart';
import FavouriteButton from '../../components/FavouriteButton';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { ProductContext } from '../../contexts/ProductContext';
import { MethodCallback, ProductExt, RecentActivityMessage, ServiceMainRESTClient } from '../../RESTAPI';
import _ from 'lodash';
import { restClient, logout } from '../../elements/PrivateRoute/PrivateRoute';
import { UserContext } from '../../contexts/UserContext';
import { CartContext } from '../../contexts/CartContext';
import InnerHTML from 'dangerously-set-html-content'

const ProductPage: React.FC = () => {
  const [qty, setQty] = React.useState<number>(1);
  const [currentProduct, setCurrentProduct] = React.useState<ProductExt>();
  const [relatedProduct, setRelatedProduct] = React.useState<ProductExt[]>([]);
  const {productId} = useParams<{productId: string}>();
  const { productState } = React.useContext(ProductContext);
  const { setRecentActivities } = React.useContext(UserContext);
  const [minOrderQty, setMinOrderQty] = React.useState<number>(1);
  const [minAddToCartQty, setMinAddToCartQty] = React.useState<number>(1);
  const [orderMultiple, setOrderMultiple] = React.useState<number>(1);
  const { cartState } = React.useContext(CartContext);
  
  const { Sider, Content } = Layout;
  const location = useLocation();

  React.useEffect(() => {
    let newMinAddToCartQty = minOrderQty;
    if(currentProduct && currentProduct.product && productState && productState.productIdToOrderMultiple && cartState && cartState.cartItems?.length > 0) {
      // find cartline with matching product
      let p = _.find(cartState.cartItems, (c) => {
        return c.productid === currentProduct.product.id
      });
      if (p && p.quantity >= newMinAddToCartQty) {
        let newOrderMultiple = getOrderMultiple(currentProduct, productState);
        newMinAddToCartQty = newOrderMultiple;
      }
    }      
    setMinAddToCartQty(newMinAddToCartQty);
  }, [minOrderQty, cartState, cartState.cartItems])  

  React.useEffect(() => {
    let newQty = qty;
    if (qty < minOrderQty) {
      newQty = minOrderQty;
    }
    if (newQty % orderMultiple !== 0) {
      newQty = (Math.floor(newQty / orderMultiple) + 1) * orderMultiple;
    }
    if (newQty !== qty) {
      setQty(newQty);
    }
  }, [minOrderQty, orderMultiple])

  React.useEffect(() => {
    if (currentProduct && currentProduct.product && productState && productState.productIdToMinOrderQty) {
      let newMinOrderQty = getMinOrderQty(currentProduct, productState);
      setMinOrderQty(newMinOrderQty);
    }
  }, [currentProduct, productState, productState.productIdToMinOrderQty])

  React.useEffect(() => {
    if (currentProduct && currentProduct.product && productState && productState.productIdToOrderMultiple) {
      let newOrderMultiple = getOrderMultiple(currentProduct, productState);
      setOrderMultiple(newOrderMultiple);
    }
  }, [currentProduct, productState, productState.productIdToOrderMultiple])

  React.useEffect(() => {
    if(productId && productId.length > 0 && productState && productState.productIdToProduct && productState.productIdToProduct.size > 0) {
        let products: Array<ProductExt> = [...productState.productIdToProduct.values()];
        let product = productState.productIdToProduct.get(productId);
        if (!product) {
            product = _.find(products, (productExt) => {
                return formatText(productExt.product.name).toLowerCase() === productId.toLowerCase()
            });
        }
        setCurrentProduct(product);

        let related = _.filter(products, (p) => {
            return p.product.itemClass === product?.product.itemClass && p.product.id != product?.product.id
        })
        if (!related || related.length == 0) {
            related = _.filter(products, (p) => {
                return p.product.procurementGroup === product?.product.procurementGroup && p.product.id != product?.product.id
            })
        }
        if (product?.vendorDevices?.length == 1) {
            let newRelated = _.filter(related, (p) => {
                return p.vendorDevices?.length == 1 && p.vendorDevices[0] === product?.vendorDevices[0];
            })
            if (newRelated && newRelated.length > 0) {
                related = newRelated;
            }
        }

        // randomize
        if (related && related.length > 1) {
            let randomizedRelated: Array<ProductExt> = new Array<ProductExt>();
            let n: number = related.length;
            for (let i: number = 0; i < n; i++) {
                let randomIndex: number = Math.floor(Math.random() * related.length);
                randomizedRelated.push(related[randomIndex]);
                related.splice(randomIndex, 1);
            }
            related = randomizedRelated;
        }

        setRelatedProduct(related);
    }
  }, [productId, productState.productIdToProduct])

  React.useEffect(() => {
    if(currentProduct) {
        const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
            onFailure(error: string): void {
                alert(error);
            },
            onProgress(loaded: number, total: number): void { },
            onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
                if (!returnMessage.authenticated) {
                    logout();
                } else {
                    setRecentActivities(returnMessage.recentActivities);
                }
            }
        }
        restClient.addActivityViewProduct(currentProduct.product.id, RecentActivityCallback)
    }
  }, [currentProduct])


  
  const handleChange = (value: string | number | undefined) => {
    if(value) {
      let origQty = +value;
      let newQty = origQty;
      if (newQty < minAddToCartQty) {
        newQty = minAddToCartQty;
      }
      let multipleRemainder = (newQty - minAddToCartQty) % orderMultiple;
      if (multipleRemainder > 0) {
        newQty += (orderMultiple - multipleRemainder);
      }
      if (origQty !== newQty) {
        message.success({ content: "Qty will be adjusted from " + origQty + " to " + newQty + " to fit min order qty " + minOrderQty + " and order multiple " + orderMultiple, key: 'updatable', duration: 5 });
      }
      setQty(newQty);
    }
  }

  if(location.pathname.includes(':productId')) {
    return  <Redirect
        to={{
            pathname: "/shop"
        }}
    />;
  }

  const productInfo = currentProduct?.attributes?.map((v, k) => {
      return (
            <div key={k}>
                <h2 className='headerTitle'>{v.name}</h2>
                <p className='productText'>
                  <InnerHTML html={v.value.replace(/(?:\r\n|\r|\n)/g, '<br />')} />
                </p>
            </div>
        )
  })

  return (
    <MainLayout>
      <ProductTabs productId={currentProduct?.product.id}/>
      <Layout className='overFlowHidden'>
        <Content>
          <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='productImageSliderWrapper'>
                <div className='onlyMobile productDetails productDetailsMobile'>
                  <h2 className='productTitle'>{currentProduct?.product.name}</h2>
                  <p className='productDesc'>{currentProduct?.product.shortName}</p>
                  <FavouriteButton id={currentProduct?.product.id} favourite={productState.favourites.includes(currentProduct?.product.id ? currentProduct?.product.id : '')} className='top10px topMinus15px'/>
                </div>
                <ProductImageSlider images={[currentProduct?.product.image, currentProduct?.product.image2, currentProduct?.product.image3]}/>
              </Col>
          </Row>
          <div className='content-wrapper marginBottom40px marginTop40px'>
            <Row>
                <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                  <div className='productMain'>    

                    <p className='productAttributeTitle'>Dealer Price</p>
                    <p className='productPriceWrapper'>
                      <strong><span className='productPrice'>{formatCurrency(currentProduct?.priceBreakInfo.priceBreaks[0].price)} ex</span>
                      { currentProduct && currentProduct.wasPrice &&
                          <span className='fontSize14px text-primaryColor' style={{position: "relative", top: "-7px", marginLeft: "10px", textDecoration: "line-through"}}>{formatCurrency(currentProduct.wasPrice)} ex</span>
                      }
                      { currentProduct && currentProduct.priceBreakInfo.priceBreaks.length > 1 &&
                        currentProduct.priceBreakInfo.priceBreaks.map((priceBreak, index) => {
                            if (index > 0) {
                                return (<><br/><span className='productPrice'>&gt;={priceBreak.quantity + ': ' + formatCurrency(priceBreak.price)} ex</span></>)
                            }
                        })
                      }
                      </strong>
                    </p>                  

                    <Divider/>
                    <p className='productAttributeTitle'>Minimum Order Qty</p>
                    <p className='productAttribute'><strong>{ minOrderQty }</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Order Multiple</p>
                    <p className='productAttribute'><strong>{ orderMultiple }</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>RRP</p>
                    <p className='productAttribute'><strong>{formatCurrency(currentProduct?.retailPrice)} inc</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Margin</p>
                    <p className='productAttribute'><strong>{currentProduct ? calcMargin(currentProduct.retailPrice, currentProduct.priceBreakInfo.priceBreaks[0].price) + '%' : 'None'}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Product Status</p>
                    <p className='productAttribute'><strong>{currentProduct?.product.statusLabel}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Cellnet ID</p>
                    <p className='productAttribute'><strong>{currentProduct?.product.id}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Barcode</p>
                    <p className='productAttribute'><strong>{currentProduct?.product.ean}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Availability</p>
                    <p className='productAttribute'>{(currentProduct && isFirstCharNumber(currentProduct.available)) ? (<>Stock On Hand <strong>{ currentProduct.available }</strong></>)
                         : (currentProduct ? currentProduct.available : '')}</p>

                    <Divider/>
                    <p className='productAttributeTitle'>Rotatable</p>
                    <p className='productAttribute'><strong>{currentProduct && currentProduct.isRotatable ? 'Yes' : 'No'}</strong></p>

                    {
                      currentProduct && (<><Button className='marginTop25px maxWidth240 noPadding' size='small' href={productPDFpublic + currentProduct?.product.id} target='_blank' type="link" icon={<DownloadOutlined />}>
                        Download Public PDF
                      </Button>
                      <Button className='marginTop20px maxWidth240 noPadding' size='small' href={productPDFstandard + currentProduct?.product.id} target='_blank' type="link" icon={<DownloadOutlined />}>
                        Download Standard PDF
                      </Button>
                      <Button className='marginTop20px maxWidth240 noPadding' size='small' href={productPDFenhance + currentProduct?.product.id} target='_blank' type="link" icon={<DownloadOutlined />}>
                        Download Enhanced PDF
                      </Button></>)
                    }
                    
                  </div>
                  <div className='onlyMobile'>
                  <Row gutter={20} className='addToCartWrapperMobile'>
                    <Col xs={8} sm={8} md={8} lg={0} xl={0}>
                      {/* <div style={{marginTop: "5px"}}>
                      <strong className='priceMobile'>{formatCurrency(currentProduct?.priceBreakInfo.priceBreaks[0].price)}</strong>
                      { currentProduct && currentProduct.wasPrice &&
                          <span className='fontSize14px text-primaryColor' style={{position: "relative", top: "-7px", marginLeft: "10px", textDecoration: "line-through"}}>{formatCurrency(currentProduct.wasPrice)}</span>
                      }
                      </div> */}
                    </Col>
                    <Col xs={16} sm={16} md={16} lg={0} xl={0}>
                      <Row gutter={10}>
                        <Col span={7}>
                          <InputNumber onChange={(value) => {handleChange(value);}} className='qty' size="large" value={qty} min={minAddToCartQty} max={100000} step={orderMultiple} />
                        </Col>
                        <Col span={17}>
                          {
                            currentProduct ? 
                              <AddToCart qty={qty} product={currentProduct}/>
                            : null
                          }
                        </Col>
                      </Row> 
                    </Col>
                  </Row>
                  </div>
                </Col>

                <Col xs={0} sm={0} md={0} lg={16} xl={16}>
                    {
                        (currentProduct && currentProduct.attributes) && (
                            <div className='productMain'>
                                {
                                    productInfo
                                }
                            </div>
                        )  
                    }
                  
                </Col>
            </Row>
          </div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='similarProductSlide'>
              <ProductListSlider bottomPosition textCenter slideToShow={4} productList={relatedProduct} title='Similar Items'/>
            </Col>
          </Row>
        </Content>
        <Sider collapsedWidth={0} className='productDetails onlyDesktop'>
          <h2 className='productTitle'>{currentProduct?.product.name}</h2>
          <p className='productDesc'>{currentProduct?.product.shortName}</p>
          <FavouriteButton id={currentProduct?.product.id} favourite={productState.favourites.includes(currentProduct?.product.id ? currentProduct?.product.id : '')} className='top10px topMinus15px'/>
          <p className='productAttributeTitle'>Dealer Price</p>
          <p className='productPriceWrapper'>
            <span className='productPrice'>{formatCurrency(currentProduct?.priceBreakInfo.priceBreaks[0].price)} ex</span>
            { currentProduct && currentProduct.wasPrice &&
                <span className='fontSize14px text-primaryColor' style={{position: "relative", top: "-7px", marginLeft: "10px", textDecoration: "line-through"}}>{formatCurrency(currentProduct.wasPrice)} ex</span>
            }
            { currentProduct && currentProduct.priceBreakInfo.priceBreaks.length > 1 &&
              currentProduct.priceBreakInfo.priceBreaks.map((priceBreak, index) => {
                  if (index > 0) {
                      return (<><br/><span className='productPrice'>&gt;={priceBreak.quantity + ': ' + formatCurrency(priceBreak.price)} ex</span></>)
                  }
              })
            }
          </p>

          <Divider/>
          <p className='productAttributeTitle'>Minimum Order Qty</p>
          <p className='productAttribute'><strong>{ minOrderQty }</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Order Multiple</p>
          <p className='productAttribute'><strong>{ orderMultiple }</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>RRP</p>
          <p className='productAttribute'><strong>{formatCurrency(currentProduct?.retailPrice)} inc</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Margin</p>
          <p className='productAttribute'><strong>{currentProduct ? calcMargin(currentProduct.retailPrice, currentProduct.priceBreakInfo.priceBreaks[0].price) + '%' : 'None'}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Product Status</p>
          <p className='productAttribute'><strong>{currentProduct?.product.statusLabel}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Cellnet ID</p>
          <p className='productAttribute'><strong>{currentProduct?.product.id}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Barcode</p>
          <p className='productAttribute'><strong>{currentProduct?.product.ean}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Availability</p>
          <p className='productAttribute'>{(currentProduct && isFirstCharNumber(currentProduct.available)) ? (<>Stock On Hand <strong>{ currentProduct.available }</strong></>)
                         : (currentProduct ? currentProduct.available : '')}</p>

          <Divider/>
          <p className='productAttributeTitle'>Rotatable</p>
          <p className='productAttribute'><strong>{currentProduct && currentProduct.isRotatable ? 'Yes' : 'No'}</strong></p>

          {
            currentProduct && <>
            <Row className='addToCartWrapper' gutter={20}>
                <Col span={7}>
                  <InputNumber onChange={(value) => {handleChange(value);}} className='qty' size="large" value={qty} min={minAddToCartQty} max={100000} step={orderMultiple} />
                </Col>
                <Col span={17}>
                  {
                    currentProduct ? 
                      <AddToCart qty={qty} product={currentProduct}/>
                    : null
                  }
                </Col>
            </Row>            
            <Button className='marginTop25px maxWidth240 overflowInitial' size='small' href={productPDFpublic + currentProduct?.product.id} target='_blank' type="primary" icon={<DownloadOutlined />}>
              Download Public PDF
            </Button><Button className='marginTop20px maxWidth240 overflowInitial' size='small' href={productPDFstandard + currentProduct?.product.id} target='_blank' type="primary" icon={<DownloadOutlined />}>
              Download Standard PDF
            </Button><Button className='marginTop20px maxWidth240 overflowInitial' size='small' href={productPDFenhance + currentProduct?.product.id} target='_blank' type="primary" icon={<DownloadOutlined />}>
              Download Enhanced PDF
            </Button><br/><br/></>
          }

        </Sider>
      </Layout>
    </MainLayout>
  );
}

export default ProductPage;