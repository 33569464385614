import React from 'react';
import { Row, Col} from 'antd';
import { PhoneOutlined, SendOutlined, ArrowRightOutlined } from '@ant-design/icons';
import MainLayout from '../../layouts/MainLayout';

const ContactUsPage: React.FC = () => {

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px' style={{maxWidth: '800px'}}>        
                <Row justify='center' align='middle' className='fullHeight overflowHidden'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='overflowHidden padding30px fullHeight flexColumn'>
                        <div>
                            <h1 className='fontSize48px noMarginBottom'><strong>AUSTRALIA</strong></h1>
                            <p className='noMarginBottom fontSize21px'>
                                E1, 5 Grevillea Place, Brisbane Airport QLD 4008
                            </p>
                            <p className='fontSize18px marginTop20px'>
                                <PhoneOutlined className='marginRight10px'/> <strong><a href="tel:1300235563">1300 235 563</a> (<a href="tel:+61 1300 235 563">+61 1300 235 563</a>)</strong><br/>
                                <SendOutlined className='marginRight10px'/> <strong><a href="mailto:info@cellnet.com.au">info@cellnet.com.au</a></strong>
                            </p>
                            <p className='marginTop20px fontSize18px'>OR</p>

                            <h1 className='fontSize48px noMarginBottom'><strong>NEW ZEALAND</strong></h1>
                            <p className='noMarginBottom fontSize21px'>
                                10a Orbit Drive, Rosedale, Auckland 0632
                            </p>
                            <p className='fontSize18px marginTop20px'>
                                <PhoneOutlined className='marginRight10px'/> <strong><a href="tel:08002355638">0800 235 5638</a> (<a href="tel:+648002355638">+64 800 235 5638</a>)</strong><br/>
                                <SendOutlined className='marginRight10px'/> <strong><a href="mailto:info@cellnet.co.nz">info@cellnet.co.nz</a></strong>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>                
    );
}

export default ContactUsPage;