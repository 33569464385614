import React from 'react';
import { AlertObj, MyUserProfile, UserState } from '../Constants';
import { AccountMessage, OrdersMessage, BackOrdersMessage, InvoicesMessage, DueInvoicesMessage, TransactionsAndPaymentsMessage, DeliveryAddressesMessage, TrackingDatasMessage, Address, Customer, DataFeedMessage, PreferencesMessage, RecentActivity, ReturnsMessage, UserAccountExt, ConnectBanner } from '../RESTAPI';
import { UserReducer } from './UserReducer';

type UserContextObject = {
    setCurrentCustomer: (payload:Customer) => void;
    setCurrentPayer: (payload:Customer) => void;
    setCurrentUserAccount: (payload:UserAccountExt) => void;
    setUserAccounts: (payload:Array<UserAccountExt>) => void;
    setCustomers: (payload:Array<Customer>) => void;
    setPayers: (payload:Array<Customer>) => void;
    setManagedUserAccounts: (payload:Array<UserAccountExt>) => void;
    setManagedUserProfiles: (payload:Array<UserAccountExt>) => void;
    setAccount: (payload:AccountMessage) => void;
    setOrders: (payload:OrdersMessage) => void;
    setBackOrders: (payload:BackOrdersMessage) => void;
    setInvoices: (payload:InvoicesMessage) => void;
    setDueInvoices: (payload:DueInvoicesMessage) => void;
    setTransactionsAndPayments: (payload:TransactionsAndPaymentsMessage) => void;
    setDeliveryAddresses: (payload:DeliveryAddressesMessage) => void;
    setTrackingDatas: (payload:TrackingDatasMessage) => void;
    setRecentActivities: (payload:Array<RecentActivity>) => void;
    setNotifications: (payload:Array<AlertObj>) => void;
    setUserProfile: (payload:MyUserProfile) => void;
    setReturns: (payload:ReturnsMessage) => void;
    setAddresses: (payload:Array<Address>) => void;
    setDatafeeds: (payload:DataFeedMessage) => void;
    setHomeContent: (payload:string) => void;
    setBanners: (payload:Array<ConnectBanner>) => void;
    setEmail: (payload: string) => void;
    setPhone: (payload: string) => void;
    setFirstName: (payload: string) => void;
    setLastName: (payload: string) => void;
    setPreferences: (payload: PreferencesMessage) => void;
    setLastPath: (payload: string) => void;
    userState: UserState
}

const initialState = {} as UserState;

export const UserContext = React.createContext<UserContextObject>({
    setCurrentCustomer: () => null,
    setCurrentPayer: () => null,
    setCurrentUserAccount: () => null,
    setUserAccounts: () => null,
    setCustomers: () => null,
    setPayers: () => null,
    setManagedUserAccounts: () => null,
    setManagedUserProfiles: () => null,
    setAccount: () => null,
    setOrders: () => null,
    setBackOrders: () => null,
    setInvoices: () => null,
    setDueInvoices: () => null,
    setTransactionsAndPayments: () => null,
    setDeliveryAddresses: () => null,
    setTrackingDatas: () => null,
    setRecentActivities: () => null,
    setNotifications: () => null,
    setUserProfile: () => null,
    setReturns: () => null,
    setAddresses: () => null,
    setDatafeeds: () => null,
    setHomeContent: () => null,
    setBanners: () => null,
    setEmail: () => null,
    setPhone: () => null,
    setFirstName: () => null,
    setLastName: () => null,
    setPreferences: () => null,
    setLastPath: () => null,
    userState: initialState
});

const UserContextProvider: React.FC = ({children}) => {

    const [userState, dispatch] = React.useReducer(UserReducer, initialState)

    const setCurrentCustomer = (payload: Customer) => {
        dispatch({type: 'SET_CURRENT_CUSTOMER', payload})
    }

    const setCurrentPayer = (payload: Customer) => {
        dispatch({type: 'SET_CURRENT_PAYER', payload})
    }

    const setCurrentUserAccount = (payload: UserAccountExt) => {
        dispatch({type: 'SET_CURRENT_USER_ACCOUNT', payload})
    }

    const setUserAccounts = (payload: Array<UserAccountExt>) => {
        dispatch({type: 'SET_USER_ACCOUNTS', payload})
    }

    const setCustomers = (payload: Array<Customer>) => {
        dispatch({type: 'SET_CUSTOMERS', payload})
    }

    const setPayers = (payload: Array<Customer>) => {
        dispatch({type: 'SET_PAYERS', payload})
    }

    const setManagedUserAccounts = (payload: Array<UserAccountExt>) => {
        dispatch({type: 'SET_MANAGED_USER_ACCOUNTS', payload})
    }

    const setManagedUserProfiles = (payload: Array<UserAccountExt>) => {
        dispatch({type: 'SET_MANAGED_USER_PROFILE', payload})
    }

    const setAccount = (payload: AccountMessage) => {
        dispatch({type: 'SET_ACCOUNT', payload})
    }

    const setOrders = (payload: OrdersMessage) => {
        dispatch({type: 'SET_ORDERS', payload})
    }
    
    const setBackOrders = (payload: BackOrdersMessage) => {
        dispatch({type: 'SET_BACK_ORDERS', payload})
    }
    
    const setInvoices = (payload: InvoicesMessage) => {
        dispatch({type: 'SET_INVOICES', payload})
    }
    
    const setDueInvoices = (payload: DueInvoicesMessage) => {
        dispatch({type: 'SET_DUE_INVOICES', payload})
    }

    const setTransactionsAndPayments = (payload: TransactionsAndPaymentsMessage) => {
        dispatch({type: 'SET_TRANSACTIONS_AND_PAYMENTS', payload})
    }    
    
    const setDeliveryAddresses = (payload: DeliveryAddressesMessage) => {
        dispatch({type: 'SET_DELIVERY_ADDRESSES', payload})
    }
    
    const setTrackingDatas = (payload: TrackingDatasMessage) => {
        dispatch({type: 'SET_TRACKING_DATAS', payload})
    }    

    const setRecentActivities = (payload: Array<RecentActivity>) => {
        dispatch({type: 'SET_RECENT_ACTIVITIES', payload})
    }

    const setNotifications = (payload: Array<AlertObj>) => {
        dispatch({type: 'SET_NOTIFICATIONS', payload})
    }

    const setReturns = (payload: ReturnsMessage) => {
        dispatch({type: 'SET_RETURNS', payload})
    }

    const setUserProfile = (payload: MyUserProfile) => {
        dispatch({type: 'SET_USER_PROFILE', payload})
    }

    const setAddresses = (payload: Array<Address>) => {
        dispatch({type: 'SET_ADDRESSES', payload})
    }

    const setDatafeeds = (payload: DataFeedMessage) => {
        dispatch({type: 'SET_DATA_FEEDS', payload})
    }

    const setHomeContent = (payload: string) => {
        dispatch({type: 'SET_HOME_CONTENT', payload})
    }

    const setBanners = (payload: Array<ConnectBanner>) => {
        dispatch({type: 'SET_BANNERS', payload})
    }

    const setFirstName = (payload: string) => {
        dispatch({ type: 'SET_FIRST_NAME', payload })
    }

    const setLastName = (payload: string) => {
        dispatch({ type: 'SET_LAST_NAME', payload })
    }    

    const setEmail = (payload: string) => {
        dispatch({ type: 'SET_EMAIL', payload })
    }

    const setPhone = (payload: string) => {
        dispatch({ type: 'SET_PHONE', payload })
    }

    const setPreferences = (payload: PreferencesMessage) => {
        dispatch({ type: 'SET_PREFERENCES', payload })
    }

    const setLastPath = (payload: string) => {
        dispatch({ type: 'SET_LAST_PATH', payload })
    }    

    const contextValues = {
        setCurrentCustomer,
        setCurrentPayer,
        setCurrentUserAccount,
        setUserAccounts,
        setCustomers,
        setPayers,
        setManagedUserAccounts,
        setManagedUserProfiles,
        setAccount,
        setOrders,
        setBackOrders,
        setInvoices,
        setDueInvoices,
        setTransactionsAndPayments,
        setDeliveryAddresses,
        setTrackingDatas,       
        setRecentActivities,
        setNotifications,
        setUserProfile,
        setReturns,
        setAddresses,
        setDatafeeds,
        setHomeContent,
        setBanners,
        setFirstName,
        setLastName,
        setEmail,
        setPhone,
        setPreferences,
        setLastPath,
        userState
    } 

    return ( 
        <UserContext.Provider value={contextValues} >
            { children }
        </UserContext.Provider>
     );
}
 
export default UserContextProvider;