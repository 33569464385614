import React from 'react';
import { Card } from 'antd';
import OrderStatusObject from '../elements/OrderStatus/OrderStatus';
import { Order, OrderLine } from '../RESTAPI';
import { Link } from 'react-router-dom';

const orderLimit = 4;

const OrderStatusCard: React.FC<{orders: Array<Order>, title: string, orderLines: {[index: string]: OrderLine[]} | undefined}> = ({orders, title, orderLines }) => {
    return (
        <>
        <h2 className='headerTitle visibleMobile textCenter'>{title}</h2>
        <div className='orderStatusCard'>
            <Card bordered={false} actions={[
                <Link to='/your-account/your-orders'><div>View All Orders</div></Link>
            ]}>
                {
                    orders && orders.map((order, key) => {
                        if(key <= orderLimit) {
                            return (
                                <OrderStatusObject order={order} key={key} orderLine={orderLines ? orderLines[order.orderno] : undefined}/>
                            )
                        } else {
                            return null
                        }
                        
                    })
                }
            </Card>
        </div>
        </>
    );
}

export default OrderStatusCard;
