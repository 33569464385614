import React from 'react';
import {Image} from 'antd';

const IconAnimate:React.FC<{custom?: boolean, onClick?: () => void, className?: string, imageSrc: string}> = ({children, custom = false, onClick, className, imageSrc}) => {
    return (
        <div className={custom ? (className ? className : '') : 'iconAnimate ' + (className ? className : '')}>
            <Image src={imageSrc} preview={false} onClick={onClick}/>
            {children}
        </div>
  );
}

export default IconAnimate;

    