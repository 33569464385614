import React from 'react';
import { Row, Col, Typography, Button, Select, message} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import {PlusOutlined} from '@ant-design/icons';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import AddNewAddress from '../../../components/AddNewAddress';
import { Address, AddressesMessage, MethodCallback, ServiceMainRESTClient } from '../../../RESTAPI';
import { optionStates } from '../../../Constants';
import { UserContext } from '../../../contexts/UserContext';
import { restClient, logout } from '../../../elements/PrivateRoute/PrivateRoute';

const Addresses: React.FC = () => {
    const [addressList, setAddressList] = React.useState<Array<Address>>([]);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [isAddressEdit, setIsAddressEdit] = React.useState<number>();
    const [visible, setVisible] = React.useState<boolean>(false);

    const { userState } = React.useContext(UserContext);

    React.useEffect(() => {
        if(userState.addresses && userState.addresses.length > 0) {
            setAddressList(userState.addresses);
        } /*else {
            if(userState.account) {
                setAddressList([userState.account.invoiceAddress]);
            }
        }*/
    }, [userState.addresses, userState.account])

    const handleSave = () => {
        const list = [...addressList];
        const currentKey = isAddressEdit;
        let currentAddress = {} as Address;

        for (let index = 0; index < list.length; index++) {
            if(index === currentKey) {
                currentAddress = list[index];
            }
        }

        if(currentAddress.addressId.length > 0) {
            restClient.updateAddress(parseInt(currentAddress.addressId), currentAddress.addressName, currentAddress.companyName, currentAddress.contactName, currentAddress.lines[0], currentAddress.lines[1], currentAddress.suburb, currentAddress.city, currentAddress.state, currentAddress.postcode, updateAddressCallback);
        }
    }

    const updateAddressCallback: MethodCallback<AddressesMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(returnMessage: AddressesMessage): void {
            if(!returnMessage.authenticated) {
                logout();
            } else {
                message.loading({ content: 'Saving address...', key: 'updatable' });

                if(returnMessage.error) {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                } else {
                    setTimeout(() => {
                        message.success({ content: 'Saved address!', key: 'updatable', duration: 2 });
                        setIsEdit(false);
                        setIsAddressEdit(undefined);
                    }, 200);
                }
            }
        }
    }

    const deleteAddressCallback: MethodCallback<AddressesMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(returnMessage: AddressesMessage): void {
            if(!returnMessage.authenticated) {
                logout();
            } else {
                message.loading({ content: 'Deleting address...', key: 'updatable' });

                if(returnMessage.error) {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                } else {
                    setTimeout(() => {
                        message.success({ content: 'Deleted address!', key: 'updatable', duration: 2 });
                    }, 200);
                }
            }
        }
    }

    const handleRemove = (key: number) => {
        const list = [...addressList];
        const newList:Array<Address> = [];

        for (let index = 0; index < list.length; index++) {
            if(index !== key) {
                newList.push(list[index])
            } else {
                restClient.deleteAddress(parseInt(list[index].addressId), deleteAddressCallback)
            }
        }

        setAddressList(newList);
    }

    const changeValue = (key: keyof Address, value: string, line?: number) => {
        const list = [...addressList];
        const newList:Array<Address> = [];
        const currentKey = isAddressEdit;

        for (let index = 0; index < list.length; index++) {
            if(index === currentKey) {
                if(key !== 'lines') {
                    list[index][key] =  value;
                } else {
                    if(line) {
                        list[index][key][line - 1] =  value;
                    }
                }
                
            }

            newList.push(list[index])
        }

        return newList;
    }

    const handleChangeContact = (value: string) => {
        setAddressList(changeValue('contactName',value));
    }

    const handleChangeCompany = (value: string) => {
        setAddressList(changeValue('companyName',value));
    }

    /*const handleChangeType = (value: string) => {
        const list = [...addressList];
        const newList:Array<Address> = [];
        const currentKey = isAddressEdit;

        for (let index = 0; index < list.length; index++) {
            if(index === currentKey) {
                //list[index].type =  value;
            }

            newList.push(list[index])
        }

        setAddressList(newList);
    }*/

    const handleChangeSuburb = (value: string) => {
        setAddressList(changeValue('suburb',value));
    }

    const handleChangeCity = (value: string) => {
        setAddressList(changeValue('city',value));
    }

    const handleChangeState = (value: string) => {
        setAddressList(changeValue('state',value));
    }

    const onSelectState = (value: any) => {
        setAddressList(changeValue('state',value));
    }    

    const handleChangePostcode = (value: string) => {
        setAddressList(changeValue('postcode',value));
    }

    const handleChangePhone = (value: string) => {
        setAddressList(changeValue('contactPhone',value));
    }

    const handleChangeName = (value: string) => {
        setAddressList(changeValue('addressName',value));
    }

    const handleChangeAddressLine1 = (value: string) => {
        setAddressList(changeValue('lines',value, 1));
    }

    const handleChangeAddressLine2 = (value: string) => {
        setAddressList(changeValue('lines',value, 2));
    }

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20}>
                    <Col span={24}>
                        <h2 className='headerTitle'>Addresses</h2>
                        <MobileAccountMenu site='Addresses'/>
                        <Row gutter={[15, 15]}>
                            {
                                addressList.map((address, key) => {
                                    return (
                                        <Col key={key} xs={24} md={12} lg={6} xl={6}>
                                            <div className='addressContainer'>
                                                <div>
                                                {/*<p className='noMarginBottom'><Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeType(value)}} className='text-grayColor'>{address.type}</Typography.Text></p>*/}
                                                    <p className='noMarginBottom'><strong><Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeName(value)}}>{address.addressName}</Typography.Text></strong></p>
                                                    <p>
                                                    {
                                                        isEdit && isAddressEdit === key ? <br/> : null
                                                    }                                                        
                                                    <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeContact(value)}}>{address.contactName}</Typography.Text><br/>
                                                    <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeCompany(value)}}>{address.companyName !== 'undefined' ? address.companyName : ''}</Typography.Text><br/>
                                                    <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeAddressLine1(value)}}>{address.lines[0]}</Typography.Text><br/>
                                                    <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeAddressLine2(value)}}>{address.lines[1] !== 'undefined' ? address.lines[1] : ''}</Typography.Text><br/>
                                                    <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeSuburb(value)}}>{address.suburb}</Typography.Text><br/> 
                                                    {
                                                        userState.currentCustomer.country.includes('NZ') ?
                                                        (
                                                            <>
                                                                <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangeCity(value)}}>{address.city !== 'undefined' ? address.city : ''}</Typography.Text><br/> 
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            isEdit && isAddressEdit === key ? <Select size="middle" onChange={onSelectState} defaultValue={address.state} options={optionStates}/> :
                                                            <Typography.Text editable={{editing: false}} className='marginRight10px'>{address.state}</Typography.Text>
                                                        )
                                                    }
                                                    <br/>
                                                    <Typography.Text editable={{editing: isEdit && isAddressEdit === key, onChange: (value) => handleChangePostcode(value)}}>{address.postcode}</Typography.Text> <br/>
                                                    </p>
                                                </div>

                                                <div>
                                                    <Button type='text' className='linkUnderline' onClick={() => {setIsEdit(!isEdit); setIsAddressEdit(key)}}>
                                                        {isEdit && isAddressEdit === key ? 'Exit Edit Mode':'Edit'}
                                                    </Button>
                                                    <Button type='text' disabled={addressList.length > 1 ? false : true} className='linkUnderline marginLeft20px' onClick={() => handleRemove(key)}>Remove</Button>
                                                    {
                                                        (isEdit && isAddressEdit === key) && <><br/><Button type='text' className='linkUnderline' onClick={() => {handleSave()}}>
                                                            Submit Change
                                                        </Button><br/></>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                            <Col xs={24} md={12} lg={6} xl={6}>
                                <Button type="dashed" block className='addNewAddress' onClick={() => setVisible(true)}>
                                    <PlusOutlined /><br/>
                                    <strong>Add New</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <AddNewAddress visible={visible} setVisible={(flag) => setVisible(flag)}/>
        </MainLayout>
    );
}

export default Addresses;
