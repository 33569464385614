import React from 'react';
import { Row, Col } from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { UserContext } from '../../../contexts/UserContext';
import { fileDownload } from '../../../Constants';

const DownloadFiles: React.FC = () => {
    const {userState} = React.useContext(UserContext);
    
    return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px marginTop40px'> 
            <MobileAccountMenu site='Manage Users'/>
            <div className='accountSection fontSize16px mobileMarginBottom20px'>
                <Row gutter={30}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='paddingBottom20px'>
                        <h4 className='displayInlineBlock'><strong>AVAILABLE FILES</strong></h4>
                        {
                            userState.account ? (<div>
                                {
                                    userState.account.fileDetails?.map((v, k) => {
                                        return (<>
                                            <a href={fileDownload + v.name} key={k} target='_blank'  rel="noreferrer">
                                                {v.name}
                                            </a><br/></>
                                        )
                                    })
                                }
                            </div>) : (
                                <div>
                                    <p>There is no available files to download</p>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </div>
        </div>
    </MainLayout>
  );
}

export default DownloadFiles;
