import { Button, Drawer, Row, Col, Form, Input, Select, message} from 'antd';
import React from 'react';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';
import { DealerApplicationMessage, DealerApplication, DealerApplicationAddress, MethodCallback } from '../RESTAPI';
import { optionStates } from '../Constants';

const AddNewDealerApplicationAddress: React.FC<{visible: boolean, setVisible: (visible: boolean) => void, dealerApplication: DealerApplication, setDealerApplicationAddresses: (addresses: Array<DealerApplicationAddress>) => void, addressType: string}> = ({visible = false, setVisible, dealerApplication, setDealerApplicationAddresses, addressType}) => {

  const handleSaveAddress = (values: any) => {
    restClient.addDealerApplicationAddress(dealerApplication.mainemail, dealerApplication.accounttype, dealerApplication.applicationid, addressType, values.addressLine1,
      values.addressLine2 === undefined ? '' : values.addressLine2, values.suburb, values.city === undefined ? '' : values.city, values.state === undefined ? '' : values.state, values.postcode, addAddressCallback);
  }

  const addAddressCallback: MethodCallback<DealerApplicationMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(returnMessage: DealerApplicationMessage, context?: any): void {
      message.loading({ content: 'Adding address...', key: 'updatable' });

      if(returnMessage.error) {
          setTimeout(() => {
              message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
          }, 200);
      } else {
          setTimeout(() => {
              message.success({ content: 'Saved address!', key: 'updatable', duration: 2 });
              setDealerApplicationAddresses(returnMessage.addresses);
              setVisible(false);
          }, 200);
      }
    }
  }

    return (
        <Drawer
          title="Add Address"
          width='100%'
          height='100vh'
          placement='bottom'
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Form layout="vertical" hideRequiredMark onFinish={(values) => {handleSaveAddress(values)}}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='addressLine1' label='' rules={[{required: true, message: 'Please input your address!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Address Line 1' suffix={<label className="floating-label" htmlFor="addressLine1">Address Line 1</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item hasFeedback name='addressLine2' label='' rules={[{required: false, message: 'Please input your address!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Address Line 2' suffix={<label className="floating-label" htmlFor="addressLine2">Address Line 2</label>}/> 
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item hasFeedback name='postcode' label='' rules={[{required: true, message: 'Please input your postcode!' }]}> 
                    <Input size="large" className="has-float-label" type='number' placeholder='Postcode' suffix={<label className="floating-label" htmlFor="postcode">Postcode</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Form.Item hasFeedback name='suburb' label='' rules={[{required: true, message: 'Please input your suburb!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Suburb' suffix={<label className="floating-label" htmlFor="suburb">Suburb</label>}/> 
                </Form.Item>
              </Col>
              {
                dealerApplication.businessarea.includes('NZ') ?
                (   
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item hasFeedback name='city' label='' rules={[{required: true, message: 'Please input your city!' }]}> 
                        <Input size="large" className="has-float-label" placeholder='City' suffix={<label className="floating-label" htmlFor="city">City</label>}/> 
                    </Form.Item>
                  </Col>                             
                )
                :
                (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item hasFeedback name='state' label='' rules={[{required: true, message: 'Please input your state!' }]}> 
                        <Select size="large" className="has-float-label add-address-state" placeholder='State' options={optionStates}/> 
                    </Form.Item>
                  </Col>
                )
              }
            </Row>
            <div className='footerBtnDrawer form'>
                <Button htmlType='submit' type="primary">
                    Done
                </Button>
                <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
            </div>
          </Form>
        </Drawer>
    );
}

export default AddNewDealerApplicationAddress;
