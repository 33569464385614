import React from 'react';
import {Row, Col, Result, Button} from 'antd';
import HomeBg from '../components/HomeBg';
import { pageVariants } from '../Constants';
import { motion } from "framer-motion";
import CellnetLogo from '../elements/Logo/Logo';
import { useHistory  } from 'react-router-dom';

function NoMatch() {
  let history = useHistory();
  return (
    <Row justify='center' align='middle' className='fullHeight overflowHidden'>
        <HomeBg/>
        <Col xs={24} sm={24} md={8} lg={8} xl={13} className='overflowHidden padding30px fullHeight verticalCenter flexColumn whiteBg'>
          <motion.div initial='initial' animate="in" exit="out" variants={pageVariants}>
            <CellnetLogo to='/' className="textCenter marginBottom40px"/>
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Button type="primary" onClick={() => history.goBack()}>Go Back</Button>}
            />,
            
          </motion.div>
        </Col>
    </Row>
  );
}

export default NoMatch;
