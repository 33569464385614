import React from 'react';
import {Layout, AutoComplete  } from 'antd';
import ProductSearchOption from '../ProductSearchOption/ProductSearchOptions';
import {LeftOutlined} from '@ant-design/icons';
import { ProductExt } from '../../RESTAPI';

const { Sider } = Layout;
const { Option } = AutoComplete;

const MobileSearch: React.FC<{handleKeyDown: (e: any) => void, searchValue: string, setOpenMobileSearch: (flag: boolean) => void, handleSearch: (value: string) => void ,openMobileSearch: boolean, result: Array<ProductExt>}> = ({handleKeyDown, setOpenMobileSearch, searchValue = '', handleSearch, openMobileSearch = false, result = []}) => {
    return (
        <Sider
            className={'mobileSearch' + (openMobileSearch === false ? '' : ' zIndex2')}
            collapsible collapsed={openMobileSearch === false ? true : false}
            collapsedWidth="0"
            width='100%'
            zeroWidthTriggerStyle={{display: 'none'}}
            >
            <LeftOutlined className='closeSearch' onClick={() => {handleSearch(''); setOpenMobileSearch(false)}}/>
            <AutoComplete onKeyDown={handleKeyDown} defaultValue={searchValue} open className='autoComplete' dropdownClassName='mobile-autoComplete-dropdown' allowClear onSearch={handleSearch} placeholder="What are you looking for?" >
                {result.map((product: ProductExt, key: number) => (
                    <Option key={key} value={product.product.name}>
                        <ProductSearchOption product={product} searchValue={searchValue}/>
                    </Option>
                ))}
            </AutoComplete>
        </Sider>
    );
}

export default MobileSearch;