import React from 'react';
import {Upload, message, Row, Col, Typography  } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import {InboxOutlined} from '@ant-design/icons';
import { CartContext } from '../../contexts/CartContext';
import { MethodCallback, ServiceMainRESTClient, ShoppingCart, ShoppingCartsMessage } from '../../RESTAPI';
import { orderByCSV } from '../../Constants';
import Cookies from 'universal-cookie';
import { DraggerProps } from 'antd/lib/upload';
import { restClient, logout } from '../../elements/PrivateRoute/PrivateRoute';

const { Dragger } = Upload;
const { Paragraph, Title } = Typography;

const cookies = new Cookies();

const OrderByCSV: React.FC = () => {
  const { cartState, setShoppingCart, loadApprovingCart } = React.useContext(CartContext);  
  const props: DraggerProps = {
    name: 'csvFile',
    action: orderByCSV,
    multiple: false,
    method: 'post',
    showUploadList: true,
    headers: {
      'Accept': 'application/json',
      'token-X': cookies.get('token')
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        restClient.shoppingCartsFromServer(ShoppingCartCallback)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const ShoppingCartCallback: MethodCallback<ShoppingCartsMessage> = {
    onFailure(error: string, context: any): void {
    },
  
    onProgress(loaded: number, total: number): void {},
  
    onSuccess(message: ShoppingCartsMessage, context: any): void {
        if(message.authenticated) {
            setShoppingCart(message);
        } else {
            logout()
        }
    }
  }  

  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '800px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
              <h2 className='headerTitle textCenter marginTop20px'>UPLOAD CSV TO CART</h2>
              <Paragraph className='textCenter'>
                Please ensure your CSV file contains the headers ProductId and Quantity.
              </Paragraph>
              <div className='whiteBg padding20px uploadContainer' style={{maxWidth: '484px', margin: '0 auto'}}>
                <Dragger {...props} listType='picture'>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Upload file or drag & drop</p>
                </Dragger>
              </div>
          </Col>
      </Row>
      </div>
    </MainLayout>
  );
}

export default OrderByCSV;
