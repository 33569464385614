import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Redirect, useLocation, useParams } from 'react-router-dom';
const Embedded: React.FC = () => {

    const {hash, height} = useParams<{hash: string, height: string}>();

    return (
      <MainLayout>
        <iframe src={'https://cdn.flipsnack.com/widget/v2/widget.html?hash=' + hash} width="100%" height={height} seamless={true} scrolling="no" frameBorder="0" allowFullScreen></iframe>
      </MainLayout>
    );
}

export default Embedded;
