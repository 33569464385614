import React from 'react';
import { Select, TreeSelect } from 'antd';

const { SHOW_PARENT } = TreeSelect;

const FilterSelect: React.FC<{setFilterDrawer?: (flag: boolean) => void, disableClick?: boolean, isTreeSelect: boolean, virtual: boolean, dropdownClassName: string, placeholder: string, selected: Array<string>, handleUpdateFilter: (value: Array<string>, type: string) => void}> = ({disableClick = false, children, virtual = true, dropdownClassName, placeholder, isTreeSelect, selected, handleUpdateFilter, setFilterDrawer}) => {
    function handleChange(value: Array<string>) {
        handleUpdateFilter(value, placeholder);
    }

    const maxTagPlaceholder = (value: string) => {
        return(<div>
            <strong>{value}</strong> ({selected.length})
        </div>)
    }

    const handleOnClick = (e: React.MouseEvent) => {
        if(disableClick) {
            e.preventDefault();
            
            if(setFilterDrawer) {
                setFilterDrawer(true);
            }
        }
    }

    if(isTreeSelect) {
        return (
            <TreeSelect treeCheckable treeDefaultExpandAll={false} disabled={disableClick} onClick={(e) => handleOnClick(e)} virtual={virtual} value={selected} dropdownClassName={dropdownClassName} multiple maxTagCount={0} maxTagPlaceholder={() => maxTagPlaceholder(placeholder)} bordered={false} className='filterSelect' onChange={handleChange} placeholder={placeholder} showArrow showCheckedStrategy={SHOW_PARENT}>
                {children}
            </TreeSelect >
        );
    } else {
        return (
            <Select disabled={disableClick} onClick={(e) => handleOnClick(e)} virtual={virtual} value={selected} dropdownClassName={dropdownClassName} mode="tags" maxTagCount={0} maxTagPlaceholder={() => maxTagPlaceholder(placeholder)} bordered={false} className='filterSelect' onChange={handleChange} tokenSeparators={[',']} placeholder={placeholder} showArrow>
                {children}
            </Select>
        );
    }
    
}

export default FilterSelect;
