import { Button, Drawer, Row, Col, Radio, Select, InputNumber, Checkbox, Divider, Modal} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { UserContext } from '../contexts/UserContext';
import { DueInvoicesMessage, TransactionsAndPaymentsMessage, AllocateInvoicePaymentsMessage, AllocationPayment, CheckCCAllocationPaymentMessage, Invoice, MethodCallback, PaymentMethodBean, Transaction, UpdatePayPalAllocationPaymentMessage } from '../RESTAPI';
import { formatCurrency, roundPlaces } from '../utils';
import _ from 'lodash';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';
import { creditCheck, invoiceDownload } from '../Constants';
import PaypalButton from './PaypalButton';

const {Option} = Select;

const paymentOptions = [
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_CREDIT.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.VISA_CREDIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_DEBIT.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.VISA_DEBIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_CREDIT.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.MASTERCARD_CREDIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_DEBIT.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.MASTERCARD_DEBIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.AMEX.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.AMEX.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.PAYPAL.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.PAYPAL.getId() }
];

interface DueInvoice extends Invoice  {
    totalDue: number,
    prepareToPay: number,
    transactions: Transaction[],
    allocatedPayment: AllocationPayment[]
}

const MakePayment: React.FC<{visible: boolean, setVisible: (visible: boolean) => void}> = ({visible = false, setVisible}) => {
    const [payment, setPayment] = React.useState<string>('');
    const [totalPay, setTotalPay] = React.useState<number>(0);
    const [checkedList, setCheckedList] = React.useState<Array<any>>([]);
    
    const [checkoutPaymentVisible, setCheckoutPaymentVisible] = React.useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = React.useState<string>('');
    const [iframeKey, setIframeKey] = React.useState<number>(1);
    const [allocationToken, setAllocationToken] = React.useState<string>('');
    const [ccPaymentId, setCCPaymentId] = React.useState<string>('');
    const [goPaypal, setGoPaypal] = React.useState<boolean>(false);
    const [payPalPayment, setPayPalPayment] = React.useState<string>('');
    const [paypalClientId, setPaypalClientId] = React.useState<string>('');

    const [adminFee, setAdminFee] = React.useState<number>(0);

    const {userState, setDueInvoices} = React.useContext(UserContext);

    const [dueInvoices, setOurDueInvoices] = React.useState<DueInvoice[]>([]);

    const [surcharge, setSurcharge] = React.useState<number>(0);
    const [discount, setDiscount] = React.useState<number>(0);
    const [totalPayment, setTotalPayment] = React.useState<number>(0);    

    const handlePaymentSelect = (e: RadioChangeEvent) => {
        setPayment(e.target.value);
        setPayPalPayment('');
        calculateTotal(undefined, undefined, e.target.value);
    }

    const onChangeCheckList = (list: CheckboxValueType[]) => {
        setCheckedList(list);
        calculateTotal(list, undefined, undefined);
    }

    const onChangeTotal = (invoiceno: number) => (value: string | number | undefined) => {
        if(value) {
            const newDueInvoices = [...dueInvoices];

            newDueInvoices.forEach((invoice) => {
                if(invoice.invoiceno === invoiceno) {
                    invoice.prepareToPay = parseInt(value.toString());
                }
            })
            setOurDueInvoices(newDueInvoices);
            calculateTotal(undefined, newDueInvoices, undefined);
        }
    }

    const calculateTotal = (checkedListWorkaround: CheckboxValueType[] | undefined, dueInvoicesWorkaround: DueInvoice[] | undefined, paymentWorkaround: string | undefined) => {
        if (checkedListWorkaround === undefined && checkedList) {
          checkedListWorkaround = checkedList;
        }
        if (dueInvoicesWorkaround === undefined && dueInvoices) {
          dueInvoicesWorkaround = dueInvoices;
        }        
        if (paymentWorkaround === undefined && payment) {
          paymentWorkaround = payment;
        }
        const allocatedPayment = [] as number[];
        const allocatedInvoiceIds = [] as string[];
        let rawTotalPayment: number = 0.0;
        if (checkedListWorkaround && dueInvoicesWorkaround) {
          dueInvoicesWorkaround.forEach((invoice) => {
              if (checkedListWorkaround?.includes(invoice.invoiceno)) {
                  allocatedPayment.push(invoice.prepareToPay);
                  allocatedInvoiceIds.push(invoice.invoiceno.toString());
                  rawTotalPayment += invoice.prepareToPay;
              }
          })
        }
        if (allocatedInvoiceIds.length > 0 && allocatedPayment.length > 0 && paymentWorkaround) {
            restClient.allocateInvoicePayments(allocatedInvoiceIds, allocatedPayment, paymentWorkaround, true, calculatePriceAllocateInvoicePaymentsCallback);
        }
        else {
          setSurcharge(0);
          setDiscount(0);
          setTotalPayment(rawTotalPayment);
          setTotalPay(rawTotalPayment);
        }
    }

    const iFrameChange = () => {
        console.log(ccPaymentId);
        if (ccPaymentId !== '' && allocationToken !== '') {
            restClient.checkCCAllocationPaymentStatus(allocationToken, ccPaymentId, checkCCPaymentStatusCallback);
        }
    }
    
    const dueInvoicesCallback: MethodCallback<DueInvoicesMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(message: DueInvoicesMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if (!message.authenticated) {
                logout();
            } else {
                // save something
                context.setDueInvoices(message);
            }
        }
    }    

    const updatePayPalAllocationPaymentCallback: MethodCallback<UpdatePayPalAllocationPaymentMessage> = {
        onFailure(error: string, context: any): void {
            alert(error);
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: UpdatePayPalAllocationPaymentMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    setPayPalPayment('');
                    setAllocationToken('');
                    setCCPaymentId('');
                    setSurcharge(0);
                    setDiscount(0);
                    setTotalPayment(0);
                    setTotalPay(0);
                    setCheckedList([]);
                    setCheckoutPaymentVisible(false);
                    setIframeSrc('');
                    setOurDueInvoices([]);
                    restClient.dueInvoicesFromServer(dueInvoicesCallback, { setDueInvoices });
                    setVisible(false);
                } else {
                    alert(message.error);
                }

            } else {
                logout()
            }
        }
    }

    const checkCCPaymentStatusCallback: MethodCallback<CheckCCAllocationPaymentMessage> = {
        onFailure(error: string, context: any): void {
            alert(error);
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: CheckCCAllocationPaymentMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    if (message.status !== 'PENDING') {
                        setPayPalPayment('');
                        setAllocationToken('');
                        setCCPaymentId('');
                        setSurcharge(0);
                        setDiscount(0);
                        setTotalPayment(0);
                        setTotalPay(0);
                        setCheckedList([]);
                        setCheckoutPaymentVisible(false);
                        setIframeSrc('');
                        setOurDueInvoices([]);
                        // updating account
                        restClient.dueInvoicesFromServer(dueInvoicesCallback, { setDueInvoices });
                        setVisible(false);                        
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
        }
    }

    const calculatePriceAllocateInvoicePaymentsCallback: MethodCallback<AllocateInvoicePaymentsMessage> = {
        onFailure(error: string, context: any): void {
            alert(error);
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: AllocateInvoicePaymentsMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    setSurcharge(message.surcharge);
                    setDiscount(message.discount);
                    setTotalPayment(message.totalPayment);
                    setTotalPay(message.totalPayment);
                } else {
                    alert(message.error);
                }
            } else {
                logout();
            }
        }
    }    

    const allocateInvoicePaymentsCallback: MethodCallback<AllocateInvoicePaymentsMessage> = {
        onFailure(error: string, context: any): void {
            alert(error);
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: AllocateInvoicePaymentsMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    setAllocationToken(message.allocationToken);
                    setSurcharge(message.surcharge);
                    setDiscount(message.discount);
                    setTotalPayment(message.totalPayment);
                    if (message.ccPaymentId != null) {
                        setCCPaymentId(message.ccPaymentId);
                        setIframeSrc(creditCheck + '?allocationToken=' + message.allocationToken);
                        setIframeKey(iframeKey + 1); // this forces and iframe update even if the src is the same
                        setCheckoutPaymentVisible(true);
                    } else if (message.payPalPayment != null) {
                        setGoPaypal(true);
                        setPayPalPayment(message.payPalPayment);
                        setPaypalClientId(message.payPalClientId);
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
        }
    }

    const handlePayment = () => {
        const allocatedPayment = [] as number[];
        const allocatedInvoiceIds = [] as string[];
        dueInvoices.forEach((invoice) => {
            if(checkedList.includes(invoice.invoiceno)) {
                allocatedPayment.push(invoice.prepareToPay);
                allocatedInvoiceIds.push(invoice.invoiceno.toString());
            }
        })
        restClient.allocateInvoicePayments(allocatedInvoiceIds, allocatedPayment, payment, false, allocateInvoicePaymentsCallback);
    }

    React.useEffect(() => {
        if(userState.dueInvoices && userState.transactionsAndPayments) {
            const dueInvoices = [] as DueInvoice[];
            userState.dueInvoices.invoices.forEach(invoice => {
                let transactionAmount = 0;
                let allocatedPaymentAmount = 0;
                let totalDue = 0;
                const transactionsLinked = userState.transactionsAndPayments.invoiceNoToTransactions[invoice.invoiceno];
                const allocationPaymentsLinked = userState.transactionsAndPayments.invoiceNoToAllocationPayments[invoice.invoiceno];

                if (transactionsLinked && transactionsLinked.length > 0) {
                    transactionsLinked.forEach(transaction => {
                        transactionAmount -= transaction.paidAmount;
                    })
                }

                if(allocationPaymentsLinked && allocationPaymentsLinked.length > 0) {
                    allocationPaymentsLinked.forEach(allocatedPayment => {
                        allocatedPaymentAmount += allocatedPayment.paidAmount;
                    })
                }
                
                // check which one is higher
                totalDue = invoice.amount - Math.max(transactionAmount, allocatedPaymentAmount);

                if(totalDue > 0) {
                    dueInvoices.unshift({
                        ...invoice,
                        totalDue: roundPlaces(totalDue, 2),
                        transactions: transactionsLinked,
                        prepareToPay: roundPlaces(totalDue, 2),
                        allocatedPayment: allocationPaymentsLinked
                    })
                }
                
            });

            setOurDueInvoices(dueInvoices);
        }
    }, [userState.dueInvoices, userState.transactionsAndPayments])

    return (
        <Drawer
          title="Make Payment"
          width='100%'
          height='100vh'
          placement='bottom'
          onClose={() => setVisible(false)}
          visible={visible}

          className='gradientBorderBackground forPayment'>
            <Row gutter={[16, 16]} className='maxHeight380px overflowYAuto'>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Checkbox.Group className='flexColumn paymentSelect' onChange={onChangeCheckList} value={checkedList}>
                    {
                        dueInvoices.map((invoice, key) => (
                            <Row gutter={[10, 10]} key={key}>
                                <Col span={18}>
                                    <Checkbox value={invoice.invoiceno}><a target='_blank' rel="noreferrer" href={invoiceDownload + invoice.invoiceno}>{invoice.invoiceno}</a> ({formatCurrency(invoice.totalDue)})</Checkbox>
                                </Col>
                                <Col span={6}>
                                    <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} disabled={!checkedList.includes(invoice.invoiceno)} min={0} max={invoice.totalDue} defaultValue={invoice.prepareToPay} onChange={onChangeTotal(invoice.invoiceno)} />
                                </Col>
                            </Row>
                        ))
                    }
                </Checkbox.Group>
              </Col>
            </Row>
            <Divider/>
            <Radio.Group
                options={paymentOptions}
                value={payment}
                className='verticalRadioGroup'
                onChange={handlePaymentSelect}
            />
            <div className='paymentBtnDrawer fontSize18px'>
                {
                    discount > 0 ?
                    <div className='textAlignLeft'>
                        <p className='text-grayColor noMarginBottom'>Discount</p>
                        <p className='noMarginBottom'>
                            <strong>{formatCurrency(discount)}</strong><br/>
                        </p>
                    </div>
                    : null 
                }
                <div className='textAlignLeft'>
                    <p className='text-grayColor noMarginBottom'>Payment Processing Fee</p>
                    <p className='noMarginBottom'>
                        <strong>{formatCurrency(surcharge)}</strong><br/>
                    </p>
                </div>
                <div className='textAlignLeft'>
                    <p className='text-grayColor noMarginBottom'>Total</p>
                    <p className='noMarginBottom'>
                        <strong>{formatCurrency(totalPayment)}</strong><br/>
                    </p>
                </div>
                <div style={{ width: '100%', minWidth: '150px', maxWidth: '750px'}}>                
                {
                    (payPalPayment === '' && totalPayment > 0.0 && payment) && <Button onClick={handlePayment} type="primary" style={{ width: '100%'}}>
                        Pay
                    </Button>
                }
                {
                    (goPaypal && payment === PaymentMethodBean.PAYPAL.getId() && payPalPayment !== '') &&
                     <PaypalButton intent='capture' clientId={paypalClientId} currency='AUD' total={payPalPayment} orderNo={allocationToken} style={{size: 'responsive', layout: 'horizontal', shape: 'rect', }} token
                        onApprove={(data) => {
//                          console.log("Hello from onApprove - data=" + JSON.stringify(data) + " actions=" + JSON.stringify(actions));
                            restClient.updatePayPalAllocationPayment(allocationToken, data.orderID, data.payerID, updatePayPalAllocationPaymentCallback);
                        }}
                        onCancel={(data) => {
//                          console.log("Hello from onCancel - data=" + JSON.stringify(data) + " actions=" + JSON.stringify(actions));
                            setGoPaypal(false);
                            setPayPalPayment('');
                            setPayment('');
                        }}
                    />
                }
                </div>
            </div>            

            <Modal
                title="Payment"
                visible={checkoutPaymentVisible}
                centered
                closable={true}
                footer={null}
                width={600}
                onCancel={() => { setCheckoutPaymentVisible(false); }}
            >
                <iframe key={iframeKey} title='Credit Card Payment' src={iframeSrc} id='paymentIFrame' frameBorder='0' onLoad={() => iFrameChange()}></iframe>
            </Modal>
        </Drawer>
    );
}

export default MakePayment;
