import React from 'react';
import { Row, Col, Divider, Image, Popover, message } from 'antd';
import { dateStringFormat, formatCurrency, getBreakPrice, totalReturnQty, totalReturnAmount, useWindowSize } from '../../utils';
import { imageUrl, returnStatus, tabletSize } from '../../Constants';
import { AnimatePresence, motion } from 'framer-motion';
import { DownOutlined } from '@ant-design/icons';
import { ReturnRequest, ReturnRequestLine } from '../../RESTAPI';
import { ProductContext } from '../../contexts/ProductContext';
import _ from 'lodash';
import { UserContext } from '../../contexts/UserContext';
import { Link } from 'react-router-dom';
import {DeleteReturnMessage, MethodCallback } from '../../RESTAPI';
import { restClient, ReturnCallback, logout } from '../../elements/PrivateRoute/PrivateRoute';

const ReturnDetails: React.FC<{returnRequest: ReturnRequest, returnLines: Array<ReturnRequestLine> | undefined}> = ({returnRequest, returnLines: returnLines}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const [address, setAddress] = React.useState<string>('');
    const [deleteVisible, setDeleteVisible] = React.useState<number>(0);
    //const [invoice, setInvoice] = React.useState<Invoice>(); // should have invoice donwload here
    //const [invoiceOpen, setInvoiceOpen] = React.useState<boolean>(false);
    const size = useWindowSize();

    const { productState } = React.useContext(ProductContext);
    const { userState, setReturns } = React.useContext(UserContext);

    React.useEffect(() => {
        if(returnRequest) {
            if(returnRequest.address1.length > 0) {
                // setting up address
                setAddress(returnRequest.address1 + ' ' + returnRequest.address2 + ' ' + returnRequest.address3 + ', ' + returnRequest.suburb + ', ' + (userState.currentCustomer.country.includes('NZ') ? returnRequest.city : returnRequest.state) + ' ' + returnRequest.postcode);
            }
        }
    }, [returnRequest])

    const handleDeleteVisible = () => {
        if(deleteVisible === returnRequest.requestno) {
            setDeleteVisible(0);
        }
    }

    const handleDeleteReturn = (requestno: number) => {
        restClient.deleteIncompleteReturnRequest(requestno, deleteReturnCallback)
    }

    const deleteReturnCallback: MethodCallback<DeleteReturnMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(returnMessage: DeleteReturnMessage, context: any): void {
            message.loading({ content: 'Deleting incomplete request...', key: 'updatable' });
            if(returnMessage.authenticated) {
                if(returnMessage.error === null || returnMessage.error === '') {
                    restClient.returnsFromServer(ReturnCallback, { setReturns });
                    setTimeout(() => {
                        message.success({ content: 'Deleted request!', key: 'updatable', duration: 2 });
                        setDeleteVisible(0);
                    }, 200);
                    
                } else {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                }
            } else {
                logout()
            }
        }
    }
    
    return (
        <div className='orderContainer'>
            <p className='noMarginBottom text-secondaryColor'><strong>{returnStatus[returnRequest.status]}</strong></p>
            <Row gutter={20}>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <p>
                        {dateStringFormat(returnRequest.registeredtime)} <br/>
                        <Link to={'/your-account/returns/' + returnRequest.requestno}><strong>{returnRequest.requestno}</strong><br/>
                        <strong>{returnRequest.customerref}</strong>
                        </Link>
                    </p>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <p>
                        Total Return <br/>
                        <p><strong>{totalReturnQty(returnLines)}</strong><br/><strong>{formatCurrency(totalReturnAmount(returnLines))}</strong></p>
                    </p>
                </Col>
                <Col xs={0} sm={0} md={16} lg={16} xl={16}>
                    <div className='flexSpaceBetween'>
                        <div style={{minWidth: '145px'}}>
                            <p>
                                Reason <br/>
                                <strong>{userState.returns.allReasonCodeToReason[returnRequest.reason] ? userState.returns.allReasonCodeToReason[returnRequest.reason].name : ''}</strong>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <DownOutlined className='animation collapseButton onlyMobile' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />
            <Divider className='onlyDesktop'/>
            <AnimatePresence initial={false}>
                {(isOpen || size.width >= tabletSize) && (
                <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    {
                        returnLines && returnLines.map((line, key) => {
                            const product = productState.productIdToProduct.get(line.itemno);

                            if(product) {
                                const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks, line.qty);
                                return (<Row gutter={15} key={key} className='alignItemCenter rowOrderLine'>
                                    <Col xs={5} sm={5} md={2} lg={2} xl={2}>
                                        <Image preview={false} src={imageUrl + product.product.image}/>
                                    </Col>
                                    <Col xs={19} sm={19} md={18} lg={18} xl={18}>
                                        <p className='noMarginBottom'><strong>{product.product.name}</strong></p>
                                        <p className='noMarginBottom'>{product.product.shortName}</p>
                                        <p className='noMarginBottom text-grayColor'><strong>{line.qty} pc @ {formatCurrency(currentPrice)}</strong></p>
                                    </Col>
                                </Row>)
                            } else {
                                return null
                            }
                            
                        })
                    }
                    {
                        returnRequest.status === 'IN' && (<div className='flexSpaceBetween'><Link to={'/your-account/edit-request/' + returnRequest.requestno}>
                            Edit Request
                        </Link>
                        <Popover
                            content={
                                <div className='flexSpaceBetween'>
                                    <a href='/' onClick={(e) => {e.preventDefault(); handleDeleteReturn(returnRequest.requestno)}}>Delete</a>
                                    <a href='/' onClick={(e) => {e.preventDefault(); setDeleteVisible(0)}}>No</a>
                                </div>
                            }
                            title="Are you sure to delete this request?"
                            trigger="click"
                            visible={deleteVisible === returnRequest.requestno}
                            onVisibleChange={handleDeleteVisible}
                        >
                            <Link to='/' onClick={(e) => {e.preventDefault();setDeleteVisible(returnRequest.requestno)}}>Delete</Link>
                        </Popover></div>)
                    }
                    
                </motion.section>)}
            </AnimatePresence>
            <div className='onlyMobile'>
                <p>
                    Reason <br/>
                    <strong>{userState.returns.allReasonCodeToReason[returnRequest.reason] ? userState.returns.allReasonCodeToReason[returnRequest.reason].name : ''}</strong>
                </p>
            </div>
            <Divider className='onlyMobile'/>
        </div>
    );
}

export default ReturnDetails;
