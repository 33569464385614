import React from 'react';
import { Modal, Row, Col, Image, Button, InputNumber, message } from 'antd';
import { CartContext } from '../contexts/CartContext';
import { formatText, formatCurrency, getBreakPrice, useWindowSize, getMinOrderQty, getOrderMultiple } from '../utils';
import { imageUrl, tabletSize } from '../Constants';
import OrderSummaryInfo from './OrderSummaryInfo';
import { ProductContext } from '../contexts/ProductContext';
import { useHistory, Link } from 'react-router-dom';
import _ from 'lodash';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';
import { CheckoutMessage, FavouritesMessage, MethodCallback, ServiceMainRESTClient, ShoppingCart, ShoppingCartsMessage, UserAccess } from '../RESTAPI';

const CartModal = () => {
    const { cartState, setShoppingCart, hideCartModal } = React.useContext(CartContext);
    const { productState, addFavouriteProductId } = React.useContext(ProductContext);
    const history = useHistory();
    const size = useWindowSize();

    const updateShoppingCallback: MethodCallback<ShoppingCartsMessage> = {
        onFailure(error: string, context: any): void {
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: ShoppingCartsMessage, context: any): void {
            if (message.authenticated) {
                setShoppingCart(message);
            } else {
                logout()
            }
        }
    }

    const AddFavouriteCallBack: MethodCallback<FavouritesMessage> = {
        onFailure(error: string, context: any): void {
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: FavouritesMessage, context: any): void {
            context.addFavouriteProductId(context.id);
        }
    }

    const handleChange = (value:any, id:string) => {
        if(!isNaN(value) && value !== '' && value !== null && value > 0) {
            let origQty = +value;
            let newQty = origQty;
            if (productState && productState.productIdToProduct) {
                const product = productState.productIdToProduct.get(id);
                let minOrderQty = 1;
                let orderMultiple = 1;
                if (product && product.product && productState) {
                    if (productState.productIdToMinOrderQty) {
                        minOrderQty = getMinOrderQty(product, productState);
                    }
                    if (productState.productIdToOrderMultiple) {
                        orderMultiple = getOrderMultiple(product, productState);
                    }
                    if (newQty < minOrderQty) {
                      newQty = minOrderQty;
                    }
                    if (newQty % orderMultiple !== 0) {
                      newQty = (Math.floor(newQty / orderMultiple) + 1) * orderMultiple;
                    }
                    if (origQty !== newQty) {
                      message.success({ content: product.product.name + " will be adjusted from " + origQty + " to " + newQty + " to fit min order qty " + minOrderQty + " and order multiple " + orderMultiple, key: 'updatable', duration: 5 });
                    }
                }
            }
            restClient.updateShoppingCartProductQuantity(id, newQty, updateShoppingCallback)
        }
    }

    const handleDelete = (id: string) => {
        restClient.updateShoppingCartProductQuantity(id, 0, updateShoppingCallback)
    }

    const handleSetFavourite = (e: React.MouseEvent) => {
        const id = e.currentTarget.getAttribute('data-id');
        if (id) {
            // reverse from false to true
            restClient.addFavouriteProductId(id, AddFavouriteCallBack, { addFavouriteProductId, id });
        }
    }

    return (
        <>
            <Modal
                title="Successfully added to your cart"
                centered
                visible={(cartState.displayModal && size.width >= tabletSize) ? cartState.displayModal : false}
                className='cartModal'
                onCancel={hideCartModal}
            >
                <Row gutter={60}>
                    <Col span={14} className='borderRightGray'>
                        {
                            (productState.productIdToProduct.size > 0 && cartState.cartItems) &&
                            cartState.cartItems.map((item, key) => {
                                const productid = item.productid;
                                const product = productState.productIdToProduct.get(productid);
                                const minOrderQty = getMinOrderQty(product, productState);
                                const orderMultiple = getOrderMultiple(product, productState);

                                if (product) {
                                    const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks, item.quantity)
                                    return (
                                        <Row gutter={[30, 30]} key={key}>
                                            <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                                                {
                                                    product.product.image && product.product.image !== '' ? <Link to={'/shop/' + formatText(product.product.name)}><Image fallback={'https://via.placeholder.com/200/FFFFFF/000000?text=' + product.product.name} preview={false} src={imageUrl + (product.product ? product.product.image : '')} /></Link> : ''
                                                }
                                            </Col>
                                            <Col xs={18} sm={18} md={18} lg={20} xl={20}>
                                                <Row gutter={20}>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <h3 className='fontSize18px noMarginBottom'><Link to={'/shop/' + formatText(product.product.name)}><strong>{product.product.name}</strong></Link></h3>
                                                        <p className='fontSize16px'>{product.product.shortName}</p>
                                                        <Link to='' onClick={(e) => { e.preventDefault(); handleDelete(product.product.id) }} className='fontSize16px linkUnderline'>Delete</Link>
                                                        {/*<Link to='' className='fontSize16px linkUnderline marginLeft20px'>Save for later</Link>*/}
                                                        {
                                                            !productState.favourites.includes(product.product.id) &&
                                                            <Link to='' data-id={product.product.id} onClick={(e) => { e.preventDefault(); handleSetFavourite(e) }} className='fontSize16px linkUnderline marginLeft20px'>Add to Favourites</Link>
                                                        }

                                                    </Col>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                                        <InputNumber onChange={(value) => { handleChange(value, product.product.id) }} className='qty height50px' size="large" min={minOrderQty} max={100000} value={item.quantity} step={orderMultiple} />
                                                    </Col>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} className='textAlignRight'>
                                                        <p className='fontSize16px text-grayColor'>
                                                            {formatCurrency(currentPrice)} each<br />
                                                            <strong>{formatCurrency(currentPrice * item.quantity)}</strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </Col>
                    <Col span={10}>
                        <h2 className='headerTitle displayInlineBlock'>YOUR CART</h2><span className='fontSize16px marginLeft20px'>{cartState.itemCount} item(s)</span>
                        <OrderSummaryInfo orderBaseTotalExGST={0} freightChargeExGST={0} adminFeeExGST={0} totalGST={0} totalIncGST={0} />

                        <Button htmlType='button' block type="primary" className='marginBottom20px' onClick={hideCartModal}>CONTINUE SHOPPING</Button>
                        <Button htmlType='button' block onClick={() => { hideCartModal(); history.push('/cart') }}>GO TO CART</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title="Successfully added to your cart"
                visible={(cartState.displayModal && size.width < tabletSize) ? cartState.displayModal : false}
                className='cartModal cartModalMobile'
                onCancel={hideCartModal}
            >
                <Row>
                    <Col span={24}>
                        <Button htmlType='button' block type="primary" className='marginBottom20px' onClick={hideCartModal}>CONTINUE SHOPPING</Button>
                        <Button htmlType='button' block onClick={() => { hideCartModal(); history.push('/cart') }}>GO TO CART</Button>
                    </Col>
                </Row>
            </Modal>
        </>

    );
};

export default CartModal;