import React from 'react';
import { Badge } from 'antd';
import IconAnimate from '../IconAnimate/IconAnimate';

const IconWithNumber: React.FC<{ icon: string, onClick?: () => void, className?: string, count?: number }> = ({ icon, onClick, className = '', count = 0}) => {
    return (
        <Badge count={count > 100 ? '99+' : count}>
            {
                onClick ? <IconAnimate imageSrc={icon} className={className + ' topMinus12px'} onClick={onClick}/> : 
                <IconAnimate imageSrc={icon} className={className + ' topMinus12px'}/>
            }
        </Badge>
        
    );
}

export default IconWithNumber;
