import React from 'react';
import PageLink from '../elements/PageLink/PageLink';

const LoginFooter: React.FC<{className?: string}> = ({className = ''}) => {
    return (
        <div className={'textCenter flexCenter ' + className}>
            {/*<PageLink to="/">Privacy Policy</PageLink>
            
            <PageLink to="/">Terms of use</PageLink>*/}
        </div>
    )
  }


export default LoginFooter;
