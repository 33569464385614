import React from 'react';
import { CartContext } from '../contexts/CartContext';
import { ProductContext } from '../contexts/ProductContext';
import { UserContext } from '../contexts/UserContext';
import { calculateGST, formatCurrency, getBreakPrice } from '../utils';
import _ from 'lodash';
import { ShoppingCartline, ProductExt } from '../RESTAPI';

export const getTotalExGST = (productIdToProduct: Map<string,ProductExt>, cartItems: Array<ShoppingCartline>) => {
    let total = 0;

    if(productIdToProduct && cartItems) {
        if(productIdToProduct.size > 0 && cartItems.length > 0) {
            cartItems.forEach(c => {
                const p = productIdToProduct.get(c.productid);
    
                if(p) {
                    const currentPrice = getBreakPrice(p.priceBreakInfo.priceBreaks, c.quantity);
                    total += currentPrice * c.quantity;
                }
            });
        }
    } 
    
    return total
}

export const getTotalIncGST = (productIdToProduct: Map<string,ProductExt>, cartItems: Array<ShoppingCartline>, businessGroup: string) => {
    let totalExGST = getTotalExGST(productIdToProduct, cartItems);

    const gst = calculateGST(totalExGST, businessGroup.startsWith('AU') ? 0.1 : 0.15);
    const total = totalExGST + gst;
        
    return total
}

const OrderSummaryInfo: React.FC<{orderBaseTotalExGST: number, freightChargeExGST: number, adminFeeExGST: number, totalGST: number, totalIncGST: number}> = ({orderBaseTotalExGST, freightChargeExGST, adminFeeExGST, totalGST, totalIncGST}) => {
    const {cartState} = React.useContext(CartContext);
    const {productState} = React.useContext(ProductContext);
    const {userState} = React.useContext(UserContext);
    const orderBase = orderBaseTotalExGST > 0.0 ? orderBaseTotalExGST : getTotalExGST(productState.productIdToProduct, cartState.cartItems);
    const gst = totalGST > 0.0 ? totalGST : userState.userProfile == undefined ? 0.0 : (calculateGST(orderBase + freightChargeExGST + adminFeeExGST, userState.userProfile.businessGroup.startsWith('AU') ? 0.1 : 0.15));
    const total = totalIncGST > 0.0 ? totalIncGST : orderBase + gst;
    return (
        <>
            {
                (productState.productIdToProduct && productState.productIdToProduct.size > 0 && productState.favourites) &&
                    cartState.cartItems?.map((item, key) => {
                        const productid = item.productid;
                        const product = productState.productIdToProduct.get(productid);

                        if(product) {
                            const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks, item.quantity);
                            return (<div className='flexSpaceBetween cartDetail'>
                              <strong>{"" + item.quantity + " x " + product.product.name + " (" + formatCurrency(currentPrice) + ")"}</strong>
                              <p>{formatCurrency(item.quantity * currentPrice)}</p>
                              </div>);
                        }
                    })
            }

            <div className='flexSpaceBetween cartDetail'>
                <strong>Freight Surcharge</strong>
                <p>{formatCurrency(freightChargeExGST)}</p>
            </div>

            <div className='flexSpaceBetween cartDetail'>
                <strong>Payment Processing Fee</strong>
                <p>{formatCurrency(adminFeeExGST)}</p>
            </div>

            <div className='flexSpaceBetween cartDetail marginBottom25px'>
                <strong>GST</strong>
                <p>{formatCurrency(gst)}</p>
            </div>

            <div className='flexSpaceBetween cartDetail marginBottom20px'>
                <p className='fontSize18px'>ORDER TOTAL</p>
                <strong className='fontSize18px'>{formatCurrency(total)}</strong>
            </div>
        </>     
    );
}

export default OrderSummaryInfo;
