import { Button, Skeleton, Row, Col, Form, Input, List, Radio, DatePicker, Checkbox, message, Divider, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { PasswordLength, UserProfileObj } from '../../../Constants';
import { removeAll } from '../../../utils';
import { UserContext } from '../../../contexts/UserContext';
import MainLayout from '../../../layouts/MainLayout'; 
import { MethodCallback, UserAccess, UserProfile, UserAccountExt, UsersMessage, Customer } from '../../../RESTAPI';
import _ from 'lodash';
import { restClient, logout } from '../../../elements/PrivateRoute/PrivateRoute';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from "moment";
import { useParams } from 'react-router-dom';
import AddNewAccount from '../../../components/AddNewAccount';
import EditUserPermissions from '../../../components/EditUserPermissions';
const { Paragraph } = Typography;

const EditUser: React.FC = () => {

    const {userState} = React.useContext(UserContext);

    const [visiblePassword, setVisiblePassword] = React.useState<boolean>(false);
    const [visibleEditUserPermissions, setVisibleEditUserPermissions] = React.useState<boolean>(false);
    const [visibleAddNewAccount, setVisibleAddNewAccount] = React.useState<boolean>(false);


    const {setManagedUserProfiles, setManagedUserAccounts} = React.useContext(UserContext);

    const [currentUserProfile, setCurrentUserProfile] = React.useState<UserProfile>();
    const [currentUserAccount, setCurrentUserAccount] = React.useState<UserAccountExt>();

    const [openPassword, setOpenPassword] = React.useState<boolean>(false)

    const [userStatus, setUserStatus] = React.useState<string>('');
    const [firstName, setUserFirstName] = React.useState<string>('');
    const [lastName, setUserLastName] = React.useState<string>('');
    const [email, setUserEmail] = React.useState<string>('');
    const [phone, setUserPhone] = React.useState<string>('');    

    const {login} = useParams<{login: string}>();

    const [ userAccountsList, setUserAccountsList] = React.useState<Array<UserAccountExt>>([]);

    const [ availableNewAccountIds, setAvailableNewAccountIds] = React.useState<Array<string>>([]);
    const [ newAccountId, setNewAccountId] = React.useState<string>('');

    const handleOpenPasswordForm = () => {
        setOpenPassword(!openPassword);
    }    

    React.useEffect(() => {
        if (userState.managedUserAccounts && userState.managedUserProfiles && userState.userProfile && login) {
            let userProfile : UserProfile | undefined = userState.managedUserProfiles.find(i => i.login === login);
            if (userProfile) {
              setCurrentUserProfile(userProfile);

              let accountIds: Set<string> = new Set<string>();
              let accounts: Array<UserAccountExt> | undefined = userState.managedUserAccounts.filter(i => i.userid === userProfile?.id);
              if (accounts) {
                  let accountIdsArray: Array<string> = accounts.map(i => i.accountid);
                  setUserAccountsList(accounts);
                  accountIds = new Set<string>(accountIdsArray);
              }
              
              let accountsWeManage: Array<UserAccountExt> | undefined = userState.managedUserAccounts.filter(i => i.userid === userState.userProfile.id && i.permissions.startsWith('A'));
              if (accountsWeManage) {
                let accountIdsWeManageArray: Array<string> = accountsWeManage.map(i => i.accountid);
                let accountIdsWeManage: Set<string> = new Set<string>(accountIdsWeManageArray);
                removeAll(accountIdsWeManage, accountIds);
                setAvailableNewAccountIds(Array.from(accountIdsWeManage).sort());
              }
            }
        }
    }, [userState.managedUserAccounts, userState.managedUserProfiles, login])

    React.useEffect(() => {
        if (currentUserProfile) {
            setUserStatus(currentUserProfile.type === 'C' ? 'Active' : 'Inactive');
            setUserFirstName(currentUserProfile.firstname);
            setUserLastName(currentUserProfile.lastname);
            setUserEmail(currentUserProfile.email);
            setUserPhone(currentUserProfile.contactphone);
        }
    }, [currentUserProfile])

    const updateUserProfile:MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(returnMessage: UsersMessage, context?: any): void {
            if(!returnMessage.authenticated) {
                logout();
            } else {
    
                message.loading({ content: 'Update user currentUserProfile...', key: 'updatable' });
    
                if(returnMessage.error) {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                } else {
                    setTimeout(() => {
                        message.success({ content: 'Updated user!', key: 'updatable', duration: 2 });
                        context.setManagedUserAccounts(returnMessage.managedUserAccounts);
                        context.setManagedUserProfiles(returnMessage.managedUserProfiles);
                        setOpenPassword(false);
                    }, 200);                    
                }
            }
        }
    }

    const handleUpdateFirstName = (value: string) => {
        let firstName: string = value.trim();
        setUserFirstName(firstName);

        if(currentUserProfile) {
            restClient.updateUserProfile(currentUserProfile.id, currentUserProfile.alias, firstName, currentUserProfile.lastname, currentUserProfile.email, currentUserProfile.contactphone, '', updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts}); // need a way to not setting password here
        }  
    }

    const handleUpdateLastName = (value: string) => {
        let lastName: string = value.trim();
        setUserLastName(lastName);

        if(currentUserProfile) {
            restClient.updateUserProfile(currentUserProfile.id, currentUserProfile.alias, currentUserProfile.firstname, lastName, currentUserProfile.email, currentUserProfile.contactphone, '', updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts}); // need a way to not setting password here
        }  
    }    

    const handleUpdateEmail = (value: string) => {
        let email: string = value.trim();
        setUserEmail(email);

        if(currentUserProfile) {
            restClient.updateUserProfile(currentUserProfile.id, currentUserProfile.alias, currentUserProfile.firstname, currentUserProfile.lastname, email, currentUserProfile.contactphone, '', updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts});
        }  
    }

    const handleUpdatePhone = (value: string) => {
        let phone: string = value.trim();
        setUserPhone(phone);

        if(currentUserProfile) {
            restClient.updateUserProfile(currentUserProfile.id, currentUserProfile.alias, currentUserProfile.firstname, currentUserProfile.lastname, currentUserProfile.email, phone, '', updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts});
        } 
    }

    const handleSavePassword = (values: any) => {
        if(currentUserProfile) {
            restClient.updateUserProfile(currentUserProfile.id, currentUserProfile.alias, currentUserProfile.firstname, currentUserProfile.lastname, currentUserProfile.email, currentUserProfile.contactphone, values.password, updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts});
        }
    }

    const handleDeactivateProfile = () => {
        if(currentUserProfile) {
            restClient.deactivateUserProfile(currentUserProfile.id, updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts})    
        }
    }    

    const handleReactivateProfile = () => {
        if(currentUserProfile) {
            let defaultEmail: string = currentUserProfile.email;
            if (defaultEmail.startsWith('inactive:')) {
              defaultEmail = defaultEmail.substring("inactive:".length);
            }
            let newEmail: string | null = prompt("Please enter the new email address", defaultEmail);
            if (newEmail) {
              restClient.reactivateUserProfile(currentUserProfile.id, newEmail, updateUserProfile, {setManagedUserProfiles, setManagedUserAccounts})    
            }
        }
    }        

    if(userState.currentUserAccount && userState.currentUserAccount.permissions.split('').includes(UserAccess.ADD_NEW_USERS)) {    
        return (
            <MainLayout>
                <div className='content-wrapper marginBottom40px marginTop40px'>
                    <h2 className='headerTitle onlyDesktop textCenter'>{"Edit " + (currentUserProfile ? currentUserProfile.login : "")}</h2>
                    <h2 className='headerTitle onlyMobile textCenter'>{"Edit " + (currentUserProfile ? currentUserProfile.login : "")}</h2>        
                    <div className='accountSection' style={{maxWidth: '775px', margin: '0 auto'}}>
                        <div style={{ float: "left"}}>
                            <p className='noMarginBottom text-grayColor'>Status</p>
                            { userStatus === 'Inactive' &&
                                <Paragraph strong><span style={{color: "red"}}>Inactive</span></Paragraph>
                            }
                            { userStatus === 'Active' &&
                                <Paragraph strong>Active</Paragraph>
                            }
                        </div>
                        <div style={{ float: "right"}}>
                            { userStatus === 'Active' &&
                                <Button onClick={() => handleDeactivateProfile()} type="primary">
                                    Deactivate User
                                </Button>
                            }
                            { userStatus === 'Inactive' &&
                                <Button onClick={() => handleReactivateProfile()} type="primary">
                                    Reactivate User
                                </Button>
                            }                  
                        </div>
                        <Divider/>

                        <p className='noMarginBottom text-grayColor'>First Name</p>
                        <Paragraph editable={{onChange: handleUpdateFirstName}} strong>{firstName}</Paragraph>
                        <Divider/>

                        <p className='noMarginBottom text-grayColor'>Last Name</p>
                        <Paragraph editable={{onChange: handleUpdateLastName}} strong>{lastName}</Paragraph>
                        <Divider/>                            

                        <p className='noMarginBottom text-grayColor'>Email</p>
                        <Paragraph editable={{onChange: handleUpdateEmail}} strong>{email}</Paragraph>
                        <Divider/>

                        <p className='noMarginBottom text-grayColor'>Phone</p>
                        <Paragraph editable={{onChange: handleUpdatePhone}} strong>{phone}</Paragraph>
                        <Divider/>

                        <p className='noMarginBottom text-grayColor'>Password</p>
                        <AnimatePresence exitBeforeEnter>
                            {
                                openPassword ? 
                                <motion.div key='open' initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0, height: 0 }
                                }}
                                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}>
                                    <Form className='marginTop20px mobileMarginBottom20px' onFinish={(values) => {handleSavePassword(values)}}>
                                        <Form.Item className="group-floating-label" hasFeedback name='password' label='' rules={[({ getFieldValue }) => ({
                                            validator(rule, value) {
                                            if (value.length >= PasswordLength) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Please input a valid password!');
                                            },
                                        })]}>
                                            <Input type={visiblePassword ? "text" : "password"} size="large" className="has-float-label" placeholder='Password' prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisiblePassword(!visiblePassword)} > {visiblePassword ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">Password</label>}/>
                                        </Form.Item>

                                        <Form.Item className="group-floating-label" dependencies={['password']} hasFeedback name='confirm_password' label='' rules={[{  required: true, message: 'Please confirm your password!' }, ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('The two passwords that you entered do not match!');
                                            },
                                        })]}>
                                            <Input type={visiblePassword ? "text" : "password"} size="large" className="has-float-label" placeholder='Confirm Password' prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisiblePassword(!visiblePassword)} > {visiblePassword ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">Confirm Password</label>}/>
                                        </Form.Item>
                                        <Button type='primary' htmlType='submit'>Save Password</Button>
                                    </Form>
                                </motion.div>
                                : <motion.div key='close' initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0}
                                }}
                                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}>
                                    <Paragraph className='noMarginBottom' editable={{
                                        onStart: handleOpenPasswordForm
                                    }} strong>••••••••••</Paragraph>
                                </motion.div>
                            }
                            
                        </AnimatePresence>

                    </div>
                    <div className='accountSection fontSize16px mobileMarginBottom20px' style={{maxWidth: '775px', margin: '0 auto'}}>
                        {/* <Form>
                            <Row gutter={20}>
                                <Col xs={16} md={16} lg={12}>
                                    <Form.Item className="group-floating-label" name='filter' label=''>
                                        <Input size="large" onChange={handleFilterChange} className="has-float-label" placeholder='Filter' suffix={<label className="floating-label" htmlFor="name">Filter</label>}/>  
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>                                   */}
                        <div className='accountSectionContainer'>
                            <List
                                className="fontSize16px"
                                loading={false}
                                itemLayout="horizontal"
                                // loadMore={loadMore}
                                dataSource={userAccountsList}
                                renderItem={item => {
                                    let userAccount = item;
                                    if (userAccount && userState && userState.customers && userState.managedUserProfiles) {
                                        let customer: Customer | undefined = userState.customers.find(i => i.id === userAccount.accountid);
                                        let managerProfile: UserProfile | undefined = userState.managedUserProfiles.find(i => i.id === userAccount.managerid);
                                        if (customer && managerProfile) {
                                            return (
                                                <List.Item
                                                    actions={[]}
                                                >
                                                    <Skeleton avatar title={false} loading={false} active>
                                                    <List.Item.Meta
                                                        title={<p className='noMarginBottom'>{userAccount.accountid} &nbsp;<strong>{customer.name}</strong> &nbsp;{'manager: '}<strong>{managerProfile.firstname + ' ' + managerProfile.lastname}</strong>
                                                        <br/><strong><span style={userAccount.status == '90' ? {color: 'red'} : {}}>{userAccount.status == '90' ? "Inactive" : "Active"}</span></strong> &nbsp;{'last login: '}<strong>{userAccount.lastlogin ? userAccount.lastlogin : 'Never'}</strong></p>}
                                                    />
                                                        <div>
                                                            <Button className='text-secondaryColor' type='link' onClick={() => {setCurrentUserAccount(userAccount); setVisibleEditUserPermissions(true)}}><strong>Edit</strong></Button>
                                                        </div>
                                                        {/*<div>
                                                            <Button className='text-secondaryColor' type='link'><strong>Remove</strong></Button>
                                                        </div>*/}
                                                    </Skeleton>
                                                </List.Item>
                                            );
                                        }
                                    }
                                    return null;
                                }}
                            />
                        </div>
                    </div>
                    { availableNewAccountIds.map(availableNewAccountId => (
                    <Row style={{maxWidth: '775px', margin: '20px auto'}}>
                        <Col span={24}>
                            <Button type="dashed" block className='addNewAddress' onClick={() => {setNewAccountId(availableNewAccountId); setVisibleAddNewAccount(true)}}>
                                <PlusOutlined /><br/>
                                <strong>Add Account {availableNewAccountId + ' ' + _.find(userState.customers, i => i.id === availableNewAccountId)?.name}</strong>
                            </Button>
                        </Col>
                    </Row>
                    ))}

                </div>
                <AddNewAccount currentUserProfile={currentUserProfile} currentUserAccountId={newAccountId} visible={visibleAddNewAccount} setVisible={(flag) => setVisibleAddNewAccount(flag)}/>
                <EditUserPermissions currentUserProfile={currentUserProfile} currentUserAccount={currentUserAccount} visible={visibleEditUserPermissions} setVisible={(flag) => setVisibleEditUserPermissions(flag)}/>
            </MainLayout>
        );
      } else {
      return <></>
    }        
}

export default EditUser;
