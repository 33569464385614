import React from 'react';
import './App.less';
import Routes from './Routes';
import CartContextProvider from './contexts/CartContext';
import ProductContextProvider from './contexts/ProductContext';
import UserContextProvider from './contexts/UserContext';
// @ts-ignore
import { withOneTabEnforcer } from 'react-one-tab-enforcer';
import OneTabComponent from './OneTabComponent';

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <CartContextProvider>
          <ProductContextProvider>
            <Routes />
          </ProductContextProvider>
        </CartContextProvider>
      </UserContextProvider>
    </div>
  );
}

export default withOneTabEnforcer({appName: "cellnet-connect", OnlyOneTabComponent: OneTabComponent})(App)
