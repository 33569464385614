import React from 'react';
import {orderPageSize} from '../Constants';
import {dateStringFormat, paginate} from '../utils';
import { ReturnRequest, ReturnRequestLine, ProductExt } from '../RESTAPI';
import _ from 'lodash';
import { UserContext } from '../contexts/UserContext';
import { ProductContext } from '../contexts/ProductContext';
import ReturnDetails from '../elements/ReturnDetails/ReturnDetails';
import { Button, Form, Row, Col, DatePicker, Input } from 'antd';

const { RangePicker } = DatePicker;

const ReturnList: React.FC<{returns: Array<ReturnRequest>}> = ({returns }) => {
    const [returnLines, setReturnLines] = React.useState<{ [index: string]: ReturnRequestLine[] }>();
    const { userState, setReturns } = React.useContext(UserContext);
    const { productState } = React.useContext(ProductContext);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [maxPage, setMaxPage] = React.useState<number>(1);
    const [currentReturns, setCurrentReturns] = React.useState<Array<ReturnRequest>>([]);
    const [currentYearReturns, setCurrentYearReturns] = React.useState<Array<ReturnRequest>>([]);

    const [years, setYears] = React.useState<Array<string>>([]);
    const [selectedYear, setSelectedYear] = React.useState<string>('');
    const [selectedDefaultYear, setDefaultYear] = React.useState<string>('');

    const [search, setSearch] = React.useState<string>('');
    const [dateRange, setDateRange] = React.useState<[string, string]>(['','']);

    React.useEffect(() => {
        if(userState.returns) {
            setReturnLines(userState.returns.lines);
        }
    }, [userState.returns])

    React.useEffect(() => {
        if(returns && returns.length > 0) {
            const current = filterList(returns);
            // getting years out of orders
            /*let temp:Array<string> = [];
            returns.forEach((r) => {
                let year = r.registeredtime.toString().substring(0, 4);;
                if(!temp.includes(year)) {
                   temp.push(year); 
                }
            })
            setYears(temp);*/
            
            let tempReturns:Array<ReturnRequest> = [...current];

            /*if(selectedYear.length > 0) {
                tempReturns = _.filter(returns, (r) => {
                    return r.registeredtime.toString().substring(0, 4) === selectedYear
                })
            } else {
                if(temp.length > 0) {
                    tempReturns = _.filter(returns, (r) => {
                        return r.registeredtime.toString().substring(0, 4) === temp[0]
                    })
                    setDefaultYear(temp[0]);
                }
            }

            setCurrentYearReturns(tempReturns);*/

            tempReturns.sort((a, b) => (a.registeredtime > b.registeredtime) ? -1 : (a.registeredtime === b.registeredtime) ? ((a.requestno > b.requestno) ? -1 : 1) : 1)

            setCurrentReturns(paginate(tempReturns, orderPageSize, 1));

            setMaxPage(Math.ceil(tempReturns.length / orderPageSize));
            setCurrentPage(1);
        } else {
            setMaxPage(1);
            setCurrentPage(1);
        }
    }, [returns, selectedYear, search, dateRange])
    
    const onClick = (e: React.MouseEvent) => {
        if(currentPage < maxPage) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);

            if(currentYearReturns.length > 0) {
                setCurrentReturns([...currentReturns, ...paginate(currentYearReturns, orderPageSize, nextPage)]);
            } else {
                setCurrentReturns([...currentReturns, ...paginate(returns, orderPageSize, nextPage)]);
            }
            
        }
    }

    const hangleOnChange = (value: string) => {
        setSelectedYear(value);
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }

    const handleDateChange = (value: any, formatString: [string, string]) => {
        setDateRange(formatString);
    }

    const filterList = (returns: Array<ReturnRequest>) => {
        return _.filter(returns, (i) => {

            if(search !== '') {
                let found: boolean = false;
                let searchLC: string = search.toLowerCase();
                if (i.requestno.toString().includes(search) || i.rano.toString().includes(search) || i.customerref.toLowerCase().includes(searchLC)) {
                    found = true;
                }
                else if (userState.returns.lines) {
                    let lines: ReturnRequestLine[] = userState.returns.lines[i.requestno];
                    if (lines) {
                        for (let line of lines) {
                            if (line.itemno.includes(searchLC)) {
                                found = true;
                                break;
                            }
                            else if (productState && productState.productIdToProduct) {
                                let product : ProductExt | undefined = productState.productIdToProduct.get(line.itemno);
                                if (product && (product.product.description.toLowerCase().includes(searchLC) ||
                                        product.product.name.toLowerCase().includes(searchLC))) {
                                    found = true;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!found) {
                    return false;
                }
            }

            if(dateRange !== ['',''] && (new Date(dateRange[0]) >= new Date(dateStringFormat(i.registeredtime, false, true)) || new Date(dateRange[1]) <= new Date(dateStringFormat(i.registeredtime, false, true)))) {
                return false
            }
            return true;
        })
    }

    return (
        <>
        {/*<OrderFilter title='Returns' total={currentYearReturns.length} years={years} handleOnChange={hangleOnChange} selectedYear={selectedYear} selectedDefaultYear={selectedDefaultYear}/>*/}
        <Form>
            <Row gutter={20}>
                <Col xs={8} md={8} lg={6}>
                    <Form.Item className="group-floating-label" name='search' label=''>
                        <Input size="large" onChange={handleSearchChange} className="has-float-label" placeholder='Search' suffix={<label className="floating-label" htmlFor="name">Search</label>}/>  
                    </Form.Item>
                </Col>

                <Col xs={16} md={16} lg={12}>
                    <div className='datePicker' style={{marginTop: 0}}>
                    <Form.Item className="group-floating-label" name='dateRange' label=''>
                        <RangePicker picker='date' className="has-float-label" style={{margin: 0}} onChange={handleDateChange}/>
                    </Form.Item>
                    </div>
                </Col>
            </Row>
        </Form>
        {
            currentReturns.map((r, key) => {
                return (
                    <ReturnDetails returnRequest={r} key={key} returnLines={returnLines ? returnLines[r.requestno] : undefined}/>
                )
            })
        }
        {
            currentPage < maxPage && 
                <div className='maxWidth300px marginTop25px'>
                    <Button ghost block onClick={onClick}>LOAD MORE</Button>
                </div>
        }
        
        </>                     
    );
}

export default ReturnList;
