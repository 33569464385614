import React from 'react';
import { Image } from 'antd';
import Paypal from '../../assets/images/PayPal.svg';
import Visa from '../../assets/images/g4158.svg';
import MasterCard from '../../assets/images/mc_symbol.svg';
import Back from '../../assets/images/back.svg';
import BackColor from '../../assets/images/backColor.svg';
import Amex from '../../assets/images/amex.png';

export const PaypalIcon = () => {
    return <Image src={Paypal} preview={false}/>
}

export const VisaIcon = () => {
    return <Image src={Visa} preview={false}/>
}

export const MasterCardIcon = () => {
    return <Image src={MasterCard} preview={false}/>
}

export const BackIcon = () => {
    return <Image src={Back} preview={false}/>
}

export const BackColorIcon = () => {
    return <Image src={BackColor} preview={false}/>
}

export const AmexIcon = () => {
    return <Image src={Amex} preview={false} style={{height: '41px', width: 'auto'}} />
}