import React from 'react';
import { Button, message } from 'antd';
import { CartContext } from '../contexts/CartContext';
import { ProductContext } from '../contexts/ProductContext';
import { MethodCallback, ProductExt, ShoppingCartsMessage } from '../RESTAPI';
import { restClient, logout } from '../elements/PrivateRoute/PrivateRoute';
import { getMinOrderQty, getOrderMultiple } from '../utils';
import _ from 'lodash';

const AddToCart: React.FC<{title?: string, qty: number, product: ProductExt | undefined, type?: any}> = ({type = 'primary', title = 'Add To Cart', qty, product}) => {
  const {cartState, setShoppingCart, displayCartModal } = React.useContext(CartContext);
  const {productState} = React.useContext(ProductContext);

  const updateShoppingCallback: MethodCallback<ShoppingCartsMessage> = {
    onFailure(error: string, context: any): void {
      alert(error);
    },

    onProgress(loaded: number, total: number): void {},

    onSuccess(message: ShoppingCartsMessage, context: any): void {
        if(message.authenticated) {
            if (message.error) {
                alert(message.error);
            } else {
                setShoppingCart(message);
                displayCartModal();
            }
        } else {
            logout()
        }
    }
  }

  const handleAddToCart = (e: React.MouseEvent) => {
    if(product) {
      // qty passed in is qty to add - we need current qty in cart for this product
      let currentQty = 0;
      if(cartState && cartState.cartItems?.length > 0) {
        // find cartline with matching product
        let p = _.find(cartState.cartItems, (c) => {
          return c.productid === product.product.id
        })

        if(p) {
          currentQty = p.quantity;
        }
      }
      let origQty = currentQty + qty;
      let newQty = origQty;
      if (productState) {
          let minOrderQty = 1;
          let orderMultiple = 1;
          if (productState.productIdToMinOrderQty) {
              minOrderQty = getMinOrderQty(product, productState);
          }
          if (productState.productIdToOrderMultiple) {
              orderMultiple = getOrderMultiple(product, productState);
          }
          if (newQty < minOrderQty) {
            newQty = minOrderQty;
          }
          if (newQty % orderMultiple !== 0) {
            newQty = (Math.floor(newQty / orderMultiple) + 1) * orderMultiple;
          }
          if (origQty !== newQty) {
            message.success({ content: product.product.name + " will be adjusted from " + origQty + " to " + newQty + " to fit min order qty " + minOrderQty + " and order multiple " + orderMultiple, key: 'updatable', duration: 5 });
          }
      }      

      restClient.updateShoppingCartProductQuantity(product.product.id, newQty, updateShoppingCallback)
    }
  }
  
  if(product) {
      return (
          <Button block htmlType="button" style={type === 'link' ? { padding: 0, height: 'auto' } : {}} type={type} onClick={(e) => handleAddToCart(e)}>{title}</Button>
      );
  } else {
      return (
          <Button block htmlType="button" style={type === 'link' ? { padding: 0, height: 'auto' } : {}} type='ghost'>Not Available</Button>
      );
  }
  
}

export default AddToCart;