import React from 'react';
import loginBg from '../assets/images/login.jpg';
import regoBg from '../assets/images/sign_up.jpg';
import forgotBg from '../assets/images/forgot_pq.jpg';
import {Col, Typography, Progress} from 'antd';
import CellnetLogo from '../elements/Logo/Logo';
import { FormStep } from '../Constants';

const { Title } = Typography;

const HomeBg: React.FC<{className?: string, isRego?: boolean, steps?: Array<FormStep>, currentStep?: number, isForgot?: boolean }> = ({className = '', steps = [], currentStep = 0, isRego = false, isForgot = false}) => {
  let imgBg = loginBg;
  if(isRego) {
    imgBg = regoBg;
  }

  if(isForgot) {
    imgBg = forgotBg;
  }
    return ( 
        <Col xs={0} sm={0} md={16} lg={16} xl={11} className='noMobile padding30px verticalBottom fullHeight landingBg' style={{backgroundImage: 'url('+imgBg+')'}}>
            <div className='padding30px'>
              <CellnetLogo className="marginBottom30px" white/>
              <Title level={2} className='whiteText'>Streamline Your Stock<br/>Replenishing By Ordering Online.</Title>
              {
                steps && currentStep ? 
                  (
                    <>
                    <Progress className='width100px' percent={(100 / steps.length) * currentStep} showInfo={false} strokeLinecap='square' strokeColor='#fff' trailColor='#FFFFFF80' strokeWidth={3}></Progress>
                    <span className='whiteText marginLeft15px fontSize16px'>{('0' + currentStep).slice(-2)} - {('0' + steps.length).slice(-2)}</span>
                    </>
                  )
                : ''
              }
            </div>
        </Col>
    )
  }


export default HomeBg;
