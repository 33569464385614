import React from 'react';
import { Row, Col, Transfer, Tabs, Button, Select } from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { ProductContext } from '../../../contexts/ProductContext';
import {priceListDownload} from '../../../Constants';

const { TabPane } = Tabs;
const { Option } = Select;
interface ItemProps {
    label: string;
    value: string;
    key: string;
}

const ImportPriceList: React.FC = () => {
    const {productState} = React.useContext(ProductContext);
    const [selectedBrand, setSelectedBrand] = React.useState<string[]>([]);
    const [selectedCate, setSelectedCate] = React.useState<string[]>([]);
    const [selectedVendor, setSelectedVendor] = React.useState<string[]>([]);

    const [brands, setBrands] = React.useState<ItemProps[]>([]);
    const [cates, setCates] = React.useState<ItemProps[]>([]);
    //const [vendors, setVendors] = React.useState<any[]>([]);

    const [byType, setByType] = React.useState<'byBrand' | 'byCategory'>('byBrand');

    React.useEffect(() => {
        if(productState.brands) {
            
            const brandData = [] as ItemProps[];
            const selectedBrand = [] as string[];
            productState.brands.forEach(e => {
                brandData.push({
                    label: e.name,
                    value: e.name,
                    key: e.name
                })
                selectedBrand.push(e.name);
            });
            setSelectedBrand(selectedBrand);
            setBrands(brandData);
        }

        if(productState.categories) {
            const cateData = [] as ItemProps[];
            const selectedCate = [] as string[];
            productState.categories.forEach(e => {
                cateData.push({
                    label: e.name,
                    value: e.name,
                    key: e.name
                })

                selectedCate.push(e.name);
            });

            setCates(cateData);
            setSelectedCate(selectedCate);
        }

        /*if(productState.deviceVendors) {
            const vendorData = [] as any[];
            const selectedVendor = [] as string[];
            productState.deviceVendors.forEach(e => {
                vendorData.push({
                    key: e.name,
                    title: e.name,
                    description: e.name
                })

                selectedVendor.push(e.name);
            });

            setVendors(vendorData);
            setSelectedVendor(selectedVendor);
        }*/
    }, [productState.categories, productState.deviceVendors])
    
    const handleChangeBrand = (targetKeys: string[]) => {
        setSelectedBrand(targetKeys)
    }

    const handleChangeCate = (targetKeys: string[]) => {
        setSelectedCate(targetKeys)
    }

    const handleOnChangeFormat = (value: 'byBrand' | 'byCategory') => {
        setByType(value);
    }

    const handleSelectAllCate = () => {
        const selectedCate = [] as string[];
        productState.categories.forEach(e => {
            selectedCate.push(e.name);
        });
        setSelectedCate(selectedCate);
    }

    const handleSelectAllBrand = () => {
        const selectedBrand = [] as string[];
        productState.brands.forEach(e => {
            selectedBrand.push(e.name);
        });
        setSelectedBrand(selectedBrand);
    }


    const selectProps = {
        mode: 'multiple' as const,
        style: { width: '100%' }
    };

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <MobileAccountMenu site='Manage Users'/>
                <div className='accountSection fontSize16px mobileMarginBottom20px noBackGroundDesktop'>
                    <Row gutter={30}>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0} >
                            <h4><strong>Categories</strong></h4>
                            <Select showSearch allowClear {...selectProps} maxTagCount={5} value={selectedCate} options={cates} placeholder='Select categories...' onChange={handleChangeCate}/>
                            <Button type="primary" onClick={handleSelectAllCate} className='marginTop20px'>
                                Select All Categories
                            </Button>

                            <h4 className='marginTop25px'><strong>Brands</strong></h4>
                            <Select showSearch allowClear {...selectProps} maxTagCount={5} value={selectedBrand} options={brands} placeholder='Select brands...' onChange={handleChangeBrand}/>

                            <Button type="primary" onClick={handleSelectAllBrand} className='marginTop20px'>
                                Select All Brands
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col xs={0} sm={0} md={24} lg={24} xl={24}>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Categories" key="2">
                                    <Transfer
                                        dataSource={cates}
                                        showSearch
                                        targetKeys={selectedCate}
                                        onChange={handleChangeCate}
                                        render={item => `${item.label}`}
                                        titles={['', 'Selected']}
                                        //footer={this.renderFooter}
                                    />
                                </TabPane>
                                <TabPane tab="Brands" key="3">
                                    <Transfer
                                        dataSource={brands}
                                        showSearch
                                        targetKeys={selectedBrand}
                                        onChange={handleChangeBrand}
                                        render={item => `${item.label}`}
                                        titles={['', 'Selected']}
                                        //footer={this.renderFooter}
                                    />
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                    <Row gutter={30} className='marginTop25px'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <span className='marginRight20px'>Format By: </span>
                            <Select
                                value={byType}
                                placeholder="Select a format"
                                style={{ width: '120px' }}
                                onChange={handleOnChangeFormat}
                            >
                                <Option value="byBrand">Brands</Option>
                                <Option value="byCategory">Categories</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row gutter={30} className='marginTop25px'>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <form id="priceListForm" className='marginBottom25px' target="_blank" method="post" action={priceListDownload}>
                            <input name='format' type='hidden' value={byType}/>
                            <select name="category" multiple={true} size={30}  id="procurementGroupsInc" style={{width: "100%", height: "400px", display: 'none'}}>
                                {
                                    selectedCate.map((value, key) => (
                                        <option key={key} value={value} selected>{value}</option>
                                    ))
                                }
                            </select>

                            <select name="vendor" multiple={true} size={30}  id="vendorsInc" style={{width: "100%", height: "400px", display: 'none'}}>
                                {
                                    selectedBrand.map((value, key) => (
                                        <option key={key} value={value} selected>{value}</option>
                                    ))
                                }
                            </select>
                            
                            <Button htmlType='submit' type="primary">
                                Download Pricelist
                            </Button>
                        </form>
                        </Col>
                    </Row>
                </div>
            </div>
        </MainLayout>
  );
}

export default ImportPriceList;
