import React from 'react';
import { Button } from 'antd';

const BackToTop: React.FC<{className?: string}> = ({className = ''}) => {

    const handleScrollTop = () => {
        window.scroll({top: 0, behavior: 'smooth'});
    }
    
    return (
        <Button type="primary" onClick={handleScrollTop} block className={className + ' uppercase marginTop25px'}>Back To Top</Button>
    )
}

export default BackToTop;
